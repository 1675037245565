import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";
import {associationActions} from "../../Hoa/association/association";


export const subdivisionAction = {
    getAllData,
    submitSubdivisionAction,
    updateSubdivisionAction,
    getSubdivisionByIdAction,
    getSubdivisionNoteAction,
    getSubdivisionByManagementCompanyAction,
    deleteSubdivisionNoteAction,
    createSubdivisionNoteAction,
    getSubdivisionDocumentAction,
    exportData,
    addAssociationAction,
    getSubdivisionByNameAndCountyAction,
    deleteSubdivisionDeleteAction,
    getSubdivisionWithComputedNameByIdAction,
    deleteSubdivisionAssociationAction,
    deleteBulkSubdivisionAction,
    updateSubdivisionNoteAction
};


export const subDivisionConstant = {
    GET_ALL_SUB_DIVISION_REQUEST: 'GET_ALL_SUB_DIVISION_REQUEST',
    GET_ALL_SUB_DIVISION_SUCCESS: 'GET_ALL_SUB_DIVISION_SUCCESS',
    GET_ALL_SUB_DIVISION_FAILURE: 'GET_ALL_SUB_DIVISION_FAILURE',

    CREATE_SUB_DIVISION_REQUEST: 'CREATE_SUB_DIVISION_REQUEST',
    CREATE_SUB_DIVISION_SUCCESS: 'CREATE_SUB_DIVISION_SUCCESS',
    CREATE_SUB_DIVISION_FAILURE: 'CREATE_SUB_DIVISION_FAILURE',

    UPDATE_SUB_DIVISION_REQUEST: 'UPDATE_SUB_DIVISION_REQUEST',
    UPDATE_SUB_DIVISION_SUCCESS: 'UPDATE_SUB_DIVISION_SUCCESS',
    UPDATE_SUB_DIVISION_FAILURE: 'UPDATE_SUB_DIVISION_FAILURE',

    GET_SUB_DIVISION_BY_ID_REQUEST: 'GET_SUB_DIVISION_BY_ID_REQUEST',
    GET_SUB_DIVISION_BY_ID_SUCCESS: 'GET_SUB_DIVISION_BY_ID_SUCCESS',
    GET_SUB_DIVISION_BY_ID_FAILURE: 'GET_SUB_DIVISION_BY_ID_FAILURE',

    GET_SUBDIVISIONS_NOTES_REQUEST: 'GET_SUBDIVISIONS_NOTES_REQUEST',
    GET_SUBDIVISIONS_NOTES_SUCCESS: 'GET_SUBDIVISIONS_NOTES_SUCCESS',
    GET_SUBDIVISIONS_NOTES_FAILURE: 'GET_SUBDIVISIONS_NOTES_FAILURE',

    DELETE_SUBDIVISION_NOTE_REQUEST: 'DELETE_SUBDIVISION_NOTE_REQUEST',
    DELETE_SUBDIVISION_NOTE_SUCCESS: 'DELETE_SUBDIVISION_NOTE_SUCCESS',
    DELETE_SUBDIVISION_NOTE_FAILURE: 'DELETE_SUBDIVISION_NOTE_FAILURE',

    CREATE_SUBDIVISION_NOTE_REQUEST: 'CREATE_SUBDIVISION_NOTE_REQUEST',
    CREATE_SUBDIVISION_NOTE_SUCCESS: 'CREATE_SUBDIVISION_NOTE_SUCCESS',
    CREATE_SUBDIVISION_NOTE_FAILURE: 'CREATE_SUBDIVISION_NOTE_FAILURE',

    UPDATE_SUBDIVISION_NOTE_REQUEST: 'UPDATE_SUBDIVISION_NOTE_REQUEST',
    UPDATE_SUBDIVISION_NOTE_SUCCESS: 'UPDATE_SUBDIVISION_NOTE_SUCCESS',
    UPDATE_SUBDIVISION_NOTE_FAILURE: 'UPDATE_SUBDIVISION_NOTE_FAILURE',

    GET_SUBDIVISION_DOCUMENT_LIST_REQUEST: 'GET_SUBDIVISION_DOCUMENT_LIST_REQUEST',
    GET_SUBDIVISION_DOCUMENT_LIST_SUCCESS: 'GET_SUBDIVISION_DOCUMENT_LIST_SUCCESS',
    GET_SUBDIVISION_DOCUMENT_LIST_FAILURE: 'GET_SUBDIVISION_DOCUMENT_LIST_FAILURE',

    EXPORT_SUB_DIVISION_DATA_REQUEST: 'EXPORT_SUB_DIVISION_DATA_REQUEST',
    EXPORT_SUB_DIVISION_DATA_SUCCESS: 'EXPORT_SUB_DIVISION_DATA_SUCCESS',
    EXPORT_SUB_DIVISION_DATA_FAILURE: 'EXPORT_SUB_DIVISION_DATA_FAILURE',

    ADD_ASSOCIATION_REQUEST: 'ADD_ASSOCIATION_REQUEST',
    ADD_ASSOCIATION_SUCCESS: 'ADD_ASSOCIATION_SUCCESS',
    ADD_ASSOCIATION_FAILURE: 'ADD_ASSOCIATION_FAILURE',


    DELETE_SUBDIVISION_ASSOCIATION_REQUEST: 'DELETE_SUBDIVISION_ASSOCIATION_REQUEST',
    DELETE_SUBDIVISION_ASSOCIATION_SUCCESS: 'DELETE_SUBDIVISION_ASSOCIATION_SUCCESS',
    DELETE_SUBDIVISION_ASSOCIATION_FAILURE: 'DELETE_SUBDIVISION_ASSOCIATION_FAILURE',

    GET_SUB_DIVISION_BY_NAME_AND_COUNTY_REQUEST: 'GET_SUB_DIVISION_BY_NAME_AND_COUNTY_REQUEST',
    GET_SUB_DIVISION_BY_NAME_AND_COUNTY_SUCCESS: 'GET_SUB_DIVISION_BY_NAME_AND_COUNTY_SUCCESS',
    GET_SUB_DIVISION_BY_NAME_AND_COUNTY_FAILURE: 'GET_SUB_DIVISION_BY_NAME_AND_COUNTY_FAILURE',
    GET_SUB_DIVISION_BY_NAME_AND_COUNTY_REST: 'GET_SUB_DIVISION_BY_NAME_AND_COUNTY_REST',

    DELETE_SUB_DIVISION_REQUEST: 'DELETE_SUB_DIVISION_REQUEST',
    DELETE_SUB_DIVISION_SUCCESS: 'DELETE_SUB_DIVISION_SUCCESS',
    DELETE_SUB_DIVISION_FAILURE: 'DELETE_SUB_DIVISION_FAILURE',

    GET_SUB_DIVISION_NAME_BY_ID_REQUEST: 'GET_SUB_DIVISION_NAME_BY_ID_REQUEST',
    GET_SUB_DIVISION_NAME_BY_ID_SUCCESS: 'GET_SUB_DIVISION_NAME_BY_ID_SUCCESS',
    GET_SUB_DIVISION_NAME_BY_ID_FAILURE: 'GET_SUB_DIVISION_NAME_BY_ID_FAILURE',


    DELETE_BULK_SUB_DIVISION_REQUEST: 'DELETE_BULK_SUB_DIVISION_REQUEST',
    DELETE_BULK_SUB_DIVISION_SUCCESS: 'DELETE_BULK_SUB_DIVISION_SUCCESS',
    DELETE_BULK_SUB_DIVISION_FAILURE: 'DELETE_BULK_SUB_DIVISION_FAILURE',

    GET_SUB_DIVISIONS_BY_MANAGEMENT_COMPANY_ID_REQUEST: 'GET_SUB_DIVISIONS_BY_MANAGEMENT_COMPANY_ID_REQUEST',
    GET_SUB_DIVISIONS_BY_MANAGEMENT_COMPANY_ID_SUCCESS: 'GET_SUB_DIVISIONS_BY_MANAGEMENT_COMPANY_ID_SUCCESS',
    GET_SUB_DIVISIONS_BY_MANAGEMENT_COMPANY_ID_FAILURE: 'GET_SUB_DIVISIONS_BY_MANAGEMENT_COMPANY_ID_FAILURE',

};


//export service
export const subDivisionService = {
    getAllSubDivision,
    createSubdivision,
    updateSubdivision,
    getSubdivisionById,
    getSubdivisionByManagementCompanyService,
    getSubdivisionNotesService,
    createSubdivisionNoteService,
    updateSubdivisionNoteService,
    getSubdivisionDocuments,
    exportSubdivisionData,
    addAssociation,
    getSubdivisionByNameAndCounty,
    deleteSubdivisionById,
    deleteSubdivisionNoteById,
    getSubdivisionWithComputedNameById,
    deleteSubdivisionAssociation,
    deleteBulkSubdivision
};


/******************************** SuDivision Actions Starts here  ****************************************/


// get All Subdivision Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());
        subDivisionService.getAllSubDivision(query)
            .then(
                subDivisions => dispatch(success(subDivisions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: subDivisionConstant.GET_ALL_SUB_DIVISION_REQUEST}
    }

    function success(subDivisions) {
        return {type: subDivisionConstant.GET_ALL_SUB_DIVISION_SUCCESS, subDivisions}
    }

    function failure(error) {
        return {type: subDivisionConstant.GET_ALL_SUB_DIVISION_FAILURE, error}
    }
}


//create subdivision  Action
function submitSubdivisionAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        subDivisionService.createSubdivision(obj)
            .then(
                subdivision => {
                    if (subdivision['errorCode'] === 200) {
                        showSuccessToast("Subdivision added successfully!");
                        dispatch(success(subdivision));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(subdivision);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(subdivision) {
        return {type: subDivisionConstant.CREATE_SUB_DIVISION_REQUEST, subdivision}
    }

    function success(subdivision) {
        return {type: subDivisionConstant.CREATE_SUB_DIVISION_SUCCESS, subdivision}
    }

    function failure(error) {
        return {type: subDivisionConstant.CREATE_SUB_DIVISION_FAILURE, error}
    }
}


// get Subdivision By Id Action
function getSubdivisionByIdAction(subdivisionId) {
    return dispatch => {
        dispatch(request());
        subDivisionService.getSubdivisionById(subdivisionId)
            .then(
                subDivision => dispatch(success(subDivision)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: subDivisionConstant.GET_SUB_DIVISION_BY_ID_REQUEST}
    }

    function success(subDivision) {
        return {type: subDivisionConstant.GET_SUB_DIVISION_BY_ID_SUCCESS, subDivision}
    }

    function failure(error) {
        return {type: subDivisionConstant.GET_SUB_DIVISION_BY_ID_FAILURE, error}
    }
}

// get Subdivision  name By Id Action
function getSubdivisionWithComputedNameByIdAction(subdivisionId) {
    return dispatch => {
        dispatch(request());
        subDivisionService.getSubdivisionWithComputedNameById(subdivisionId)
            .then(
                subDivisionName => dispatch(success(subDivisionName)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: subDivisionConstant.GET_SUB_DIVISION_NAME_BY_ID_REQUEST}
    }

    function success(subDivisionName) {
        return {type: subDivisionConstant.GET_SUB_DIVISION_NAME_BY_ID_SUCCESS, subDivisionName}
    }

    function failure(error) {
        return {type: subDivisionConstant.GET_SUB_DIVISION_NAME_BY_ID_FAILURE, error}
    }
}


//update subdivision action
function updateSubdivisionAction(obj, subdivisionId, countyId, setCountyId, setDisable, setEditable) {
    return dispatch => {
        dispatch(request());
        subDivisionService.updateSubdivision(obj, subdivisionId, countyId)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        setCountyId(null);
                        showSuccessToast("Subdivision updated successfully!");
                        dispatch(success(data));
                    } else {
                        responseDTOExceptionHandler(data);
                    }
                    setDisable(false)
                    setEditable(false)
                    dispatch(getSubdivisionByIdAction(subdivisionId));
                },
                error => {
                    dispatch(getSubdivisionByIdAction(subdivisionId));
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: subDivisionConstant.UPDATE_SUB_DIVISION_REQUEST}
    }

    function success(data) {
        return {type: subDivisionConstant.UPDATE_SUB_DIVISION_SUCCESS, data}
    }

    function failure(error) {
        return {type: subDivisionConstant.UPDATE_SUB_DIVISION_FAILURE, error}
    }
}

function getSubdivisionByNameAndCountyAction(subdivisionName, county, targetCerts, isReset = false, setDisable = null, setShow = null, id = null, accountNumber = null) {
    return dispatch => {
        if (isReset === false) {
            dispatch(request());
            subDivisionService.getSubdivisionByNameAndCounty(subdivisionName, county, id, accountNumber, targetCerts)
                .then(
                    data => {
                        dispatch(success(data));
                        if (setDisable != null) {
                            setDisable(false);
                        }
                        if (setShow != null) {
                            setShow(false);
                        }
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                );
        } else {
            dispatch(rest());
        }
    };

    function request() {
        return {type: subDivisionConstant.GET_SUB_DIVISION_BY_NAME_AND_COUNTY_REQUEST}
    }

    function success(subDivision) {
        return {type: subDivisionConstant.GET_SUB_DIVISION_BY_NAME_AND_COUNTY_SUCCESS, subDivision}
    }

    function failure(error) {
        return {type: subDivisionConstant.GET_SUB_DIVISION_BY_NAME_AND_COUNTY_FAILURE, error}
    }

    function rest() {
        return {type: subDivisionConstant.GET_SUB_DIVISION_BY_NAME_AND_COUNTY_REST}
    }
}


// get All Subdivision Notes
function getSubdivisionNoteAction(subdivisionId) {
    return dispatch => {
        dispatch(request());

        subDivisionService.getSubdivisionNotesService(subdivisionId)
            .then(
                notes => dispatch(success(notes)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: subDivisionConstant.GET_SUBDIVISIONS_NOTES_REQUEST}
    }

    function success(notes) {
        return {type: subDivisionConstant.GET_SUBDIVISIONS_NOTES_SUCCESS, notes}
    }

    function failure(error) {
        return {type: subDivisionConstant.GET_SUBDIVISIONS_NOTES_FAILURE, error}
    }

}

// create Subdivision Add Note
function createSubdivisionNoteAction(subdivisionId, obj, setDisable, setShow) {
    return dispatch => {
        dispatch(request(obj));
        subDivisionService.createSubdivisionNoteService(subdivisionId, obj)
            .then(
                note => {
                    if (note['errorCode'] === 200) {
                        showSuccessToast("Note created successfully!");
                        dispatch(success(note));
                        dispatch(getSubdivisionNoteAction(subdivisionId))
                        setShow(false)
                    } else {
                        responseDTOExceptionHandler(note);
                    }
                    setDisable(false)
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(note) {
        return {type: subDivisionConstant.CREATE_SUBDIVISION_NOTE_REQUEST, note}
    }

    function success(note) {
        return {type: subDivisionConstant.CREATE_SUBDIVISION_NOTE_SUCCESS, note}
    }

    function failure(error) {
        return {type: subDivisionConstant.CREATE_SUBDIVISION_NOTE_FAILURE, error}
    }
}


// create Subdivision Add Note
function updateSubdivisionNoteAction(subdivisionId, obj, setDisable, setShow, noteId) {
    return dispatch => {
        dispatch(request(obj));
        subDivisionService.updateSubdivisionNoteService(noteId, obj)
            .then(
                note => {
                    if (note['errorCode'] === 200) {
                        showSuccessToast("Note updated successfully!");
                        dispatch(success(note));
                        dispatch(getSubdivisionNoteAction(subdivisionId))
                        setShow(false)
                    } else {
                        responseDTOExceptionHandler(note);
                    }
                    setDisable(false)
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(note) {
        return {type: subDivisionConstant.UPDATE_SUBDIVISION_NOTE_REQUEST, note}
    }

    function success(note) {
        return {type: subDivisionConstant.UPDATE_SUBDIVISION_NOTE_SUCCESS, note}
    }

    function failure(error) {
        return {type: subDivisionConstant.UPDATE_SUBDIVISION_NOTE_FAILURE, error}
    }
}


//get subdivision documents action
function getSubdivisionDocumentAction(subdivisionId) {
    return dispatch => {
        dispatch(request());
        subDivisionService.getSubdivisionDocuments(subdivisionId)
            .then(
                docs => dispatch(success(docs)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: subDivisionConstant.GET_SUBDIVISION_DOCUMENT_LIST_REQUEST}
    }

    function success(docs) {
        return {type: subDivisionConstant.GET_SUBDIVISION_DOCUMENT_LIST_SUCCESS, docs}
    }

    function failure(error) {
        return {type: subDivisionConstant.GET_SUBDIVISION_DOCUMENT_LIST_FAILURE, error}
    }
}


//export Subdivision Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        subDivisionService.exportSubdivisionData(query)
            .then(
                subdivisionData => dispatch(success(subdivisionData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: subDivisionConstant.EXPORT_SUB_DIVISION_DATA_REQUEST}
    }

    function success(subdivisionData) {
        query.setExportBtn(false);
        return {type: subDivisionConstant.EXPORT_SUB_DIVISION_DATA_SUCCESS, subdivisionData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: subDivisionConstant.EXPORT_SUB_DIVISION_DATA_FAILURE, error}
    }
}


//ADD Association Form
function addAssociationAction(associationId, subdivisionId, setDisable, setShow, formType) {
    return dispatch => {
        dispatch(request({associationId}));
        subDivisionService.addAssociation(associationId, subdivisionId)
            .then(
                subdivision => {
                    if (subdivision['errorCode'] === 200) {
                        if (formType !== "subdivision") {
                            showSuccessToast("Association Added Successfully!");
                        } else {
                            showSuccessToast("Subdivision Added Successfully!");
                        }
                        dispatch(success(subdivision));
                        if (formType !== "subdivision") {
                            dispatch(getSubdivisionByIdAction(subdivisionId));
                        } else {
                            dispatch(associationActions.getAssociationByIdAction(associationId));
                        }
                        setDisable(false);
                        setShow(false);
                    } else {
                        responseDTOExceptionHandler(subdivision);
                        setDisable(false);
                        setShow(false);
                    }
                    setShow(false);
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    setShow(false);
                    setDisable(false);
                }
            );
    };

    function request(associationId) {
        return {type: subDivisionConstant.ADD_ASSOCIATION_REQUEST, associationId}
    }

    function success(subdivision) {
        return {type: subDivisionConstant.ADD_ASSOCIATION_SUCCESS, subdivision}
    }

    function failure(error) {
        return {type: subDivisionConstant.ADD_ASSOCIATION_FAILURE, error}
    }

}


function deleteSubdivisionAssociationAction(associationId, subdivisionId, setDisable, setShow, formType) {
    return dispatch => {
        dispatch(request({associationId}));
        subDivisionService.deleteSubdivisionAssociation(associationId, subdivisionId)
            .then(
                subdivision => {
                    if (subdivision['errorCode'] === 200) {
                        if (formType !== "subdivision") {
                            showSuccessToast("Association Deleted Successfully!");
                        } else {
                            showSuccessToast("Subdivision Deleted Successfully!");
                        }
                        dispatch(success(subdivision));
                        if (formType !== "subdivision") {
                            dispatch(getSubdivisionByIdAction(subdivisionId));
                        } else {
                            dispatch(associationActions.getAssociationByIdAction(associationId));
                        }
                        setDisable(false);
                        setShow(false);
                    } else {
                        responseDTOExceptionHandler(subdivision);
                        setDisable(false);
                        setShow(false);
                    }
                    setShow(false);
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    setShow(false);
                    setDisable(false);
                }
            );
    };

    function request(associationId) {
        return {type: subDivisionConstant.DELETE_SUBDIVISION_ASSOCIATION_REQUEST, associationId}
    }

    function success(subdivision) {
        return {type: subDivisionConstant.DELETE_SUBDIVISION_ASSOCIATION_SUCCESS, subdivision}
    }

    function failure(error) {
        return {type: subDivisionConstant.DELETE_SUBDIVISION_ASSOCIATION_FAILURE, error}
    }

}


function deleteBulkSubdivisionAction(query) {
    return dispatch => {
        dispatch(request({query}));
        subDivisionService.deleteBulkSubdivision(query?.deleteList)
            .then(
                subdivision => {
                    if (subdivision['errorCode'] === 200) {
                        showSuccessToast("Subdivisions deleted successfully!");
                        dispatch(success(subdivision));
                        dispatch(getAllData(query));
                        query.setDeleteItemList([]);
                    } else {
                        responseDTOExceptionHandler(subdivision);
                    }
                    query.setShowBulkDeleteConfirmation(false);
                    query.setDisableDelete(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    query.setShowBulkDeleteConfirmation(false);
                    query.setDisableDelete(false);
                }
            );
    };

    function request(subdivisionIds) {
        return {type: subDivisionConstant.DELETE_BULK_SUB_DIVISION_REQUEST, subdivisionIds}
    }

    function success(subdivisionIds) {
        return {type: subDivisionConstant.DELETE_BULK_SUB_DIVISION_SUCCESS, subdivisionIds}
    }

    function failure(error) {
        return {type: subDivisionConstant.DELETE_BULK_SUB_DIVISION_FAILURE, error}
    }

}


//delete subdivision Action
function deleteSubdivisionDeleteAction(deleteQuery) {
    return dispatch => {
        dispatch(request());
        subDivisionService.deleteSubdivisionById(deleteQuery.deleteReference)
            .then(
                subdivision => {
                    if (subdivision['errorCode'] === 200) {
                        showSuccessToast("Subdivision deleted successfully!");
                        dispatch(success(subdivision));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(subdivision);
                    }
                    deleteQuery.setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: subDivisionConstant.DELETE_SUB_DIVISION_REQUEST,}
    }

    function success(subdivision) {
        return {type: subDivisionConstant.DELETE_SUB_DIVISION_SUCCESS, subdivision}
    }

    function failure(error) {
        return {type: subDivisionConstant.DELETE_SUB_DIVISION_FAILURE, error}
    }
}

function deleteSubdivisionNoteAction(noteId, setDisable, setShowDeleteConfirm, subdivisionId) {
    return dispatch => {
        dispatch(request());
        subDivisionService.deleteSubdivisionNoteById(noteId)
            .then(
                subdivision => {
                    if (subdivision['errorCode'] === 200) {
                        dispatch(subdivisionAction.getSubdivisionByIdAction(subdivisionId));
                        showSuccessToast("Subdivision note deleted successfully!");
                        dispatch(success(subdivision));
                        setShowDeleteConfirm(false);
                    } else {
                        responseDTOExceptionHandler(subdivision);
                    }
                    setDisable(false)
                },
                error => {
                    setDisable(false)
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: subDivisionConstant.DELETE_SUBDIVISION_NOTE_REQUEST,}
    }

    function success(subdivision) {
        return {type: subDivisionConstant.DELETE_SUBDIVISION_NOTE_SUCCESS, subdivision}
    }

    function failure(error) {
        return {type: subDivisionConstant.DELETE_SUBDIVISION_NOTE_FAILURE, error}
    }
}

function getSubdivisionByManagementCompanyAction(query) {
    return dispatch => {
        dispatch(request());
        subDivisionService.getSubdivisionByManagementCompanyService(query)
            .then(
                subDivisions => dispatch(success(subDivisions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: subDivisionConstant.GET_SUB_DIVISIONS_BY_MANAGEMENT_COMPANY_ID_REQUEST}
    }

    function success(subDivisions) {
        return {type: subDivisionConstant.GET_SUB_DIVISIONS_BY_MANAGEMENT_COMPANY_ID_SUCCESS, subDivisions}
    }

    function failure(error) {
        return {type: subDivisionConstant.GET_SUB_DIVISIONS_BY_MANAGEMENT_COMPANY_ID_FAILURE, error}
    }
}

/******************************** SuDivision Actions Ends here  ****************************************/


/************************** Subdivision Reducers starts here. **************************************/

//get all Sub-Division reducer
export function getAllSubDivisionReducer(state = {}, action) {
    switch (action.type) {
        case subDivisionConstant.GET_ALL_SUB_DIVISION_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case subDivisionConstant.GET_ALL_SUB_DIVISION_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.subDivisions
            };
        case subDivisionConstant.GET_ALL_SUB_DIVISION_FAILURE:
            return {};
        default:
            return state
    }
}

export function getSubdivisionByIdReducer(state = {}, action) {
    switch (action.type) {
        case subDivisionConstant.GET_SUB_DIVISION_BY_ID_REQUEST:
            return {

                loading: true,
                loggingIn: true,
            };
        case subDivisionConstant.GET_SUB_DIVISION_BY_ID_SUCCESS:
            return {
                loading: false,
                loggedIn: true,
                items: action.subDivision
            };
        case subDivisionConstant.GET_SUB_DIVISION_BY_ID_FAILURE:
            return {
                loading: false,
            };
        default:
            return state
    }
}

// CollectorNote
export function getAllSubdivisionNotesBySubdivisionIdReducer(state = {}, action) {
    switch (action.type) {
        case subDivisionConstant.GET_SUBDIVISIONS_NOTES_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case subDivisionConstant.GET_SUBDIVISIONS_NOTES_SUCCESS:
            return {
                loggedIn: true,
                items: action.notes
            };
        case subDivisionConstant.GET_SUBDIVISIONS_NOTES_FAILURE:
            return {};
        default:
            return state
    }
}


export function getSubdivisionDocumentListReducer(state = {}, action) {
    switch (action.type) {
        case subDivisionConstant.GET_SUBDIVISION_DOCUMENT_LIST_REQUEST:
            return {};

        case subDivisionConstant.GET_SUBDIVISION_DOCUMENT_LIST_SUCCESS:
            return {
                loggedIn: true,
                items: action.docs
            };
        case subDivisionConstant.GET_SUBDIVISION_DOCUMENT_LIST_FAILURE:
            return {};

        default:
            return state
    }
}


export function getSubdivisionNameByIdReducer(state = {}, action) {
    switch (action.type) {
        case subDivisionConstant.GET_SUB_DIVISION_NAME_BY_ID_REQUEST:
            return {

                loading: true,
                loggingIn: true,
            };
        case subDivisionConstant.GET_SUB_DIVISION_NAME_BY_ID_SUCCESS:
            return {
                loading: false,
                loggedIn: true,
                items: action.subDivisionName
            };
        case subDivisionConstant.GET_SUB_DIVISION_NAME_BY_ID_FAILURE:
            return {
                loading: false,
            };
        default:
            return state
    }
}


export function getSubdivisionByNameAndCountyReducer(state = {}, action) {
    switch (action.type) {
        case subDivisionConstant.GET_SUB_DIVISION_BY_NAME_AND_COUNTY_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case subDivisionConstant.GET_SUB_DIVISION_BY_NAME_AND_COUNTY_SUCCESS:
            return {
                loggedIn: true,
                loading: true,
                items: action.subDivision
            };
        case subDivisionConstant.GET_SUB_DIVISION_BY_NAME_AND_COUNTY_FAILURE:
            return {};
        case subDivisionConstant.GET_SUB_DIVISION_BY_NAME_AND_COUNTY_REST:
            return {};
        default:
            return state
    }
}

export function getAllSubDivisionByManagmentCompanyReducer(state = {}, action) {
    switch (action.type) {
        case subDivisionConstant.GET_SUB_DIVISIONS_BY_MANAGEMENT_COMPANY_ID_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case subDivisionConstant.GET_SUB_DIVISIONS_BY_MANAGEMENT_COMPANY_ID_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.subDivisions
            };
        case subDivisionConstant.GET_SUB_DIVISIONS_BY_MANAGEMENT_COMPANY_ID_FAILURE:
            return {};
        default:
            return state
    }
}


/************************** Subdivision Reducers ends here. **************************************/


/******************************subDivision Services starts here  ****************************************/
//get all Sub-Division
export function getAllSubDivision(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/sub-division?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

//create subdivision service api
export function createSubdivision(subdivisionObj) {
    return axios.post(`/ajax/create-subdivision/${subdivisionObj.countyId}`, subdivisionObj).then(
        (response) => {
            return response.data;
        }
    )
}


//get Sub-Division  by id
export function getSubdivisionById(subdivisionId) {
    return axios.get(`/ajax/get-subdivision-by-id/${subdivisionId}`).then(
        (response) => {
            return response.data;
        })
}


//get Sub-Division name by id
export function getSubdivisionWithComputedNameById(subdivisionId) {
    return axios.get(`/ajax/get-subdivision-name-by-id/${subdivisionId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

//update subdivision service api
export function updateSubdivision(subdivisionObj, subdivisionId, countyId) {
    return axios.put(`/ajax/update-subdivision/${subdivisionId}/${countyId}`, subdivisionObj).then(
        (response) => {
            return response.data;
        }
    )
}

// get subdivisions notes
export function getSubdivisionNotesService(subdivisionId) {
    return axios.get(`/ajax/get-subdivision-note-by-subdivision-id/${subdivisionId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


// create subdivision Note
export function createSubdivisionNoteService(subdivisionId, obj) {
    return axios.post(`/ajax/create-subdivision-note/${subdivisionId}/notes`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function updateSubdivisionNoteService(noteId, obj) {
    return axios.put(`/ajax/update-subdivision-note/${noteId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


export function getSubdivisionDocuments(subdivisionId) {
    return axios.get(`/ajax/get-subdivision-document-list/${subdivisionId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


//export subdivision Data Service
export async function exportSubdivisionData(query) {
    let paramData = serialize(query)
    return axios.post(`/export/subdivision-data?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "subdivisionData");
            }
        })
        .catch(handleExportError);
}


// add association for subdivision
export function addAssociation(associationId, subdivisionId) {
    return axios.put(`/ajax/add/subdivision/association/${associationId}/${subdivisionId}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error)
        })
}


export function deleteSubdivisionAssociation(associationId, subdivisionId) {
    return axios.delete(`/ajax/delete/subdivision/association/${associationId}/${subdivisionId}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error)
        })
}


export function deleteBulkSubdivision(subdivisionIds) {

    let subdivisionIdsList = {subdivisionIds: subdivisionIds};
    let paramData = serialize(subdivisionIdsList)
    return axios.delete(`/ajax/delete/bulk/subdivision?${paramData}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error)
        })
}

export function getSubdivisionByNameAndCounty(subdivisionName, county, id, accountNumber, targetCerts) {
    let query = {
        id: id,
        name: subdivisionName,
        countyId: county,
        accountNumber: accountNumber,
        targetCerts: targetCerts
    }
    let paramData = serialize(query);
    return axios.get(`/ajax/get-subdivision-by-name?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function deleteSubdivisionById(id) {
    return axios.delete(`/ajax/delete-subdivision-by-id/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export async function deleteSubdivisionNoteById(id) {
    return axios.delete(`/ajax/delete-subdivision-note-by-id/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}
export function getSubdivisionByManagementCompanyService(query) {
    let paramData = serialize(query);
    return axios.get(`/ajax/get-subdivision-by-association/management-company?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}





