import axios from "axios";
import exceptionHandler from "../../../common/helpers/exceptionHandler";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";
import {showInfoToast} from "../../../common/components/Toast";

export const appraisalRollActions = {
    getAllData,
    getAppraisalRollByIdAction,
    exportData,
    getPrimaryCollectorByStateAndCountyAction
};


//export constants
export const appraisalRollConstant = {
    GET_ALL_APPRAISAL_ROLL_REQUEST: 'GET_ALL_APPRAISAL_ROLL_REQUEST',
    GET_ALL_APPRAISAL_ROLL_SUCCESS: 'GET_ALL_APPRAISAL_ROLL_SUCCESS',
    GET_ALL_APPRAISAL_ROLL_FAILURE: 'GET_ALL_APPRAISAL_ROLL_FAILURE',

    GET_APPRAISAL_ROLL_BY_ID_REQUEST: 'GET_APPRAISAL_ROLL_BY_ID_REQUEST',
    GET_APPRAISAL_ROLL_BY_ID_SUCCESS: 'GET_APPRAISAL_ROLL_BY_ID_SUCCESS',
    GET_APPRAISAL_ROLL_BY_ID_FAILURE: 'GET_APPRAISAL_ROLL_BY_ID_FAILURE',

    GET_PRIMARY_COLLECTOR_BY_STATE_COUNTY_REQUEST: 'GET_PRIMARY_COLLECTOR_BY_STATE_COUNTY_REQUEST',
    GET_PRIMARY_COLLECTOR_BY_STATE_COUNTY_SUCCESS: 'GET_PRIMARY_COLLECTOR_BY_STATE_COUNTY_SUCCESS',
    GET_PRIMARY_COLLECTOR_BY_STATE_COUNTY_FAILURE: 'GET_PRIMARY_COLLECTOR_BY_STATE_COUNTY_FAILURE',

    EXPORT_APPRAISAL_ROLL_DATA_REQUEST: 'EXPORT_APPRAISAL_ROLL_DATA_REQUEST',
    EXPORT_APPRAISAL_ROLL_DATA_SUCCESS: 'EXPORT_APPRAISAL_ROLL_DATA_SUCCESS',
    EXPORT_APPRAISAL_ROLL_DATA_FAILURE: 'EXPORT_APPRAISAL_ROLL_DATA_FAILURE',
}


//export service
export const appraisalRollService = {
    getAllAppraisalRoll,
    getAppraisalRollById,
    exportAppraisalRoll,
    getPrimaryCollectorByStateAndCounty
}


/******************************** Appraisal Roll Actions Starts here  ****************************************/

// get All AppraisalRoll Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());
        appraisalRollService.getAllAppraisalRoll(query)
            .then(
                appraisalRolls => dispatch(success(appraisalRolls)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: appraisalRollConstant.GET_ALL_APPRAISAL_ROLL_REQUEST}
    }

    function success(appraisalRolls) {
        return {type: appraisalRollConstant.GET_ALL_APPRAISAL_ROLL_SUCCESS, appraisalRolls}
    }

    function failure(error) {
        return {type: appraisalRollConstant.GET_ALL_APPRAISAL_ROLL_FAILURE, error}
    }

}


//getTaxRollById Action
function getAppraisalRollByIdAction(id) {
    return dispatch => {
        dispatch(request());
        appraisalRollService.getAppraisalRollById(id)
            .then(
                appraisalRoll => dispatch(success(appraisalRoll)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: appraisalRollConstant.GET_APPRAISAL_ROLL_BY_ID_REQUEST}
    }

    function success(appraisalRoll) {
        return {type: appraisalRollConstant.GET_APPRAISAL_ROLL_BY_ID_SUCCESS, appraisalRoll}
    }

    function failure(error) {
        return {type: appraisalRollConstant.GET_APPRAISAL_ROLL_BY_ID_FAILURE, error}
    }
}


//getTaxRollById Action
function getPrimaryCollectorByStateAndCountyAction(stateAbbr, countyName) {
    return dispatch => {
        dispatch(request());
        appraisalRollService.getPrimaryCollectorByStateAndCounty(stateAbbr, countyName)
            .then(
                primaryCollector => dispatch(success(primaryCollector)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: appraisalRollConstant.GET_PRIMARY_COLLECTOR_BY_STATE_COUNTY_REQUEST}
    }

    function success(primaryCollector) {
        return {type: appraisalRollConstant.GET_PRIMARY_COLLECTOR_BY_STATE_COUNTY_SUCCESS, primaryCollector}
    }

    function failure(error) {
        return {type: appraisalRollConstant.GET_PRIMARY_COLLECTOR_BY_STATE_COUNTY_FAILURE, error}
    }
}

function exportData(query) {
    return dispatch => {
        dispatch(request());
        appraisalRollService.exportAppraisalRoll(query)
            .then(
                appraisalRollData => dispatch(success(appraisalRollData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: appraisalRollConstant.EXPORT_APPRAISAL_ROLL_DATA_REQUEST}
    }

    function success(appraisalRollData) {
        query.setExportBtn(false);
        return {type: appraisalRollConstant.EXPORT_APPRAISAL_ROLL_DATA_SUCCESS, appraisalRollData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: appraisalRollConstant.EXPORT_APPRAISAL_ROLL_DATA_FAILURE, error}
    }

}


/******************************** Appraisal Roll Actions ENDS here  ****************************************/


/************************** Reducers starts here. **************************************/

//get all appraisalRoll reducer
export function getAllAppraisalRollReducer(state = {}, action) {
    switch (action.type) {
        case appraisalRollConstant.GET_ALL_APPRAISAL_ROLL_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case appraisalRollConstant.GET_ALL_APPRAISAL_ROLL_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.appraisalRolls
            };
        case appraisalRollConstant.GET_ALL_APPRAISAL_ROLL_FAILURE:
            return {};
        default:
            return state
    }

}

//get all appraisalRoll reducer
export function getPrimaryCollectorByStateAndCountyReducer(state = {}, action) {
    switch (action.type) {
        case appraisalRollConstant.GET_PRIMARY_COLLECTOR_BY_STATE_COUNTY_REQUEST:
            return {
                loading: true,
                loggingIn: true,
            };
        case appraisalRollConstant.GET_PRIMARY_COLLECTOR_BY_STATE_COUNTY_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.primaryCollector
            };
        case appraisalRollConstant.GET_PRIMARY_COLLECTOR_BY_STATE_COUNTY_FAILURE:
            return {};
        default:
            return state
    }

}

export async function exportAppraisalRoll(query) {
    let paramData = serialize(query)
    return axios.post(`/export/appraisal-roll?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "appraisalRollData");
            }
        })
        .catch(handleExportError);
}


export function getAppraisalRollByIdReducer(state = {}, action) {
    switch (action.type) {
        case appraisalRollConstant.GET_APPRAISAL_ROLL_BY_ID_REQUEST :
            return {
                ...state,
                loggingIn: true,
            };
        case appraisalRollConstant.GET_APPRAISAL_ROLL_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.appraisalRoll
            };
        case appraisalRollConstant.GET_APPRAISAL_ROLL_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

/************************** Reducers ends here. **************************************/


/******************************appraisalRoll Services starts here  ****************************************/
//get all appraisalRolls
export function getAllAppraisalRoll(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/appraisal-rolls?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


//get all appraisal Roll by id
export function getAppraisalRollById(id) {
    return axios.get(`/ajax/get-appraisal-roll-by-id/` + id).then(
        (response) => {
            return response.data;
        })
}


//get all appraisal Roll by id
export function getPrimaryCollectorByStateAndCounty(stateAbbr, countyName) {
    let paramData = serialize({stateAbbr: stateAbbr, countyName: countyName})
    return axios.get(`/ajax/primary-collector-by-state-county?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}




/****************************** appraisal Roll Services ends here  ****************************************/




