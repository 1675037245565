import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../common/components/Toast";
import {alertActions} from "../alert/alert";
import {createMergedDashboardQuery, handleExportError, resourceToFile, serialize} from "../../common/helpers/utils";
import React from "react";


export const paymentActions = {
    getPagedPaymentsAction,
    uploadPaymentFileAction,
    exportData,
    toggleDeletedPaymentAction
};

export const paymentService = {
    getAllPaymentsService,
    uploadPaymentFile,
    exportPaymentData,
    toggleBulkDeletedPaymentService
};


export const paymentConstant = {

    GET_ALL_PAYMENT_REQUEST: 'GET_ALL_PAYMENT_REQUEST',
    GET_ALL_PAYMENT_SUCCESS: 'GET_ALL_PAYMENT_SUCCESS',
    GET_ALL_PAYMENT_FAILURE: 'GET_ALL_PAYMENT_FAILURE',

    UPLOAD_PAYMENT_REQUEST: 'UPLOAD_PAYMENT_REQUEST',
    UPLOAD_PAYMENT_SUCCESS: 'UPLOAD_PAYMENT_SUCCESS',
    UPLOAD_PAYMENT_FAILURE: 'UPLOAD_PAYMENT_FAILURE',


    EXPORT_PAYMENT_DATA_REQUEST:'EXPORT_PAYMENT_DATA_REQUEST',
    EXPORT_PAYMENT_DATA_SUCCESS:'EXPORT_PAYMENT_DATA_SUCCESS',
    EXPORT_PAYMENT_DATA_FAILURE:'EXPORT_PAYMENT_DATA_FAILURE',

    TOGGLE_BULK_DELETED_PAYMENT_REQUEST: 'TOGGLE_BULK_DELETED_PAYMENT_REQUEST',
    TOGGLE_BULK_DELETED_PAYMENT_SUCCESS: 'TOGGLE_BULK_DELETED_PAYMENT_SUCCESS',
    TOGGLE_BULK_DELETED_PAYMENT_FAILURE: 'TOGGLE_BULK_DELETED_PAYMENT_FAILURE'


};


function getPagedPaymentsAction(query) {
    return dispatch => {
        dispatch(request());
        paymentService.getAllPaymentsService(query)
            .then(
                payments => dispatch(success(payments)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: paymentConstant.GET_ALL_PAYMENT_REQUEST}
    }

    function success(payments) {
        return {type: paymentConstant.GET_ALL_PAYMENT_SUCCESS, payments}
    }

    function failure(error) {
        return {type: paymentConstant.GET_ALL_PAYMENT_FAILURE, error}
    }
}


function uploadPaymentFileAction(obj, setDisable, setShow,setShowFailedPaymentsModal,setResponseObj) {
    return dispatch => {
        dispatch(request());
        paymentService.uploadPaymentFile(obj)
            .then(
                file => {
                    if (file['errorCode'] === 200) {
                        console.log("this is response " ,Object.keys(file['bulkOrdersDTO']['failureOrders']))
                        if(Object.keys(file['bulkOrdersDTO']['failureOrders']).length > 0){
                            setResponseObj(file['bulkOrdersDTO']);
                            setShowFailedPaymentsModal(true);
                        }else {
                            showSuccessToast(file['message']);
                        }
                        dispatch(success(file));
                        setShow(false);
                        dispatch(getPagedPaymentsAction(createMergedDashboardQuery()));
                    } else {
                        responseDTOExceptionHandler(file);
                    }
                    setDisable(false)
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: paymentConstant.UPLOAD_PAYMENT_REQUEST}
    }

    function success(file) {
        return {type: paymentConstant.UPLOAD_PAYMENT_SUCCESS, file}
    }

    function failure(error) {
        return {type: paymentConstant.UPLOAD_PAYMENT_FAILURE, error}
    }
}

function toggleDeletedPaymentAction(query) {
    return dispatch => {
        dispatch(request({query}));
        paymentService.toggleBulkDeletedPaymentService(query?.deleteList)
            .then(
                payment => {
                    if (payment['errorCode'] === 200) {
                        showSuccessToast("Payment record updated successfully!");
                        dispatch(success(payment));
                        dispatch(getPagedPaymentsAction(query));
                        query.setDeleteItemList([]);
                    } else {
                        responseDTOExceptionHandler(payment);
                    }
                    query.setShowBulkDeleteConfirmation(false);
                    query.setDisableDelete(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    query.setShowBulkDeleteConfirmation(false);
                    query.setDisableDelete(false);
                }
            );
    };

    function request(paymentIds) {
        return {type: paymentConstant.TOGGLE_BULK_DELETED_PAYMENT_REQUEST, paymentIds}
    }

    function success(paymentIds) {
        return {type: paymentConstant.TOGGLE_BULK_DELETED_PAYMENT_SUCCESS, paymentIds}
    }

    function failure(error) {
        return {type: paymentConstant.TOGGLE_BULK_DELETED_PAYMENT_FAILURE, error}
    }

}


//export Payment Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        paymentService.exportPaymentData(query)
            .then(
                paymentData => dispatch(success(paymentData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: paymentConstant.EXPORT_PAYMENT_DATA_REQUEST}
    }

    function success(paymentData) {
        query.setExportBtn(false);
        return {type: paymentConstant.EXPORT_PAYMENT_DATA_SUCCESS, paymentData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: paymentConstant.EXPORT_PAYMENT_DATA_FAILURE, error}
    }
}





/***********************Reducers starts here*********************************/

export function getPagedPaymentsReducer(state = {}, action) {
    switch (action.type) {
        case paymentConstant.GET_ALL_PAYMENT_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case paymentConstant.GET_ALL_PAYMENT_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.payments
            };
        case  paymentConstant.GET_ALL_PAYMENT_FAILURE:
            return {};
        default:
            return state
    }
}

/***********************Reducers ends here*********************************/


/***********************service apis starts here*********************************/

//get all payments
export function getAllPaymentsService(query) {
    let paramData = serialize(query)
    return axios.get(`/ajax/search-payments?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function uploadPaymentFile(obj) {
    const formData = new FormData();
    formData.append('file', obj.file);

    return await axios.post(`/ajax/uploadPayments/${obj.customerId}`, formData).then(
        (response) => {
            return exceptionHandler(response);
        })
}




//export Payment Data Service
export async function exportPaymentData(query) {
    let paramData = serialize(query)
    return axios.post(`/export/payment?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "PaymentData");
            }
        })
        .catch(handleExportError);
}

export function toggleBulkDeletedPaymentService(paymentIds){
    let list = {paymentIds: paymentIds};
    let paramData = serialize(list)
    return axios.post(`/ajax/toggle/bulk/deleted/payment?${paramData}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error)
        })
}




/***********************service apis ends here*********************************/

