import React, {useState} from 'react'
import PrivacyModal from "./template/modal/PrivacyModal";
import CaliforniaPrivacyModal from "./template/modal/CaliforniaPrivacyModal";
import TermsModal from "./template/modal/TermsModal";
import AccessibilityModal from "./template/modal/AccessibilityModal";
import './Footer.css';

function Footer() {

    const [showPopUp, setShowPopUp] = useState(false);
    const [type, setType] = useState("");

    const currentYear = new Date().getFullYear();

    return (
        <div className='footer_wrapper'>
            <div className='footer_outer'>
                <div className=''>
                    <ul className='footer_inner_content'>
                        <h2 className={"mb-0"}> © {currentYear} TitleLogix Solutions, LLC</h2>
                        <h2 className={"mb-0 cursor bottom_font"} onClick={(e) => {
                            e.preventDefault();
                            setType("privacy");
                            setShowPopUp(true);
                        }}> Privacy</h2>
                        <h2 className={"mb-0 cursor bottom_font"} onClick={(e) => {
                            e.preventDefault();
                            setType("californiaPrivacy");
                            setShowPopUp(true);
                        }}> California Privacy</h2>
                        <h2 className={"mb-0 cursor bottom_font"} onClick={(e) => {
                            e.preventDefault();
                            setType("terms");
                            setShowPopUp(true);
                        }}> Terms of Use</h2>
                        <h2 className={"mb-0 cursor bottom_font"} onClick={(e) => {
                            e.preventDefault();
                            setType("accessibility");
                            setShowPopUp(true);
                        }}> Accessibility</h2>
                    </ul>
                </div>

            </div>
            {showPopUp && type === "privacy" && <PrivacyModal show={showPopUp} setShow={setShowPopUp}/>}
            {showPopUp && type === "californiaPrivacy" &&
                <CaliforniaPrivacyModal show={showPopUp} setShow={setShowPopUp}/>}
            {showPopUp && type === "terms" && <TermsModal show={showPopUp} setShow={setShowPopUp}/>}
            {showPopUp && type === "accessibility" && <AccessibilityModal show={showPopUp} setShow={setShowPopUp}/>}

        </div>

    );
}

export default Footer;