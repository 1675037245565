import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showErrorToast, showSuccessToast} from "../../../common/components/Toast";
import {alertActions} from "../../alert/alert";
import {serialize} from "../../../common/helpers/utils";


//export actions
export const associationFeeActions = {
    getAllData,
    deleteAssociationFeeAction,
    createAssociationFeeAction,
    getAssociationFeeByIdAction,
    updateAssociationFeeAction

};

//export constants
export const associationFeeConstants = {
    GET_ALL_ASSOCIATION_FEE_REQUEST: 'GET_ALL_ASSOCIATION_FEE_REQUEST',
    GET_ALL_ASSOCIATION_FEE_SUCCESS: 'GET_ALL_ASSOCIATION_FEE_SUCCESS',
    GET_ALL_ASSOCIATION_FEE_FAILURE: 'GET_ALL_ASSOCIATION_FEE_FAILURE',

    DELETE_ASSOCIATION_BY_ID_REQUEST: 'DELETE_ASSOCIATION_BY_ID_REQUEST',
    DELETE_ASSOCIATION_BY_ID_SUCCESS: 'DELETE_ASSOCIATION_BY_ID_SUCCES',
    DELETE_ASSOCIATION_BY_ID_FAILURE: 'DELETE_ASSOCIATION_BY_ID_FAILURE',

    CREATE_ASSOCIATION_FEE_REQUEST: 'CREATE_ASSOCIATION_FEE_REQUEST',
    CREATE_ASSOCIATION_FEE_SUCCESS: 'CREATE_ASSOCIATION_FEE_SUCCESS',
    CREATE_ASSOCIATION_FEE_FAILURE: 'CREATE_ASSOCIATION_FEE_FAILURE',

    GET_ASSOCIATION_FEE_BY_ID_REQUEST: 'GET_ASSOCIATION_FEE_BY_ID_REQUEST',
    GET_ASSOCIATION_FEE_BY_ID_SUCCESS: 'GET_ASSOCIATION_FEE_BY_ID_SUCCESS',
    GET_ASSOCIATION_FEE_BY_ID_FAILURE: 'GET_ASSOCIATION_FEE_BY_ID_FAILURE',

    UPDATE_ASSOCIATION_FEE_REQUEST: 'UPDATE_ASSOCIATION_FEE_REQUEST',
    UPDATE_ASSOCIATION_FEE_SUCCESS: 'UPDATE_ASSOCIATION_FEE_SUCCESS',
    UPDATE_ASSOCIATION_FEE_FAILURE: 'UPDATE_ASSOCIATION_FEE_FAILURE',

};


//export service
export const associationFeeService = {
    getAllAssociationsFee,
    deleteAssociationFee,
    createAssociationFee,
    getAssociationFeeById,
    updateAssociationFee

};


{/******************************* Association Fee Actions Starts here  ***************************************/
}

//get all association Fee Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());

        associationFeeService.getAllAssociationsFee(query)
            .then(
                associationsFee => dispatch(success(associationsFee)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: associationFeeConstants.GET_ALL_ASSOCIATION_FEE_REQUEST}
    }

    function success(associationsFee) {
        return {type: associationFeeConstants.GET_ALL_ASSOCIATION_FEE_SUCCESS, associationsFee}
    }

    function failure(error) {
        return {type: associationFeeConstants.GET_ALL_ASSOCIATION_FEE_FAILURE, error}
    }
}


// delete Collector Config By Id
function deleteAssociationFeeAction(associationFeeId, setDisable, setShowDeleteAssociationFeeConfirm, query) {
    return dispatch => {
        dispatch(request());
        associationFeeService.deleteAssociationFee(associationFeeId)
            .then(
                associationFee => {
                    if (associationFee['errorCode'] === 200) {
                        showSuccessToast("Association Fee deleted successfully!");
                        dispatch(success(associationFee));
                    } else {
                        responseDTOExceptionHandler(associationFee);
                    }
                    setDisable(false);
                    setShowDeleteAssociationFeeConfirm(false);
                    dispatch(getAllData(query));
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );

    };

    function request() {
        return {type: associationFeeConstants.DELETE_ASSOCIATION_BY_ID_REQUEST}
    }

    function success(associationFee) {
        return {type: associationFeeConstants.DELETE_ASSOCIATION_BY_ID_SUCCESS, associationFee}
    }

    function failure(error) {
        return {type: associationFeeConstants.DELETE_ASSOCIATION_BY_ID_FAILURE, error}
    }

}


//create association fee Action
function createAssociationFeeAction(obj, setDisable, setShow, query) {
    return dispatch => {
        dispatch(request({obj}));

        associationFeeService.createAssociationFee(obj)
            .then(
                associationFee => {
                    if (associationFee['errorCode'] === 200) {
                        showSuccessToast("Association Fee added successfully!");
                        setShow(false);
                        dispatch(success(associationFee));
                        dispatch(getAllData(query));
                    } else {
                        responseDTOExceptionHandler(associationFee);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(associationFee) {
        return {type: associationFeeConstants.CREATE_ASSOCIATION_FEE_REQUEST, associationFee}
    }

    function success(associationFee) {
        return {type: associationFeeConstants.CREATE_ASSOCIATION_FEE_SUCCESS, associationFee}
    }

    function failure(error) {
        return {type: associationFeeConstants.CREATE_ASSOCIATION_FEE_FAILURE, error}
    }
}


//get Association by id
function getAssociationFeeByIdAction(associationFeeId) {
    return dispatch => {
        dispatch(request());

        associationFeeService.getAssociationFeeById(associationFeeId)
            .then(
                associationFee => dispatch(success(associationFee)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: associationFeeConstants.GET_ASSOCIATION_FEE_BY_ID_REQUEST}
    }

    function success(associationFee) {
        return {type: associationFeeConstants.GET_ASSOCIATION_FEE_BY_ID_SUCCESS, associationFee}
    }

    function failure(error) {
        return {type: associationFeeConstants.GET_ASSOCIATION_FEE_BY_ID_FAILURE, error}
    }
}


// update Association Action
function updateAssociationFeeAction(obj, id, setDisable, setShow, query) {
    return dispatch => {
        dispatch(request({obj}));

        associationFeeService.updateAssociationFee(obj, id)
            .then(
                associationFee => {
                    if (associationFee['errorCode'] === 200) {
                        showSuccessToast("Association Fee updated successfully!");
                        setShow(false);
                        dispatch(success(associationFee));
                        dispatch(getAllData(query));
                    } else {
                        responseDTOExceptionHandler(associationFee);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(associationFee) {
        return {type: associationFeeConstants.UPDATE_ASSOCIATION_FEE_REQUEST, associationFee}
    }

    function success(associationFee) {
        return {type: associationFeeConstants.UPDATE_ASSOCIATION_FEE_SUCCESS, associationFee}
    }

    function failure(error) {
        return {type: associationFeeConstants.UPDATE_ASSOCIATION_FEE_FAILURE, error}
    }

}

/************************************ Association Fee Actions Ends here *******************************************/


/************************* Reducers starts here. *************************************/

//Get All Association Fee Reducer
export function getAllAssociationsFeeReducer(state = {}, action) {
    switch (action.type) {
        case associationFeeConstants.GET_ALL_ASSOCIATION_FEE_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case associationFeeConstants.GET_ALL_ASSOCIATION_FEE_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.associationsFee
            };
        case associationFeeConstants.GET_ALL_ASSOCIATION_FEE_FAILURE:
            return {};
        default:
            return state
    }
}


//Get All Association Fee Reducer
export function getAssociationsFeeByIdReducer(state = {}, action) {
    switch (action.type) {
        case associationFeeConstants.GET_ASSOCIATION_FEE_BY_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case associationFeeConstants.GET_ASSOCIATION_FEE_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.associationFee
            };
        case associationFeeConstants.GET_ASSOCIATION_FEE_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}


/************************** Reducers ends here. *************************************/


/******************************Association Fee Services starts here  ****************************************/

//get all Associations
export function getAllAssociationsFee(query) {
    let paramData = serialize(query)

    return axios.post(`/ajax/getAllAssociationsFee?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export function deleteAssociationFee(associationFeeId) {
    return axios.delete(`/ajax/delete-association-fee-by-id/${associationFeeId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


// create new Association Fee
export function createAssociationFee(obj) {
    return axios.post(`/ajax/create/associationFee`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error)
        })
}


export function getAssociationFeeById(associationFeeId) {
    return axios.get(`/ajax/get-association-fee-by-id/${associationFeeId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export function updateAssociationFee(obj, associationFeeId) {
    return axios.put(`/ajax/update/association-fee/${associationFeeId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}




{/******************************Association Fee Services ends here  ****************************************/
}


