import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {alertActions} from "../../alert/alert";
import {showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";

//export actions
export const collectorLinkActions = {
    getAllData,
    submitCollectorLinkAction,
    getCollectorLinkByIdAction,
    updateCollectorLinkAction,
    exportData,
    deleteCollectorLinkByIdAction
};

//export constants
export const collectorLinkConstants = {
    GET_ALL_COLLECTOR_LINK_REQUEST: 'GET_ALL_COLLECTOR_LINK_REQUEST',
    GET_ALL_COLLECTOR_LINK_SUCCESS: 'GET_ALL_COLLECTOR_LINK_SUCCESS',
    GET_ALL_COLLECTOR_LINK_FAILURE: 'GET_ALL_COLLECTOR_LINK_FAILURE',


    CREATE_COLLECTOR_LINK_REQUEST: 'CREATE_COLLECTOR_LINK_REQUEST',
    CREATE_COLLECTOR_LINK_SUCCESS: 'CREATE_COLLECTOR_LINK_SUCCESS',
    CREATE_COLLECTOR_LINK_FAILURE: 'CREATE_COLLECTOR_LINK_FAILURE',

    GET_COLLECTOR_LINK_BY_ID_REQUEST: 'GET_COLLECTOR_LINK_BY_ID_REQUEST',
    GET_COLLECTOR_LINK_BY_ID_SUCCESS: 'GET_COLLECTOR_LINK_BY_ID_SUCCESS',
    GET_COLLECTOR_LINK_BY_ID_FAILURE: 'GET_COLLECTOR_LINK_BY_ID_FAILURE',

    UPDATE_COLLECTOR_LINK_REQUEST: 'UPDATE_COLLECTOR_LINK_REQUEST',
    UPDATE_COLLECTOR_LINK_SUCCESS: 'UPDATE_COLLECTOR_LINK_SUCCESS',
    UPDATE_COLLECTOR_LINK_FAILURE: 'UPDATE_COLLECTOR_LINK_FAILURE',

    GET_COLLECTOR_LINK_NOTES_REQUEST: 'GET_COLLECTOR_LINK_NOTES_REQUEST',
    GET_COLLECTOR_LINK_NOTES_SUCCESS: 'GET_COLLECTOR_LINK_NOTES_SUCCESS',
    GET_COLLECTOR_LINK_NOTES_FAILURE: 'GET_COLLECTOR_LINK_NOTES_FAILURE',

    CREATE_COLLECTOR_LINK_NOTE_REQUEST: 'CREATE_COLLECTOR_LINK_NOTE_REQUEST',
    CREATE_COLLECTOR_LINK_NOTE_SUCCESS: 'CREATE_COLLECTOR_LINK_NOTE_SUCCESS',
    CREATE_COLLECTOR_LINK_NOTE_FAILURE: 'CREATE_COLLECTOR_LINK_NOTE_FAILURE',

    CREATE_CONFIGURATION_REQUEST: 'CREATE_CONFIGURATION_REQUEST',
    CREATE_CONFIGURATION_SUCCESS: 'CREATE_CONFIGURATION_SUCCESS',
    CREATE_CONFIGURATION_FAILURE: 'CREATE_CONFIGURATION_FAILURE',

    GET_COLLECTOR_LINK_CONFIG_REQUEST: 'GET_COLLECTOR_LINK_CONFIG_REQUEST',
    GET_COLLECTOR_LINK_CONFIG_SUCCESS: 'GET_COLLECTOR_LINK_CONFIG_SUCCESS',
    GET_COLLECTOR_LINK_CONFIG_FAILURE: 'GET_COLLECTOR_LINK_CONFIG_FAILURE',

    GET_COLLECTOR_LINK_LOGS_REQUEST: 'GET_COLLECTOR_LINK_LOGS_REQUEST',
    GET_COLLECTOR_LINK_LOGS_SUCCESS: 'GET_COLLECTOR_LINK_LOGS_SUCCESS',
    GET_COLLECTOR_LINK_LOGS_FAILURE: 'GET_COLLECTOR_LINK_LOGS_FAILURE',


    GET_COLLECTOR_LINK_DOCUMENT_LIST_REQUEST: 'GET_COLLECTOR_LINK_DOCUMENT_LIST_REQUEST',
    GET_COLLECTOR_LINK_DOCUMENT_LIST_SUCCESS: 'GET_COLLECTOR_LINK_DOCUMENT_LIST_SUCCESS',
    GET_COLLECTOR_LINK_DOCUMENT_LIST_FAILURE: 'GET_COLLECTOR_LINK_DOCUMENT_LIST_FAILURE',

    EXPORT_COLLECTOR_LINK_DATA_REQUEST: 'EXPORT_COLLECTOR_LINK_DATA_REQUEST',
    EXPORT_COLLECTOR_LINK_DATA_SUCCESS: 'EXPORT_COLLECTOR_LINK_DATA_SUCCESS',
    EXPORT_COLLECTOR_LINK_DATA_FAILURE: 'EXPORT_COLLECTOR_LINK_DATA_FAILURE',

    UPDATE_CONFIGURATION_REQUEST: 'UPDATE_CONFIGURATION_REQUEST',
    UPDATE_CONFIGURATION_SUCCESS: 'UPDATE_CONFIGURATION_SUCCESS',
    UPDATE_CONFIGURATION_FAILURE: 'UPDATE_CONFIGURATION_FAILURE',


    DELETE_COLLECTOR_LINK_CONFIG_BY_ID_REQUEST: 'DELETE_COLLECTOR_LINK_CONFIG_BY_ID_REQUEST',
    DELETE_COLLECTOR_LINK_CONFIG_BY_ID_SUCCESS: 'DELETE_COLLECTOR_LINK_CONFIG_BY_ID_SUCCESS',
    DELETE_COLLECTOR_LINK_CONFIG_BY_ID_FAILURE: 'DELETE_COLLECTOR_LINK_CONFIG_BY_ID_FAILURE',

    CHECK_COLLECTOR_LINK_JOB_STATUS_REQUEST: 'CHECK_COLLECTOR_LINK_JOB_STATUS_REQUEST',
    CHECK_COLLECTOR_LINK_JOB_STATUS_SUCCESS: 'CHECK_COLLECTOR_LINK_JOB_STATUS_SUCCESS',
    CHECK_COLLECTOR_LINK_JOB_STATUS_FAILURE: 'CHECK_COLLECTOR_LINK_JOB_STATUS_FAILURE',

    CREATE_JOB_REQUEST: 'CREATE_JOB_REQUEST',
    CREATE_JOB_SUCCESS: 'CREATE_JOB_SUCCESS',
    CREATE_JOB_FAILURE: 'CREATE_JOB_FAILURE',

    DELETE_COLLECTOR_LINK_REQUEST: 'DELETE_COLLECTOR_LINK_REQUEST',
    DELETE_COLLECTOR_LINK_SUCCESS: 'DELETE_COLLECTOR_LINK_SUCCESS',
    DELETE_COLLECTOR_LINK_FAILURE: 'DELETE_COLLECTOR_LINK_FAILURE',


};


//export service
export const collectorLinkService = {
    getAllCollectorLink,
    createCollectorLink,
    getCollectorLinkById,
    updateCollectorLink,
    exportCollectorData,
    deleteCollectorLinkById
};


{/******************************** collector Actions Starts here  ****************************************/
}

//get all Collector Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());

        collectorLinkService.getAllCollectorLink(query)
            .then(
                collectors => dispatch(success(collectors)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: collectorLinkConstants.GET_ALL_COLLECTOR_LINK_REQUEST}
    }

    function success(collectors) {
        return {type: collectorLinkConstants.GET_ALL_COLLECTOR_LINK_SUCCESS, collectors}
    }

    function failure(error) {
        return {type: collectorLinkConstants.GET_ALL_COLLECTOR_LINK_FAILURE, error}
    }
}


//create Collector Action
function submitCollectorLinkAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        collectorLinkService.createCollectorLink(obj)
            .then(
                collector => {
                    if (collector['errorCode'] === 200) {
                        showSuccessToast("Collector Link added successfully!");
                        dispatch(success(collector));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(collector);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(collector) {
        return {type: collectorLinkConstants.CREATE_COLLECTOR_LINK_REQUEST, collector}
    }

    function success(collector) {
        return {type: collectorLinkConstants.CREATE_COLLECTOR_LINK_SUCCESS, collector}
    }

    function failure(error) {
        return {type: collectorLinkConstants.CREATE_COLLECTOR_LINK_FAILURE, error}
    }
}


//get collector by id
function getCollectorLinkByIdAction(collectorId) {
    return dispatch => {
        dispatch(request());
        collectorLinkService.getCollectorLinkById(collectorId)
            .then(
                collector => dispatch(success(collector)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: collectorLinkConstants.GET_COLLECTOR_LINK_BY_ID_REQUEST}
    }

    function success(collector) {
        return {type: collectorLinkConstants.GET_COLLECTOR_LINK_BY_ID_SUCCESS, collector}
    }

    function failure(error) {
        return {type: collectorLinkConstants.GET_COLLECTOR_LINK_BY_ID_FAILURE, error}
    }
}

// update Collector Action
function updateCollectorLinkAction(obj, from, setDisable, collectorLinkId) {
    return dispatch => {
        dispatch(request({obj}));
        collectorLinkService.updateCollectorLink(obj, collectorLinkId)
            .then(
                collector => {
                    console.log(collector);
                    if (collector['errorCode'] === 200) {
                        showSuccessToast("Collector updated successfully!");
                        dispatch(success(collector));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(collector);
                    }
                    setDisable(false)
                    dispatch(getCollectorLinkByIdAction(collectorLinkId));
                },
                error => {
                    dispatch(getCollectorLinkByIdAction(collectorLinkId));
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(collector) {
        return {type: collectorLinkConstants.UPDATE_COLLECTOR_LINK_REQUEST, collector}
    }

    function success(collector) {
        return {type: collectorLinkConstants.UPDATE_COLLECTOR_LINK_SUCCESS, collector}
    }

    function failure(error) {
        return {type: collectorLinkConstants.UPDATE_COLLECTOR_LINK_FAILURE, error}
    }
}

//export Collector Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        collectorLinkService.exportCollectorData(query)
            .then(
                collectorData => dispatch(success(collectorData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: collectorLinkConstants.EXPORT_COLLECTOR_LINK_DATA_REQUEST}
    }

    function success(collectorData) {
        query.setExportBtn(false);
        return {type: collectorLinkConstants.EXPORT_COLLECTOR_LINK_DATA_SUCCESS, collectorData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: collectorLinkConstants.EXPORT_COLLECTOR_LINK_DATA_FAILURE, error}
    }
}

//delete collector Action
function deleteCollectorLinkByIdAction(deleteQuery) {
    return dispatch => {
        dispatch(request());
        collectorLinkService.deleteCollectorLinkById(deleteQuery.deleteReference)
            .then(
                collector => {
                    if (collector['errorCode'] === 200) {
                        showSuccessToast("Collector Link deleted successfully!");
                        dispatch(success(collector));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(collector);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: collectorLinkConstants.DELETE_COLLECTOR_LINK_REQUEST,}
    }

    function success(collector) {
        return {type: collectorLinkConstants.DELETE_COLLECTOR_LINK_SUCCESS, collector}
    }

    function failure(error) {
        return {type: collectorLinkConstants.DELETE_COLLECTOR_LINK_FAILURE, error}
    }
}


/************************************* collector Actions Ends here ********************************************/


/************************** Reducers starts here. **************************************/

//get all Collector reducer
export function getAllCollectorLinkReducer(state = {}, action) {
    switch (action.type) {
        case collectorLinkConstants.GET_ALL_COLLECTOR_LINK_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case collectorLinkConstants.GET_ALL_COLLECTOR_LINK_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.collectors
            };
        case collectorLinkConstants.GET_ALL_COLLECTOR_LINK_FAILURE:
            return {};
        default:
            return state
    }
}


//Collector by id Reducer
export function getCollectorLinkByIdReducer(state = {}, action) {
    switch (action.type) {
        case collectorLinkConstants.GET_COLLECTOR_LINK_BY_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case collectorLinkConstants.GET_COLLECTOR_LINK_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.collector
            };
        case collectorLinkConstants.GET_COLLECTOR_LINK_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

/*************************** Reducers ends here. **************************************/


/******************************collectors Services starts here  ****************************************/
//get all collectors
export function getAllCollectorLink(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/collector-links?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


// create new Collector
export function createCollectorLink(obj) {
    return axios.post(`/ajax/create/collector-link`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

//get collector by id
export function getCollectorLinkById(collectorLinkId) {
    return axios.get(`/ajax/get-collector-link-by-id/${collectorLinkId}`).then(
        (response) => {
            return response.data;
        })
}


///update collector
export function updateCollectorLink(obj, collectorLinkId) {
    return axios.put(`/ajax/update/collector-link/${collectorLinkId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

//export Collector Data Service
export async function exportCollectorData(query) {
    let paramData = serialize(query)
    return axios.post(`/export/collector-link?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "collectorData");
            }
        })
        .catch(handleExportError);
}


export async function deleteCollectorLinkById(id) {
    return axios.delete(`/ajax/delete-collector-link-by-id/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}




/******************************CollectorLinks Services ends here  ****************************************/




