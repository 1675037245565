import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../common/components/Toast";
import {alertActions} from "../alert/alert";
import {handleExportError, resourceToFile, serialize} from "../../common/helpers/utils";

export const customerPricingActions = {
    getAllData,
    deleteCustomerPricingAction,
    submitCustomerPricingAction,
    exportCustomerPricingAction
}

export const customerPricingConstants = {
    GET_ALL_CUSTOMER_PRICING_REQUEST: 'GET_ALL_CUSTOMER_PRICING_REQUEST',
    GET_ALL_CUSTOMER_PRICING_SUCCESS: 'GET_ALL_CUSTOMER_PRICING_SUCCESS',
    GET_ALL_CUSTOMER_PRICING_FAILURE: 'GET_ALL_CUSTOMER_PRICING_FAILURE',

    DELETE_CUSTOMER_PRICING_REQUEST: 'DELETE_CUSTOMER_PRICING_REQUEST',
    DELETE_CUSTOMER_PRICING_SUCCESS: 'DELETE_CUSTOMER_PRICING_SUCCESS',
    DELETE_CUSTOMER_PRICING_FAILURE: 'DELETE_CUSTOMER_PRICING_FAILURE',

    CREATE_CUSTOMER_PRICING_REQUEST: 'CREATE_CUSTOMER_PRICING_REQUEST',
    CREATE_CUSTOMER_PRICING_SUCCESS: 'CREATE_CUSTOMER_PRICING_SUCCESS',
    CREATE_CUSTOMER_PRICING_FAILURE: 'CREATE_CUSTOMER_PRICING_FAILURE',

    EXPORT_CUSTOMER_PRICING_REQUEST: 'EXPORT_CUSTOMER_PRICING_REQUEST',
    EXPORT_CUSTOMER_PRICING_SUCCESS: 'EXPORT_CUSTOMER_PRICING_SUCCESS',
    EXPORT_CUSTOMER_PRICING_FAILURE: 'EXPORT_CUSTOMER_PRICING_FAILURE',
}
export const customerPricingService = {
    getAllCustomerPricing,
    deleteCustomerPricingService,
    submitCustomerPricing,
    exportCustomerPricing
}


function getAllData(query) {
    return dispatch => {
        dispatch(request());

        customerPricingService.getAllCustomerPricing(query)
            .then(
                vendors => {
                    dispatch(success(vendors));
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );

    };

    function request() {
        return {type: customerPricingConstants.GET_ALL_CUSTOMER_PRICING_REQUEST}
    }

    function success(customers) {
        return {type: customerPricingConstants.GET_ALL_CUSTOMER_PRICING_SUCCESS, customers}
    }

    function failure(error) {
        return {type: customerPricingConstants.GET_ALL_CUSTOMER_PRICING_FAILURE, error}
    }

}

function deleteCustomerPricingAction(id, setDisable, setShowConfirmation, resetInput) {
    return dispatch => {
        dispatch(request());
        customerPricingService.deleteCustomerPricingService(id)
            .then(
                pricing => {
                    if (pricing['errorCode'] === 200) {
                        showSuccessToast("Customer Pricing Deleted Successfully!");
                        dispatch(success(pricing));
                    } else {
                        responseDTOExceptionHandler(pricing);
                    }
                    setDisable(false);
                    setShowConfirmation(false);
                    resetInput();
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: customerPricingConstants.DELETE_CUSTOMER_PRICING_REQUEST}
    }

    function success(pricing) {
        return {type: customerPricingConstants.DELETE_CUSTOMER_PRICING_SUCCESS, pricing}
    }

    function failure(error) {
        return {type: customerPricingConstants.DELETE_CUSTOMER_PRICING_FAILURE, error}
    }
}

function submitCustomerPricingAction(obj, id, setIsSubmit, setShowEditIcon, resetInput) {
    return dispatch => {
        dispatch(request({obj}));
        customerPricingService.submitCustomerPricing(obj)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        if (id === null || id === '')
                            showSuccessToast("Customer pricing added successfully!");
                        else
                            showSuccessToast("Customer pricing updated successfully!");
                        dispatch(success(data));
                        resetInput();
                        setShowEditIcon(false);
                    } else {
                        responseDTOExceptionHandler(data);
                    }
                    setIsSubmit(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(data) {
        return {type: customerPricingConstants.CREATE_CUSTOMER_PRICING_REQUEST, data}
    }

    function success(data) {
        return {type: customerPricingConstants.CREATE_CUSTOMER_PRICING_SUCCESS, data}
    }

    function failure(error) {
        return {type: customerPricingConstants.CREATE_CUSTOMER_PRICING_FAILURE, error}
    }
}


/************ Reducers started here. *******************/

export function customerPricingReducer(state = {}, action) {
    switch (action.type) {
        case customerPricingConstants.GET_ALL_CUSTOMER_PRICING_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case customerPricingConstants.GET_ALL_CUSTOMER_PRICING_SUCCESS:
            return {
                loggedIn: true,
                items: action.customers
            };
        case customerPricingConstants.GET_ALL_CUSTOMER_PRICING_FAILURE:
            return {};
        default:
            return state
    }
}

/************ Reducers ended here. *******************/

export async function submitCustomerPricing(data) {
    return axios.post(`/ajax/create-customer-pricing`, data).then(
        (response) => {
            return response.data;
        })
}

function getAllCustomerPricing(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/customer-pricing?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

function deleteCustomerPricingService(id) {
    return axios.delete(`/ajax/delete-by-customer-pricing-id/${id}`).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error);
    })
}

function exportCustomerPricingAction(query, setIsExport) {

    return dispatch => {
        dispatch(request());
        customerPricingService.exportCustomerPricing(query)
            .then(
                exportData => {
                    dispatch(success(exportData))
                    setIsExport(false)
                },
                error => {
                    dispatch(failure(error.toString()))
                    setIsExport(false)
                }
            );
    };

    function request() {
        return {type: customerPricingConstants.EXPORT_CUSTOMER_PRICING_REQUEST}
    }

    function success(exportData) {
        return {type: customerPricingConstants.EXPORT_CUSTOMER_PRICING_SUCCESS, exportData}
    }

    function failure(error) {
        return {type: customerPricingConstants.EXPORT_CUSTOMER_PRICING_FAILURE, error}
    }
}


export async function exportCustomerPricing(query) {
    let paramData = serialize(query)
    return axios.post(`/export/customer-pricing?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    ).then((response) => {
        if (response.data.byteLength === 0) {
            showInfoToast("No data found to export!")
        } else {
            resourceToFile(response.data, "CustomerPricing");
        }
    })
        .catch(handleExportError);

}




