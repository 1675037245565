import axios from "axios";
import {serialize} from "../../../common/helpers/utils";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";

//export actions
export const taxRoundingConfigActions = {
    getAllData,
    getTaxRoundingConfigByIdAction,
    createTaxRoundingConfigAction,
    updateTaxRoundingConfigByIdAction,
    deleteTaxRoundingConfigAction,
};

//export constants
export const taxRoundingConfigConstant = {
    GET_ALL_TAX_ROUNDING_CONFIG_REQUEST: 'GET_ALL_TAX_ROUNDING_CONFIG_REQUEST',
    GET_ALL_TAX_ROUNDING_CONFIG_SUCCESS: 'GET_ALL_TAX_ROUNDING_CONFIG_SUCCESS',
    GET_ALL_TAX_ROUNDING_CONFIG_FAILURE: 'GET_ALL_TAX_ROUNDING_CONFIG_FAILURE',

    GET_ALL_TAX_ROUNDING_CONFIG_BY_ID_REQUEST: 'GET_ALL_TAX_ROUNDING_CONFIG_BY_ID_REQUEST',
    GET_ALL_TAX_ROUNDING_CONFIG_BY_ID_SUCCESS: 'GET_ALL_TAX_ROUNDING_CONFIG_BY_ID_SUCCESS',
    GET_ALL_TAX_ROUNDING_CONFIG_BY_ID_FAILURE: 'GET_ALL_TAX_ROUNDING_CONFIG_BY_ID_FAILURE',

    EXPORT_TAX_ROUNDING_CONFIG_DATA_REQUEST: 'EXPORT_TAX_ROUNDING_CONFIG_DATA_REQUEST',
    EXPORT_TAX_ROUNDING_CONFIG_DATA_SUCCESS: 'EXPORT_TAX_ROUNDING_CONFIG_DATA_SUCCESS',
    EXPORT_TAX_ROUNDING_CONFIG_DATA_FAILURE: 'EXPORT_TAX_ROUNDING_CONFIG_DATA_FAILURE',

    CREATE_TAX_ROUNDING_CONFIG_REQUEST: 'CREATE_TAX_ROUNDING_CONFIG_REQUEST',
    CREATE_TAX_ROUNDING_CONFIG_SUCCESS: 'CREATE_TAX_ROUNDING_CONFIG_SUCCESS',
    CREATE_TAX_ROUNDING_CONFIG_FAILURE: 'CREATE_TAX_ROUNDING_CONFIG_FAILURE',

    UPDATE_TAX_ROUNDING_CONFIG_REQUEST: 'UPDATE_TAX_ROUNDING_CONFIG_REQUEST',
    UPDATE_TAX_ROUNDING_CONFIG_SUCCESS: 'UPDATE_TAX_ROUNDING_CONFIG_SUCCESS',
    UPDATE_TAX_ROUNDING_CONFIG_FAILURE: 'UPDATE_TAX_ROUNDING_CONFIG_FAILURE',

    DELETE_TAX_ROUNDING_CONFIG_REQUEST:'DELETE_TAX_ROUNDING_CONFIG_REQUEST',
    DELETE_TAX_ROUNDING_CONFIG_SUCCESS:'DELETE_TAX_ROUNDING_CONFIG_SUCCESS',
    DELETE_TAX_ROUNDING_CONFIG_FAILURE:'DELETE_TAX_ROUNDING_CONFIG_FAILURE'

};

//export service
export const taxRoundingConfigService = {
    getAllTaxRoundingConfig,
    getTaxRoundingConfigById,
    createTaxRoundingConfig,
    updateTaxRoundingConfigById,
    deleteTaxRoundingConfig
};


{/******************************** TaxRoundingConfig Actions Starts here  ****************************************/
}

// get All TaxRoundingConfig Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());
        taxRoundingConfigService.getAllTaxRoundingConfig(query)
            .then(
                taxRoundingConfigs => dispatch(success(taxRoundingConfigs)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: taxRoundingConfigConstant.GET_ALL_TAX_ROUNDING_CONFIG_REQUEST}
    }

    function success(taxRoundingConfigs) {
        return {type: taxRoundingConfigConstant.GET_ALL_TAX_ROUNDING_CONFIG_SUCCESS, taxRoundingConfigs}
    }

    function failure(error) {
        return {type: taxRoundingConfigConstant.GET_ALL_TAX_ROUNDING_CONFIG_FAILURE, error}
    }

}

function getTaxRoundingConfigByIdAction(id) {
    return dispatch => {
        dispatch(request());
        taxRoundingConfigService.getTaxRoundingConfigById(id)
            .then(
                taxRoundingConfig => dispatch(success(taxRoundingConfig)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: taxRoundingConfigConstant.GET_ALL_TAX_ROUNDING_CONFIG_BY_ID_REQUEST}
    }

    function success(taxRoundingConfig) {
        return {type: taxRoundingConfigConstant.GET_ALL_TAX_ROUNDING_CONFIG_BY_ID_SUCCESS, taxRoundingConfig}
    }

    function failure(error) {
        return {type: taxRoundingConfigConstant.GET_ALL_TAX_ROUNDING_CONFIG_BY_ID_FAILURE, error}
    }
}


function createTaxRoundingConfigAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        taxRoundingConfigService.createTaxRoundingConfig(obj)
            .then(
                config => {
                    if (config['errorCode'] === 200) {
                        showSuccessToast("Tax Round Config added successfully!");
                        dispatch(success(config));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(config);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(config) {
        return {type: taxRoundingConfigConstant.CREATE_TAX_ROUNDING_CONFIG_REQUEST, config}
    }

    function success(config) {
        return {type: taxRoundingConfigConstant.CREATE_TAX_ROUNDING_CONFIG_SUCCESS, config}
    }

    function failure(error) {
        return {type: taxRoundingConfigConstant.CREATE_TAX_ROUNDING_CONFIG_FAILURE, error}
    }
}

function updateTaxRoundingConfigByIdAction(obj, id, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        taxRoundingConfigService.updateTaxRoundingConfigById(obj, id)
            .then(
                config => {
                    if (config['errorCode'] === 200) {
                        showSuccessToast("Tax Round Config updated successfully!");
                        dispatch(success(config));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(config);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(config) {
        return {type: taxRoundingConfigConstant.UPDATE_TAX_ROUNDING_CONFIG_REQUEST, config}
    }

    function success(config) {
        return {type: taxRoundingConfigConstant.UPDATE_TAX_ROUNDING_CONFIG_SUCCESS, config}
    }

    function failure(error) {
        return {type: taxRoundingConfigConstant.UPDATE_TAX_ROUNDING_CONFIG_FAILURE, error}
    }
}


function deleteTaxRoundingConfigAction(deleteQuery) {
    return dispatch => {
        dispatch(request(deleteQuery));
        taxRoundingConfigService.deleteTaxRoundingConfig(deleteQuery.deleteReference)
            .then(
                juri => {
                    if (juri['errorCode'] === 200) {
                        showSuccessToast("Tax Rounding Config deleted successfully!");
                        dispatch(success(juri));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(juri);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(deleteQuery) {
        return {type: taxRoundingConfigConstant.DELETE_TAX_ROUNDING_CONFIG_REQUEST,deleteQuery}
    }

    function success(juri) {
        return {type: taxRoundingConfigConstant.DELETE_TAX_ROUNDING_CONFIG_SUCCESS, juri}
    }

    function failure(error) {
        return {type: taxRoundingConfigConstant.DELETE_TAX_ROUNDING_CONFIG_FAILURE, error}
    }

}


/************************************* TaxRoundingConfig Actions Ends here ********************************************/


/************************** Reducers starts here. **************************************/

//get all taxRoundingConfig reducer
export function getAllTaxRoundingConfigReducer(state = {}, action) {
    switch (action.type) {
        case taxRoundingConfigConstant.GET_ALL_TAX_ROUNDING_CONFIG_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case taxRoundingConfigConstant.GET_ALL_TAX_ROUNDING_CONFIG_SUCCESS:
            return {
                loading: false,
                items: action.taxRoundingConfigs
            };
        case taxRoundingConfigConstant.GET_ALL_TAX_ROUNDING_CONFIG_FAILURE:
            return {};
        default:
            return state
    }

}

export function getTaxRoundingConfigByIdReducer(state = {}, action) {
    switch (action.type) {
        case taxRoundingConfigConstant.GET_ALL_TAX_ROUNDING_CONFIG_BY_ID_REQUEST:
            return {
                ...state,
            };
        case taxRoundingConfigConstant.GET_ALL_TAX_ROUNDING_CONFIG_BY_ID_SUCCESS:
            return {
                items: action.taxRoundingConfig
            };
        case taxRoundingConfigConstant.GET_ALL_TAX_ROUNDING_CONFIG_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

/*************************** Reducers ends here. **************************************/


/******************************taxRoundingConfig Services starts here  ****************************************/
//get all collectors
export function getAllTaxRoundingConfig(query) {
    let paramData = serialize(query)
    return axios.get(`/ajax/tax-rounding-configs?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


//get all taxRoundingConfig by id
export function getTaxRoundingConfigById(id) {
    return axios.get(`/ajax/get-tax-rounding-configs-by-id/` + id).then(
        (response) => {
            return response.data;
        })
}

export function createTaxRoundingConfig(obj) {
    return axios.post(`/ajax/create-tax-round-config`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function updateTaxRoundingConfigById(obj, id) {
    return axios.put(`/ajax/update-tax-round-config-by-id/${id}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function deleteTaxRoundingConfig(id){
    return axios.delete(`/ajax/delete-tax-round-config-by-id/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}
/******************************taxRoundingConfig Services ends here  ****************************************/




