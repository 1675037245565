import axios from "axios";
import {showErrorToast, showSuccessToast} from "../../common/components/Toast";
import {alertActions} from "../alert/alert";
import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import {serialize} from "../../common/helpers/utils";

export const regionsActions = {
    getAllData,
    createRegionAction,
    updateRegionAction,
    deleteRegionAction,
    getRegionByIdAction,
    getRegionsByCustomerIdAction
}

export const regionsConstants = {
    GET_ALL_REGION_REQUEST: 'GET_ALL_REGION_REQUEST',
    GET_ALL_REGION_SUCCESS: 'GET_ALL_REGION_SUCCESS',
    GET_ALL_REGION_FAILURE: 'GET_ALL_REGION_FAILURE',

    CREATE_REGION_REQUEST: 'CREATE_REGION_REQUEST',
    CREATE_REGION_SUCCESS: 'CREATE_REGION_SUCCESS',
    CREATE_REGION_FAILURE: 'CREATE_REGION_FAILURE',


    UPDATE_REGION_REQUEST: 'UPDATE_REGION_REQUEST',
    UPDATE_REGION_SUCCESS: 'UPDATE_REGION_SUCCESS',
    UPDATE_REGION_FAILURE: 'UPDATE_REGION_FAILURE',


    DELETE_REGION_REQUEST: 'DELETE_REGION_REQUEST',
    DELETE_REGION_SUCCESS: 'DELETE_REGION_SUCCESS',
    DELETE_REGION_FAILURE: 'DELETE_REGION_FAILURE',

    GET_REGION_BY_ID_REQUEST: 'GET_REGION_BY_ID_REQUEST',
    GET_REGION_BY_ID_SUCCESS: 'GET_REGION_BY_ID_SUCCESS',
    GET_REGION_BY_ID_FAILURE: 'GET_REGION_BY_ID_FAILURE',

    GET_REGION_BY_CUSTOMER_ID_REQUEST: 'GET_REGION_BY_CUSTOMER_ID_REQUEST',
    GET_REGION_BY_CUSTOMER_ID_SUCCESS: 'GET_REGION_BY_CUSTOMER_ID_SUCCESS',
    GET_REGION_BY_CUSTOMER_ID_FAILURE: 'GET_REGION_BY_CUSTOMER_ID_FAILURE',
}

//export service
export const regionsService = {
    getAllRegions,
    createRegionService,
    updateRegionService,
    deleteRegionService,
    getRegionByIdService,
    getRegionsByCustomerId,
}

function getAllData(query) {
    return dispatch => {
        dispatch(request());

        regionsService.getAllRegions(query)
            .then(
                regions => dispatch(success(regions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: regionsConstants.GET_ALL_REGION_REQUEST}
    }

    function success(regions) {
        return {type: regionsConstants.GET_ALL_REGION_SUCCESS, regions}
    }

    function failure(error) {
        return {type: regionsConstants.GET_ALL_REGION_FAILURE, error}
    }

}

function createRegionAction(obj, setDisable, setShow, query) {
    return dispatch => {
        dispatch(request({obj}));
        regionsService.createRegionService(obj)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        showSuccessToast("Region added successfully!");
                        setShow(false);
                        dispatch(success(data));
                        dispatch(getAllData(query));
                    } else {
                        responseDTOExceptionHandler(data);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: regionsConstants.CREATE_REGION_REQUEST}
    }

    function success(region) {
        return {type: regionsConstants.CREATE_REGION_SUCCESS, region}
    }

    function failure(error) {
        return {type: regionsConstants.CREATE_REGION_FAILURE, error}
    }

}

//update region Data
function updateRegionAction(data, setDisable, setShow, regionId,query) {
    return dispatch => {
        dispatch(request());
        regionsService.updateRegionService(data,regionId)
            .then(
                region => {
                    if (region['errorCode'] === 200) {
                        setShow(false);
                        showSuccessToast("Region updated successfully!");
                        dispatch(success(region));
                        dispatch(getAllData(query));
                    } else {
                        responseDTOExceptionHandler(region);
                    }
                    setDisable(false);
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: regionsConstants.UPDATE_REGION_REQUEST}
    }

    function success(region) {
        return {type: regionsConstants.UPDATE_REGION_SUCCESS, region}
    }

    function failure(error) {
        return {type: regionsConstants.UPDATE_REGION_FAILURE, error}
    }

}

function deleteRegionAction(regionId, setDisable, setShowDeleteModal, query) {
    return dispatch => {
        dispatch(request());
        regionsService.deleteRegionService(regionId)
            .then(
                region => {
                    if (region['errorCode'] === 200) {
                        showSuccessToast("Region deleted!");
                        dispatch(success(region));
                        setShowDeleteModal(false)
                        dispatch(getAllData(query));
                    } else {
                        responseDTOExceptionHandler(region);
                    }
                    setDisable(false)
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: regionsConstants.DELETE_REGION_REQUEST}
    }

    function success(region) {
        return {type: regionsConstants.DELETE_REGION_SUCCESS, region}
    }

    function failure(error) {
        return {type: regionsConstants.DELETE_REGION_FAILURE, error}
    }
}


function getRegionByIdAction(regionId) {
    return dispatch => {
        dispatch(request());
        regionsService.getRegionByIdService(regionId)
            .then(
                region => dispatch(success(region)),
                error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: regionsConstants.GET_REGION_BY_ID_REQUEST}
    }

    function success(region) {
        return {type: regionsConstants.GET_REGION_BY_ID_SUCCESS, region}
    }

    function failure(error) {
        return {type: regionsConstants.GET_REGION_BY_ID_FAILURE, error}
    }
}


function getRegionsByCustomerIdAction(customerId) {
    return dispatch => {
        dispatch(request());
        regionsService.getRegionsByCustomerId(customerId)
            .then(
                regions => dispatch(success(regions)),
                error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: regionsConstants.GET_REGION_BY_CUSTOMER_ID_REQUEST}
    }

    function success(regions) {
        return {type: regionsConstants.GET_REGION_BY_CUSTOMER_ID_SUCCESS, regions}
    }

    function failure(error) {
        return {type: regionsConstants.GET_REGION_BY_CUSTOMER_ID_FAILURE, error}
    }
}

/************ Reducers started here. *******************/

//region list reducer
export function regionsReducer(state = {}, action) {
    switch (action.type) {
        case regionsConstants.GET_ALL_REGION_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case regionsConstants.GET_ALL_REGION_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.regions
            };
        case regionsConstants.GET_ALL_REGION_FAILURE:
            return {};
        default:
            return state
    }
}


//region info  by name
export function getRegionByIdReducer(state = {}, action) {
    switch (action.type) {
        case regionsConstants.GET_REGION_BY_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case regionsConstants.GET_REGION_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.region
            };
        case regionsConstants.GET_REGION_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}


//region info  by customer id
export function getRegionsByCustomerIdReducer(state = {}, action) {
    switch (action.type) {
        case regionsConstants.GET_REGION_BY_CUSTOMER_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case regionsConstants.GET_REGION_BY_CUSTOMER_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.regions
            };
        case regionsConstants.GET_REGION_BY_CUSTOMER_ID_FAILURE:
            return {};
        default:
            return state
    }
}

/**************** Reducers ends here. ***************************/


export async function createRegionService(data) {
    return axios.post(`/ajax/create-customer-region`, data).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error)
    })
}

function getAllRegions(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/customer-regions?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

function updateRegionService(data,regionId) {
    return axios.put(`/ajax/update-customer-region-by-id/${regionId}`, data).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error);
    })
}

function deleteRegionService(regionId) {
    return axios.delete(`/ajax/delete-region-by-id/${regionId}`).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error);
    })
}


function getRegionByIdService(regionId) {
    return axios.get(`/ajax/customer-region-by-id/${regionId}`).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error);
    })
}


function getRegionsByCustomerId(customerId) {
    return axios.get(`/ajax/get-region-by-customer-id/${customerId}`).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error);
    })
}




