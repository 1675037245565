import axios from "axios";
import {showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {alertActions} from "../../alert/alert";
import {history} from "../../../common/helpers/history";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";

//export actions
export const countyActions = {
    getAllData,
    submitCountyAction,
    getCountyByStateAbbrAction,
    getCountyByStateIdAction,
    getCountyByIdAction,
    updateCountyAction,
    exportData,
};

//export constants
export const countyConstants = {
    GET_ALL_COUNTY_REQUEST: 'GET_ALL_COUNTY_REQUEST',
    GET_ALL_COUNTY_SUCCESS: 'GET_ALL_COUNTY_SUCCESS',
    GET_ALL_COUNTY_FAILURE: 'GET_ALL_COUNTY_FAILURE',

    CREATE_COUNTY_REQUEST: 'CREATE_COUNTY_REQUEST',
    CREATE_COUNTY_SUCCESS: 'CREATE_COUNTY_SUCCESS',
    CREATE_COUNTY_FAILURE: 'CREATE_COUNTY_FAILURE',

    GET_ALL_COUNTY_LIST_REQUEST: 'GET_ALL_COUNTY_LIST_REQUEST',
    GET_ALL_COUNTY_LIST_SUCCESS: 'GET_ALL_COUNTY_LIST_SUCCESS',
    GET_ALL_COUNTY_LIST_FAILURE: 'GET_ALL_COUNTY_LIST_FAILURE',

    GET_ALL_COUNTY_BY_STATE_ABBR_REQUEST: 'GET_ALL_COUNTY_BY_STATE_ABBR_REQUEST',
    GET_ALL_COUNTY_BY_STATE_ABBR_SUCCESS: 'GET_ALL_COUNTY_BY_STATE_ABBR_SUCCESS',
    GET_ALL_COUNTY_BY_STATE_ABBR_FAILURE: 'GET_ALL_COUNTY_BY_STATE_ABBR_FAILURE',
    RESET_COUNTY_BY_STATE_ABBR: 'RESET_COUNTY_BY_STATE_ABBR',


    GET_COUNTY_BY_NAME_AND_STATE_REQUEST: 'GET_COUNTY_BY_NAME_AND_STATE_REQUEST',
    GET_COUNTY_BY_NAME_AND_STATE_SUCCESS: 'GET_COUNTY_BY_NAME_AND_STATE_SUCCESS',
    GET_COUNTY_BY_NAME_AND_STATE_FAILURE: 'GET_COUNTY_BY_NAME_AND_STATE_FAILURE',

    UPDATE_COUNTY_REQUEST: 'UPDATE_COUNTY_REQUEST',
    UPDATE_COUNTY_SUCCESS: 'UPDATE_COUNTY_SUCCESS',
    UPDATE_COUNTY_FAILURE: 'UPDATE_COUNTY_FAILURE',

    EXPORT_COUNTY_DATA_REQUEST: 'EXPORT_COUNTY_DATA_REQUEST',
    EXPORT_COUNTY_DATA_SUCCESS: 'EXPORT_COUNTY_DATA_SUCCESS',
    EXPORT_COUNTY_DATA_FAILURE: 'EXPORT_COUNTY_DATA_FAILURE',

    GET_COUNTY_BY_STATE_ID_REQUEST: 'GET_COUNTY_BY_STATE_ID_REQUEST',
    GET_COUNTY_BY_STATE_ID_SUCCESS: 'GET_COUNTY_BY_STATE_ID_SUCCESS',
    GET_COUNTY_BY_STATE_ID_FAILURE: 'GET_COUNTY_BY_STATE_ID_FAILURE',

    GET_COUNTY_BY_STATE_NAME_REQUEST: 'GET_COUNTY_BY_STATE_NAME_REQUEST',
    GET_COUNTY_BY_STATE_NAME_SUCCESS: 'GET_COUNTY_BY_STATE_NAME_SUCCESS',
    GET_COUNTY_BY_STATE_NAME_FAILURE: 'GET_COUNTY_BY_STATE_NAME_FAILURE',

    GET_COUNTY_BY_MSA_ID_REQUEST: 'GET_COUNTY_BY_MSA_ID_REQUEST',
    GET_COUNTY_BY_MSA_ID_SUCCESS: 'GET_COUNTY_BY_MSA_ID_SUCCESS',
    GET_COUNTY_BY_MSA_ID_FAILURE: 'GET_COUNTY_BY_MSA_ID_FAILURE',


};

//export service
export const countyService = {
    getAllCounties,
    createCounty,
    getAllCountyByStateAbbr,
    getCountyByStateId,
    getCountyById,
    updateCounty,
    exportCountyData,
};


{/******************************** county Actions Starts here  ****************************************/
}

//get all County Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());
        countyService.getAllCounties(query)
            .then(
                counties => dispatch(success(counties)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: countyConstants.GET_ALL_COUNTY_REQUEST}
    }

    function success(counties) {
        return {type: countyConstants.GET_ALL_COUNTY_SUCCESS, counties}
    }

    function failure(error) {
        return {type: countyConstants.GET_ALL_COUNTY_FAILURE, error}
    }
}

//submit County Action
function updateCountyAction(obj, id, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        countyService.updateCounty(obj, id)
            .then(
                county => {
                    if (county['errorCode'] === 200) {
                        showSuccessToast("County updated successfully!");
                        dispatch(success(county));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(county);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(county) {
        return {type: countyConstants.UPDATE_COUNTY_REQUEST, county}
    }

    function success(county) {
        return {type: countyConstants.UPDATE_COUNTY_SUCCESS, county}
    }

    function failure(error) {
        return {type: countyConstants.UPDATE_COUNTY_FAILURE, error}
    }
}


function submitCountyAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        countyService.createCounty(obj)
            .then(
                county => {
                    if (county['errorCode'] === 200) {
                        showSuccessToast("County added successfully!");
                        dispatch(success(county));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(county);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(county) {
        return {type: countyConstants.CREATE_COUNTY_REQUEST, county}
    }

    function success(county) {
        return {type: countyConstants.CREATE_COUNTY_SUCCESS, county}
    }

    function failure(error) {
        return {type: countyConstants.CREATE_COUNTY_FAILURE, error}
    }
}


//get all County Action
function getCountyByStateAbbrAction(stateAbbr, isReset = false) {
    return dispatch => {
        if (isReset === false) {
            dispatch(request());
            countyService.getAllCountyByStateAbbr(stateAbbr)
                .then(
                    county => dispatch(success(county)),
                    error => dispatch(failure(error.toString()))
                );
        } else {
            dispatch(reset())
        }
    };

    function request() {
        return {type: countyConstants.GET_ALL_COUNTY_BY_STATE_ABBR_REQUEST}
    }

    function success(county) {
        return {type: countyConstants.GET_ALL_COUNTY_BY_STATE_ABBR_SUCCESS, county}
    }

    function failure(error) {
        return {type: countyConstants.GET_ALL_COUNTY_BY_STATE_ABBR_FAILURE, error}
    }

    function reset() {
        return {type: countyConstants.RESET_COUNTY_BY_STATE_ABBR}
    }
}


function getCountyByStateIdAction(stateId) {
    return dispatch => {
        dispatch(request());

        countyService.getCountyByStateId(stateId)
            .then(
                counties => dispatch(success(counties)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: countyConstants.GET_COUNTY_BY_STATE_ID_REQUEST}
    }

    function success(counties) {
        return {type: countyConstants.GET_COUNTY_BY_STATE_ID_SUCCESS, counties}
    }

    function failure(error) {
        return {type: countyConstants.GET_COUNTY_BY_STATE_ID_FAILURE, error}
    }

}

function getCountyByIdAction(countyId) {
    return dispatch => {
        dispatch(request());

        countyService.getCountyById(countyId)
            .then(
                county => dispatch(success(county)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: countyConstants.GET_COUNTY_BY_NAME_AND_STATE_REQUEST}
    }

    function success(county) {
        return {type: countyConstants.GET_COUNTY_BY_NAME_AND_STATE_SUCCESS, county}
    }

    function failure(error) {
        return {type: countyConstants.GET_COUNTY_BY_NAME_AND_STATE_FAILURE, error}
    }
}


//export County Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        countyService.exportCountyData(query)
            .then(
                countyData => dispatch(success(countyData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: countyConstants.EXPORT_COUNTY_DATA_REQUEST}
    }

    function success(countyData) {
        query.setExportBtn(false);
        return {type: countyConstants.EXPORT_COUNTY_DATA_SUCCESS, countyData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: countyConstants.EXPORT_COUNTY_DATA_FAILURE, error}
    }
}

{/************************************* county Actions Ends here ********************************************/
}


{/************************** Reducers starts here. **************************************/
}

//get all counties reducer
export function getAllCountiesReducer(state = {}, action) {
    switch (action.type) {
        case countyConstants.GET_ALL_COUNTY_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case countyConstants.GET_ALL_COUNTY_SUCCESS:
            return {
                loading: false,
                loggingIn: true,
                items: action.counties
            };
        case countyConstants.GET_ALL_COUNTY_FAILURE:
            return {};
        default:
            return state
    }
}

export function getAllCountiesByStateIdReducer(state = {}, action) {
    switch (action.type) {
        case countyConstants.GET_COUNTY_BY_STATE_ID_REQUEST:
            return {
                ...state,
                loggingIn: true,
                loading: true
            };
        case countyConstants.GET_COUNTY_BY_STATE_ID_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.counties
            };
        case countyConstants.GET_COUNTY_BY_STATE_ID_FAILURE:
            return {
                loading: false
            };
        default:
            return state
    }
}


//get all county list reducer
export function getCountyByStateAbbrReducer(state = {}, action) {
    switch (action.type) {
        case countyConstants.GET_ALL_COUNTY_BY_STATE_ABBR_REQUEST:
            return {
                loggingIn: true,
            };
        case countyConstants.GET_ALL_COUNTY_BY_STATE_ABBR_SUCCESS:
            return {
                loggedIn: true,
                items: action.county
            };
        case countyConstants.GET_ALL_COUNTY_BY_STATE_ABBR_FAILURE:
            return {};
        case countyConstants.RESET_COUNTY_BY_STATE_ABBR:
            return {};
        default:
            return state
    }
}

export function getCountyByIdReducer(state = {}, action) {
    switch (action.type) {
        case countyConstants.GET_COUNTY_BY_NAME_AND_STATE_REQUEST:
            return {
                loggingIn: true,
            };
        case countyConstants.GET_COUNTY_BY_NAME_AND_STATE_SUCCESS:
            return {
                loggedIn: true,
                items: action.county
            };
        case countyConstants.GET_COUNTY_BY_NAME_AND_STATE_FAILURE:
            return {};
        default:
            return state
    }
}



{/*************************** Reducers ends here. **************************************/
}


{/******************************County Services starts here  ****************************************/
}

//get all counties
export function getAllCounties(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/counties?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

// create new County
export function createCounty(obj) {

    return axios.get(`/ajax/state-abbr/${obj.state}`)
        .then((response) => {
            const addCountyBody = {
                ...obj,
                stateAbbreviation: obj.state,
                state: response.data,
            }

            return axios.post(`/ajax/submitCounty`, addCountyBody)
                .then((response) => {
                    return response.data;
                }).catch((error) => {
                    return Promise.reject(error);
                })
        })
}


//get all County by State Abbr
export async function getAllCountyByStateAbbr(stateAbbreviation) {
    return await axios.get(`/ajax/county-by-state/${stateAbbreviation}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

//get all County by State ID
export async function getCountyByStateId(stateId) {
    return await axios.get(`/ajax/county-by-state-id/${stateId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export function getCountyById(countyId) {
    return axios.get(`/ajax/get-county-by-id/${countyId}`).then(
        (response) => {
            return response.data;
        })
}


///update county by name//
export function updateCounty(obj, id) {
    return axios.get(`/ajax/state-abbr/${obj.state}`)
        .then((response) => {
            const addCountyBody = {
                ...obj,
                state: response.data,
            }
            return axios.put(`/ajax/update-county-by-id/${id}`, addCountyBody)
                .then((response) => {
                    return response.data;
                }).catch((error) => {
                    return Promise.reject(error);
                })
        })
}


//export County Data Service
export async function exportCountyData(query) {
    let paramData = serialize(query)
    return axios.post(`/export/county?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "countyData");
            }
        })
        .catch(handleExportError);
}

{/******************************County Services ends here  ****************************************/
}



