import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";

export const scraperModuleActions = {
    getAllData,
    getScraperModuleById,
    createScraperModule,
    updateScraperModule,
    exportScraperData,
    getScraperAllModules,
    deleteScraperModuleAction
}

export const scraperModuleService = {
    getScrapersModuleService,
    getScraperModuleByIdService,
    createScraperModuleService,
    updateScraperModuleService,
    exportScraperDataService,
    getScraperAllModulesService,
    deleteScraperModuleService
}

export const scraperModuleConstant = {
    GET_ALL_SCRAPERS_MODULE_REQUEST: 'GET_ALL_SCRAPERS_MODULE_REQUEST',
    GET_ALL_SCRAPERS_MODULE_SUCCESS: 'GET_ALL_SCRAPERS_MODULE_SUCCESS',
    GET_ALL_SCRAPERS_MODULE_FAILURE: 'GET_ALL_SCRAPERS_MODULE_FAILURE',

    GET_SCRAPERS_MODULE_BY_ID_REQUEST: 'GET_SCRAPERS_MODULE_BY_ID_REQUEST',
    GET_SCRAPERS_MODULE_BY_ID_SUCCESS: 'GET_SCRAPERS_MODULE_BY_ID_SUCCESS',
    GET_SCRAPERS_MODULE_BY_ID_FAILURE: 'GET_SCRAPERS_MODULE_BY_ID_FAILURE',

    CREATE_SCRAPERS_MODULE_REQUEST: 'CREATE_SCRAPERS_MODULE_REQUEST',
    CREATE_SCRAPERS_MODULE_SUCCESS: 'CREATE_SCRAPERS_MODULE_SUCCESS',
    CREATE_SCRAPERS_MODULE__FAILURE: 'CREATE_SCRAPERS_MODULE_FAILURE',

    UPDATE_SCRAPERS_MODULE_REQUEST: 'UPDATE_SCRAPERS_MODULE_REQUEST',
    UPDATE_SCRAPERS_MODULE_SUCCESS: 'UPDATE_SCRAPERS_MODULE_SUCCESS',
    UPDATE_SCRAPERS_MODULE__FAILURE: 'UPDATE_SCRAPERS_MODULE_FAILURE',

    EXPORT_SCRAPER_DATA_REQUEST: 'EXPORT_SCRAPER_DATA_REQUEST',
    EXPORT_SCRAPER_DATA_SUCCESS: 'EXPORT_SCRAPER_DATA_SUCCESS',
    EXPORT_SCRAPER_DATA_FAILURE: 'EXPORT_SCRAPER_DATA_FAILURE',

    GET_SCRAPERS_MODULE_LIST_REQUEST: 'GET_SCRAPERS_MODULE_LIST_REQUEST',
    GET_SCRAPERS_MODULE_LIST_SUCCESS: 'GET_SCRAPERS_MODULE_LIST_SUCCESS',
    GET_SCRAPERS_MODULE_LIST_FAILURE: 'GET_SCRAPERS_MODULE_LIST_FAILURE',

    DELETE_SCRAPERS_MODULE_REQUEST: 'DELETE_SCRAPERS_MODULE_REQUEST',
    DELETE_SCRAPERS_MODULE_SUCCESS: 'DELETE_SCRAPERS_MODULE_SUCCESS',
    DELETE_SCRAPERS_MODULE_FAILURE: 'DELETE_SCRAPERS_MODULE_FAILURE',

}

function getAllData(query) {
    return dispatch => {
        dispatch(request());
        scraperModuleService.getScrapersModuleService(query)
            .then(
                scraperModules => dispatch(success(scraperModules)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: scraperModuleConstant.GET_ALL_SCRAPERS_MODULE_REQUEST}
    }

    function success(scraperModules) {
        return {type: scraperModuleConstant.GET_ALL_SCRAPERS_MODULE_SUCCESS, scraperModules}
    }

    function failure(error) {
        return {type: scraperModuleConstant.GET_ALL_SCRAPERS_MODULE_FAILURE, error}
    }
}

function deleteScraperModuleAction(deleteQuery) {
    return dispatch => {
        dispatch(request());
        scraperModuleService.deleteScraperModuleService(deleteQuery.deleteReference)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        showSuccessToast("ScraperModule deleted successfully!");
                        dispatch(success(data));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(data);
                    }
                    deleteQuery.setDisable(false)

                },
                error => {
                    deleteQuery.setDisable(false)
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: scraperModuleConstant.DELETE_SCRAPERS_MODULE_REQUEST}
    }

    function success(data) {
        return {type: scraperModuleConstant.DELETE_SCRAPERS_MODULE_REQUEST, data}
    }

    function failure(error) {
        return {type: scraperModuleConstant.DELETE_SCRAPERS_MODULE_FAILURE, error}
    }

}


// get By id
function getScraperModuleById(scraperModuleId) {
    return dispatch => {
        dispatch(request());
        scraperModuleService.getScraperModuleByIdService(scraperModuleId)
            .then(
                code => dispatch(success(code)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: scraperModuleConstant.GET_SCRAPERS_MODULE_BY_ID_REQUEST}
    }

    function success(scraperModule) {
        return {type: scraperModuleConstant.GET_SCRAPERS_MODULE_BY_ID_SUCCESS, scraperModule}
    }

    function failure(error) {
        return {type: scraperModuleConstant.GET_SCRAPERS_MODULE_BY_ID_FAILURE, error}
    }
}


function createScraperModule(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        scraperModuleService.createScraperModuleService(obj)
            .then(
                scrapers => {
                    if (scrapers['errorCode'] === 200) {
                        showSuccessToast("Scraper Module Created Successfully!");
                        dispatch(success(scrapers));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(scrapers);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(scrapers) {
        return {type: scraperModuleConstant.CREATE_SCRAPERS_MODULE_REQUEST, scrapers}
    }

    function success(scrapers) {
        return {type: scraperModuleConstant.CREATE_SCRAPERS_MODULE_SUCCESS, scrapers}
    }

    function failure(error) {
        return {type: scraperModuleConstant.CREATE_SCRAPERS_MODULE__FAILURE, error}
    }
}


function updateScraperModule(obj, id, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        scraperModuleService.updateScraperModuleService(obj, id)
            .then(
                scrapers => {
                    if (scrapers['errorCode'] === 200) {
                        showSuccessToast("Scraper Module Updated Successfully!");
                        dispatch(success(scrapers));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(scrapers);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(scrapers) {
        return {type: scraperModuleConstant.UPDATE_SCRAPERS_MODULE_REQUEST, scrapers}
    }

    function success(scrapers) {
        return {type: scraperModuleConstant.UPDATE_SCRAPERS_MODULE_SUCCESS, scrapers}
    }

    function failure(error) {
        return {type: scraperModuleConstant.UPDATE_SCRAPERS_MODULE__FAILURE, error}
    }
}


//export Scraper Data
function exportScraperData(query) {
    return dispatch => {
        dispatch(request());
        scraperModuleService.exportScraperDataService(query)
            .then(
                scraperData => dispatch(success(scraperData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: scraperModuleConstant.EXPORT_SCRAPER_DATA_REQUEST}
    }

    function success(scraperData) {
        query.setExportBtn(false);
        return {type: scraperModuleConstant.EXPORT_SCRAPER_DATA_SUCCESS, scraperData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: scraperModuleConstant.EXPORT_SCRAPER_DATA_FAILURE, error}
    }
}


// get All by list
function getScraperAllModules() {
    return dispatch => {
        dispatch(request());
        scraperModuleService.getScraperAllModulesService()
            .then(
                scraperModules => dispatch(success(scraperModules)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: scraperModuleConstant.GET_SCRAPERS_MODULE_LIST_REQUEST}
    }

    function success(scraperModules) {
        return {type: scraperModuleConstant.GET_SCRAPERS_MODULE_LIST_SUCCESS, scraperModules}
    }

    function failure(error) {
        return {type: scraperModuleConstant.GET_SCRAPERS_MODULE_LIST_FAILURE, error}
    }
}


/////Reducer//////
export function getAllScrapersModuleReducer(state = {}, action) {
    switch (action.type) {
        case scraperModuleConstant.GET_ALL_SCRAPERS_MODULE_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case scraperModuleConstant.GET_ALL_SCRAPERS_MODULE_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.scraperModules
            };
        case  scraperModuleConstant.GET_ALL_SCRAPERS_MODULE_FAILURE:
            return {};
        default:
            return state
    }
}


export function getScraperModuleByIdReducer(state = {}, action) {
    switch (action.type) {
        case scraperModuleConstant.GET_SCRAPERS_MODULE_BY_ID_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case scraperModuleConstant.GET_SCRAPERS_MODULE_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.scraperModule
            };
        case  scraperModuleConstant.GET_SCRAPERS_MODULE_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}


export function getAllScrapersModuleByListReducer(state = {}, action) {
    switch (action.type) {
        case scraperModuleConstant.GET_SCRAPERS_MODULE_LIST_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case scraperModuleConstant.GET_SCRAPERS_MODULE_LIST_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.scraperModules
            };
        case  scraperModuleConstant.GET_SCRAPERS_MODULE_LIST_FAILURE:
            return {};
        default:
            return state
    }
}


export function getScrapersModuleService(query) {
    let paramData = serialize(query);
    return axios.get(`/ajax/scraper-modules?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export function getScraperModuleByIdService(scraperModuleId) {
    return axios.get(`/ajax/get-scraper-modules/${scraperModuleId}`).then(
        (response) => {
            return response.data;
        });
}


//submit scraper module
export function createScraperModuleService(obj) {
    return axios.post(`/ajax/create/scraperModule`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

//update
export function updateScraperModuleService(obj, scraperModuleId) {
    return axios.put(`/ajax/update/scraperModule/${scraperModuleId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function deleteScraperModuleService(scraperModuleId){
    return axios.delete(`/ajax/modules/scraper/${scraperModuleId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

//export Scraper Module Data Service
export async function exportScraperDataService(query) {
    let paramData = serialize(query)
    return axios.post(`/export/scraper?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "scraperModuleData");
            }
        })
        .catch(handleExportError);
}


export function getScraperAllModulesService() {
    return axios.get(`/ajax/get-scraper-modules-list`).then(
        (response) => {
            return exceptionHandler(response);
        });
}


