import axios from "axios";
import {showErrorToast, showSuccessToast} from "../../common/components/Toast";
import {alertActions} from "../alert/alert";
import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import {history} from "../../common/helpers/history";
import {serialize} from "../../common/helpers/utils";

export const branchesActions = {
    getAllData,
    submitBranchAction,
    submitBranchContactAction,
    updateBranchContactAction,
    getBranchByNameAction,
    getBranchContactsAction,
    deleteBranchContactAction,
    updateBranchAction,
    deleteBranchAction,
    getBranchByIdAction,
    getBranchByCustomerIdAction,
    getBranchByCustomerIdsAction,
    getContactByIdAction
}

export const branchesConstants = {
    GET_ALL_BRANCH_REQUEST: 'GET_ALL_BRANCH_REQUEST',
    GET_ALL_BRANCH_SUCCESS: 'GET_ALL_BRANCH_SUCCESS',
    GET_ALL_BRANCH_FAILURE: 'GET_ALL_BRANCH_FAILURE',

    CREATE_BRANCH_REQUEST: 'CREATE_BRANCH_REQUEST',
    CREATE_BRANCH_SUCCESS: 'CREATE_BRANCH_SUCCESS',
    CREATE_BRANCH_FAILURE: 'CREATE_BRANCH_FAILURE',


    CREATE_BRANCH_CONTACT_REQUEST: 'CREATE_BRANCH_CONTACT_REQUEST',
    CREATE_BRANCH_CONTACT_SUCCESS: 'CREATE_BRANCH_CONTACT_SUCCESS',
    CREATE_BRANCH_CONTACT_FAILURE: 'CREATE_BRANCH_CONTACT_FAILURE',

    UPDATE_BRANCH_REQUEST: 'UPDATE_BRANCH_REQUEST',
    UPDATE_BRANCH_SUCCESS: 'UPDATE_BRANCH_SUCCESS',
    UPDATE_BRANCH_FAILURE: 'UPDATE_BRANCH_FAILURE',

    UPDATE_BRANCH_CONTACT_REQUEST: 'UPDATE_BRANCH_CONTACT_REQUEST',
    UPDATE_BRANCH_CONTACT_SUCCESS: 'UPDATE_BRANCH_CONTACT_SUCCESS',
    UPDATE_BRANCH_CONTACT_FAILURE: 'UPDATE_BRANCH_CONTACT_FAILURE',

    GET_BRANCH_BY_NAME_REQUEST: 'GET_BRANCH_BY_NAME_REQUEST',
    GET_BRANCH_BY_NAME_SUCCESS: 'GET_BRANCH_BY_NAME_SUCCESS',
    GET_BRANCH_BY_NAME_FAILURE: 'GET_BRANCH_BY_NAME_FAILURE',
    GET_BRANCH_BY_NAME_REST: 'GET_BRANCH_BY_NAME_REST',


    GET_BRANCH_CONTACTS_REQUEST: 'GET_BRANCH_CONTACTS_REQUEST',
    GET_BRANCH_CONTACTS_SUCCESS: 'GET_BRANCH_CONTACTS_SUCCESS',
    GET_BRANCH_CONTACTS_FAILURE: 'GET_BRANCH_CONTACTS_FAILURE',
    GET_BRANCH_CONTACTS_REST: 'GET_BRANCH_CONTACTS_REST',

    DELETE_BRANCH_REQUEST: 'DELETE_BRANCH_REQUEST',
    DELETE_BRANCH_SUCCESS: 'DELETE_BRANCH_SUCCESS',
    DELETE_BRANCH_FAILURE: 'DELETE_BRANCH_FAILURE',

    GET_BRANCH_BY_ID_REQUEST: 'GET_BRANCH_BY_ID_REQUEST',
    GET_BRANCH_BY_ID_SUCCESS: 'GET_BRANCH_BY_ID_SUCCESS',
    GET_BRANCH_BY_ID_FAILURE: 'GET_BRANCH_BY_ID_FAILURE',

    GET_BRANCH_BY_CUSTOMER_ID_REQUEST: 'GET_BRANCH_BY_CUSTOMER_ID_REQUEST',
    GET_BRANCH_BY_CUSTOMER_ID_SUCCESS: 'GET_BRANCH_BY_CUSTOMER_ID_SUCCESS',
    GET_BRANCH_BY_CUSTOMER_ID_FAILURE: 'GET_BRANCH_BY_CUSTOMER_ID_FAILURE',

    GET_BRANCH_BY_CUSTOMER_IDS_REQUEST: 'GET_BRANCH_BY_CUSTOMER_IDS_REQUEST',
    GET_BRANCH_BY_CUSTOMER_IDS_SUCCESS: 'GET_BRANCH_BY_CUSTOMER_IDS_SUCCESS',
    GET_BRANCH_BY_CUSTOMER_IDS_FAILURE: 'GET_BRANCH_BY_CUSTOMER_IDS_FAILURE',

    GET_BRANCH_CONTACT_BY_ID_REQUEST: 'GET_BRANCH_CONTACT_BY_ID_REQUEST',
    GET_BRANCH_CONTACT_BY_ID_SUCCESS: 'GET_BRANCH_CONTACT_BY_ID_SUCCESS',
    GET_BRANCH_CONTACT_BY_ID_FAILURE: 'GET_BRANCH_CONTACT_BY_ID_FAILURE',

    DELETE_BRANCH_CONTACT_REQUEST: 'DELETE_BRANCH_CONTACT_REQUEST',
    DELETE_BRANCH_CONTACT_SUCCESS: 'DELETE_BRANCH_CONTACT_SUCCESS',
    DELETE_BRANCH_CONTACT_FAILURE: 'DELETE_BRANCH_CONTACT_FAILURE',

}

//export service
export const branchesService = {
    getAllBranches,
    submitBranchService,
    submitBranchContactService,
    updateBranchContactService,
    getBranchByNameService,
    getBranchContactService,
    updateBranchService,
    deleteBranchService,
    getBranchByIdService,
    getBranchByCustomerId,
    getBranchByCustomerIds,
    getContactById,
    deleteBranchContact
}

function getAllData(query) {
    return dispatch => {
        dispatch(request());

        branchesService.getAllBranches(query)
            .then(
                branches => dispatch(success(branches)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: branchesConstants.GET_ALL_BRANCH_REQUEST}
    }

    function success(branches) {
        return {type: branchesConstants.GET_ALL_BRANCH_SUCCESS, branches}
    }

    function failure(error) {
        return {type: branchesConstants.GET_ALL_BRANCH_FAILURE, error}
    }

}

function submitBranchAction(obj, setDisable, setShow, query) {
    return dispatch => {
        dispatch(request({obj}));
        branchesService.submitBranchService(obj)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        showSuccessToast("Branch added successfully!");
                        setShow(false);
                        dispatch(success(data));
                        dispatch(getAllData(query));
                    } else {
                        responseDTOExceptionHandler(data);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: branchesConstants.CREATE_BRANCH_REQUEST}
    }

    function success(branch) {
        return {type: branchesConstants.CREATE_BRANCH_SUCCESS, branch}
    }

    function failure(error) {
        return {type: branchesConstants.CREATE_BRANCH_FAILURE, error}
    }

}

function getContactByIdAction(branchContactId) {
    return dispatch => {
        dispatch(request());
        branchesService.getContactById(branchContactId)
            .then(
                contact => dispatch(success(contact)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: branchesConstants.GET_BRANCH_CONTACT_BY_ID_REQUEST}
    }

    function success(contact) {
        return {type: branchesConstants.GET_BRANCH_CONTACT_BY_ID_SUCCESS, contact}
    }

    function failure(error) {
        return {type: branchesConstants.GET_BRANCH_CONTACT_BY_ID_FAILURE, error}
    }
}


function submitBranchContactAction(branchId, obj, setShow, setDisable) {
    return dispatch => {
        dispatch(request({obj}));
        branchesService.submitBranchContactService(branchId, obj)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        showSuccessToast("Branch contact added successfully!");
                        dispatch(success(data));
                        setShow(false);
                        dispatch(branchesActions.getBranchContactsAction(branchId))
                    } else {
                        responseDTOExceptionHandler(data);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(data) {
        return {type: branchesConstants.CREATE_BRANCH_CONTACT_REQUEST, data}
    }

    function success(data) {
        return {type: branchesConstants.CREATE_BRANCH_CONTACT_SUCCESS, data}
    }

    function failure(error) {
        return {type: branchesConstants.CREATE_BRANCH_CONTACT_FAILURE, error}
    }

}


function updateBranchContactAction(obj, contactId, setDisable, setShow, parentId) {
    return dispatch => {
        dispatch(request({obj}));
        branchesService.updateBranchContactService(obj, contactId, parentId)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        showSuccessToast("Branch contact updated successfully!");
                        setShow(false);
                        dispatch(success(data));
                        dispatch(branchesActions.getBranchContactsAction(obj.branchId));
                    } else {
                        responseDTOExceptionHandler(data);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(data) {
        return {type: branchesConstants.UPDATE_BRANCH_CONTACT_REQUEST, data}
    }

    function success(data) {
        return {type: branchesConstants.UPDATE_BRANCH_CONTACT_SUCCESS, data}
    }

    function failure(error) {
        return {type: branchesConstants.UPDATE_BRANCH_CONTACT_FAILURE, error}
    }

}

//submit branch by name Data
function getBranchByNameAction(data, customerId, isRest = false) {
    return dispatch => {
        if (isRest === false) {
            dispatch(request());
            branchesService.getBranchByNameService(data, customerId)
                .then(
                    branch => dispatch(success(branch)),
                    error => dispatch(failure(error.toString())));
        } else {
            dispatch(rest());
        }
    };

    function request() {
        return {type: branchesConstants.GET_BRANCH_BY_NAME_REQUEST}
    }

    function success(branch) {
        return {type: branchesConstants.GET_BRANCH_BY_NAME_SUCCESS, branch}
    }

    function failure(error) {
        return {type: branchesConstants.GET_BRANCH_BY_NAME_FAILURE, error}
    }

    function rest() {
        return {type: branchesConstants.GET_BRANCH_BY_NAME_REST}
    }
}

function getBranchContactsAction(branchId, isRest = false) {
    return dispatch => {
        if (isRest === false) {
            dispatch(request());
            branchesService.getBranchContactService(branchId)
                .then(
                    branch => dispatch(success(branch)),
                    error => dispatch(failure(error.toString())));
        } else {
            dispatch(rest());
        }
    };

    function request() {
        return {type: branchesConstants.GET_BRANCH_CONTACTS_REQUEST}
    }

    function success(branchContacts) {
        return {type: branchesConstants.GET_BRANCH_CONTACTS_SUCCESS, branchContacts}
    }

    function failure(error) {
        return {type: branchesConstants.GET_BRANCH_CONTACTS_FAILURE, error}
    }

    function rest() {
        return {type: branchesConstants.GET_BRANCH_BY_NAME_REST}
    }
}


function deleteBranchContactAction(parentId, contactId, setDisable, setShowContactDeleteConfirm, branchId) {
    return dispatch => {
        dispatch(request({parentId}));
        branchesService.deleteBranchContact(parentId)
            .then(
                branchData => {
                    if (branchData['errorCode'] === 200) {
                        showSuccessToast("Branch Contact deleted successfully!");
                        dispatch(success(branchData));
                        dispatch(getBranchContactsAction(branchId));
                    } else {
                        responseDTOExceptionHandler(branchData);
                    }
                    setDisable(false)
                    setShowContactDeleteConfirm(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(branchData) {
        return {type: branchesConstants.DELETE_BRANCH_CONTACT_REQUEST, branchData}
    }

    function success(branchData) {
        return {type: branchesConstants.DELETE_BRANCH_CONTACT_SUCCESS, branchData}
    }

    function failure(error) {
        return {type: branchesConstants.DELETE_BRANCH_CONTACT_FAILURE, error}
    }
}

//update branch Data
function updateBranchAction(data, setDisable, branchName, customerId, setEditable, branchId) {
    return dispatch => {
        dispatch(request());
        branchesService.updateBranchService(data)
            .then(
                branch => {
                    if (branch['errorCode'] === 200) {
                        setEditable(false);
                        showSuccessToast("Branch updated successfully!");
                        dispatch(success(branch));
                        dispatch(branchesActions.getBranchByIdAction(customerId,branchId));
                        history.replace(`/customers/${customerId}/${branchId}`);
                    } else {
                        responseDTOExceptionHandler(branch);
                    }
                    setDisable(false);
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: branchesConstants.UPDATE_BRANCH_REQUEST}
    }

    function success(branch) {
        return {type: branchesConstants.UPDATE_BRANCH_SUCCESS, branch}
    }

    function failure(error) {
        return {type: branchesConstants.UPDATE_BRANCH_FAILURE, error}
    }

}

function deleteBranchAction(deleteQuery) {
    return dispatch => {
        dispatch(request());
        branchesService.deleteBranchService(deleteQuery.customReference, deleteQuery.deleteReference)
            .then(
                branch => {
                    if (branch['errorCode'] === 200) {
                        showSuccessToast("Branch deleted!");
                        dispatch(success(branch));
                        deleteQuery.setShowDeleteConfirm(false)
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(branch);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: branchesConstants.DELETE_BRANCH_REQUEST}
    }

    function success(branch) {
        return {type: branchesConstants.DELETE_BRANCH_SUCCESS, branch}
    }

    function failure(error) {
        return {type: branchesConstants.DELETE_BRANCH_FAILURE, error}
    }
}


function getBranchByIdAction(customerId, branchId) {
    return dispatch => {
        dispatch(request());
        branchesService.getBranchByIdService(customerId, branchId)
            .then(
                branch => dispatch(success(branch)),
                error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: branchesConstants.GET_BRANCH_BY_ID_REQUEST}
    }

    function success(branch) {
        return {type: branchesConstants.GET_BRANCH_BY_ID_SUCCESS, branch}
    }

    function failure(error) {
        return {type: branchesConstants.GET_BRANCH_BY_ID_FAILURE, error}
    }
}


function getBranchByCustomerIdAction(customerId) {
    return dispatch => {
        dispatch(request());
        branchesService.getBranchByCustomerId(customerId)
            .then(
                branches => dispatch(success(branches)),
                error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: branchesConstants.GET_BRANCH_BY_CUSTOMER_ID_REQUEST}
    }

    function success(branches) {
        return {type: branchesConstants.GET_BRANCH_BY_CUSTOMER_ID_SUCCESS, branches}
    }

    function failure(error) {
        return {type: branchesConstants.GET_BRANCH_BY_CUSTOMER_ID_FAILURE, error}
    }
}
export function getBranchByCustomerIdsAction(customerIds) {
    return dispatch => {
        dispatch(request());
        branchesService.getBranchByCustomerIds(customerIds)
            .then(
                branches => dispatch(success(branches)),
                error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: branchesConstants.GET_BRANCH_BY_CUSTOMER_IDS_REQUEST}
    }

    function success(branches) {
        return {type: branchesConstants.GET_BRANCH_BY_CUSTOMER_IDS_SUCCESS, branches}
    }

    function failure(error) {
        return {type: branchesConstants.GET_BRANCH_BY_CUSTOMER_IDS_FAILURE, error}
    }
}

/************ Reducers started here. *******************/

//branch list reducer
export function branchesReducer(state = {}, action) {
    switch (action.type) {
        case branchesConstants.GET_ALL_BRANCH_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case branchesConstants.GET_ALL_BRANCH_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.branches
            };
        case branchesConstants.GET_ALL_BRANCH_FAILURE:
            return {};
        default:
            return state
    }
}

//branch info  by name
export function getBranchByNameReducer(state = {}, action) {
    switch (action.type) {
        case branchesConstants.GET_BRANCH_BY_NAME_REQUEST:
            return {
                loggingIn: true,
            };
        case branchesConstants.GET_BRANCH_BY_NAME_SUCCESS:
            return {
                loggedIn: true,
                items: action.branch
            };
        case branchesConstants.GET_BRANCH_BY_NAME_FAILURE:
            return {};
        case branchesConstants.GET_BRANCH_BY_NAME_REST:
            return {};
        default:
            return state
    }
}


export function getBranchContactsReducer(state = {}, action) {
    switch (action.type) {
        case branchesConstants.GET_BRANCH_CONTACTS_REQUEST:
            return {
                loggingIn: true,
            };
        case branchesConstants.GET_BRANCH_CONTACTS_SUCCESS:
            return {
                loggedIn: true,
                items: action.branchContacts
            };
        case branchesConstants.GET_BRANCH_CONTACTS_FAILURE:
            return {};
        case branchesConstants.GET_BRANCH_CONTACTS_REST:
            return {};
        default:
            return state
    }
}

//branch info  by name
export function getBranchByIdReducer(state = {}, action) {
    switch (action.type) {
        case branchesConstants.GET_BRANCH_BY_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case branchesConstants.GET_BRANCH_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.branch
            };
        case branchesConstants.GET_BRANCH_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}


//branch info  by customer id
export function getBranchByCustomerIdReducer(state = {}, action) {
    switch (action.type) {
        case branchesConstants.GET_BRANCH_BY_CUSTOMER_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case branchesConstants.GET_BRANCH_BY_CUSTOMER_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.branches
            };
        case branchesConstants.GET_BRANCH_BY_CUSTOMER_ID_FAILURE:
            return {};
        default:
            return state
    }
}
export function getBranchByCustomerIdsReducer(state = {}, action) {
    switch (action.type) {
        case branchesConstants.GET_BRANCH_BY_CUSTOMER_IDS_REQUEST:
            return {
                loggingIn: true,
            };
        case branchesConstants.GET_BRANCH_BY_CUSTOMER_IDS_SUCCESS:
            return {
                loggedIn: true,
                items: action.branches
            };
        case branchesConstants.GET_BRANCH_BY_CUSTOMER_IDS_FAILURE:
            return {};
        default:
            return state
    }
}


export function getBranchContactByIdReducer(state = {}, action) {
    switch (action.type) {
        case branchesConstants.GET_BRANCH_CONTACT_BY_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case branchesConstants.GET_BRANCH_CONTACT_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.contact
            };
        case branchesConstants.GET_BRANCH_CONTACT_BY_ID_FAILURE:
            return {};
        default:
            return state
    }

}

/**************** Reducers ends here. ***************************/


export async function submitBranchService(data) {
    return axios.post(`/ajax/createBranch`, data).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error)
    })
}

export async function submitBranchContactService(branchId, data) {
    return axios.post(`/ajax/createBranchContact/${branchId}`, data).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error)
    })
}

function getAllBranches(query) {
    let paramData = serialize(query)
    return axios.get(`/ajax/get-customer-branches?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

function getBranchByNameService(name, customerId) {
    let paramData = serialize({branchName: name});
    return axios.get(`/ajax/get-branches-by-name/${customerId}?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

function getBranchContactService(branchId) {
    console.log("---------branchId-------", branchId);
    return axios.get(`/ajax/get-branch-contacts/${branchId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

function updateBranchService(data) {
    return axios.put(`/ajax/updateBranch`, data).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error);
    })
}

function updateBranchContactService(data, contactId, parentId) {
    return axios.put(`/ajax/updateBranchContact/${contactId}/${parentId}`, data).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error);
    })
}

function deleteBranchService(customerId, branchId) {
    return axios.delete(`/ajax/delete-by-branch-id/${customerId}/${branchId}`).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error);
    })
}


function getBranchByIdService(customerId, branchId) {
    return axios.get(`/ajax/get-branch-by-id/${customerId}/${branchId}`).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error);
    })
}


function getBranchByCustomerId(customerId) {
    return axios.get(`/ajax/get-branch-by-customer-id/${customerId}`).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error);
    })
}

export function getBranchByCustomerIds(customerIds) {
    console.log(customerIds);
    let list = {customerIds:customerIds}
    let paramData = serialize(list)
    return axios.post(`/ajax/branches-by-customer-ids?${paramData}`).then((response) => {
        return exceptionHandler(response);
    }).catch((error) => {
        console.log(error);
    });
}
function deleteBranchContact(id) {
    return axios.delete(`/ajax/delete/branch-contact-by-id/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function getContactById(branchContactId) {
    console.log(branchContactId);
    return axios.get(`/ajax/get-branch-contact/${branchContactId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}




