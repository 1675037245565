import axios from "axios";
import {showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {
    GetCachedResponseFromReducer,
    handleExportError,
    resourceToFile,
    serialize
} from "../../../common/helpers/utils";

//export actions
export const billReleaseActions = {
    getAllData,
    createBillReleaseAction,
    getBillReleaseByIdAction,
    updateBillReleaseAction,
    exportData,
    deleteBillReleaseByIdAction
};

//export constants
export const billReleaseConstants = {
    GET_ALL_BILL_RELEASE_REQUEST: 'GET_ALL_BILL_RELEASE_REQUEST',
    GET_ALL_BILL_RELEASE_SUCCESS: 'GET_ALL_BILL_RELEASE_SUCCESS',
    GET_ALL_BILL_RELEASE_FAILURE: 'GET_ALL_BILL_RELEASE_FAILURE',

    CREATE_BILL_RELEASE_REQUEST: 'CREATE_BILL_RELEASE_REQUEST',
    CREATE_BILL_RELEASE_SUCCESS: 'CREATE_BILL_RELEASE_SUCCESS',
    CREATE_BILL_RELEASE_FAILURE: 'CREATE_BILL_RELEASE_FAILURE',

    GET_BILL_RELEASE_BY_ID_REQUEST: 'GET_BILL_RELEASE_BY_ID_REQUEST',
    GET_BILL_RELEASE_BY_ID_SUCCESS: 'GET_BILL_RELEASE_BY_ID_SUCCESS',
    GET_BILL_RELEASE_BY_ID_FAILURE: 'GET_BILL_RELEASE_BY_ID_FAILURE',

    UPDATE_BILL_RELEASE_REQUEST: 'UPDATE_BILL_RELEASE_REQUEST',
    UPDATE_BILL_RELEASE_SUCCESS: 'UPDATE_BILL_RELEASE_SUCCESS',
    UPDATE_BILL_RELEASE_FAILURE: 'UPDATE_BILL_RELEASE_FAILURE',

    EXPORT_BILL_RELEASE_DATA_REQUEST: 'EXPORT_BILL_RELEASE_DATA_REQUEST',
    EXPORT_BILL_RELEASE_DATA_SUCCESS: 'EXPORT_BILL_RELEASE_DATA_SUCCESS',
    EXPORT_BILL_RELEASE_DATA_FAILURE: 'EXPORT_BILL_RELEASE_DATA_FAILURE',

    DELETE_BILL_RELEASE_REQUEST: 'DELETE_BILL_RELEASE_REQUEST',
    DELETE_BILL_RELEASE_SUCCESS: 'DELETE_BILL_RELEASE_SUCCESS',
    DELETE_BILL_RELEASE_FAILURE: 'DELETE_BILL_RELEASE_FAILURE',

};

//export service
export const billReleaseService = {
    getAllBillReleases,
    createBillRelease,
    getBillReleaseById,
    updateBillRelease,
    exportBillReleaseData,
    deleteBillReleaseById
};

{/************************************* billRelease Actions Starts here  ******************************************/
}

function deleteBillReleaseByIdAction(deleteQuery) {
    return dispatch => {
        dispatch(request());
        billReleaseService.deleteBillReleaseById(deleteQuery.deleteReference)
            .then(
                billRelease => {
                    if (billRelease['errorCode'] === 200) {
                        showSuccessToast("Bill Release deleted successfully!");
                        dispatch(success(billRelease));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(billRelease);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: billReleaseConstants.DELETE_BILL_RELEASE_REQUEST}
    }

    function success(billRelease) {
        return {type: billReleaseConstants.DELETE_BILL_RELEASE_SUCCESS, billRelease}
    }

    function failure(error) {
        return {type: billReleaseConstants.DELETE_BILL_RELEASE_FAILURE, error}
    }
}

function getAllData(query) {
    return dispatch => {
        dispatch(request());

        billReleaseService.getAllBillReleases(query)

            .then(
                billReleases => dispatch(success(billReleases)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: billReleaseConstants.GET_ALL_BILL_RELEASE_REQUEST}
    }

    function success(billReleases) {
        return {type: billReleaseConstants.GET_ALL_BILL_RELEASE_SUCCESS, billReleases}
    }

    function failure(error) {
        return {type: billReleaseConstants.GET_ALL_BILL_RELEASE_FAILURE, error}
    }
}


//submit BillRelease Action
function createBillReleaseAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        billReleaseService.createBillRelease(obj)
            .then(
                billRelease => {
                    if (billRelease['errorCode'] === 200) {
                        showSuccessToast("Bill Release added successfully!");
                        dispatch(success(billRelease));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(billRelease);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(billRelease) {
        return {type: billReleaseConstants.CREATE_BILL_RELEASE_REQUEST, billRelease}
    }

    function success(billRelease) {
        return {type: billReleaseConstants.CREATE_BILL_RELEASE_SUCCESS, billRelease}
    }

    function failure(error) {
        return {type: billReleaseConstants.CREATE_BILL_RELEASE_FAILURE, error}
    }
}


function getBillReleaseByIdAction(id) {
    return dispatch => {
        if (GetCachedResponseFromReducer("getBillReleaseByIdReducer") === undefined) {
            dispatch(request());
            billReleaseService.getBillReleaseById(id)
                .then(
                    billRelease => dispatch(success(billRelease)),
                    error => dispatch(failure(error.toString()))
                );
        }
    };

    function request() {
        return {type: billReleaseConstants.GET_BILL_RELEASE_BY_ID_REQUEST}
    }

    function success(billRelease) {
        return {type: billReleaseConstants.GET_BILL_RELEASE_BY_ID_SUCCESS, billRelease}
    }

    function failure(error) {
        return {type: billReleaseConstants.GET_BILL_RELEASE_BY_ID_FAILURE, error}
    }
}


function updateBillReleaseAction(obj, billReleaseId, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        billReleaseService.updateBillRelease(obj, billReleaseId)
            .then(
                billRelease => {
                    if (billRelease['errorCode'] === 200) {
                        showSuccessToast("Bill Release updated successfully!");
                        dispatch(success(billRelease));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(billRelease);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(billRelease) {
        return {type: billReleaseConstants.UPDATE_BILL_RELEASE_REQUEST, billRelease}
    }

    function success(billRelease) {
        return {type: billReleaseConstants.UPDATE_BILL_RELEASE_SUCCESS, billRelease}
    }

    function failure(error) {
        return {type: billReleaseConstants.UPDATE_BILL_RELEASE_FAILURE, error}
    }
}

{/***************************** billRelease Actions Ends here **********************************/
}


{/****************** Reducers starts here. **************************************/
}

//get all billReleases reducer
export function getAllPagedBillReleaseReducer(billRelease = {}, action) {
    switch (action.type) {
        case billReleaseConstants.GET_ALL_BILL_RELEASE_REQUEST:
            return {
                ...billRelease,
                loading: true,
                loggingIn: true,
            };
        case billReleaseConstants.GET_ALL_BILL_RELEASE_SUCCESS:
            return {
                loading: false,
                loggingIn: true,
                items: action.billReleases
            };
        case billReleaseConstants.GET_ALL_BILL_RELEASE_FAILURE:
            return {};
        default:
            return billRelease
    }
}

//get billRelease by id
export function getBillReleaseByIdReducer(billRelease = {}, action) {
    switch (action.type) {
        case billReleaseConstants.GET_BILL_RELEASE_BY_ID_REQUEST:
            return {};
        case billReleaseConstants.GET_BILL_RELEASE_BY_ID_SUCCESS:
            return {
                items: action.billRelease
            };
        case billReleaseConstants.GET_BILL_RELEASE_BY_ID_FAILURE:
            return {};
        default:
            return billRelease
    }
}

//export BillRelease Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        billReleaseService.exportBillReleaseData(query)
            .then(
                billReleaseData => dispatch(success(billReleaseData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: billReleaseConstants.EXPORT_BILL_RELEASE_DATA_REQUEST}
    }

    function success(billReleaseData) {
        query.setExportBtn(false);
        return {type: billReleaseConstants.EXPORT_BILL_RELEASE_DATA_SUCCESS, billReleaseData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: billReleaseConstants.EXPORT_BILL_RELEASE_DATA_FAILURE, error}
    }
}



{/*************************** Reducers ends here. **************************************/
}


{/******************************BillRelease Services starts here  ****************************************/
}

//get all counties
export async function getAllBillReleases(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/billReleases?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function getBillReleaseById(id) {
    return axios.get(`/ajax/get-billRelease-by-id/${id}`).then(
        (response) => {
            return response.data;
        })
}

export async function createBillRelease(obj) {
    return axios.post(`/ajax/create-bill-release`, obj)
        .then((response) => {
            return response.data
        })
}

export async function updateBillRelease(obj, billReleaseId) {
    return axios.put(`/ajax/update-bill-release/${billReleaseId}`, obj)
        .then((response) => {
            return response.data
        }).catch((error) => {
            return Promise.reject(error);
        })
}


//export BillRelease Data Service
export async function exportBillReleaseData(query) {
    let paramData = serialize(query)
    return axios.post(`/export/billRelease?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "billReleaseData");
            }
        })
        .catch(handleExportError);
}




export async function deleteBillReleaseById(billReleaseId) {
    return axios.delete(`/ajax/delete-bill-release/${billReleaseId}`).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error)
    })
}

{/******************************BillRelease Services ends here  ****************************************/
}




