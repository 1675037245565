import axios from "axios";
import exceptionHandler from "../../../common/helpers/exceptionHandler";
import {showSuccessToast} from "../../../common/components/Toast";
import {serialize} from "../../../common/helpers/utils";

export const registeredScrapersAction = {
    getRegisteredScrapersAction,
    toggleWorkerDisabledStatusAction
};

export const registeredScrapersService = {
    getRegisteredScrapersService,
    toggleWorkerDisabledStatus
};

export const registeredScrapersConstant = {

    GET_ALL_REGISTERED_SCRAPERS_REQUEST: 'GET_ALL_REGISTERED_SCRAPERS_REQUEST',
    GET_ALL_REGISTERED_SCRAPERS_SUCCESS: 'GET_ALL_REGISTERED_SCRAPERS_SUCCESS',
    GET_ALL_REGISTERED_SCRAPERS_FAILURE: 'GET_ALL_REGISTERED_SCRAPERS_FAILURE',
};

export const toggleScraperConstant = {
    TOGGLE_SCRAPER_REQUEST: "TOGGLE_SCRAPER_REQUEST",
    TOGGLE_SCRAPER_SUCCESS: "TOGGLE_SCRAPER_SUCCESS",
    TOGGLE_SCRAPER_FAILURE: "GET_ALL_REGISTERED_SCRAPERS_FAILURE"
}

function getRegisteredScrapersAction(query) {
    return dispatch => {
        dispatch(request());
        registeredScrapersService.getRegisteredScrapersService(query)
            .then(
                scrapers => dispatch(success(scrapers)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: registeredScrapersConstant.GET_ALL_REGISTERED_SCRAPERS_REQUEST}
    }

    function success(scrapers) {
        return {type: registeredScrapersConstant.GET_ALL_REGISTERED_SCRAPERS_SUCCESS, scrapers}
    }

    function failure(error) {
        return {type: registeredScrapersConstant.GET_ALL_REGISTERED_SCRAPERS_FAILURE, error}
    }
}

function toggleWorkerDisabledStatusAction(id,setShow,setDisable) {
    return dispatch => {
        dispatch(request());
        registeredScrapersService.toggleWorkerDisabledStatus(id)
            .then(
                scrapers => {
                    showSuccessToast("Scraper status toggle Successfully!");
                    dispatch(success(scrapers));
                    dispatch(getRegisteredScrapersAction({}));
                    setShow(false);
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()))
                    setDisable(false)
                }
            );
    };

    function request() {
        return {type: toggleScraperConstant.TOGGLE_SCRAPER_REQUEST}
    }

    function success(scrapers) {
        return {type: toggleScraperConstant.TOGGLE_SCRAPER_SUCCESS, scrapers}
    }

    function failure(error) {
        return {type: toggleScraperConstant.TOGGLE_SCRAPER_FAILURE, error}
    }
}


/***********************Reducers starts here*********************************/

export function  getRegisteredScrapersReducer(state = {}, action) {
    switch (action.type) {
        case registeredScrapersConstant.GET_ALL_REGISTERED_SCRAPERS_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case registeredScrapersConstant.GET_ALL_REGISTERED_SCRAPERS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.scrapers
            };
        case  registeredScrapersConstant.GET_ALL_REGISTERED_SCRAPERS_FAILURE:
            return {};
        default:
            return state
    }
}

/***********************Reducers ends here*********************************/



/***********************service apis starts here*********************************/

//get all SystemProperty
export function getRegisteredScrapersService(query) {
    let paramData = serialize(query)
    return axios.get(`/ajax/get-registered-scrapers?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export function toggleWorkerDisabledStatus(id) {
    return axios.put(`/ajax/toggle-scraper-disabled/${id}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}




/***********************service apis ends here*********************************/

