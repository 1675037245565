import React, {useState} from "react";
import UnsavedChangesWarning from "./UnsavedChangesWarning";

export function Form({fields, onSubmit, id = 0 }) {

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    return <>
        <form action="" id={id} onSubmit={(e) => {
            setUnsavedChanges(false);
            onSubmit(e);
        }} onChange={() => {
            setUnsavedChanges(true);
        }} onSelect={() => {
            setUnsavedChanges(true);
        }}>
            {fields}
        </form>

        <UnsavedChangesWarning unsavedChanges={unsavedChanges}/>

    </>
}