import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";

//export actions
export const teamActions = {
    getAllData,
    createTeamAction,
    getTeamByIdAction,
    updateTeamAction,
    deleteTeamByIdAction,
    exportData,
    getTeamsManagerAction

}


//export constants
export const teamConstants = {
    GET_ALL_TEAMS_REQUEST: 'GET_ALL_TEAMS_REQUEST',
    GET_ALL_TEAMS_SUCCESS: 'GET_ALL_TEAMS_SUCCESS',
    GET_ALL_TEAMS_FAILURE: 'GET_ALL_TEAMS_FAILURE',

    CREATE_TEAM_REQUEST: 'CREATE_TEAM_REQUEST',
    CREATE_TEAM_SUCCESS: 'CREATE_TEAM_SUCCESS',
    CREATE_TEAM_FAILURE: 'CREATE_TEAM_FAILURE',

    GET_TEAM_BY_ID_REQUEST: 'GET_TEAM_BY_ID_REQUEST',
    GET_TEAM_BY_ID_SUCCESS: 'GET_TEAM_BY_ID_SUCCESS',
    GET_TEAM_BY_ID_FAILURE: 'GET_TEAM_BY_ID_FAILURE',

    UPDATE_TEAM_REQUEST: 'UPDATE_TEAM_REQUEST',
    UPDATE_TEAM_SUCCESS: 'UPDATE_TEAM_SUCCESS',
    UPDATE_TEAM_FAILURE: 'UPDATE_TEAM_FAILURE',

    DELETE_TEAM_REQUEST: 'DELETE_TEAM_REQUEST',
    DELETE_TEAM_SUCCESS: 'DELETE_TEAM_SUCCESS',
    DELETE_TEAM_FAILURE: 'DELETE_TEAM_FAILURE',

    EXPORT_TEAM_DATA_REQUEST: 'EXPORT_TEAM_DATA_REQUEST',
    EXPORT_TEAM_DATA_SUCCESS: 'EXPORT_TEAM_DATA_SUCCESS',
    EXPORT_TEAM_DATA_FAILURE: 'EXPORT_TEAM_DATA_FAILURE',

    GET_TEAMS_MANAGER_REQUEST: 'GET_TEAMS_MANAGER_REQUEST',
    GET_TEAMS_MANAGER_SUCCESS: 'GET_TEAMS_MANAGER_SUCCESS',
    GET_TEAMS_MANAGER_FAILURE: 'GET_TEAMS_MANAGER_FAILURE',

}


//export service
export const teamService = {
    getAllTeams,
    createTeam,
    getTeamById,
    updateTeam,
    deleteTeamById,
    exportTeamData,
    teamsManagerService

};

{/*********** Actions started here. ******************/
}

//get all team action
function getAllData(query) {
    return dispatch => {
        dispatch(request());

        teamService.getAllTeams(query)
            .then(
                teams => dispatch(success(teams)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: teamConstants.GET_ALL_TEAMS_REQUEST}
    }

    function success(teams) {
        return {type: teamConstants.GET_ALL_TEAMS_SUCCESS, teams}
    }

    function failure(error) {
        return {type: teamConstants.GET_ALL_TEAMS_FAILURE, error}
    }

}


//submit teams Data
function createTeamAction(teamBody, from, setDisable) {
    return dispatch => {
        dispatch(request());

        teamService.createTeam(teamBody)
            .then(
                team => {
                    if (team['errorCode'] === 200) {
                        showSuccessToast("Team added successfully!");
                        dispatch(success(team));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(team);
                    }
                    setDisable(false);
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: teamConstants.CREATE_TEAM_REQUEST}
    }

    function success(team) {
        return {type: teamConstants.CREATE_TEAM_SUCCESS, team}
    }

    function failure(error) {
        return {type: teamConstants.CREATE_TEAM_FAILURE, error}
    }
}


//get Team By id action
function getTeamByIdAction(id) {
    return dispatch => {
        dispatch(request());

        teamService.getTeamById(id)
            .then(
                team => dispatch(success(team)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: teamConstants.GET_TEAM_BY_ID_REQUEST}
    }

    function success(team) {
        return {type: teamConstants.GET_TEAM_BY_ID_SUCCESS, team}
    }

    function failure(error) {
        return {type: teamConstants.GET_TEAM_BY_ID_FAILURE, error}
    }

}

function getTeamsManagerAction(query) {
    return dispatch => {
        dispatch(request());

        teamService.teamsManagerService(query)
            .then(
                managers => dispatch(success(managers)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: teamConstants.GET_TEAMS_MANAGER_REQUEST}
    }

    function success(managers) {
        return {type: teamConstants.GET_TEAMS_MANAGER_SUCCESS, managers}
    }

    function failure(error) {
        return {type: teamConstants.GET_TEAMS_MANAGER_FAILURE, error}
    }

}

// update team  action
function updateTeamAction(teamBody, id, setDisable) {
    return dispatch => {
        dispatch(request());

        teamService.updateTeam(teamBody, id)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        showSuccessToast("Team updated successfully!");
                        dispatch(success(data));
                        dispatch(getTeamByIdAction(id));
                    } else {
                        responseDTOExceptionHandler(data);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: teamConstants.UPDATE_TEAM_REQUEST,}
    }

    function success(team) {
        return {type: teamConstants.UPDATE_TEAM_SUCCESS, team}
    }

    function failure(error) {
        return {type: teamConstants.UPDATE_TEAM_SUCCESS, error}
    }
}


// delete team  action
function deleteTeamByIdAction(deleteQuery) {
    return dispatch => {
        dispatch(request());

        teamService.deleteTeamById(deleteQuery.deleteReference)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        showSuccessToast("Team deleted successfully!");
                        dispatch(success(data));
                        deleteQuery.setShowDeleteConfirm(false)
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(data);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: teamConstants.DELETE_TEAM_REQUEST,}
    }

    function success(team) {
        return {type: teamConstants.DELETE_TEAM_SUCCESS, team}
    }

    function failure(error) {
        return {type: teamConstants.DELETE_TEAM_FAILURE, error}
    }
}

function exportData(query) {
    return dispatch => {
        dispatch(request());
        teamService.exportTeamData(query)
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: teamConstants.EXPORT_TEAM_DATA_REQUEST}
    }

    function success(userData) {
        query.setExportBtn(false);
        return {type: teamConstants.EXPORT_TEAM_DATA_SUCCESS, userData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: teamConstants.EXPORT_TEAM_DATA_FAILURE, error}
    }

}


/*********** Actions ended here. ******************/


/*********** Reducers started here. ******************/
//get all team reducer
export function getAllTeamReducer(state = {}, action) {
    switch (action.type) {
        case teamConstants.GET_ALL_TEAMS_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case teamConstants.GET_ALL_TEAMS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.teams
            };
        case teamConstants.GET_ALL_TEAMS_FAILURE:
            return {};
        default:
            return state
    }
}

export function getTeamByIdReducer(state = {}, action) {
    switch (action.type) {
        case teamConstants.GET_TEAM_BY_ID_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case teamConstants.GET_TEAM_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.team
            };
        case teamConstants.GET_TEAM_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}


export function getTeamsManagersReducer(state = {}, action) {
    switch (action.type) {
        case teamConstants.GET_TEAMS_MANAGER_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case teamConstants.GET_TEAMS_MANAGER_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.managers
            };
        case teamConstants.GET_TEAMS_MANAGER_FAILURE:
            return {};
        default:
            return state
    }
}

/*********** Reducers ended here. ******************/


/*********** Services started here. ******************/

export async function getAllTeams(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/teams?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


// submit team data
export function createTeam(inputBody) {
    return axios.post(`/ajax/submit/team`, inputBody)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


//get team by id service
export function getTeamById(id) {
    return axios.get(`/ajax/get-team-by-id/${id}`).then(
        (response) => {
            return response.data;
        })
}

// update team service
export function updateTeam(teamBody, teamId) {
    return axios.put(`/ajax/team/update/${teamId}`, teamBody)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

// update team service
export function deleteTeamById(teamId) {
    return axios.delete(`/ajax/team/delete/${teamId}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export async function exportTeamData(query) {
    let paramData = serialize(query)
    return axios.post(`/export/team?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "teamData");
            }
        })
        .catch(handleExportError);
}

export function teamsManagerService(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/teams-managers?${paramData}`).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}



/*********** Services ended here. ******************/


