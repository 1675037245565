//export actions
import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {serialize} from "../../../common/helpers/utils";


export const systemActions = {
    getAllData,
    updateSystemPropertyAction,
    getAllSystemPropertyByIdAction

};

//export constants
export const systemPropertyConstants = {
    GET_ALL_SYSTEM_PROPERTY_REQUEST: 'GET_ALL_SYSTEM_PROPERTY_REQUEST',
    GET_ALL_SYSTEM_PROPERTY_SUCCESS: 'GET_ALL_SYSTEM_PROPERTY_SUCCESS',
    GET_ALL_SYSTEM_PROPERTY_FAILURE: 'GET_ALL_SYSTEM_PROPERTY_FAILURE',


    UPDATE_SYSTEM_PROPERTY_REQUEST: 'UPDATE_SYSTEM_PROPERTY_REQUEST',
    UPDATE_SYSTEM_PROPERTY_SUCCESS: 'UPDATE_SYSTEM_PROPERTY_SUCCESS',
    UPDATE_SYSTEM_PROPERTY_FAILURE: 'UPDATE_SYSTEM_PROPERTY_FAILURE',

    GET_SYSTEM_PROPERTY_BY_ID_REQUEST: 'GET_SYSTEM_PROPERTY_BY_ID_REQUEST',
    GET_SYSTEM_PROPERTY_BY_ID_SUCCESS: 'GET_SYSTEM_PROPERTY_BY_ID_SUCCESS',
    GET_SYSTEM_PROPERTY_BY_ID_FAILURE: 'GET_SYSTEM_PROPERTY_BY_ID_FAILURE',
};


//export service
export const systemPropertyService = {
    getAllSystemProperty,
    updateSystemProperty,
    getAllSystemPropertyById


}


//get all System Property Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());

        systemPropertyService.getAllSystemProperty(query)
            .then(
                systemProperties => dispatch(success(systemProperties)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: systemPropertyConstants.GET_ALL_SYSTEM_PROPERTY_REQUEST}
    }

    function success(systemProperties) {
        return {type: systemPropertyConstants.GET_ALL_SYSTEM_PROPERTY_SUCCESS, systemProperties}
    }

    function failure(error) {
        return {type: systemPropertyConstants.GET_ALL_SYSTEM_PROPERTY_FAILURE, error}
    }
}


export function getAllSystemPropertyByIdReducer(state = {}, action) {
    switch (action.type) {
        case systemPropertyConstants.GET_SYSTEM_PROPERTY_BY_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case systemPropertyConstants.GET_SYSTEM_PROPERTY_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.systemProperties
            };
        case systemPropertyConstants.GET_SYSTEM_PROPERTY_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}


function getAllSystemPropertyByIdAction(systemPropertyId) {
    return dispatch => {
        dispatch(request());

        systemPropertyService.getAllSystemPropertyById(systemPropertyId)
            .then(
                systemProperties => dispatch(success(systemProperties)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: systemPropertyConstants.GET_SYSTEM_PROPERTY_BY_ID_REQUEST}
    }

    function success(systemProperties) {
        return {type: systemPropertyConstants.GET_SYSTEM_PROPERTY_BY_ID_SUCCESS, systemProperties}
    }

    function failure(error) {
        return {type: systemPropertyConstants.GET_SYSTEM_PROPERTY_BY_ID_FAILURE, error}
    }
}


// submit System Property Action
function updateSystemPropertyAction(obj, systemPropertyId, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        systemPropertyService.updateSystemProperty(obj, systemPropertyId)
            .then(
                systemProperty => {
                    if (systemProperty['errorCode'] === 200) {
                        showSuccessToast("System Property updated successfully!");
                        dispatch(success(systemProperty));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(systemProperty);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(systemProperty) {
        return {type: systemPropertyConstants.UPDATE_SYSTEM_PROPERTY_REQUEST, systemProperty}
    }

    function success(systemProperty) {
        return {type: systemPropertyConstants.UPDATE_SYSTEM_PROPERTY_SUCCESS, systemProperty}
    }

    function failure(error) {
        return {type: systemPropertyConstants.UPDATE_SYSTEM_PROPERTY_FAILURE, error}
    }

}

//get all SystemProperty
export function getAllSystemProperty(query) {
    let paramData = serialize(query)
    return axios.get(`/ajax/system-properties?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


//get all SystemProperty By I'd
export function getAllSystemPropertyById(systemPropertiesId) {
    return axios.get(`/ajax/system-properties/${systemPropertiesId}`).then(
        (response) => {
            return response.data;
        })
}

///update System Property by name//
export function updateSystemProperty(obj, systemPropertyId) {
    return axios.put(`/ajax/update/system-properties/${systemPropertyId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })

}


//get all system-Property reducer
export function getAllSystemPropertyReducer(state = {}, action) {
    switch (action.type) {
        case systemPropertyConstants.GET_ALL_SYSTEM_PROPERTY_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case systemPropertyConstants.GET_ALL_SYSTEM_PROPERTY_SUCCESS:
            return {
                loading: false,
                loggingIn: true,
                items: action.systemProperties
            };
        case systemPropertyConstants.GET_ALL_SYSTEM_PROPERTY_FAILURE:
            return {};
        default:
            return state
    }
}

