import React, {useEffect, useState} from "react";
import Label from "../../../../common/helpers/Label";
import {history} from "../../../../common/helpers/history";
import {
    CommonSelectDropDownTag,
    CommonSubmitLoader,
    isUserHaveAccess,
    useAuthorities,
    useEnumByName
} from "../../../../common/helpers/utils";
import "../../jurisdiction/templates/jurisdiction.css";
import {isArray} from "chart.js/helpers";
import Preloader from "../../../../common/components/Preloader";
import Breadcrumb from "../../../../common/components/Breadcrumb";
import {useLocation} from "react-router";
import {useDispatch} from "react-redux";
import {cannedNotesActions} from "../cannedNotes";
import CommonDeleteBtn from "../../../../common/components/CommonDeleteBtn";
import CommonAuditableInfoBtn from "../../../../common/components/CommonAuditableInfoBtn";
import {Form} from "../../../../common/components/Form";


const CannedNotesFormFields = ({formType, cannedId, crumbs, connedNotesData, isReadOnly = false}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const authorities = useAuthorities();
    const [comment, setComment] = useState('');
    const [disable, setDisable] = useState(false);
    const CertificateNoteTypeEnums = useEnumByName("CertificateNoteType");
    const [noteNumber, setNoteNumber] = useState(null);
    const [bold, setBold] = useState(false);
    const [yellowHighlight, setYellowHighlight] = useState(false);
    const [redColor, setRedColor] = useState(false);
    const [type, setType] = useState(false);


    useEffect(() => {
        if (connedNotesData != null && connedNotesData.items != null && formType === 'Update') {
            setComment(connedNotesData?.items?.comment)
            setNoteNumber(connedNotesData?.items?.noteNumber);
            setRedColor(connedNotesData?.items?.redColor);
            setYellowHighlight(connedNotesData?.items?.yellowHighlight);
            setBold(connedNotesData?.items?.bold);
            setType(connedNotesData?.items?.type);
        }
    }, [connedNotesData])


    const input = {
        comment,
        noteNumber,
        redColor,
        yellowHighlight,
        bold,
        type
    }


    function handleSubmit(e) {
        e.preventDefault();
        const {from} = location.state || {from: {pathname: "/canned-notes"}};
        setDisable(true);
        dispatch(cannedNotesActions.updateCannedNoteAction(cannedId, input, setDisable, from))
    }

    return (
        <>
            {!isArray(CertificateNoteTypeEnums) ? <Preloader/> :

                <div className="m-0 p-0 ">

                    <div className="row">

                        <section id="content-wrapper">

                            <div className="col-lg-12 ml-auto d-flex">
                                <Breadcrumb crumbs={crumbs}/>
                                {isUserHaveAccess(authorities, ["CANNOTE-D"]) &&
                                    <CommonDeleteBtn
                                        HeaderContent={"Canned Note"}
                                        deleteReference={cannedId}
                                        from={"/canned-notes"}
                                        fetchAction={cannedNotesActions.deleteCannedNoteByIdAction}
                                    />
                                }

                                {
                                    isUserHaveAccess(authorities, ["AUDIT-V"]) &&
                                    <CommonAuditableInfoBtn createdBy={connedNotesData.items?.createdBy}
                                                            creationDate={connedNotesData.items?.creationDate}
                                                            modifiedBy={connedNotesData.items?.modifiedBy}
                                                            modifiedDate={connedNotesData.items?.modifiedDate}/>
                                }

                            </div>

                            {formType === "Update" &&

                                <div className={"col-12 mt-3"}>

                                    <div className="row">

                                        <div className="col-lg-8 m-auto">

                                            <div className="form_design">

                                                <Form onSubmit={handleSubmit} fields={<>
                                                    <div className="form_header ml-2 mr-2">
                                                        <h2>{formType} Canned Notes</h2>
                                                        <hr className="mb-4"/>
                                                    </div>

                                                    <div className={"ml-2 mr-2"}>

                                                        <div className="form-group row">

                                                            <div className={"col-lg-4"}>
                                                                <Label text={`Note No.`} required={true}/>
                                                            </div>

                                                            <div className="col-sm-8">

                                                                <input className="form-control" type="number"
                                                                       name="noteNumber"
                                                                       value={noteNumber}
                                                                       onChange={(e) => {
                                                                           setNoteNumber(e.target.value)
                                                                       }}
                                                                       placeholder="Enter Note No..."
                                                                       autoComplete={false}
                                                                       required={true}
                                                                       readOnly={true}
                                                                />
                                                            </div>


                                                        </div>

                                                        <div className="form-group row">

                                                            <div className={"col-lg-4"}>
                                                                <Label text={`Comment`} required={true}/>
                                                            </div>

                                                            <div className="col-sm-8">

                                                                <textarea className="form-control"
                                                                          name="comment"
                                                                          value={comment}
                                                                          placeholder="Enter Comment...."
                                                                          rows="3"
                                                                          onChange={(e) => {
                                                                              setComment(e.target.value)
                                                                          }}
                                                                          required
                                                                          readOnly={isReadOnly}
                                                                />
                                                            </div>


                                                        </div>

                                                        <div className="form-group row">

                                                            <div className={"col-lg-4"}>
                                                                <Label text={"CertificateNote Type"} required={true}/>
                                                            </div>

                                                            <div className="col-sm-8">

                                                                {CommonSelectDropDownTag(Array.isArray(CertificateNoteTypeEnums) && CertificateNoteTypeEnums, "value", "constant", "Select CertificateNote Type...", null, false, type, setType, true, isReadOnly === true, null, (value) => {
                                                                    setType(value);
                                                                })}

                                                            </div>
                                                        </div>

                                                        <div className="form-group row">

                                                            <div className={"col-lg-4"}>
                                                                <Label text={`Bold`} required={false}/>
                                                            </div>

                                                            <div className="col-sm-8">
                                                                <input value={bold}
                                                                       type="checkbox" checked={bold}
                                                                       onClick={(e) => {
                                                                           setBold(!bold);
                                                                       }}
                                                                       disabled={isReadOnly}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">

                                                            <div className={"col-lg-4"}>
                                                                <Label text={`Yellow Highlight`} required={false}/>
                                                            </div>

                                                            <div className="col-sm-8">
                                                                <input value={yellowHighlight}
                                                                       type="checkbox" checked={yellowHighlight}
                                                                       onClick={(e) => {
                                                                           setYellowHighlight(!yellowHighlight);
                                                                       }}
                                                                       disabled={isReadOnly}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">

                                                            <div className={"col-lg-4"}>
                                                                <Label text={`Red Color`} required={false}/>
                                                            </div>

                                                            <div className="col-sm-8">
                                                                <input value={redColor}
                                                                       type="checkbox" checked={redColor}
                                                                       onClick={(e) => {
                                                                           setRedColor(!redColor);
                                                                       }}
                                                                       disabled={isReadOnly}
                                                                />
                                                            </div>
                                                        </div>

                                                        {isReadOnly === false && <div className="form-group row">
                                                            <div className="col-lg-4">
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <button className="cm_btn cm_bg"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            history.push("/canned-notes");
                                                                        }}>Cancel
                                                                </button>
                                                                {CommonSubmitLoader(disable, formType)}
                                                            </div>
                                                        </div>}


                                                    </div>
                                                </>}>
                                                </Form>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            }

                        </section>
                    </div>
                </div>

            }
        </>
    )
}

export default CannedNotesFormFields;