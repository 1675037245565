import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../common/components/Toast";
import {alertActions} from "../alert/alert";
import {handleExportError, resourceToFile, serialize} from "../../common/helpers/utils";

export const fulfillmentPricingActions = {
    getAllData,
    deleteFulfillmentPricingAction,
    submitFulfillmentPricingAction,
    exportFulfillmentPricingAction
}

export const fulfillmentPricingConstants = {
    GET_ALL_FULFILLMENT_PRICING_REQUEST: 'GET_ALL_FULFILLMENT_PRICING_REQUEST',
    GET_ALL_FULFILLMENT_PRICING_SUCCESS: 'GET_ALL_FULFILLMENT_PRICING_SUCCESS',
    GET_ALL_FULFILLMENT_PRICING_FAILURE: 'GET_ALL_FULFILLMENT_PRICING_FAILURE',

    DELETE_FULFILLMENT_PRICING_REQUEST: 'DELETE_FULFILLMENT_PRICING_REQUEST',
    DELETE_FULFILLMENT_PRICING_SUCCESS: 'DELETE_FULFILLMENT_PRICING_SUCCESS',
    DELETE_FULFILLMENT_PRICING_FAILURE: 'DELETE_FULFILLMENT_PRICING_FAILURE',

    CREATE_FULFILLMENT_PRICING_REQUEST: 'CREATE_FULFILLMENT_PRICING_REQUEST',
    CREATE_FULFILLMENT_PRICING_SUCCESS: 'CREATE_FULFILLMENT_PRICING_SUCCESS',
    CREATE_FULFILLMENT_PRICING_FAILURE: 'CREATE_FULFILLMENT_PRICING_FAILURE',

    EXPORT_FULFILLMENT_PRICING_REQUEST: 'EXPORT_FULFILLMENT_PRICING_REQUEST',
    EXPORT_FULFILLMENT_PRICING_SUCCESS: 'EXPORT_FULFILLMENT_PRICING_SUCCESS',
    EXPORT_FULFILLMENT_PRICING_FAILURE: 'EXPORT_FULFILLMENT_PRICING_FAILURE',
}
export const fulfillmentPricingService = {
    getAllFulfillmentPricing,
    deleteFulfillmentPricingService,
    submitFulfillmentPricing,
    exportFulfillmentPricing
}


function getAllData(query) {
    return dispatch => {
        dispatch(request());

        fulfillmentPricingService.getAllFulfillmentPricing(query)
            .then(
                fulfillment => {
                    dispatch(success(fulfillment));
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );

    };

    function request() {
        return {type: fulfillmentPricingConstants.GET_ALL_FULFILLMENT_PRICING_REQUEST}
    }

    function success(fulfillment) {
        return {type: fulfillmentPricingConstants.GET_ALL_FULFILLMENT_PRICING_SUCCESS, fulfillment}
    }

    function failure(error) {
        return {type: fulfillmentPricingConstants.GET_ALL_FULFILLMENT_PRICING_FAILURE, error}
    }

}

function deleteFulfillmentPricingAction(id, setDisable, setShowConfirmation, resetInput) {
    return dispatch => {
        dispatch(request());
        fulfillmentPricingService.deleteFulfillmentPricingService(id)
            .then(
                pricing => {
                    if (pricing['errorCode'] === 200) {
                        showSuccessToast("Fulfillment Pricing Deleted Successfully!");
                        dispatch(success(pricing));
                    } else {
                        responseDTOExceptionHandler(pricing);
                    }
                    setDisable(false);
                    setShowConfirmation(false);
                    resetInput();
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: fulfillmentPricingConstants.DELETE_FULFILLMENT_PRICING_REQUEST}
    }

    function success(pricing) {
        return {type: fulfillmentPricingConstants.DELETE_FULFILLMENT_PRICING_SUCCESS, pricing}
    }

    function failure(error) {
        return {type: fulfillmentPricingConstants.DELETE_FULFILLMENT_PRICING_FAILURE, error}
    }
}

function submitFulfillmentPricingAction(obj, id, setIsSubmit, setShowEditIcon, resetInput) {
    return dispatch => {
        dispatch(request({obj}));
        fulfillmentPricingService.submitFulfillmentPricing(obj)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        if (id === null || id === '')
                            showSuccessToast("Fulfillment pricing added successfully!");
                        else
                            showSuccessToast("Fulfillment pricing updated successfully!");
                        dispatch(success(data));
                        resetInput();
                        setShowEditIcon(false);
                    } else {
                        responseDTOExceptionHandler(data);
                    }
                    setIsSubmit(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(data) {
        return {type: fulfillmentPricingConstants.CREATE_FULFILLMENT_PRICING_REQUEST, data}
    }

    function success(data) {
        return {type: fulfillmentPricingConstants.CREATE_FULFILLMENT_PRICING_SUCCESS, data}
    }

    function failure(error) {
        return {type: fulfillmentPricingConstants.CREATE_FULFILLMENT_PRICING_FAILURE, error}
    }
}

function exportFulfillmentPricingAction(query, setIsExport) {

    return dispatch => {
        dispatch(request());
        fulfillmentPricingService.exportFulfillmentPricing(query)
            .then(
                exportData => {
                    dispatch(success(exportData))
                    setIsExport(false)
                },
                error => {
                    dispatch(failure(error.toString()))
                    setIsExport(false)
                }
            );
    };

    function request() {
        return {type: fulfillmentPricingConstants.EXPORT_FULFILLMENT_PRICING_REQUEST}
    }

    function success(exportData) {
        return {type: fulfillmentPricingConstants.EXPORT_FULFILLMENT_PRICING_SUCCESS, exportData}
    }

    function failure(error) {
        return {type: fulfillmentPricingConstants.EXPORT_FULFILLMENT_PRICING_FAILURE, error}
    }
}


/************ Reducers started here. *******************/

export function fulfillmentPricingReducer(state = {}, action) {
    switch (action.type) {
        case fulfillmentPricingConstants.GET_ALL_FULFILLMENT_PRICING_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case fulfillmentPricingConstants.GET_ALL_FULFILLMENT_PRICING_SUCCESS:
            return {
                loggedIn: true,
                items: action.fulfillment
            };
        case fulfillmentPricingConstants.GET_ALL_FULFILLMENT_PRICING_FAILURE:
            return {};
        default:
            return state
    }
}

/************ Reducers ended here. *******************/

export async function submitFulfillmentPricing(data) {
    return axios.post(`/ajax/create-or-update-fulfillment-pricing`, data).then(
        (response) => {
            return response.data;
        })
}

function getAllFulfillmentPricing(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/fulfillment-pricing?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

function deleteFulfillmentPricingService(id) {
    return axios.delete(`/ajax/delete-by-fulfillment-pricing-id/${id}`).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error);
    })
}


export async function exportFulfillmentPricing(query) {
    let paramData = serialize(query)
    return axios.post(`/export/fulfillment-pricing?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    ).then((response) => {
        if (response.data.byteLength === 0) {
            showInfoToast("No data found to export!")
        } else {
            resourceToFile(response.data, "FulfillmentPricing");
        }
    })
        .catch(handleExportError);

}




