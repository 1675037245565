import ConfirmationModal from "./ConfirmationModal";
import React, {useState} from "react";
import {Action, Dispatch} from "redux";
import {useDispatch} from "react-redux";
import {createMergedDashboardQuery} from "../helpers/utils";
import _ from "lodash";


interface CommonDeleteBtnProps {
    HeaderContent: string;
    deleteReference: string;
    customReference?: any;
    from?: string;
    fetchAction: Dispatch<Action>;
    setNestModalReference?: boolean;
    nestedItems?: any;
    isDisable? : boolean
}

const CommonDeleteBtn = (props: CommonDeleteBtnProps) => {
    const dispatch = useDispatch();
    const {
        HeaderContent,
        deleteReference,
        customReference = null,
        from,
        fetchAction,
        setNestModalReference,
        nestedItems,
        isDisable = false
    } = props;


    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [disable, setDisable] = useState(false);

    let dataQuery: any = createMergedDashboardQuery();

    const deleteBtnAction = () => {
        let query = {
            ...dataQuery,
            deleteReference: deleteReference,
            customReference: customReference,
            setDisable: setDisable,
            setShowDeleteConfirm: setShowDeleteConfirm,
            from: from,
            query: createMergedDashboardQuery(),
            setNestModalReference: setNestModalReference,
            nestedItems: nestedItems
        }
        setDisable(true);
        dispatch(fetchAction(query));
    }


    return (
        <>

            <div className={isDisable ? "cm_btn ml-auto disabled_button" : "cm_btn ml-auto"}>
                <i title={"Delete " + HeaderContent} onClick={(e) => {
                    e.preventDefault();
                    if (isDisable === false){
                        setShowDeleteConfirm(true);
                    }
                }}
                   className="fa fa-trash cursor">
                </i></div>

            {showDeleteConfirm && <ConfirmationModal title={"Delete " + HeaderContent}
                                                     body={"Are you sure you want to delete this " + HeaderContent + "?"}
                                                     action={deleteBtnAction}
                                                     show={showDeleteConfirm}
                                                     setShow={setShowDeleteConfirm}
                                                     disable={disable}/>}

        </>
    )


}


export default CommonDeleteBtn;