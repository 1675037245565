import axios from "axios";
import {showSuccessToast} from "../../../common/components/Toast";
import {alertActions} from "../../alert/alert";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {serialize} from "../../../common/helpers/utils";
import {stateCodeActions} from "./stateCode";

//export actions
export const stateCodeAliasActions = {
    getAllAliasByStateCodeIdAction,
    createStateCodeAliasAction,
    getStateCodeAliasByIdAction,
    updateStateCodeAliasAliasAction,
    deleteStateCodeAliasByIdAction
};

//export constants
export const stateCodeConstants = {
    GET_ALL_STATE_CODE_ALIAS_REQUEST: 'GET_ALL_STATE_CODE_ALIAS_REQUEST',
    GET_ALL_STATE_CODE_ALIAS_SUCCESS: 'GET_ALL_STATE_CODE_ALIAS_SUCCESS',
    GET_ALL_STATE_CODE_ALIAS_FAILURE: 'GET_ALL_STATE_CODE_ALIAS_FAILURE',

    CREATE_STATE_CODE_ALIAS_REQUEST: 'CREATE_STATE_CODE_ALIAS_REQUEST',
    CREATE_STATE_CODE_ALIAS_SUCCESS: 'CREATE_STATE_CODE_ALIAS_SUCCESS',
    CREATE_STATE_CODE_ALIAS_FAILURE: 'CREATE_STATE_CODE_ALIAS_FAILURE',

    GET_STATE_CODE_ALIAS_BY_ID_REQUEST: 'GET_STATE_CODE_ALIAS_BY_ID_REQUEST',
    GET_STATE_CODE_ALIAS_BY_ID_SUCCESS: 'GET_STATE_CODE_ALIAS_BY_ID_SUCCESS',
    GET_STATE_CODE_ALIAS_BY_ID_FAILURE: 'GET_STATE_CODE_ALIAS_BY_ID_FAILURE',

    UPDATE_STATE_CODE_ALIAS_REQUEST: 'UPDATE_STATE_CODE_ALIAS_REQUEST',
    UPDATE_STATE_CODE_ALIAS_SUCCESS: 'UPDATE_STATE_CODE_ALIAS_SUCCESS',
    UPDATE_STATE_CODE_ALIAS_FAILURE: 'UPDATE_STATE_CODE_ALIAS_FAILURE',

    DELETE_STATE_CODE_ALIAS_REQUEST: 'DELETE_STATE_CODE_ALIAS_REQUEST',
    DELETE_STATE_CODE_ALIAS_SUCCESS: 'DELETE_STATE_CODE_ALIAS_SUCCESS',
    DELETE_STATE_CODE_ALIAS_FAILURE: 'DELETE_STATE_CODE_ALIAS_FAILURE'

};

//export service
export const stateCodeService = {
    getAllStateCodeAlias,
    createStateCodeAlias,
    getStateCodeAliasById,
    updateStateCodeAlias,
    deleteStateCodeAliasByIdService
};

{/************************************* stateCode Actions Starts here  ******************************************/
}

function getAllAliasByStateCodeIdAction(query) {
    return dispatch => {
        dispatch(request());

        stateCodeService.getAllStateCodeAlias(query)

            .then(
                stateCodes => dispatch(success(stateCodes)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: stateCodeConstants.GET_ALL_STATE_CODE_ALIAS_REQUEST}
    }

    function success(stateCodes) {
        return {type: stateCodeConstants.GET_ALL_STATE_CODE_ALIAS_SUCCESS, stateCodes}
    }

    function failure(error) {
        return {type: stateCodeConstants.GET_ALL_STATE_CODE_ALIAS_FAILURE, error}
    }
}


//submit StateCodeAlias Action
function createStateCodeAliasAction(obj, stateCodeId, setDisable, setShow) {
    return dispatch => {
        dispatch(request({obj}));

        stateCodeService.createStateCodeAlias(obj)
            .then(
                stateCode => {
                    if (stateCode['errorCode'] === 200) {
                        showSuccessToast("Alias added successfully!");
                        dispatch(success(stateCode));
                        dispatch(getAllAliasByStateCodeIdAction({
                            stateCodeId: stateCodeId
                        }))
                        setShow(false)
                    } else {
                        responseDTOExceptionHandler(stateCode);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(stateCode) {
        return {type: stateCodeConstants.CREATE_STATE_CODE_ALIAS_REQUEST, stateCode}
    }

    function success(stateCode) {
        return {type: stateCodeConstants.CREATE_STATE_CODE_ALIAS_SUCCESS, stateCode}
    }

    function failure(error) {
        return {type: stateCodeConstants.CREATE_STATE_CODE_ALIAS_FAILURE, error}
    }
}


function getStateCodeAliasByIdAction(id) {
    return dispatch => {
        dispatch(request());
        stateCodeService.getStateCodeAliasById(id)
            .then(
                stateCode => dispatch(success(stateCode)),
                error => dispatch(failure(error.toString()))
            );

    };

    function request() {
        return {type: stateCodeConstants.GET_STATE_CODE_ALIAS_BY_ID_REQUEST}
    }

    function success(stateCode) {
        return {type: stateCodeConstants.GET_STATE_CODE_ALIAS_BY_ID_SUCCESS, stateCode}
    }

    function failure(error) {
        return {type: stateCodeConstants.GET_STATE_CODE_ALIAS_BY_ID_FAILURE, error}
    }
}


function updateStateCodeAliasAliasAction(obj, stateCodeId, aliasId, setDisable, setShow) {
    return dispatch => {
        dispatch(request({obj}));

        stateCodeService.updateStateCodeAlias(obj, aliasId)
            .then(
                stateCode => {
                    if (stateCode['errorCode'] === 200) {
                        showSuccessToast("Alias updated successfully!");
                        dispatch(success(stateCode));
                        dispatch(stateCodeActions.getStateCodeByIdAction(stateCodeId))
                        setShow(false)
                    } else {
                        responseDTOExceptionHandler(stateCode);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(stateCode) {
        return {type: stateCodeConstants.UPDATE_STATE_CODE_ALIAS_REQUEST, stateCode}
    }

    function success(stateCode) {
        return {type: stateCodeConstants.UPDATE_STATE_CODE_ALIAS_SUCCESS, stateCode}
    }

    function failure(error) {
        return {type: stateCodeConstants.UPDATE_STATE_CODE_ALIAS_FAILURE, error}
    }
}

function deleteStateCodeAliasByIdAction(aliasId, setDisable, setShowDeleteConfirm, stateCodeId) {
    return dispatch => {
        dispatch(request());
        stateCodeService.deleteStateCodeAliasByIdService(aliasId)
            .then(
                linkedAccount => {
                    if (linkedAccount['errorCode'] === 200) {
                        showSuccessToast("Alias deleted successfully!");
                        dispatch(success(linkedAccount));
                        setShowDeleteConfirm(false);
                        dispatch(getAllAliasByStateCodeIdAction({'stateCodeId': stateCodeId}));
                    } else {
                        responseDTOExceptionHandler(linkedAccount);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: stateCodeConstants.DELETE_STATE_CODE_ALIAS_REQUEST,}
    }

    function success(linkedAccount) {
        return {type: stateCodeConstants.DELETE_STATE_CODE_ALIAS_SUCCESS, linkedAccount}
    }

    function failure(error) {
        return {type: stateCodeConstants.DELETE_STATE_CODE_ALIAS_FAILURE, error}
    }

}


{/***************************** stateCode Actions Ends here **********************************/
}


{/****************** Reducers starts here. **************************************/
}

//get all stateCodes alias reducer
export function getAllPagedAliasByStateCodeIdAction(stateCode = {}, action) {
    switch (action.type) {
        case stateCodeConstants.GET_ALL_STATE_CODE_ALIAS_REQUEST:
            return {
                ...stateCode,
                loading: true,
                loggingIn: true,
            };
        case stateCodeConstants.GET_ALL_STATE_CODE_ALIAS_SUCCESS:
            return {
                loading: false,
                loggingIn: true,
                items: action.stateCodes
            };
        case stateCodeConstants.GET_ALL_STATE_CODE_ALIAS_FAILURE:
            return {};
        default:
            return stateCode
    }
}

//get stateCode alias by id
export function getStateCodeAliasByIdReducer(stateCode = {}, action) {
    switch (action.type) {
        case stateCodeConstants.GET_STATE_CODE_ALIAS_BY_ID_REQUEST:
            return {};
        case stateCodeConstants.GET_STATE_CODE_ALIAS_BY_ID_SUCCESS:
            return {
                items: action.stateCode
            };
        case stateCodeConstants.GET_STATE_CODE_ALIAS_BY_ID_FAILURE:
            return {};
        default:
            return stateCode
    }
}

{/*************************** Reducers ends here. **************************************/
}

{/******************************StateCodeAlias Services starts here  ****************************************/
}

export async function getAllStateCodeAlias(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/state-code-alias/search?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function getStateCodeAliasById(id) {
    return axios.get(`/ajax/get-state-code-alias-by-id/${id}`).then(
        (response) => {
            return response.data;
        })
}

export async function createStateCodeAlias(obj) {
    return axios.post(`/ajax/state-code-alias/create`, obj)
        .then((response) => {
            return response.data
        })
}

export async function updateStateCodeAlias(obj, stateCodeId) {
    return axios.put(`/ajax/update-state-code-alias-by-id/${stateCodeId}`, obj)
        .then((response) => {
            return response.data
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export async function deleteStateCodeAliasByIdService(id) {
    return axios.delete(`/ajax/delete-state-code-alias-by-id/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

{/******************************StateCodeAlias Services ends here  ****************************************/
}




