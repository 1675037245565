import axios from "axios";
import {showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {
    handleExportError,
    resourceToFile,
    serialize
} from "../../../common/helpers/utils";

//export actions
export const stateActions = {
    getAllData,
    submitStateAction,
    getStateByIdAction,
    updateStateAction,
    exportData
};

//export constants
export const stateConstants = {
    GET_ALL_STATE_REQUEST: 'GET_ALL_STATE_REQUEST',
    GET_ALL_STATE_SUCCESS: 'GET_ALL_STATE_SUCCESS',
    GET_ALL_STATE_FAILURE: 'GET_ALL_STATE_FAILURE',

    CREATE_STATE_REQUEST: 'CREATE_STATE_REQUEST',
    CREATE_STATE_SUCCESS: 'CREATE_STATE_SUCCESS',
    CREATE_STATE_FAILURE: 'CREATE_STATE_FAILURE',

    GET_STATE_BY_ID_REQUEST: 'GET_STATE_BY_ID_REQUEST',
    GET_STATE_BY_ID_SUCCESS: 'GET_STATE_BY_ID_SUCCESS',
    GET_STATE_BY_ID_FAILURE: 'GET_STATE_BY_ID_FAILURE',

    UPDATE_STATE_REQUEST: 'UPDATE_STATE_REQUEST',
    UPDATE_STATE_SUCCESS: 'UPDATE_STATE_SUCCESS',
    UPDATE_STATE_FAILURE: 'UPDATE_STATE_FAILURE',

    EXPORT_STATE_DATA_REQUEST: 'EXPORT_STATE_DATA_REQUEST',
    EXPORT_STATE_DATA_SUCCESS: 'EXPORT_STATE_DATA_SUCCESS',
    EXPORT_STATE_DATA_FAILURE: 'EXPORT_STATE_DATA_FAILURE'

};

//export service
export const stateService = {
    getAllStates,
    createState,
    getStateById,
    updateState,
    exportStateData
};

{/************************************* state Actions Starts here  ******************************************/
}

function getAllData(query) {
    return dispatch => {
        dispatch(request());

        stateService.getAllStates(query)

            .then(
                states => dispatch(success(states)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: stateConstants.GET_ALL_STATE_REQUEST}
    }

    function success(states) {
        return {type: stateConstants.GET_ALL_STATE_SUCCESS, states}
    }

    function failure(error) {
        return {type: stateConstants.GET_ALL_STATE_FAILURE, error}
    }
}


//submit State Action
function submitStateAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        stateService.createState(obj)
            .then(
                state => {
                    if (state['errorCode'] === 200) {
                        showSuccessToast("State added successfully!");
                        dispatch(success(state));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(state);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(state) {
        return {type: stateConstants.CREATE_STATE_REQUEST, state}
    }

    function success(state) {
        return {type: stateConstants.CREATE_STATE_SUCCESS, state}
    }

    function failure(error) {
        return {type: stateConstants.CREATE_STATE_FAILURE, error}
    }
}


function getStateByIdAction(id) {
    return dispatch => {
        dispatch(request());
        stateService.getStateById(id)
            .then(
                state => dispatch(success(state)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: stateConstants.GET_STATE_BY_ID_REQUEST}
    }

    function success(state) {
        return {type: stateConstants.GET_STATE_BY_ID_SUCCESS, state}
    }

    function failure(error) {
        return {type: stateConstants.GET_STATE_BY_ID_FAILURE, error}
    }
}


function updateStateAction(obj, stateId, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        stateService.updateState(obj, stateId)
            .then(
                state => {
                    if (state['errorCode'] === 200) {
                        showSuccessToast("State updated successfully!");
                        dispatch(success(state));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(state);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(state) {
        return {type: stateConstants.UPDATE_STATE_REQUEST, state}
    }

    function success(state) {
        return {type: stateConstants.UPDATE_STATE_SUCCESS, state}
    }

    function failure(error) {
        return {type: stateConstants.UPDATE_STATE_FAILURE, error}
    }
}

{/***************************** state Actions Ends here **********************************/
}


{/****************** Reducers starts here. **************************************/
}

//get all states reducer
export function getAllPagedStateReducer(state = {}, action) {
    switch (action.type) {
        case stateConstants.GET_ALL_STATE_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case stateConstants.GET_ALL_STATE_SUCCESS:
            return {
                loading: false,
                loggingIn: true,
                items: action.states
            };
        case stateConstants.GET_ALL_STATE_FAILURE:
            return {};
        default:
            return state
    }
}

//get state by id
export function getStateByIdReducer(state = {}, action) {
    switch (action.type) {
        case stateConstants.GET_STATE_BY_ID_REQUEST:
            return {};
        case stateConstants.GET_STATE_BY_ID_SUCCESS:
            return {
                items: action.state
            };
        case stateConstants.GET_STATE_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

//export State Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        stateService.exportStateData(query)
            .then(
                stateData => dispatch(success(stateData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: stateConstants.EXPORT_STATE_DATA_REQUEST}
    }

    function success(stateData) {
        query.setExportBtn(false);
        return {type: stateConstants.EXPORT_STATE_DATA_SUCCESS, stateData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: stateConstants.EXPORT_STATE_DATA_FAILURE, error}
    }
}

{/*************************** Reducers ends here. **************************************/
}


{/******************************State Services starts here  ****************************************/
}

//get all counties
export async function getAllStates(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/states/paged?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function getStateById(id) {
    return axios.get(`/ajax/get-state-by-id/${id}`).then(
        (response) => {
            return response.data;
        })
}

export async function createState(obj) {
    return axios.post(`/ajax/submitState`, obj)
        .then((response) => {
            return response.data
        })
}

export async function updateState(obj, stateId) {
    return axios.put(`/ajax/update-state-by-id/${stateId}`, obj)
        .then((response) => {
            return response.data
        }).catch((error) => {
            return Promise.reject(error);
        })
}


//export State Data Service
export async function exportStateData(query) {
    let paramData = serialize(query)
    return axios.post(`/export/state?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "stateData");
            }
        })
        .catch(handleExportError);
}


{/******************************State Services ends here  ****************************************/
}




