import React, {useState} from "react";
import AuditableInfoModal from "./AuditableInfoModal";

interface AuditableObj {
    createdBy: string,
    modifiedBy: string,
    creationDate: string,
    modifiedDate: string,
}

const CommonAuditableInfoBtn = (props: AuditableObj) => {

    const {
        createdBy,
        modifiedBy,
        creationDate,
        modifiedDate
    } = props;

    const [showAuditableInfoModal, setShowAuditableInfoModal] = useState(false);

    return (
        <>
            <div className="cm_btn" onClick={(e) => {
                e.preventDefault();
                setShowAuditableInfoModal(!showAuditableInfoModal);
            }}><i title="Auditable Info"
                  className="cursor fa fa-solid fa-stethoscope"></i>
            </div>

            {showAuditableInfoModal === true &&
                <AuditableInfoModal show={showAuditableInfoModal} setShow={setShowAuditableInfoModal}
                                    modifiedBy={modifiedBy}
                                    createdBy={createdBy}
                                    creationDate={creationDate}
                                    modifiedDate={modifiedDate}/>}
        </>


    )
}

export default CommonAuditableInfoBtn;