import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";


export const thirdPartyProviderActions = {
    getAllData,
    createThirdPartyProviderAction,
    getThirdPartyProviderByIdAction,
    updateThirdPartyProviderAction,
    getThirdPartyProviderNoteAction,
    deleteThirdPartyProviderNoteAction,
    createThirdPartyProviderNoteAction,
    exportData,
    deleteThirdPartyProviderByIdAction
};


//export constants
export const thirdPartyProviderConstant = {
    GET_ALL_THIRD_PARTY_PROVIDER_REQUEST: 'GET_ALL_THIRD_PARTY_PROVIDER_REQUEST',
    GET_ALL_THIRD_PARTY_PROVIDER_SUCCESS: 'GET_ALL_THIRD_PARTY_PROVIDER_SUCCESS',
    GET_ALL_THIRD_PARTY_PROVIDER_FAILURE: 'GET_ALL_THIRD_PARTY_PROVIDER_FAILURE',

    CREATE_THIRD_PARTY_PROVIDER_REQUEST: 'CREATE_THIRD_PARTY_PROVIDER_REQUEST',
    CREATE_THIRD_PARTY_PROVIDER_SUCCESS: 'CREATE_THIRD_PARTY_PROVIDER_SUCCESS',
    CREATE_THIRD_PARTY_PROVIDER_FAILURE: 'CREATE_THIRD_PARTY_PROVIDER_FAILURE',

    GET_THIRD_PARTY_PROVIDER_BY_ID_REQUEST: 'GET_THIRD_PARTY_PROVIDER_BY_ID_REQUEST',
    GET_THIRD_PARTY_PROVIDER_BY_ID_SUCCESS: 'GET_THIRD_PARTY_PROVIDER_BY_ID_SUCCESS',
    GET_THIRD_PARTY_PROVIDER_BY_ID_FAILURE: 'GET_THIRD_PARTY_PROVIDER_BY_ID_FAILURE',

    UPDATE_THIRD_PARTY_PROVIDER_REQUEST: 'UPDATE_THIRD_PARTY_PROVIDER_REQUEST',
    UPDATE_THIRD_PARTY_PROVIDER_SUCCESS: 'UPDATE_THIRD_PARTY_PROVIDER_SUCCESS',
    UPDATE_THIRD_PARTY_PROVIDER_FAILURE: 'UPDATE_THIRD_PARTY_PROVIDER_FAILURE',

    GET_THIRD_PARTY_PROVIDER_BY_STATE_ID_REQUEST: 'GET_THIRD_PARTY_PROVIDER_BY_STATE_ID_REQUEST',
    GET_THIRD_PARTY_PROVIDER_BY_STATE_ID_SUCCESS: 'GET_THIRD_PARTY_PROVIDER_BY_STATE_ID_SUCCESS',
    GET_THIRD_PARTY_PROVIDER_BY_STATE_ID_FAILURE: 'GET_THIRD_PARTY_PROVIDER_BY_STATE_ID_FAILURE',

    EXPORT_THIRD_PARTY_PROVIDER_DATA_REQUEST: 'EXPORT_THIRD_PARTY_PROVIDER_DATA_REQUEST',
    EXPORT_THIRD_PARTY_PROVIDER_DATA_SUCCESS: 'EXPORT_THIRD_PARTY_PROVIDER_DATA_SUCCESS',
    EXPORT_THIRD_PARTY_PROVIDER_DATA_FAILURE: 'EXPORT_THIRD_PARTY_PROVIDER_DATA_FAILURE',

    DELETE_THIRD_PARTY_PROVIDER_REQUEST: 'DELETE_THIRD_PARTY_PROVIDER_REQUEST',
    DELETE_THIRD_PARTY_PROVIDER_SUCCESS: 'DELETE_THIRD_PARTY_PROVIDER_SUCCESS',
    DELETE_THIRD_PARTY_PROVIDER_FAILURE: 'DELETE_THIRD_PARTY_PROVIDER_FAILURE',

    GET_THIRD_PARTY_PROVIDER_NOTES_REQUEST: 'GET_THIRD_PARTY_PROVIDER_NOTES_REQUEST',
    GET_THIRD_PARTY_PROVIDER_NOTES_SUCCESS: 'GET_THIRD_PARTY_PROVIDER_NOTES_SUCCESS',
    GET_THIRD_PARTY_PROVIDER_NOTES_FAILURE: 'GET_SUBDIVISIONS_NOTES_FAILURE',

    DELETE_THIRD_PARTY_PROVIDER_NOTE_REQUEST: 'DELETE_THIRD_PARTY_PROVIDER_NOTE_REQUEST',
    DELETE_THIRD_PARTY_PROVIDER_NOTE_SUCCESS: 'DELETE_THIRD_PARTY_PROVIDER_NOTE_SUCCESS',
    DELETE_THIRD_PARTY_PROVIDER_NOTE_FAILURE: 'DELETE_THIRD_PARTY_PROVIDER_NOTE_FAILURE',

    CREATE_THIRD_PARTY_PROVIDER_NOTE_REQUEST: 'CREATE_THIRD_PARTY_PROVIDER_NOTE_REQUEST',
    CREATE_THIRD_PARTY_PROVIDER_NOTE_SUCCESS: 'CREATE_THIRD_PARTY_PROVIDER_NOTE_SUCCESS',
    CREATE_THIRD_PARTY_PROVIDER_NOTE_FAILURE: 'CREATE_THIRD_PARTY_PROVIDER_NOTE_FAILURE',

}


//export service
export const thirdPartyProviderService = {
    getAllThirdPartyProvider,
    createThirdPartyProvider,
    getThirdPartyProviderById,
    updateThirdPartyProvider,
    exportThirdPartyProvider,
    deleteThirdPartyProviderById,
    getThirdPartyProviderNotesService,
    createThirdPartyProviderNoteService,
    deleteThirdPartyProviderNoteById

}


/******************************** Provider Actions Starts here  ****************************************/

// get All thirdPartyProvider Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());
        thirdPartyProviderService.getAllThirdPartyProvider(query)
            .then(
                managementCompanies => dispatch(success(managementCompanies)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: thirdPartyProviderConstant.GET_ALL_THIRD_PARTY_PROVIDER_REQUEST}
    }

    function success(managementCompanies) {
        return {type: thirdPartyProviderConstant.GET_ALL_THIRD_PARTY_PROVIDER_SUCCESS, managementCompanies}
    }

    function failure(error) {
        return {type: thirdPartyProviderConstant.GET_ALL_THIRD_PARTY_PROVIDER_FAILURE, error}
    }

}

//create provider Action
function createThirdPartyProviderAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        thirdPartyProviderService.createThirdPartyProvider(obj)
            .then(
                thirdPartyProvider => {
                    if (thirdPartyProvider['errorCode'] === 200) {
                        showSuccessToast("Provider added successfully!");
                        dispatch(success(thirdPartyProvider));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(thirdPartyProvider);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(thirdPartyProvider) {
        return {type: thirdPartyProviderConstant.CREATE_THIRD_PARTY_PROVIDER_REQUEST, thirdPartyProvider}
    }

    function success(thirdPartyProvider) {
        return {type: thirdPartyProviderConstant.CREATE_THIRD_PARTY_PROVIDER_SUCCESS, thirdPartyProvider}
    }

    function failure(error) {
        return {type: thirdPartyProviderConstant.CREATE_THIRD_PARTY_PROVIDER_FAILURE, error}
    }
}


//get Provider by id
function getThirdPartyProviderByIdAction(thirdPartyProviderId) {
    return dispatch => {
        dispatch(request());

        thirdPartyProviderService.getThirdPartyProviderById(thirdPartyProviderId)
            .then(
                thirdPartyProvider => dispatch(success(thirdPartyProvider)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: thirdPartyProviderConstant.GET_THIRD_PARTY_PROVIDER_BY_ID_REQUEST}
    }

    function success(thirdPartyProvider) {
        return {type: thirdPartyProviderConstant.GET_THIRD_PARTY_PROVIDER_BY_ID_SUCCESS, thirdPartyProvider}
    }

    function failure(error) {
        return {type: thirdPartyProviderConstant.GET_THIRD_PARTY_PROVIDER_BY_ID_FAILURE, error}
    }
}


//update provider Action
function updateThirdPartyProviderAction(obj, id, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));
        thirdPartyProviderService.updateThirdPartyProvider(obj, id)
            .then(
                thirdPartyProvider => {
                    if (thirdPartyProvider['errorCode'] === 200) {
                        showSuccessToast("Provider updated successfully!");
                        dispatch(success(thirdPartyProvider));
                    } else {
                        responseDTOExceptionHandler(thirdPartyProvider);
                    }
                    setDisable(false);
                    dispatch(getThirdPartyProviderByIdAction(id));
                },
                error => {
                    dispatch(getThirdPartyProviderByIdAction(id));
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(thirdPartyProvider) {
        return {type: thirdPartyProviderConstant.UPDATE_THIRD_PARTY_PROVIDER_REQUEST, thirdPartyProvider}
    }

    function success(thirdPartyProvider) {
        return {type: thirdPartyProviderConstant.UPDATE_THIRD_PARTY_PROVIDER_SUCCESS, thirdPartyProvider}
    }

    function failure(error) {
        return {type: thirdPartyProviderConstant.UPDATE_THIRD_PARTY_PROVIDER_FAILURE, error}
    }
}

function exportData(query) {
    return dispatch => {
        dispatch(request());
        thirdPartyProviderService.exportThirdPartyProvider(query)
            .then(
                thirdPartyProviderData => dispatch(success(thirdPartyProviderData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: thirdPartyProviderConstant.EXPORT_THIRD_PARTY_PROVIDER_DATA_REQUEST}
    }

    function success(taxRollData) {
        query.setExportBtn(false);
        return {type: thirdPartyProviderConstant.EXPORT_THIRD_PARTY_PROVIDER_DATA_SUCCESS, taxRollData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: thirdPartyProviderConstant.EXPORT_THIRD_PARTY_PROVIDER_DATA_FAILURE, error}
    }

}

// id,setDisable,setShowDeleteMsaConfirm,query

//delete association Action
function deleteThirdPartyProviderByIdAction(deleteQuery) {
    return dispatch => {
        dispatch(request());
        thirdPartyProviderService.deleteThirdPartyProviderById(deleteQuery.deleteReference)
            .then(
                association => {
                    if (association['errorCode'] === 200) {
                        showSuccessToast("Provider deleted successfully!");
                        dispatch(success(association));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(association);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: thirdPartyProviderConstant.DELETE_THIRD_PARTY_PROVIDER_REQUEST}
    }

    function success(association) {
        return {type: thirdPartyProviderConstant.DELETE_THIRD_PARTY_PROVIDER_SUCCESS, association}
    }

    function failure(error) {
        return {type: thirdPartyProviderConstant.DELETE_THIRD_PARTY_PROVIDER_FAILURE, error}
    }
}

function getThirdPartyProviderNoteAction(thirdPartyProviderId) {
    return dispatch => {
        dispatch(request());

        thirdPartyProviderService.getThirdPartyProviderNotesService(thirdPartyProviderId)
            .then(
                notes => dispatch(success(notes)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: thirdPartyProviderConstant.CREATE_THIRD_PARTY_PROVIDER_NOTE_REQUEST}
    }

    function success(notes) {
        return {type: thirdPartyProviderConstant.GET_THIRD_PARTY_PROVIDER_NOTES_SUCCESS, notes}
    }

    function failure(error) {
        return {type: thirdPartyProviderConstant.GET_THIRD_PARTY_PROVIDER_NOTES_FAILURE, error}
    }

}

// create Provider Add Note
function createThirdPartyProviderNoteAction(thirdPartyProviderId, obj, setDisable, setShow) {
    return dispatch => {
        dispatch(request(obj));
        thirdPartyProviderService.createThirdPartyProviderNoteService(thirdPartyProviderId, obj)
            .then(
                note => {
                    if (note['errorCode'] === 200) {
                        showSuccessToast("Provider note created successfully!");
                        dispatch(success(note));
                        dispatch(getThirdPartyProviderNoteAction(thirdPartyProviderId))
                        setShow(false)
                    } else {
                        responseDTOExceptionHandler(note);
                    }
                    setDisable(false)
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(note) {
        return {type: thirdPartyProviderConstant.CREATE_THIRD_PARTY_PROVIDER_NOTE_REQUEST, note}
    }

    function success(note) {
        return {type: thirdPartyProviderConstant.CREATE_THIRD_PARTY_PROVIDER_NOTE_SUCCESS, note}
    }

    function failure(error) {
        return {type: thirdPartyProviderConstant.CREATE_THIRD_PARTY_PROVIDER_NOTE_FAILURE, error}
    }
}

function deleteThirdPartyProviderNoteAction(noteId, setDisable, setShowDeleteConfirm, thirdPartyProviderId) {
    return dispatch => {
        dispatch(request());
        thirdPartyProviderService.deleteThirdPartyProviderNoteById(noteId)
            .then(
                thirdPartyProvider => {
                    if (thirdPartyProvider['errorCode'] === 200) {
                        dispatch(thirdPartyProviderActions.getThirdPartyProviderNoteAction(thirdPartyProviderId));
                        showSuccessToast("Provider note deleted successfully!");
                        dispatch(success(thirdPartyProvider));
                        setShowDeleteConfirm(false);
                    } else {
                        responseDTOExceptionHandler(thirdPartyProvider);
                    }
                    setDisable(false)
                },
                error => {
                    setDisable(false)
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: thirdPartyProviderConstant.DELETE_THIRD_PARTY_PROVIDER_NOTE_REQUEST,}
    }

    function success(subdivision) {
        return {type: thirdPartyProviderConstant.DELETE_THIRD_PARTY_PROVIDER_NOTE_SUCCESS, subdivision}
    }

    function failure(error) {
        return {type: thirdPartyProviderConstant.DELETE_THIRD_PARTY_PROVIDER_NOTE_FAILURE, error}
    }
}


/******************************** Provider Actions ENDS here  ****************************************/


/************************** Reducers starts here. **************************************/
//get all getAllThirdPartyProvider reducer
export function getAllThirdPartyProviderReducer(state = {}, action) {
    switch (action.type) {
        case thirdPartyProviderConstant.GET_ALL_THIRD_PARTY_PROVIDER_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case thirdPartyProviderConstant.GET_ALL_THIRD_PARTY_PROVIDER_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.managementCompanies
            };
        case thirdPartyProviderConstant.GET_ALL_THIRD_PARTY_PROVIDER_FAILURE:
            return {};
        default:
            return state
    }

}

//get provider by id reducer
export function getThirdPartyProviderByIdReducer(state = {}, action) {
    switch (action.type) {
        case thirdPartyProviderConstant.GET_THIRD_PARTY_PROVIDER_BY_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case thirdPartyProviderConstant.GET_THIRD_PARTY_PROVIDER_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.thirdPartyProvider
            };
        case thirdPartyProviderConstant.GET_THIRD_PARTY_PROVIDER_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

export function getAllThirdPartyProviderNotesByProviderIdReducer(state = {}, action) {
    switch (action.type) {
        case thirdPartyProviderConstant.GET_THIRD_PARTY_PROVIDER_NOTES_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case thirdPartyProviderConstant.GET_THIRD_PARTY_PROVIDER_NOTES_SUCCESS:
            return {
                loggedIn: true,
                items: action.notes
            };
        case thirdPartyProviderConstant.GET_THIRD_PARTY_PROVIDER_NOTES_FAILURE:
            return {};
        default:
            return state
    }
}


/************************** Reducers ends here. **************************************/

/****************************** Provider Services starts here  ****************************************/
export function getAllThirdPartyProvider(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/third-party-providers/search?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

// create new provider
export function createThirdPartyProvider(obj) {
    return axios.post(`/ajax/third-party-provider/create`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function getThirdPartyProviderById(id) {
    return axios.get(`/ajax/get-third-party-provider-by-id/${id}`).then(
        (response) => {
            return response.data;
        })
}

export function updateThirdPartyProvider(obj, id) {
    return axios.put(`/ajax/update-third-party-provider/${id}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export async function exportThirdPartyProvider(query) {
    let paramData = serialize(query)
    return axios.post(`/export/third-party-providers?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "thirdPartyProviderData");
            }
        })
        .catch(handleExportError);
}

export async function deleteThirdPartyProviderById(id) {
    return axios.delete(`/ajax/delete-third-party-provider-by-id/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function getThirdPartyProviderNotesService(thirdPartyProviderId) {
    return axios.get(`/ajax/get-third-party-provider-notes-by-id/${thirdPartyProviderId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export function createThirdPartyProviderNoteService(thirdPartyProviderId, obj) {
    return axios.post(`/ajax/create-third-party-provider-note/${thirdPartyProviderId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export async function deleteThirdPartyProviderNoteById(id) {
    return axios.delete(`/ajax/delete-third-party-provider-note-by-id/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}



/****************************** Provider Services ends here  ****************************************/




