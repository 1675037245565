import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {serialize} from "../../../common/helpers/utils";

export const codeActions = {
    getAllData,
    getCodeList,
    submitCodeAction,
    getCodeByIdAction,
    updateCodeAction,
    deleteCodeAction
};

export const codeService = {
    getAllCode,
    submitCode,
    getCodeById,
    updateCode,
    getCodesList,
    deleteCode
};

export const codeConstant = {

    GET_ALL_CODE_REQUEST: 'GET_ALL_CODE_REQUEST',
    GET_ALL_CODE_SUCCESS: 'GET_ALL_CODE_SUCCESS',
    GET_ALL_CODE_FAILURE: 'GET_ALL_CODE_FAILURE',

    GET_ALL_CODE_LIST_REQUEST: 'GET_ALL_CODE_LIST_REQUEST',
    GET_ALL_CODE_LIST_SUCCESS: 'GET_ALL_CODE_LIST_SUCCESS',
    GET_ALL_CODE_LIST_FAILURE: 'GET_ALL_CODE_LIST_FAILURE',

    CREATE_CODE_REQUEST: 'CREATE_CODE_REQUEST',
    CREATE_CODE_SUCCESS: 'CREATE_CODE_SUCCESS',
    CREATE_CODE_FAILURE: 'CREATE_CODE_FAILURE',


    GET_CODE_BY_ID_REQUEST: 'GET_CODE_BY_ID_REQUEST',
    GET_CODE_BY_ID_SUCCESS: 'GET_CODE_BY_ID_SUCCESS',
    GET_CODE_BY_ID_FAILURE: 'GET_CODE_BY_ID_FAILURE',

    UPDATE_CODE_REQUEST: 'CREATE_CODE_REQUEST',
    UPDATE_CODE_SUCCESS: 'CREATE_CODE_SUCCESS',
    UPDATE_CODE_FAILURE: 'CREATE_CODE_FAILURE',

    DELETE_CODE_REQUEST:'DELETE_CODE_REQUEST',
    DELETE_CODE_SUCCESS:'DELETE_CODE_SUCCESS',
    DELETE_CODE_FAILURE:'DELETE_CODE_FAILURE',


};


{/*************************action starts here *************************************/
}

function getAllData(query) {
    return dispatch => {
        dispatch(request());
        codeService.getAllCode(query)
            .then(
                code => dispatch(success(code)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: codeConstant.GET_ALL_CODE_REQUEST}
    }

    function success(code) {
        return {type: codeConstant.GET_ALL_CODE_SUCCESS, code}
    }

    function failure(error) {
        return {type: codeConstant.GET_ALL_CODE_FAILURE, error}
    }
}


//create Code Action
function submitCodeAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        codeService.submitCode(obj)
            .then(
                msa => {
                    if (msa['errorCode'] === 200) {
                        showSuccessToast("Code Created Successfully!");
                        dispatch(success(msa));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(msa);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(msa) {
        return {type: codeConstant.CREATE_CODE_REQUEST, msa}
    }

    function success(msa) {
        return {type: codeConstant.CREATE_CODE_SUCCESS, msa}
    }

    function failure(error) {
        return {type: codeConstant.CREATE_CODE_FAILURE, error}
    }
}


function getCodeByIdAction(codeId) {
    return dispatch => {
        dispatch(request());
        codeService.getCodeById(codeId)
            .then(
                code => dispatch(success(code)),
                error => dispatch(failure(error.toString()))
            );

    };

    function request() {
        return {type: codeConstant.GET_CODE_BY_ID_REQUEST}
    }

    function success(code) {
        return {type: codeConstant.GET_CODE_BY_ID_SUCCESS, code}
    }

    function failure(error) {
        return {type: codeConstant.GET_CODE_BY_ID_FAILURE, error}
    }
}


//create msa Action
function updateCodeAction(obj, id, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        codeService.updateCode(obj, id)
            .then(
                code => {
                    if (code['errorCode'] === 200) {
                        showSuccessToast("Code Updated Successfully!");
                        dispatch(success(code));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(code);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(code) {
        return {type: codeConstant.UPDATE_CODE_REQUEST, code}
    }

    function success(code) {
        return {type: codeConstant.UPDATE_CODE_SUCCESS, code}
    }

    function failure(error) {
        return {type: codeConstant.UPDATE_CODE_FAILURE, error}
    }

}

//delete code Action
function deleteCodeAction(deleteQuery) {
    return dispatch => {
        dispatch(request({deleteQuery}));

        codeService.deleteCode(deleteQuery.deleteReference)
            .then(
                code => {
                    if (code['errorCode'] === 200) {
                        showSuccessToast("Code deleted Successfully!");
                        dispatch(success(code));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(code);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(id) {
        return {type: codeConstant.DELETE_CODE_REQUEST, id}
    }
    function success(code) {
        return {type: codeConstant.DELETE_CODE_SUCCESS, code}
    }
    function failure(error) {
        return {type: codeConstant.DELETE_CODE_FAILURE, error}
    }

}


function getCodeList() {
    return dispatch => {
        dispatch(request());
        codeService.getCodesList()
            .then(
                code => dispatch(success(code)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: codeConstant.GET_ALL_CODE_LIST_REQUEST}
    }

    function success(codes) {
        return {type: codeConstant.GET_ALL_CODE_LIST_SUCCESS, codes}
    }

    function failure(error) {
        return {type: codeConstant.GET_ALL_CODE_LIST_FAILURE, error}
    }
}


{/*************************action ends here *************************************/
}


{/************************* Reducers starts here. *************************************/
}


export function getAllCodeReducer(state = {}, action) {
    switch (action.type) {
        case codeConstant.GET_ALL_CODE_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case codeConstant.GET_ALL_CODE_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.code
            };
        case  codeConstant.GET_ALL_CODE_FAILURE:
            return {};
        default:
            return state
    }
}

export function getCodeByIdReducer(state = {}, action) {
    switch (action.type) {
        case codeConstant.GET_CODE_BY_ID_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case codeConstant.GET_CODE_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.code
            };
        case  codeConstant.GET_CODE_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}


export function getCodeListReducer(state = {}, action) {
    switch (action.type) {
        case codeConstant.GET_ALL_CODE_LIST_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case codeConstant.GET_ALL_CODE_LIST_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.codes
            };
        case  codeConstant.GET_ALL_CODE_LIST_FAILURE:
            return {};
        default:
            return state
    }
}

{/************************* Reducers ends here. *************************************/
}

//get all code
export function getAllCode(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/get-all-code?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


// create new Metro Area
export function submitCode(obj) {
    return axios.post(`/ajax/create/code`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


//get all SystemProperty
export function getCodeById(codeId) {
    return axios.get(`/ajax/get-code-by-id/${codeId}`).then(
        (response) => {
            return response.data;
        })
}

// update code
export function updateCode(obj, codeId) {
    return axios.put(`/ajax/update/code/${codeId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


export function getCodesList() {
    return axios.get(`/ajax/get-all-codes`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

// delete code
export function deleteCode(codeId) {
    return axios.delete(`/ajax/delete/code/${codeId}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}




{/******************************Msa Services ends here  ****************************************/
}