import {formatValueAsDate, onDraggableStart} from "../helpers/utils";
import React, {useEffect, useRef, useState} from "react";
import Draggable from "react-draggable";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../features/user/users/users";
import SkeletonCell from "./SkeletonCell";

function AuditableInfoModal({show, setShow, creationDate, createdBy, modifiedDate, modifiedBy}) {

    const [bounds, setBounds] = useState({left: 0, right: 0});
    const draggleRef = useRef(null);
    const userData = useSelector(state => state.getAuditableInfoReducer);
    const dispatch = useDispatch();
    const [addBy, setAddedBy] = useState(createdBy);
    const [modifyBy, setModifyBy] = useState(modifiedBy);

    useEffect(() => {
        let userIds = [];
        if (addBy) {
            userIds.push(addBy);
        }
        if (modifyBy) {
            userIds.push(modifyBy);
        }
        dispatch(userActions.getAuditableInfoAction(userIds));
    }, []);


    useEffect(() => {
        if (userData.items) {
            setAddedBy(userData.items.filter(el => el.id === addBy)[0]?.username);
            setModifyBy(userData.items.filter(el => el.id === modifyBy)[0]?.username);
        }
    }, [userData]);

    function dismissModal(e) {
        e.preventDefault();
        setShow(false);
    }

    function applyFallback(val) {
        return val === null || val === "" ? '-' : val;
    }

    return (
        <div className={!show ? "modal fade" : "modal fade show customModal"} id="noteAddModal"
             tabIndex="-1"
             role="dialog" style={!show ? {display: 'none'} : {display: 'block', overflow: 'scroll'}}
             aria-labelledby="noteAddModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">

            <Draggable handle=".handler"
                       bounds={bounds}
                       onStart={(event, uiData) => onDraggableStart(event, uiData, draggleRef, bounds, setBounds)}>

                <div className="modal-dialog modal-dialog-centered" role="document" ref={draggleRef}>
                    <div className="modal-content">
                        <div className="modal-header handler">
                            <h3 className="modal-title" id="exampleModalLabel">Auditable Info</h3>
                            <i className="fa fa-arrows ml-3 mt-1  modal_drag_context_icon"/>
                            <button type="button" className="close" onClick={dismissModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">

                            <div className="form-group row">
                                <label htmlFor="" className="col-sm-4 col-form-label">Created By</label>
                                <div className="col-sm-8">
                                    {userData.items ? <input className="form-control" value={applyFallback(addBy)}
                                                             type="text"
                                    /> : <SkeletonCell/>}
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="" className="col-sm-4 col-form-label">Creation Date</label>
                                <div className="col-sm-8">
                                    {userData.items ? <input className="form-control"
                                                             value={applyFallback(formatValueAsDate(creationDate))}
                                                             type="text"
                                    /> : <SkeletonCell/>}
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="" className="col-sm-4 col-form-label">Modified By</label>
                                <div className="col-sm-8">
                                    {userData.items ? <input className="form-control"
                                                             value={applyFallback(modifyBy)}
                                                             type="text"
                                    /> : <SkeletonCell/>}
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="" className="col-sm-4 col-form-label">Modified Date</label>
                                <div className="col-sm-8">
                                    {userData.items ? <input className="form-control"
                                                             value={applyFallback(formatValueAsDate(modifiedDate))}
                                                             type="text"/> : <SkeletonCell/>}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className={"cursor cm_btn btn_bg"} onClick={dismissModal}>Ok</button>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    )
}

export default AuditableInfoModal;