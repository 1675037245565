import React from 'react';
import "./Components.css"
import ReactLogo from "../../images/loader/loder.svg";
import ErrorPage from "./ErrorPage";


const Preloader = (props) => {

    const {show} = props;

    return (
        <>
            {
                props?.props?.items?.error === undefined ? <div
                        className={`preloader bg-soft flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
                        <img loading="lazy" className="loader-element animate__animated animate__jackInTheBox"
                             src={ReactLogo}
                             height={100} alt={""}/>
                    </div> :

                    <ErrorPage error={props?.props?.items?.error} status={props?.props?.items?.status} msg={props?.props?.items?.message}/>

            }
        </>
    );
};
export default Preloader;
