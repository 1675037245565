import axios from "axios";
import exceptionHandler from "../../../common/helpers/exceptionHandler";
import {serialize} from "../../../common/helpers/utils";

export const recordAuditAction = {
    getAllData,
    getRecordAuditByIdAction,
    getRecordAuditEntityNameListAction
}

export const recordAuditService = {
    getAllAudit,
    getRecordAuditById,
    getRecordAuditEntityNameList
}

export const recordAuditConstant = {
    GET_ALL_RECORD_AUDIT_REQUEST: 'GET_ALL_RECORD_AUDIT_REQUEST',
    GET_ALL_RECORD_AUDIT_SUCCESS: 'GET_ALL_RECORD_AUDIT_SUCCESS',
    GET_ALL_RECORD_AUDIT_FAILURE: 'GET_ALL_RECORD_AUDIT_FAILURE',

    GET_RECORD_AUDIT_BY_ID_REQUEST: 'GET_RECORD_AUDIT_BY_ID_REQUEST',
    GET_RECORD_AUDIT_BY_ID_SUCCESS: 'GET_RECORD_AUDIT_BY_ID_SUCCESS',
    GET_RECORD_AUDIT_BY_ID_FAILURE: 'GET_RECORD_AUDIT_BY_ID_FAILURE',

    GET_RECORD_AUDIT_ENTITY_NAME_REQUEST: 'GET_RECORD_AUDIT_ENTITY_NAME_REQUEST',
    GET_RECORD_AUDIT_ENTITY_NAME_SUCCESS: 'GET_RECORD_AUDIT_ENTITY_NAME_SUCCESS',
    GET_RECORD_AUDIT_ENTITY_NAME_FAILURE: 'GET_RECORD_AUDIT_ENTITY_NAME_FAILURE',
}


function getAllData(query) {
    return dispatch => {
        dispatch(request());
        recordAuditService.getAllAudit(query)
            .then(
                audit => dispatch(success(audit)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: recordAuditConstant.GET_ALL_RECORD_AUDIT_REQUEST}
    }

    function success(audit) {
        return {type: recordAuditConstant.GET_ALL_RECORD_AUDIT_SUCCESS, audit}
    }

    function failure(error) {
        return {type: recordAuditConstant.GET_ALL_RECORD_AUDIT_FAILURE, error}
    }
}



function getRecordAuditByIdAction(auditId) {
    return dispatch => {
        dispatch(request());
        recordAuditService.getRecordAuditById(auditId)
            .then(
                audit => dispatch(success(audit)),
                error => dispatch(failure(error.toString()))
            );

    };

    function request() {
        return {type: recordAuditConstant.GET_RECORD_AUDIT_BY_ID_REQUEST}
    }

    function success(audit) {
        return {type: recordAuditConstant.GET_RECORD_AUDIT_BY_ID_SUCCESS, audit}
    }

    function failure(error) {
        return {type: recordAuditConstant.GET_RECORD_AUDIT_BY_ID_FAILURE, error}
    }
}
function getRecordAuditEntityNameListAction() {
    return dispatch => {
        dispatch(request());
        recordAuditService.getRecordAuditEntityNameList()
            .then(
                list => dispatch(success(list)),
                error => dispatch(failure(error.toString()))
            );

    };

    function request() {
        return {type: recordAuditConstant.GET_RECORD_AUDIT_ENTITY_NAME_REQUEST}
    }

    function success(list) {
        return {type: recordAuditConstant.GET_RECORD_AUDIT_ENTITY_NAME_SUCCESS, list}
    }

    function failure(error) {
        return {type: recordAuditConstant.GET_RECORD_AUDIT_ENTITY_NAME_FAILURE, error}
    }
}

export function getAllRecordAuditReducer(state = {}, action) {
    switch (action.type) {
        case recordAuditConstant.GET_ALL_RECORD_AUDIT_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case recordAuditConstant.GET_ALL_RECORD_AUDIT_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.audit
            };
        case  recordAuditConstant.GET_ALL_RECORD_AUDIT_FAILURE:
            return {};
        default:
            return state
    }
}



export function getRecordAuditByIdReducer(state = {}, action) {
    switch (action.type) {
        case recordAuditConstant.GET_RECORD_AUDIT_BY_ID_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case recordAuditConstant.GET_RECORD_AUDIT_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.audit
            };
        case  recordAuditConstant.GET_RECORD_AUDIT_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

export function getRecordAuditEntityListReducer(state = {}, action) {
    switch (action.type) {
        case recordAuditConstant.GET_RECORD_AUDIT_ENTITY_NAME_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case recordAuditConstant.GET_RECORD_AUDIT_ENTITY_NAME_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.list
            };
        case  recordAuditConstant.GET_RECORD_AUDIT_ENTITY_NAME_FAILURE:
            return {};
        default:
            return state
    }
}
export function getAllAudit(query){
    let paramData = serialize(query)
    return axios.post(`/ajax/get-all-record-audit`, paramData).then(
        (response) => {
            return exceptionHandler(response);
        })
}
// get record audit by id
export function getRecordAuditById(auditId) {
    return axios.get(`/ajax/get-record-audit-by-id/${auditId}`).then(
        (response) => {
            return response.data;
        })
}
export function getRecordAuditEntityNameList() {
    return axios.get(`/ajax/get-record-audit-entity-name-list`).then(
        (response) => {
            return response.data;
        })
}
