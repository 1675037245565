import React, {useRef, useState} from "react";
import Draggable from "react-draggable";
import {onDraggableStart} from "../helpers/utils";

export default function ConfirmationModal({title, body, action = null, show, setShow, disable, isHoaOrder = null}) {

    function dismissModal(e) {
        e.preventDefault();
        setShow(false);
    }

    const [bounds, setBounds] = useState({left: 0, right: 0});
    const draggleRef = useRef(null);

    return (
        <div className={show === false ? "modal fade" : "modal fade show customModal"} role="dialog"
             style={show === false ? {display: 'none'} : {display: 'block'}} id="confirmationModal" tabIndex="-1"
             aria-labelledby="confirmationModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">

            <Draggable handle=".handler"
                       bounds={bounds}
                       onStart={(event, uiData) => onDraggableStart(event, uiData, draggleRef, bounds, setBounds)}>

                <div className="modal-dialog modal-dialog-centered" role="document" ref={draggleRef}>
                    <div className="modal-content group_main_form">
                        <div className={isHoaOrder ? "green_bg modal-header handler" : "modal-header handler"}>
                            <h4 className="modal-title" id="customerModalLabel">{title}</h4>
                            <i className="fa fa-arrows ml-3 mt-1  modal_drag_context_icon"/>
                            <button type="button" className="close" onClick={dismissModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body mr-auto">
                            <p>{body}</p>
                        </div>
                        <div className="modal-footer">
                            {title !== 'Alert' &&
                                <>
                                    <button className="cursor cm_btn cm_bg" onClick={dismissModal}>Cancel</button>
                                    {disable === true ?
                                        <button disabled
                                                className={isHoaOrder === true ? "cursor cm_btn btn_success_spinner ml-0 btn_bg" : "cursor cm_btn btn_success_spinner ml-0"}>
                                            <>
                                                Confirm...
                                                <span className="spinner"/>
                                            </>
                                        </button>
                                        :
                                        <button onClick={action}
                                                className={isHoaOrder === true ? "cursor cm_btn ml-0 btn_bg" : "cursor cm_btn ml-0"}>Confirm</button>
                                    }
                                </>
                            }

                            {title === 'Alert' &&
                                <button className={isHoaOrder !== true ? "cursor btn cm_btn" : "cursor cm_btn btn_bg"}
                                        onClick={action ? action : dismissModal}>Ok</button>
                            }
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    )

}