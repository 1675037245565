import React, {useMemo, useRef, useState} from "react";
import DataTable from "../../../../common/components/DataTable";
import {createClickableLinkWindowRefresh, onDraggableStart} from "../../../../common/helpers/utils";
import {searchOrderModalActions} from "./searchOrderModalRedux";
import Draggable from "react-draggable";

function SearchOrderModal({show, setShow, customerOrderNumber, selector, setShowLoader}) {

    const [bounds, setBounds] = useState({left: 0, right: 0});
    const draggleRef = useRef(null);

    const getProductStatusDotInfo = (productStatus) => {
        const newInfo = {color: '#96938e', title: 'New'};
        const pendingInfo = {color: '#FFB631', title: 'Pending'};
        const completedInfo = {color: '#4682B4', title: 'Completed'};
        const deliveredInfo = {color: '#73B446', title: 'Delivered'};
        const errorInfo = {color: '#B44646', title: 'Error'};

        switch (productStatus) {
            case "NEW":
            case "UPDATED":
                return newInfo;
            case "FETCHED":
            case "PARSED":
                return pendingInfo;
            case "PDF_CREATED":
                return completedInfo;
            case "DELIVERED":
                return deliveredInfo;
            case "PDF_FAILED":
                return errorInfo;
            default:
                return {...errorInfo, title: productStatus};
        }
    };


    function dismissModal(e) {
        e.preventDefault();
        setShow(false);
        setShowLoader(false);
    }

    let columns = useMemo(
        () => [
            {
                Header: "Order No.", accessor: "customerOrderNumber",

                Cell: props => {
                    setShowLoader(false);
                    let path = "/orders/" + props.row.original.id;
                    return createClickableLinkWindowRefresh(path, props.row.original.customerOrderNumber);
                },
            },

            {
                Header: "Product(s)", accessor: "products", disableSortBy: true,
                Cell: props => {
                    return <>
                        {props.row.original.products.map((product) => {
                            const dotInfo = getProductStatusDotInfo(product.productStatus);
                            return (
                                <>
                                    <div className="cursor"
                                         onClick={() => window.location.href = "/orders/" + props.row.original.id}>
                                        <i className='product_status_circle fa fa-solid fa-circle'
                                           style={{color: dotInfo.color}}
                                           title={dotInfo.title}
                                        />
                                        <span>{product.type}</span>
                                    </div>
                                </>
                            )
                        })}
                    </>
                }
            }
        ], []
    )


    let customFilter = {
        "customerOrderNumber": customerOrderNumber
    }
    return (
        <>
            <div className={show === false ? "modal fade" : "modal fade show customModal"} id="global_search"
                 tabIndex="-1"
                 role="dialog" style={{display: 'none'}}
                 aria-labelledby="noteAddModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">

                <Draggable handle=".handler"
                           bounds={bounds}
                           onStart={(event, uiData) => onDraggableStart(event, uiData, draggleRef, bounds, setBounds)}>

                    <div className="modal-dialog modal-dialog-centered note-modal-dialog" role="document"
                         ref={draggleRef}>
                        <div className="modal-content group_main_form">
                            <div className="modal-header handler">
                                <h4 className="modal-title" id="noteAddModalLabel">Search Order</h4>
                                <i className="fa fa-arrows ml-3 mt-1  modal_drag_context_icon"/>

                                <button type="button" className="close" onClick={dismissModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="table_data mt-2">
                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            defaultPageSize={8}
                                            fetchAction={searchOrderModalActions.getGlobalSearchData}
                                            exportAction={null}
                                            customFilter={customFilter}
                                            selector={selector}
                                            appendUrl={false}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group ml-auto">
                                <div className="col-sm-12">
                                    <button className="cm_btn cm_bg" onClick={dismissModal}>Cancel</button>
                                </div>
                            </div>


                        </div>
                    </div>
                </Draggable>
            </div>
        </>


    )

}

export default SearchOrderModal;

