import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";

export const qcRuleActions = {
    getAllData,
    getQcRuleById,
    submitQcRule,
    updateQCRule,
    deleteQcLogicAction,
    exportQcLogicDataAction
};

export const qcRuleService = {
    getAllQCRule,
    getQcRuleByIdService,
    submitQcRuleService,
    updateQcRuleService,
    deleteQcLogic,
    exportQcLogicDataService
};


export const qcRuleConstant = {

    GET_ALL_QC_RULE_REQUEST: 'GET_ALL_QC_RULE_REQUEST',
    GET_ALL_QC_RULE_SUCCESS: 'GET_ALL_QC_RULE_SUCCESS',
    GET_ALL_QC_RULE_FAILURE: 'GET_ALL_QC_RULE_FAILURE',

    GET_ALL_QC_RULE_BY_ID_REQUEST: 'GET_ALL_QC_RULE_BY_ID_REQUEST',
    GET_ALL_QC_RULE_BY_ID_SUCCESS: 'GET_ALL_QC_RULE_BY_ID_SUCCESS',
    GET_ALL_QC_RULE_BY_ID_FAILURE: 'GET_ALL_QC_RULE_BY_ID_FAILURE',

    SUBMIT_QC_RULE_REQUEST: 'SUBMIT_QC_RULE_REQUEST',
    SUBMIT_QC_RULE_SUCCESS: 'SUBMIT_QC_RULE_SUCCESS',
    SUBMIT_QC_RULE__FAILURE: 'SUBMIT_QC_RULE__FAILURE',

    UPDATE_QC_RULE_REQUEST: 'UPDATE_QC_RULE_REQUEST',
    UPDATE_QC_RULE_SUCCESS: 'UPDATE_QC_RULE_SUCCESS',
    UPDATE_QC_RULE__FAILURE: 'UPDATE_QC_RULE__FAILURE',

    DELETE_QC_RULE_REQUEST: 'DELETE_QC_RULE_REQUEST',
    DELETE_QC_RULE_SUCCESS: 'DELETE_QC_RULE_SUCCESS',
    DELETE_QC_RULE_FAILURE: 'DELETE_QC_RULE_FAILURE',

    EXPORT_QC_RULE_DATA_REQUEST: 'EXPORT_QC_RULE_DATA_REQUEST',
    EXPORT_QC_RULE_DATA_SUCCESS: 'EXPORT_QC_RULE_DATA_SUCCESS',
    EXPORT_QC_RULE_DATA_FAILURE: 'EXPORT_QC_RULE_DATA_FAILURE',

};


export function getAllQCRuleReducer(state = {}, action) {
    switch (action.type) {
        case qcRuleConstant.GET_ALL_QC_RULE_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case qcRuleConstant.GET_ALL_QC_RULE_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.qcRules
            };
        case  qcRuleConstant.GET_ALL_QC_RULE_FAILURE:
            return {};
        default:
            return state
    }
}


export function getQcRuleByIdReducer(state = {}, action) {
    switch (action.type) {
        case qcRuleConstant.GET_ALL_QC_RULE_BY_ID_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case qcRuleConstant.GET_ALL_QC_RULE_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.qcRule
            };
        case  qcRuleConstant.GET_ALL_QC_RULE_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

function exportQcLogicDataAction(query) {
    return dispatch => {
        dispatch(request());
        qcRuleService.exportQcLogicDataService(query)
            .then(
                code => dispatch(success(code)),
                error => dispatch(failure(error.toString()))
            );
    }

    function request() {
        return {type: qcRuleConstant.EXPORT_QC_RULE_DATA_REQUEST}
    }

    function success(qcRules) {
        query.setExportBtn(false);
        return {type: qcRuleConstant.EXPORT_QC_RULE_DATA_SUCCESS, qcRules}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: qcRuleConstant.EXPORT_QC_RULE_DATA_FAILURE, error}
    }
}

function getAllData(query) {
    return dispatch => {
        dispatch(request());
        qcRuleService.getAllQCRule(query)
            .then(
                code => dispatch(success(code)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: qcRuleConstant.GET_ALL_QC_RULE_REQUEST}
    }

    function success(qcRules) {
        return {type: qcRuleConstant.GET_ALL_QC_RULE_SUCCESS, qcRules}
    }

    function failure(error) {
        return {type: qcRuleConstant.GET_ALL_QC_RULE_FAILURE, error}
    }
}


// get By id
function getQcRuleById(qcRuleId) {
    return dispatch => {
        dispatch(request());
        qcRuleService.getQcRuleByIdService(qcRuleId)
            .then(
                code => dispatch(success(code)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: qcRuleConstant.GET_ALL_QC_RULE_BY_ID_REQUEST}
    }

    function success(qcRule) {
        return {type: qcRuleConstant.GET_ALL_QC_RULE_BY_ID_SUCCESS, qcRule}
    }

    function failure(error) {
        return {type: qcRuleConstant.GET_ALL_QC_RULE_BY_ID_SUCCESS, error}
    }
}

// submit qc Rule
function submitQcRule(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        qcRuleService.submitQcRuleService(obj)
            .then(
                msa => {
                    if (msa['errorCode'] === 200) {
                        showSuccessToast("QC Rule Created Successfully!");
                        dispatch(success(msa));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(msa);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(qcRule) {
        return {type: qcRuleConstant.SUBMIT_QC_RULE_REQUEST, qcRule}
    }

    function success(qcRule) {
        return {type: qcRuleConstant.SUBMIT_QC_RULE_SUCCESS, qcRule}
    }

    function failure(error) {
        return {type: qcRuleConstant.SUBMIT_QC_RULE__FAILURE, error}
    }
}

//update qcRule
function updateQCRule(obj, id, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        qcRuleService.updateQcRuleService(obj, id)
            .then(
                code => {
                    if (code['errorCode'] === 200) {
                        showSuccessToast("Qc Rule Updated Successfully!");
                        dispatch(success(code));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(code);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(qcRule) {
        return {type: qcRuleConstant.UPDATE_QC_RULE_REQUEST, qcRule}
    }

    function success(qcRule) {
        return {type: qcRuleConstant.UPDATE_QC_RULE_SUCCESS, qcRule}
    }

    function failure(error) {
        return {type: qcRuleConstant.UPDATE_QC_RULE__FAILURE, error}
    }
}


function deleteQcLogicAction(deleteQuery) {
    return dispatch => {
        dispatch(request({deleteQuery}));

        qcRuleService.deleteQcLogic(deleteQuery.deleteReference)
            .then(
                qcLogic => {
                    if (qcLogic['errorCode'] === 200) {
                        showSuccessToast("Qc Logic Deleted Successfully!");
                        dispatch(success(qcLogic));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(qcLogic);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(id) {
        return {type: qcRuleConstant.DELETE_QC_RULE_REQUEST, id}
    }

    function success(qcLogic) {
        return {type: qcRuleConstant.DELETE_QC_RULE_SUCCESS, qcLogic}
    }

    function failure(error) {
        return {type: qcRuleConstant.DELETE_QC_RULE_FAILURE, error}
    }
}





export function getAllQCRule(query) {
    let paramData = serialize(query)
    return axios.get(`/ajax/get-all-qcRules?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export function getQcRuleByIdService(qcRuleId) {
    return axios.get(`/ajax/get-qc-logic/${qcRuleId}`).then(
        (response) => {
            return response.data;
        })
}

//submit qcRule
export function submitQcRuleService(obj) {
    return axios.post(`/ajax/create/qcRule`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

//submit qcRule
export function deleteQcLogic(qcLogicId) {
    return axios.delete(`/ajax/delete/qcLogic/${qcLogicId}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


//update
export function updateQcRuleService(obj, qcRuleId) {
    return axios.put(`/ajax/update/qcRule/${qcRuleId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


//export qc logic data
export async function exportQcLogicDataService(query) {
    let paramData = serialize(query)
    return axios.post(`/export/qcLogic?${paramData}`, null, {
        headers: {
            'Content-Disposition': "attachment; filename=template.csv",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }, responseType: 'arraybuffer',
    })
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "qcLogicData");
            }
        })
        .catch(handleExportError);
}
