import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";
import {highRiskAccountActions} from "../highRiskAccount/highRiskAccount";

//export actions
export const linkedAccountActions = {
    getAllLinkedAccountAction,
    createBulkLinkedAccountAction,
    getLinkedAccountByIdAction,
    updateLinkedAccountAction,
    deleteLinkedAccountActions,
    exportLinkedAccountDataAction
}

//export constants
export const linkedAccountConstants = {
    GET_ALL_LINKED_ACCOUNTS_REQUEST: "GET_ALL_LINKED_ACCOUNTS_REQUEST",
    GET_ALL_LINKED_ACCOUNTS_SUCCESS: "GET_ALL_LINKED_ACCOUNTS_SUCCESS",
    GET_ALL_LINKED_ACCOUNTS_FAILURE: "GET_ALL_LINKED_ACCOUNTS_FAILURE",

    CREATE_BULK_LINKED_ACCOUNT_REQUEST: "CREATE_BULK_LINKED_ACCOUNT_REQUEST",
    CREATE_BULK_LINKED_ACCOUNT_SUCCESS: "CREATE_BULK_LINKED_ACCOUNT_SUCCESS",
    CREATE_BULK_LINKED_ACCOUNT_FAILURE: "CREATE_BULK_LINKED_ACCOUNT_FAILURE",

    GET_LINKED_ACCOUNT_BY_ID_REQUEST: 'GET_LINKED_ACCOUNT_BY_ID_REQUEST',
    GET_LINKED_ACCOUNT_BY_ID_SUCCESS: 'GET_LINKED_ACCOUNT_BY_ID_SUCCESS',
    GET_LINKED_ACCOUNT_BY_ID_FAILURE: 'GET_LINKED_ACCOUNT_BY_ID_FAILURE',

    UPDATE_LINKED_ACCOUNT_BY_ID_REQUEST: 'UPDATE_LINKED_ACCOUNT_BY_ID_REQUEST',
    UPDATE_LINKED_ACCOUNT_BY_ID_SUCCESS: 'UPDATE_LINKED_ACCOUNT_BY_ID_SUCCESS',
    UPDATE_LINKED_ACCOUNT_BY_ID_FAILURE: 'UPDATE_LINKED_ACCOUNT_BY_ID_FAILURE',

    DELETE_LINKED_ACCOUNT_REQUEST: 'DELETE_LINKED_ACCOUNT_REQUEST',
    DELETE_LINKED_ACCOUNT_SUCCESS: 'DELETE_LINKED_ACCOUNT_SUCCESS',
    DELETE_LINKED_ACCOUNT_FAILURE: 'DELETE_LINKED_ACCOUNT_FAILURE',

    EXPORT_LINKED_ACCOUNT_REQUEST: 'EXPORT_LINKED_ACCOUNT_REQUEST',
    EXPORT_LINKED_ACCOUNT_SUCCESS: 'EXPORT_LINKED_ACCOUNT_SUCCESS',
    EXPORT_LINKED_ACCOUNT_FAILURE: 'EXPORT_LINKED_ACCOUNT_FAILURE',


}

//export service
export const linkedAccountService = {
    getAllLinkedAccountService,
    createBulkLinkedAccountService,
    getLinkedAccountByIdService,
    updateLinkedAccountByIdService,
    deleteLinkedAccountByIdService,
    exportLinkedAccountDataService
}

// Start Action


//export linked account data action
function exportLinkedAccountDataAction(query) {
    return dispatch => {
        dispatch(request());
        linkedAccountService.exportLinkedAccountDataService(query)
            .then(
                linkedAccountData => dispatch(success(linkedAccountData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: linkedAccountConstants.EXPORT_LINKED_ACCOUNT_REQUEST}
    }

    function success(linkedAccountData) {
        query.setExportBtn(false);
        return {type: linkedAccountConstants.EXPORT_LINKED_ACCOUNT_SUCCESS, linkedAccountData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: linkedAccountConstants.EXPORT_LINKED_ACCOUNT_FAILURE, error}
    }
}


//delete linked account Action
function deleteLinkedAccountActions(deleteQuery) {
    return dispatch => {
        dispatch(request());
        linkedAccountService.deleteLinkedAccountByIdService(deleteQuery.deleteReference)
            .then(
                linkedAccount => {
                    if (linkedAccount['errorCode'] === 200) {
                        showSuccessToast("Linked account deleted successfully!");
                        dispatch(success(linkedAccount));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(linkedAccount);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: linkedAccountConstants.DELETE_LINKED_ACCOUNT_REQUEST,}
    }

    function success(linkedAccount) {
        return {type: linkedAccountConstants.DELETE_LINKED_ACCOUNT_SUCCESS, linkedAccount}
    }

    function failure(error) {
        return {type: linkedAccountConstants.DELETE_LINKED_ACCOUNT_FAILURE, error}
    }

}


// update linked account action
function updateLinkedAccountAction(linkedAccountId, requestBody, setDisable, from) {
    return dispatch => {
        dispatch(request({requestBody}));
        linkedAccountService.updateLinkedAccountByIdService(linkedAccountId, requestBody)
            .then(
                linkedAccount => {
                    if (linkedAccount['errorCode'] === 200) {
                        showSuccessToast("Linked account updated successfully!");
                        dispatch(success(linkedAccount));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(linkedAccount);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(linkedAccount) {
        return {type: linkedAccountConstants.UPDATE_LINKED_ACCOUNT_BY_ID_REQUEST, linkedAccount}
    }

    function success(linkedAccount) {
        return {type: linkedAccountConstants.GET_LINKED_ACCOUNT_BY_ID_SUCCESS, linkedAccount}
    }

    function failure(error) {
        return {type: linkedAccountConstants.UPDATE_LINKED_ACCOUNT_BY_ID_FAILURE, error}
    }
}

//get linked account by id action
function getLinkedAccountByIdAction(linkedAccountId) {
    return dispatch => {
        dispatch(request());
        linkedAccountService.getLinkedAccountByIdService(linkedAccountId)
            .then(
                linkedAccount => dispatch(success(linkedAccount)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: linkedAccountConstants.GET_LINKED_ACCOUNT_BY_ID_REQUEST}
    }

    function success(linkedAccounts) {
        return {type: linkedAccountConstants.GET_LINKED_ACCOUNT_BY_ID_SUCCESS, linkedAccounts}
    }

    function failure(error) {
        return {type: linkedAccountConstants.GET_LINKED_ACCOUNT_BY_ID_FAILURE, error}
    }
}

//get all linked account Action
function getAllLinkedAccountAction(query) {
    return dispatch => {
        dispatch(request());
        linkedAccountService.getAllLinkedAccountService(query)
            .then(
                collectors => dispatch(success(collectors)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: linkedAccountConstants.GET_ALL_LINKED_ACCOUNTS_REQUEST}
    }

    function success(linkedAccounts) {
        return {type: linkedAccountConstants.GET_ALL_LINKED_ACCOUNTS_SUCCESS, linkedAccounts}
    }

    function failure(error) {
        return {type: linkedAccountConstants.GET_ALL_LINKED_ACCOUNTS_FAILURE, error}
    }
}


// Create Bulk Account Action
function createBulkLinkedAccountAction(requestBody, from, setDisable, verifiedHighRiskAccount = null, highRiskAccountId = null,setShow =true , query ={}) {
    return dispatch => {
        dispatch(request());
        linkedAccountService.createBulkLinkedAccountService(requestBody)
            .then(
                linkedAccount => {
                    if (linkedAccount['errorCode'] === 200) {
                        showSuccessToast("LinkedAccount added successfully!");
                        dispatch(success(linkedAccount));
                        if(verifiedHighRiskAccount){
                            dispatch(highRiskAccountActions.verifyHighRiskAccountByIdAction(verifiedHighRiskAccount, from, setDisable, highRiskAccountId, setShow, query));
                        }
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(linkedAccount);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(linkedAccount) {
        return {type: linkedAccountConstants.CREATE_BULK_LINKED_ACCOUNT_REQUEST, linkedAccount}
    }

    function success(linkedAccount) {
        return {type: linkedAccountConstants.CREATE_BULK_LINKED_ACCOUNT_SUCCESS, linkedAccount}
    }

    function failure(error) {
        return {type: linkedAccountConstants.CREATE_BULK_LINKED_ACCOUNT_FAILURE, error}
    }
}


// End Action


// Start Reducer

export function getAllLinkedAccountReducer(state = {}, action) {

    switch (action.type) {
        case linkedAccountConstants.GET_ALL_LINKED_ACCOUNTS_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case linkedAccountConstants.GET_ALL_LINKED_ACCOUNTS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.linkedAccounts
            };
        case linkedAccountConstants.GET_ALL_LINKED_ACCOUNTS_FAILURE:
            return {};
        default:
            return state
    }

}

//get all linked account reducer
export function getLinkedAccountByIdReducer(state = {}, action) {
    switch (action.type) {
        case linkedAccountConstants.GET_LINKED_ACCOUNT_BY_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case linkedAccountConstants.GET_LINKED_ACCOUNT_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.linkedAccounts
            };
        case linkedAccountConstants.GET_LINKED_ACCOUNT_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}


// End Reducer


// Start Service

//export linked account data Service
export async function exportLinkedAccountDataService(query) {
    let paramData = serialize(query)
    return axios.post(`/export/linkedAccounts/export-csv?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "linkedAccountData");
            }
        })
        .catch(handleExportError);
}


// delete linked account by id service
export async function deleteLinkedAccountByIdService(linkedAccountId) {
    return axios.delete(`/ajax/linkedAccount/${linkedAccountId}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


//update linked account by id service
export function updateLinkedAccountByIdService(linkedAccountId, requestBody) {
    return axios.put(`/ajax/update/linkedAccount/${linkedAccountId}`, requestBody)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

//get linked account by id service
export function getLinkedAccountByIdService(linkedAccountId) {
    return axios.get(`/ajax/linkedAccount/${linkedAccountId}`).then(
        (response) => {
            return response.data;
        })
}

//get all linked account service
export function getAllLinkedAccountService(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/linkedAccount/search?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

// create bulk linked account Service
export function createBulkLinkedAccountService(requestBody) {
    return axios.post(`/ajax/linkedAccount/bulk`, requestBody).then(
        (response) => {
            return exceptionHandler(response);
        })
}





// End Service