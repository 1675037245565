import React from 'react';
import {Link} from 'react-router-dom';


function Breadcrumb(props) {

    function isLast(index) {
        return index === props.crumbs.length - 1;
    }

    return (

        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {
                    props.crumbs.map((crumb, ci) => {
                        const disabled = isLast(ci) ? 'disabled' : '';
                        return (
                            <>
                                {!disabled ? <li key={ci} className="breadcrumb-item">
                                        <a href={crumb.url}>{crumb.content}</a>
                                    </li> :
                                    <li className="breadcrumb-item active" aria-current="page">{crumb.content}
                                    </li>
                                }
                            </>
                        );

                    })
                }
            </ol>
        </nav>
    )
}

export default Breadcrumb;