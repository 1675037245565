import '../../app/App.css'
import React from "react";

export default function SkeletonCell() {

        return (
            <div className="loading w-100">
                <div className="bar"/>
            </div>
        )

}