import React from "react";
import './Pagination.css';
import {useDispatch} from "react-redux";
import {userSettingsAction} from "./userSettingsModal/userSettings";
import {changeUserSettingPageSize} from "../helpers/utils";

function Pagination({
                        displayText,
                        pageCount,
                        setPageNumber,
                        pageNumber,
                        pageSize,
                        userSettingsData = null,
                        appendUrl = true
                    }) {

    const dispatch = useDispatch();

    function changePage(event) {
        const clickedPageNumber = Number(event.target.textContent) - 1;
        setPageNumber(clickedPageNumber);
    }

    function goToPreviousPage() {
        const previousPageNumber = Number(pageNumber) - 1;
        if (previousPageNumber >= 0 && pageCount > previousPageNumber) {
            setPageNumber(previousPageNumber);
        }
    }

    function goToNextPage() {
        const nextPageNumber = Number(pageNumber) + 1;
        if (pageCount > nextPageNumber) {
            setPageNumber(nextPageNumber);
        }
    }


    function changePerPageSize(event) {
        const clickedPageSize = Number(event.target.textContent);
        userSettingsData !== null && dispatch(userSettingsAction.updateUserSettingsActions(changeUserSettingPageSize(userSettingsData, clickedPageSize), null, null))
    }

    function onLastPage() {
        return pageNumber + 1 === pageCount;
    }

    return (

        <div className="pagination_outer ml-2">

            <div className="content_detail__pagination cdp">
                {appendUrl && <span className="display_text">{pageCount > 0 ? displayText : ''}</span>}


                <a onClick={goToPreviousPage}
                   className={pageCount > 0 ? (pageNumber === 0 ? 'cdp_i nav_btns cdi_disable disabled disabled-cursor noselect' : 'cdp_i nav_btns cursor noselect') : 'cdp_i nav_btns cursor noselect'}><i
                    className="fa fa-arrow-left"
                    aria-hidden="true"/>
                </a>


                {
                    pageCount > 0 ?
                        (
                            pageNumber > 6 ?
                                <>
                                    <a className="cdp_i cursor noselect"
                                       key={0}
                                       onClick={changePage}>1</a>
                                    <a className="cdp_i cursor noselect"
                                       key={1} onClick={changePage}>2</a>
                                    <a className="cdp_i cursor noselect">...</a>
                                </>
                                : <></>
                        )
                        : <></>
                }

                {
                    [...Array.from(Array(
                        pageCount
                    ).keys())].slice((pageNumber < 7 ? 0 : (pageNumber <= pageCount - 8 ? pageNumber - 2 : pageNumber - 3)), ((pageCount - 1) > 7 ? (pageNumber < 7 ? 7 : (pageNumber < pageCount - 8 ? pageNumber + 2 : pageNumber + (pageCount - pageNumber))) : pageCount)).map((num, i) =>
                        <a className={num === pageNumber ? 'cdp_i active cursor noselect' : 'cdp_i cursor noselect'}
                           key={i} onClick={changePage}>{num + 1}</a>)
                }

                {
                    pageCount > 0 ?
                        (pageNumber < (pageCount - 4)) && pageCount > 6 ?
                            <>
                                <a className="cdp_i cursor noselect">...</a>
                                <a className="cdp_i cursor noselect"
                                   key={pageCount - 2} onClick={changePage}>{pageCount - 1}</a>
                                <a className="cdp_i cursor noselect"
                                   key={pageCount - 1} onClick={changePage}>{pageCount}</a>
                            </>
                            : <></>
                        : <></>

                }

                <a onClick={goToNextPage}
                   className={pageCount > 0 ? (onLastPage() ? 'cdp_i nav_btns cdi_disable disabled disabled-cursor noselect' : 'cdp_i nav_btns cursor noselect') : 'cdp_i nav_btns cursor noselect'}
                ><i className="fa fa-arrow-right" aria-hidden="true"/>
                </a>
            </div>

            {
                (userSettingsData === null || displayText === '') ?
                    <></>
                    :
                    <>
                        <div className="pages_details">
                            Results per page
                            <a onClick={changePerPageSize}
                               className={pageSize === 15 ? 'ml-2 cdp_i active cursor noselect' : 'ml-2 cdp_i cursor noselect'}>15</a>
                            <a onClick={changePerPageSize}
                               className={pageSize === 25 ? 'cdp_i active cursor noselect' : 'cdp_i cursor noselect'}>25</a>
                            <a onClick={changePerPageSize}
                               className={pageSize === 50 ? 'cdp_i active cursor noselect' : 'cdp_i cursor noselect'}>50</a>

                            <a onClick={changePerPageSize}
                               className={pageSize === 100 ? 'cdp_i active cursor noselect' : 'cdp_i cursor noselect'}>100</a>
                            <a onClick={changePerPageSize}
                               className={pageSize === 150 ? 'cdp_i active cursor noselect' : 'cdp_i cursor noselect'}>150</a>

                        </div>
                    </>

            }

        </div>
    )
}

export default Pagination;