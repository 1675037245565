import {toast} from "react-toastify";


const position = 'bottom-left'
const duration = 3000

export function showErrorToast(content: string, toastId: string = "error1", className: string = "default_toast_class") {
    console.log(content);
    if (content !== "Token Expired!" && !content?.includes("LockAcquisitionException") && !content?.includes("TransactionException")) {

        toast.configure();
        toast.error(content, {
            position: position,
            autoClose: duration,
            toastId: toastId,
            className: className
        });
    }
}


export function showSuccessToast(content: string, toastId: string = "success1", className: string = "default_toast_class") {
    toast.configure();
    toast.success(content, {
        position: position,
        autoClose: duration,
        toastId: toastId,
        className: className
    });
}


export function showWarningToast(content: string) {
    toast.configure();
    toast.warn(content, {
        position: position,
        autoClose: duration,
        toastId: 'warn1',
    });
}

export function showInfoToast(content: string) {
    toast.configure();
    toast.info(content, {
        position: position,
        autoClose: duration,
        toastId: 'warn1',
    });
}