import axios from "axios";
import {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showErrorToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";

//export actions
export const roleActions = {
    createRoleAction,
    updateRoleAction,
    getRoleByIdAction
};

//export constants
export const roleConstants = {
    CREATE_ROLE_REQUEST: 'CREATE_ROLE_REQUEST',
    CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
    CREATE_ROLE_FAILURE: 'CREATE_ROLE_FAILURE',

    UPDATE_ROLE_REQUEST: 'UPDATE_ROLE_REQUEST',
    UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
    UPDATE_ROLE_FAILURE: 'UPDATE_ROLE_FAILURE',

    GET_ROLE_BY_ID_REQUEST: 'GET_ROLE_BY_ID_REQUEST',
    GET_ROLE_BY_ID_SUCCESS: 'GET_ROLE_BY_ID_SUCCESS',
    GET_ROLE_BY_ID_FAILURE: 'GET_ROLE_BY_ID_FAILURE',

};

//export service
export const rolesService = {
    createRole,
    updateRole,
    getRoleById

};


/******************************* roles Actions Starts here  ***************************************/
//submit role Data
function createRoleAction(obj, from,setDisable) {
    return dispatch => {
        dispatch(request({obj}));
        rolesService.createRole(obj)
            .then(
                role => {
                    if (role['errorCode'] === 200) {
                        showSuccessToast("Role added successfully!");
                        dispatch(success(role));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(role);
                    }
                    setDisable(false);
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(role) {
        return {type: roleConstants.CREATE_ROLE_REQUEST, role}
    }

    function success(role) {
        return {type: roleConstants.CREATE_ROLE_SUCCESS, role}
    }

    function failure(error) {
        return {type: roleConstants.CREATE_ROLE_FAILURE, error}
    }
}


//update role Data
function updateRoleAction(obj, from,setDisable) {
    return dispatch => {
        dispatch(request({obj}));
        rolesService.updateRole(obj)
            .then(
                role => {
                    if (role['errorCode'] === 200) {
                        showSuccessToast("Role updated successfully!");
                        dispatch(success(role));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(role);
                    }
                    setDisable(false);
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(role) {
        return {type: roleConstants.UPDATE_ROLE_REQUEST, role}
    }

    function success(role) {
        return {type: roleConstants.UPDATE_ROLE_SUCCESS, role}
    }

    function failure(error) {
        return {type: roleConstants.UPDATE_ROLE_FAILURE, error}
    }
}

function getRoleByIdAction(id) {
    return dispatch => {
         dispatch(request());

           rolesService.getRoleById(id)
               .then(
                   role => dispatch(success(role)),
                   error => dispatch(failure(error.toString()))
               );
    };

    function request() {
        return {type: roleConstants.GET_ROLE_BY_ID_REQUEST}
    }

    function success(role) {
        return {type: roleConstants.GET_ROLE_BY_ID_SUCCESS, role}
    }

    function failure(error) {
        return {type: roleConstants.GET_ROLE_BY_ID_FAILURE, error}
    }
}

/************************************ roles Actions Ends here *******************************************/

export function getRoleByIdReducer(state = {}, action) {
    switch (action.type) {
        case roleConstants.GET_ROLE_BY_ID_REQUEST:
            return {};
        case roleConstants.GET_ROLE_BY_ID_SUCCESS:
            return {
                items: action.role
            };
        case roleConstants.GET_ROLE_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}


/******************************Roles Services starts here  ****************************************/

// submit role data
export function createRole(obj) {

    return axios.post(`/ajax/createRole`, obj)
        .then((response) => {
            return response.data;
        })
}


export function updateRole(obj) {

    return axios.put(`/ajax/updateRole`, obj)
        .then((response) => {
            return response.data;
        })

}

export async function getRoleById(id) {
    return axios.get(`/ajax/get-role-by-id/${id}`).then(
        (response) => {
            return response.data;
        })
}

/******************************Roles Services ends here  ****************************************/




