import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";


export const managementCompanyActions = {
    getAllData,
    submitManagementCompanyAction,
    getManagementCompanyByIdAction,
    updateManagementCompanyAction,
    getManagementCompanyNoteAction,
    deleteManagementCompanyNoteAction,
    createManagementCompanyNoteAction,
    getAllManagementCompanyListAction,
    exportData,
    deleteManagementCompanyByIdAction
};


//export constants
export const managementCompanyConstant = {
    GET_ALL_MANAGEMENT_COMPANY_REQUEST: 'GET_ALL_MANAGEMENT_COMPANY_REQUEST',
    GET_ALL_MANAGEMENT_COMPANY_SUCCESS: 'GET_ALL_MANAGEMENT_COMPANY_SUCCESS',
    GET_ALL_MANAGEMENT_COMPANY_FAILURE: 'GET_ALL_MANAGEMENT_COMPANY_FAILURE',

    CREATE_MANAGEMENT_COMPANY_REQUEST: 'CREATE_MANAGEMENT_COMPANY_REQUEST',
    CREATE_MANAGEMENT_COMPANY_SUCCESS: 'CREATE_MANAGEMENT_COMPANY_SUCCESS',
    CREATE_MANAGEMENT_COMPANY_FAILURE: 'CREATE_MANAGEMENT_COMPANY_FAILURE',


    GET_MANAGEMENT_COMPANY_BY_ID_REQUEST: 'GET_MANAGEMENT_COMPANY_BY_ID_REQUEST',
    GET_MANAGEMENT_COMPANY_BY_ID_SUCCESS: 'GET_MANAGEMENT_COMPANY_BY_ID_SUCCESS',
    GET_MANAGEMENT_COMPANY_BY_ID_FAILURE: 'GET_MANAGEMENT_COMPANY_BY_ID_FAILURE',

    UPDATE_MANAGEMENT_COMPANY_REQUEST: 'UPDATE_MANAGEMENT_COMPANY_REQUEST',
    UPDATE_MANAGEMENT_COMPANY_SUCCESS: 'UPDATE_MANAGEMENT_COMPANY_SUCCESS',
    UPDATE_MANAGEMENT_COMPANY_FAILURE: 'UPDATE_MANAGEMENT_COMPANY_FAILURE',


    GET_MANAGEMENT_COMPANY_BY_STATE_ID_REQUEST: 'GET_MANAGEMENT_COMPANY_BY_STATE_ID_REQUEST',
    GET_MANAGEMENT_COMPANY_BY_STATE_ID_SUCCESS: 'GET_MANAGEMENT_COMPANY_BY_STATE_ID_SUCCESS',
    GET_MANAGEMENT_COMPANY_BY_STATE_ID_FAILURE: 'GET_MANAGEMENT_COMPANY_BY_STATE_ID_FAILURE',

    GET_ALL_MANAGEMENT_COMPANY_LIST_REQUEST: 'GET_ALL_MANAGEMENT_COMPANY_LIST_REQUEST',
    GET_ALL_MANAGEMENT_COMPANY_LIST_SUCCESS: 'GET_ALL_MANAGEMENT_COMPANY_LIST_SUCCESS',
    GET_ALL_MANAGEMENT_COMPANY_LIST_FAILURE: 'GET_ALL_MANAGEMENT_COMPANY_LIST_FAILURE',

    EXPORT_MANAGEMENT_COMPANY_DATA_REQUEST: 'EXPORT_MANAGEMENT_COMPANY_DATA_REQUEST',
    EXPORT_MANAGEMENT_COMPANY_DATA_SUCCESS: 'EXPORT_MANAGEMENT_COMPANY_DATA_SUCCESS',
    EXPORT_MANAGEMENT_COMPANY_DATA_FAILURE: 'EXPORT_MANAGEMENT_COMPANY_DATA_FAILURE',

    DELETE_MANAGEMENT_COMPANY_REQUEST: 'DELETE_MANAGEMENT_COMPANY_REQUEST',
    DELETE_MANAGEMENT_COMPANY_SUCCESS: 'DELETE_MANAGEMENT_COMPANY_SUCCESS',
    DELETE_MANAGEMENT_COMPANY_FAILURE: 'DELETE_MANAGEMENT_COMPANY_FAILURE',

    GET_MANAGEMENT_COMPANY_NOTES_REQUEST: 'GET_MANAGEMENT_COMPANY_NOTES_REQUEST',
    GET_MANAGEMENT_COMPANY_NOTES_SUCCESS: 'GET_MANAGEMENT_COMPANY_NOTES_SUCCESS',
    GET_MANAGEMENT_COMPANY_NOTES_FAILURE: 'GET_SUBDIVISIONS_NOTES_FAILURE',

    DELETE_MANAGEMENT_COMPANY_NOTE_REQUEST: 'DELETE_MANAGEMENT_COMPANY_NOTE_REQUEST',
    DELETE_MANAGEMENT_COMPANY_NOTE_SUCCESS: 'DELETE_MANAGEMENT_COMPANY_NOTE_SUCCESS',
    DELETE_MANAGEMENT_COMPANY_NOTE_FAILURE: 'DELETE_MANAGEMENT_COMPANY_NOTE_FAILURE',

    CREATE_MANAGEMENT_COMPANY_NOTE_REQUEST: 'CREATE_MANAGEMENT_COMPANY_NOTE_REQUEST',
    CREATE_MANAGEMENT_COMPANY_NOTE_SUCCESS: 'CREATE_MANAGEMENT_COMPANY_NOTE_SUCCESS',
    CREATE_MANAGEMENT_COMPANY_NOTE_FAILURE: 'CREATE_MANAGEMENT_COMPANY_NOTE_FAILURE',

}


//export service
export const managementCompanyService = {
    getAllManagementCompany,
    submitManagementCompany,
    getManagementCompanyById,
    updateManagementCompany,
    getAllManagementCompanyList,
    exportManagementCompany,
    deleteManagementCompanyById,
    getManagementCompanyNotesService,
    createManagementCompanyNoteService,
    deleteManagementCompanyNoteById

}


/******************************** Management Company Actions Starts here  ****************************************/

// get All managementCompany Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());
        managementCompanyService.getAllManagementCompany(query)
            .then(
                managementCompanies => dispatch(success(managementCompanies)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: managementCompanyConstant.GET_ALL_MANAGEMENT_COMPANY_REQUEST}
    }

    function success(managementCompanies) {
        return {type: managementCompanyConstant.GET_ALL_MANAGEMENT_COMPANY_SUCCESS, managementCompanies}
    }

    function failure(error) {
        return {type: managementCompanyConstant.GET_ALL_MANAGEMENT_COMPANY_FAILURE, error}
    }

}

//create management company Action
function submitManagementCompanyAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        managementCompanyService.submitManagementCompany(obj)
            .then(
                managementCompany => {
                    if (managementCompany['errorCode'] === 200) {
                        showSuccessToast("Management Company added successfully!");
                        dispatch(success(managementCompany));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(managementCompany);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(managementCompany) {
        return {type: managementCompanyConstant.CREATE_MANAGEMENT_COMPANY_REQUEST, managementCompany}
    }

    function success(managementCompany) {
        return {type: managementCompanyConstant.CREATE_MANAGEMENT_COMPANY_SUCCESS, managementCompany}
    }

    function failure(error) {
        return {type: managementCompanyConstant.CREATE_MANAGEMENT_COMPANY_FAILURE, error}
    }
}


//get Management Company by id
function getManagementCompanyByIdAction(managementCompanyId) {
    return dispatch => {
        dispatch(request());

        managementCompanyService.getManagementCompanyById(managementCompanyId)
            .then(
                managementCompany => dispatch(success(managementCompany)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: managementCompanyConstant.GET_MANAGEMENT_COMPANY_BY_ID_REQUEST}
    }

    function success(managementCompany) {
        return {type: managementCompanyConstant.GET_MANAGEMENT_COMPANY_BY_ID_SUCCESS, managementCompany}
    }

    function failure(error) {
        return {type: managementCompanyConstant.GET_MANAGEMENT_COMPANY_BY_ID_FAILURE, error}
    }
}


//update management company Action
function updateManagementCompanyAction(obj, id, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));
        managementCompanyService.updateManagementCompany(obj, id)
            .then(
                managementCompany => {
                    if (managementCompany['errorCode'] === 200) {
                        showSuccessToast("Management Company updated successfully!");
                        dispatch(success(managementCompany));
                        setDisable(false);
                        dispatch(getManagementCompanyByIdAction(id));
                    } else {
                        setDisable(false);
                        responseDTOExceptionHandler(managementCompany);
                    }
                },
                error => {
                    setDisable(false);
                    dispatch(getManagementCompanyByIdAction(id));
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(managementCompany) {
        return {type: managementCompanyConstant.UPDATE_MANAGEMENT_COMPANY_REQUEST, managementCompany}
    }

    function success(managementCompany) {
        return {type: managementCompanyConstant.UPDATE_MANAGEMENT_COMPANY_SUCCESS, managementCompany}
    }

    function failure(error) {
        return {type: managementCompanyConstant.UPDATE_MANAGEMENT_COMPANY_FAILURE, error}
    }
}

// get All managementCompany List Action
function getAllManagementCompanyListAction() {
    return dispatch => {
        dispatch(request());
        managementCompanyService.getAllManagementCompanyList()
            .then(
                managementCompanies => dispatch(success(managementCompanies)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: managementCompanyConstant.GET_ALL_MANAGEMENT_COMPANY_LIST_REQUEST}
    }

    function success(managementCompanies) {
        return {type: managementCompanyConstant.GET_ALL_MANAGEMENT_COMPANY_LIST_SUCCESS, managementCompanies}
    }

    function failure(error) {
        return {type: managementCompanyConstant.GET_ALL_MANAGEMENT_COMPANY_LIST_FAILURE, error}
    }

}

function exportData(query) {
    return dispatch => {
        dispatch(request());
        managementCompanyService.exportManagementCompany(query)
            .then(
                managementCompanyData => dispatch(success(managementCompanyData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: managementCompanyConstant.EXPORT_MANAGEMENT_COMPANY_DATA_REQUEST}
    }

    function success(taxRollData) {
        query.setExportBtn(false);
        return {type: managementCompanyConstant.EXPORT_MANAGEMENT_COMPANY_DATA_SUCCESS, taxRollData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: managementCompanyConstant.EXPORT_MANAGEMENT_COMPANY_DATA_FAILURE, error}
    }

}

// id,setDisable,setShowDeleteMsaConfirm,query

//delete association Action
function deleteManagementCompanyByIdAction(deleteQuery) {
    return dispatch => {
        dispatch(request());
        managementCompanyService.deleteManagementCompanyById(deleteQuery.deleteReference)
            .then(
                association => {
                    if (association['errorCode'] === 200) {
                        showSuccessToast("Management Company deleted successfully!");
                        dispatch(success(association));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(association);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: managementCompanyConstant.DELETE_MANAGEMENT_COMPANY_REQUEST}
    }

    function success(association) {
        return {type: managementCompanyConstant.DELETE_MANAGEMENT_COMPANY_SUCCESS, association}
    }

    function failure(error) {
        return {type: managementCompanyConstant.DELETE_MANAGEMENT_COMPANY_FAILURE, error}
    }
}

function getManagementCompanyNoteAction(managementCompanyId) {
    return dispatch => {
        dispatch(request());

        managementCompanyService.getManagementCompanyNotesService(managementCompanyId)
            .then(
                notes => dispatch(success(notes)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: managementCompanyConstant.CREATE_MANAGEMENT_COMPANY_NOTE_REQUEST}
    }

    function success(notes) {
        return {type: managementCompanyConstant.GET_MANAGEMENT_COMPANY_NOTES_SUCCESS, notes}
    }

    function failure(error) {
        return {type: managementCompanyConstant.GET_MANAGEMENT_COMPANY_NOTES_FAILURE, error}
    }

}

// create Subdivision Add Note
function createManagementCompanyNoteAction(managmentCompanyId, obj, setDisable, setShow) {
    return dispatch => {
        dispatch(request(obj));
        managementCompanyService.createManagementCompanyNoteService(managmentCompanyId, obj)
            .then(
                note => {
                    if (note['errorCode'] === 200) {
                        showSuccessToast("Note created successfully!");
                        dispatch(success(note));
                        dispatch(getManagementCompanyNoteAction(managmentCompanyId))
                        setShow(false)
                    } else {
                        responseDTOExceptionHandler(note);
                    }
                    setDisable(false)
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(note) {
        return {type: managementCompanyConstant.CREATE_MANAGEMENT_COMPANY_NOTE_REQUEST, note}
    }

    function success(note) {
        return {type: managementCompanyConstant.CREATE_MANAGEMENT_COMPANY_NOTE_SUCCESS, note}
    }

    function failure(error) {
        return {type: managementCompanyConstant.CREATE_MANAGEMENT_COMPANY_NOTE_FAILURE, error}
    }
}
function deleteManagementCompanyNoteAction(noteId, setDisable, setShowDeleteConfirm, managementCompanyId) {
    return dispatch => {
        dispatch(request());
        managementCompanyService.deleteManagementCompanyNoteById(noteId)
            .then(
                managementCompany => {
                    if (managementCompany['errorCode'] === 200) {
                        dispatch(managementCompanyActions.getManagementCompanyNoteAction(managementCompanyId));
                        showSuccessToast("Management company note deleted successfully!");
                        dispatch(success(managementCompany));
                        setShowDeleteConfirm(false);
                    } else {
                        responseDTOExceptionHandler(managementCompany);
                    }
                    setDisable(false)
                },
                error => {
                    setDisable(false)
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: managementCompanyConstant.DELETE_MANAGEMENT_COMPANY_NOTE_REQUEST,}
    }

    function success(subdivision) {
        return {type: managementCompanyConstant.DELETE_MANAGEMENT_COMPANY_NOTE_SUCCESS, subdivision}
    }

    function failure(error) {
        return {type: managementCompanyConstant.DELETE_MANAGEMENT_COMPANY_NOTE_FAILURE, error}
    }
}


/******************************** Management Company Actions ENDS here  ****************************************/


/************************** Reducers starts here. **************************************/
//get all getAllManagementCompany reducer
export function getAllManagementCompanyReducer(state = {}, action) {
    switch (action.type) {
        case managementCompanyConstant.GET_ALL_MANAGEMENT_COMPANY_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case managementCompanyConstant.GET_ALL_MANAGEMENT_COMPANY_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.managementCompanies
            };
        case managementCompanyConstant.GET_ALL_MANAGEMENT_COMPANY_FAILURE:
            return {};
        default:
            return state
    }

}

//get management company by id reducer
export function getManagementCompanyByIdReducer(state = {}, action) {
    switch (action.type) {
        case managementCompanyConstant.GET_MANAGEMENT_COMPANY_BY_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case managementCompanyConstant.GET_MANAGEMENT_COMPANY_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.managementCompany
            };
        case managementCompanyConstant.GET_MANAGEMENT_COMPANY_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

export function getAllManagementCompanyNotesByManagementIdReducer(state = {}, action) {
    switch (action.type) {
        case managementCompanyConstant.GET_MANAGEMENT_COMPANY_NOTES_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case managementCompanyConstant.GET_MANAGEMENT_COMPANY_NOTES_SUCCESS:
            return {
                loggedIn: true,
                items: action.notes
            };
        case managementCompanyConstant.GET_MANAGEMENT_COMPANY_NOTES_FAILURE:
            return {};
        default:
            return state
    }
}


/************************** Reducers ends here. **************************************/


/****************************** Management Company Services starts here  ****************************************/
export function getAllManagementCompany(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/ManagementCompanies?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


// create new management company
export function submitManagementCompany(obj) {
    return axios.post(`/ajax/create/management-company`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function getManagementCompanyById(id) {
    return axios.get(`/ajax/get-management-company-by-id/${id}`).then(
        (response) => {
            return response.data;
        })
}


export function updateManagementCompany(obj, id) {
    return axios.put(`/ajax/update/management-company/${id}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function getAllManagementCompanyList() {
    return axios.get(`/ajax/get-all-management-company`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export async function exportManagementCompany(query) {
    let paramData = serialize(query)
    return axios.post(`/export/management-company?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "managementCompanyData");
            }
        })
        .catch(handleExportError);
}


export async function deleteManagementCompanyById(id) {
    return axios.delete(`/ajax/delete-mgtm-company-by-id/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function getManagementCompanyNotesService(managementCompanyId) {
    return axios.get(`/ajax/get-management-company-notes-by-id/${managementCompanyId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


// create subdivision Note
export function createManagementCompanyNoteService(managementCompanyId, obj) {
    return axios.post(`/ajax/create-management-company-note/${managementCompanyId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


export async function deleteManagementCompanyNoteById(id) {
    return axios.delete(`/ajax/delete-management-company-note-by-id/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}



/****************************** Management Company Services ends here  ****************************************/




