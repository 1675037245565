import {orderService} from "../../../order/orders";

export const searchOrderModalActions = {
    getGlobalSearchData,
}

//export constants
export const searchOrderModalConstants = {
    GET_ORDER_BY_ORDER_NUMBER_REQUEST: 'GET_ORDER_BY_ORDER_NUMBER_REQUEST',
    GET_ORDER_BY_ORDER_NUMBER_SUCCESS: 'GET_ORDER_BY_ORDER_NUMBER_SUCCESS',
    GET_ORDER_BY_ORDER_NUMBER_FAILURE: 'GET_ORDER_BY_ORDER_NUMBER_FAILURE',
}


function getGlobalSearchData(query) {
    return dispatch => {
        dispatch(request());

        orderService.getAllOrders(query)
            .then(
                orderList => {
                    if (orderList.data.length === 1) {
                        window.location.href = "/orders/" + orderList.data[0].id;
                    } else {
                        dispatch(success(orderList));
                        document.getElementById("global_search").style.display = "block";
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() {
        return {type: searchOrderModalConstants.GET_ORDER_BY_ORDER_NUMBER_REQUEST}
    }

    function success(orderList) {
        return {type: searchOrderModalConstants.GET_ORDER_BY_ORDER_NUMBER_SUCCESS, orderList}
    }

    function failure(error) {
        return {type: searchOrderModalConstants.GET_ORDER_BY_ORDER_NUMBER_FAILURE, error}
    }

}


{/***********Reducers starts here**********/
}

//get all order reducer
export function getOrdersListByOrderNumberReducer(state = {}, action) {
    switch (action.type) {
        case searchOrderModalConstants.GET_ORDER_BY_ORDER_NUMBER_REQUEST:
            return {
                loggedIn: true,
                loading: true
            };

        case searchOrderModalConstants.GET_ORDER_BY_ORDER_NUMBER_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.orderList
            };
        case searchOrderModalConstants.GET_ORDER_BY_ORDER_NUMBER_FAILURE:
            return {
                loggedIn: true,
                loading: false
            };

        default:
            return state
    }
}
