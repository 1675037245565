import axios from "axios";
import exceptionHandler from "../../../common/helpers/exceptionHandler";
import {resourceToFileForReport, serialize} from "../../../common/helpers/utils";

export const orderEventActions = {
    getPagedOrderEventAction,
    exportOrderEventAction
};

export const orderEventService = {
    getAllOrderEventsService,
    exportOrderEventDataService
};


export const orderEventConstant = {

    GET_ALL_ORDER_EVENT_REQUEST: 'GET_ALL_ORDER_EVENT_REQUEST',
    GET_ALL_ORDER_EVENT_SUCCESS: 'GET_ALL_ORDER_EVENT_SUCCESS',
    GET_ALL_ORDER_EVENT_FAILURE: 'GET_ALL_ORDER_EVENT_FAILURE',

    EXPORT_ALL_ORDER_EVENT_REQUEST: 'EXPORT_ALL_ORDER_EVENT_REQUEST',
    EXPORT_ALL_ORDER_EVENT_SUCCESS: 'EXPORT_ALL_ORDER_EVENT_SUCCESS',
    EXPORT_ALL_ORDER_EVENT_FAILURE: 'EXPORT_ALL_ORDER_EVENT_FAILURE',


};


function getPagedOrderEventAction(query) {
    return dispatch => {
        dispatch(request());
        orderEventService.getAllOrderEventsService(query)
            .then(
                orderEvents => dispatch(success(orderEvents)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: orderEventConstant.GET_ALL_ORDER_EVENT_REQUEST}
    }

    function success(orderEvents) {
        return {type: orderEventConstant.GET_ALL_ORDER_EVENT_SUCCESS, orderEvents}
    }

    function failure(error) {
        return {type: orderEventConstant.GET_ALL_ORDER_EVENT_FAILURE, error}
    }
}


/***********************Reducers starts here*********************************/

export function getPagedOrderEventsReducer(state = {}, action) {
    switch (action.type) {
        case orderEventConstant.GET_ALL_ORDER_EVENT_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case orderEventConstant.GET_ALL_ORDER_EVENT_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.orderEvents
            };
        case  orderEventConstant.GET_ALL_ORDER_EVENT_FAILURE:
            return {};
        default:
            return state
    }
}

/***********************Reducers ends here*********************************/


/***********************service apis starts here*********************************/

//get all order events
export function getAllOrderEventsService(query) {
    let paramData = serialize(query)
    return axios.get(`/ajax/search-order-events?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}



/***********************service apis ends here*********************************/



// Export Report History Action
function exportOrderEventAction(query) {
    return dispatch => {
        dispatch(request());
        orderEventService.exportOrderEventDataService(query)
            .then(
                reportData => dispatch(success(reportData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: orderEventConstant.EXPORT_ALL_ORDER_EVENT_REQUEST}
    }

    function success(reportData) {
        query.setExportBtn(false);
        return {type: orderEventConstant.EXPORT_ALL_ORDER_EVENT_SUCCESS, reportData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: orderEventConstant.EXPORT_ALL_ORDER_EVENT_FAILURE, error}
    }
}

//

//export Report History Service
export async function exportOrderEventDataService(query) {
    let paramData = serialize(query)
    return axios.post(`/export/order-event?${paramData}`)
        .then((response) => {
            resourceToFileForReport(response.data, "OrderEventReport");
        })
        .catch((error) => console.log(error));
}


