import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";


//export actions
export const msaActions = {
    getAllData,
    submitMetroAreaAction,
    getMetroAreaByIdAction,
    exportData,
    updateMetroAreaAction,
    deleteMetroAreaByIdAction,
    getMetroAreaByStateIdAction,
    getMetroAreaByStateAbbrAction
};

//export constants
export const msaConstants = {
    GET_ALL_METRO_AREA_REQUEST: 'GET_ALL_METRO_AREA_REQUEST',
    GET_ALL_METRO_AREA_SUCCESS: 'GET_ALL_METRO_AREA_SUCCESS',
    GET_ALL_METRO_AREA_FAILURE: 'GET_ALL_METRO_AREA_FAILURE',

    CREATE_METRO_AREA_REQUEST: 'CREATE_METRO_AREA_REQUEST',
    CREATE_METRO_AREA_SUCCESS: 'CREATE_METRO_AREA_SUCCESS',
    CREATE_METRO_AREA_FAILURE: 'CREATE_METRO_AREA_FAILURE',

    GET_METRO_AREA_BY_ID_REQUEST: 'GET_METRO_AREA_BY_ID_REQUEST',
    GET_METRO_AREA_BY_ID_SUCCESS: 'GET_METRO_AREA_BY_ID_SUCCESS',
    GET_METRO_AREA_BY_ID_FAILURE: 'GET_METRO_AREA_BY_ID_FAILURE',


    UPDATE_METRO_AREA_REQUEST: 'UPDATE_METRO_AREA_REQUEST',
    UPDATE_METRO_AREA_SUCCESS: 'UPDATE_METRO_AREA_SUCCESS',
    UPDATE_METRO_AREA_FAILURE: 'UPDATE_METRO_AREA_FAILURE',

    DELETE_METRO_AREA_REQUEST: 'DELETE_METRO_AREA_REQUEST',
    DELETE_METRO_AREA_SUCCESS: 'DELETE_METRO_AREA_SUCCESS',
    DELETE_METRO_AREA_FAILURE: 'DELETE_METRO_AREA_FAILURE',


    GET_METRO_AREA_BY_STATE_ID_REQUEST: 'GET_METRO_AREA_BY_STATE_ID_REQUEST',
    GET_METRO_AREA_BY_STATE_ID_SUCCESS: 'GET_METRO_AREA_BY_STATE_ID_SUCCESS',
    GET_METRO_AREA_BY_STATE_ID_FAILURE: 'GET_METRO_AREA_BY_STATE_ID_FAILURE',

    GET_METRO_AREA_BY_STATE_ABBR_REQUEST: 'GET_METRO_AREA_BY_STATE_ABBR_REQUEST',
    GET_METRO_AREA_BY_STATE_ABBR_SUCCESS: 'GET_METRO_AREA_BY_STATE_ABBR_SUCCESS',
    GET_METRO_AREA_BY_STATE_ABBR_FAILURE: 'GET_METRO_AREA_BY_STATE_ABBR_FAILURE',
    RESET_METRO_AREA_BY_STATE_ABBR: 'RESET_METRO_AREA_BY_STATE_ABBR',


    EXPORT_METRO_AREA_DATA_REQUEST: 'EXPORT_METRO_AREA_DATA_REQUEST',
    EXPORT_METRO_AREA_DATA_SUCCESS: 'EXPORT_METRO_AREA_DATA_SUCCESS',
    EXPORT_METRO_AREA_DATA_FAILURE: 'EXPORT_METRO_AREA_DATA_FAILURE',

};


//export service
export const msaService = {
    getAllMetroArea,
    submitMetroArea,
    getMetroAreaById,
    updateMetroArea,
    exportMetroArea,
    deleteMetroAreaById,
    getMetroAreaByStateId,
    getMetroAreaByStateAbbr
};


{/******************************* msa Actions Starts here  ***************************************/
}

//get all msa Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());

        msaService.getAllMetroArea(query)
            .then(
                msaList => dispatch(success(msaList)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: msaConstants.GET_ALL_METRO_AREA_REQUEST}
    }

    function success(msaList) {
        return {type: msaConstants.GET_ALL_METRO_AREA_SUCCESS, msaList}
    }

    function failure(error) {
        return {type: msaConstants.GET_ALL_METRO_AREA_FAILURE, error}
    }
}

// export msa action
function exportData(query) {
    return dispatch => {
        dispatch(request());
        msaService.exportMetroArea(query)
            .then(
                metroArea => dispatch(success(metroArea)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: msaConstants.EXPORT_METRO_AREA_DATA_REQUEST}
    }

    function success(metroArea) {
        query.setExportBtn(false);
        return {type: msaConstants.EXPORT_METRO_AREA_DATA_SUCCESS, metroArea}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: msaConstants.EXPORT_METRO_AREA_DATA_FAILURE, error}
    }
}


//create msa Action
function submitMetroAreaAction(obj, counties, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        msaService.submitMetroArea(obj, counties)
            .then(
                msa => {
                    if (msa['errorCode'] === 200) {
                        showSuccessToast("Metro Area added successfully!");
                        dispatch(success(msa));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(msa);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(msa) {
        return {type: msaConstants.CREATE_METRO_AREA_REQUEST, msa}
    }

    function success(msa) {
        return {type: msaConstants.CREATE_METRO_AREA_SUCCESS, msa}
    }

    function failure(error) {
        return {type: msaConstants.CREATE_METRO_AREA_FAILURE, error}
    }

}


function getMetroAreaByIdAction(id) {
    return dispatch => {

           dispatch(request());
           msaService.getMetroAreaById(id)
               .then(
                   msa => dispatch(success(msa)),
                   error => dispatch(failure(error.toString()))
               );

    };

    function request() {return {type: msaConstants.GET_METRO_AREA_BY_ID_REQUEST}}
    function success(msa) {return {type: msaConstants.GET_METRO_AREA_BY_ID_SUCCESS, msa}}
    function failure(error) {return {type: msaConstants.GET_METRO_AREA_BY_ID_FAILURE, error}}
}


//create msa Action
function updateMetroAreaAction(obj, id, counties, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        msaService.updateMetroArea(obj, id, counties)
            .then(
                msa => {
                    if (msa['errorCode'] === 200) {
                        showSuccessToast("Metro Area updated successfully!");
                        dispatch(success(msa));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(msa);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(msa) {
        return {type: msaConstants.UPDATE_METRO_AREA_REQUEST, msa}
    }

    function success(msa) {
        return {type: msaConstants.UPDATE_METRO_AREA_SUCCESS, msa}
    }

    function failure(error) {
        return {type: msaConstants.UPDATE_METRO_AREA_FAILURE, error}
    }

}



//delete msa Action
function deleteMetroAreaByIdAction(deleteQuery) {
    return dispatch => {
        dispatch(request(deleteQuery));
        msaService.deleteMetroAreaById(deleteQuery.deleteReference)
            .then(
                msa => {
                    if (msa['errorCode'] === 200) {
                        showSuccessToast("Metro Area deleted successfully!");
                        dispatch(success(msa));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from)
                    } else {
                        responseDTOExceptionHandler(msa);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: msaConstants.DELETE_METRO_AREA_REQUEST,}
    }

    function success(msa) {
        return {type: msaConstants.DELETE_METRO_AREA_SUCCESS, msa}
    }

    function failure(error) {
        return {type: msaConstants.DELETE_METRO_AREA_FAILURE, error}
    }

}


function getMetroAreaByStateIdAction(stateId) {
    return dispatch => {
        dispatch(request());
        msaService.getMetroAreaByStateId(stateId)
            .then(
                msaList => dispatch(success(msaList)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: msaConstants.GET_METRO_AREA_BY_STATE_ID_REQUEST}
    }

    function success(msaList) {
        return {type: msaConstants.GET_METRO_AREA_BY_STATE_ID_SUCCESS, msaList}
    }

    function failure(error) {
        return {type: msaConstants.GET_METRO_AREA_BY_STATE_ID_FAILURE, error}
    }
}


function getMetroAreaByStateAbbrAction(stateId,isReset=false) {
    return dispatch => {
        if(isReset===false){
            dispatch(request());
            msaService.getMetroAreaByStateAbbr(stateId)
                .then(
                    msaList => dispatch(success(msaList)),
                    error => dispatch(failure(error.toString()))
                );
        }
        else{
            dispatch(reset())
        }

    };

    function request() {
        return {type: msaConstants.GET_METRO_AREA_BY_STATE_ABBR_REQUEST}
    }

    function success(msaList) {
        return {type: msaConstants.GET_METRO_AREA_BY_STATE_ABBR_SUCCESS, msaList}
    }

    function failure(error) {
        return {type: msaConstants.GET_METRO_AREA_BY_STATE_ABBR_FAILURE, error}
    }
    function reset(){
        return {type: msaConstants.RESET_METRO_AREA_BY_STATE_ABBR}
    }
}


{/************************************ msa Actions Ends here *******************************************/
}


{/************************* Reducers starts here. *************************************/
}

//get all city reducer
export function getAllMetroAreaReducer(state = {}, action) {
    switch (action.type) {
        case msaConstants.GET_ALL_METRO_AREA_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case msaConstants.GET_ALL_METRO_AREA_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.msaList
            };
        case msaConstants.GET_ALL_METRO_AREA_FAILURE:
            return {};
        default:
            return state
    }
}


//get state by id
export function getMetroAreaByIdReducer(state = {}, action) {
    switch (action.type) {
        case msaConstants.GET_METRO_AREA_BY_ID_REQUEST:
            return {};
        case msaConstants.GET_METRO_AREA_BY_ID_SUCCESS:
            return {
                items: action.msa
            };
        case msaConstants.GET_METRO_AREA_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

export function getMetroAreaByStateIdReducer(state = {}, action) {
    switch (action.type) {
        case msaConstants.GET_METRO_AREA_BY_STATE_ID_REQUEST:
            return {};
        case msaConstants.GET_METRO_AREA_BY_STATE_ID_SUCCESS:
            return {
                items: action.msaList
            };
        case msaConstants.GET_METRO_AREA_BY_STATE_ID_FAILURE:
            return {};
        default:
            return state
    }
}


export function getMetroAreaByStateAbbrReducer(state = {}, action) {
    switch (action.type) {
        case msaConstants.GET_METRO_AREA_BY_STATE_ABBR_REQUEST:
            return {};
        case msaConstants.GET_METRO_AREA_BY_STATE_ABBR_SUCCESS:
            return {
                items: action.msaList
            };
        case msaConstants.GET_METRO_AREA_BY_STATE_ABBR_FAILURE:
            return {};
        case msaConstants.RESET_METRO_AREA_BY_STATE_ABBR:
            return {};
        default:
            return state
    }
}

{/************************** Reducers ends here. *************************************/
}

{/******************************Msa Services starts here  ****************************************/
}

//get all msa
export function getAllMetroArea(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/metro-areas?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


// create new Metro Area
export function submitMetroArea(obj, counties) {
    let countiesList = {counties: counties};
    let paramData = serialize(countiesList)
    return axios.post(`/ajax/create/msa?${paramData}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


export async function getMetroAreaById(id) {
    return axios.get(`/ajax/get-metro-area-by-id/${id}`).then(
        (response) => {
            return response.data;
        })
}

export function getMetroAreaByStateId(stateId) {
    return axios.get(`/ajax/get-metro-area-by-state-id/${stateId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export function getMetroAreaByStateAbbr(stateAbbr) {
    return axios.get(`/ajax/get-metro-area-by-state-abbr/${stateAbbr}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

// update  Metro Area
export function updateMetroArea(obj, id, counties) {
    let countiesList = {counties: counties};
    let paramData = serialize(countiesList)

    return axios.post(`/ajax/update/msa/${id}?${paramData}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

//export Metro Area
export async function exportMetroArea(query) {
    let paramData = serialize(query)
    return axios.post(`/export/metroArea?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "metroData");
            }
        })
        .catch(handleExportError);
}


export async function deleteMetroAreaById(id) {
    return axios.get(`/ajax/delete-metro-area-by-id/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}




{/******************************Msa Services ends here  ****************************************/
}



