import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showSuccessToast} from "../../../common/components/Toast";
import {alertActions} from "../../alert/alert";
import {subdivisionAction} from "./subDivision";

export const parentAccountActions = {
    getAllData,
    createParentAccountAction,
    deleteParentAccountAction,
    updateParentAccountAction,
    getParentAccountByIdAction
};



export const parentAccountConstant = {
    GET_ALL_PARENT_ACCOUNT_REQUEST: 'GET_ALL_PARENT_ACCOUNT_REQUEST',
    GET_ALL_PARENT_ACCOUNT_SUCCESS: 'GET_ALL_PARENT_ACCOUNT_SUCCESS',
    GET_ALL_PARENT_ACCOUNT_FAILURE: 'GET_ALL_PARENT_ACCOUNT_FAILURE',

    CREATE_PARENT_ACCOUNT_REQUEST: 'CREATE_PARENT_ACCOUNT_REQUEST',
    CREATE_PARENT_ACCOUNT_SUCCESS: 'CREATE_PARENT_ACCOUNT_SUCCESS',
    CREATE_PARENT_ACCOUNT_FAILURE: 'CREATE_PARENT_ACCOUNT_FAILURE',

    DELETE_PARENT_ACCOUNT_REQUEST:'DELETE_PARENT_ACCOUNT_REQUEST',
    DELETE_PARENT_ACCOUNT_SUCCESS:'DELETE_PARENT_ACCOUNT_SUCCESS',
    DELETE_PARENT_ACCOUNT_FAILURE:'DELETE_PARENT_ACCOUNT_FAILURE',

    UPDATE_PARENT_ACCOUNT_REQUEST:'UPDATE_PARENT_ACCOUNT_REQUEST',
    UPDATE_PARENT_ACCOUNT_SUCCESS:'UPDATE_PARENT_ACCOUNT_SUCCESS',
    UPDATE_PARENT_ACCOUNT_FAILURE:'UPDATE_PARENT_ACCOUNT_FAILURE',

    GET_PARENT_ACCOUNT_BY_ID_REQUEST:'GET_PARENT_ACCOUNT_BY_ID_REQUEST',
    GET_PARENT_ACCOUNT_BY_ID_SUCCESS:'GET_PARENT_ACCOUNT_BY_ID_SUCCESS',
    GET_PARENT_ACCOUNT_BY_ID_FAILURE:'GET_PARENT_ACCOUNT_BY_ID_FAILURE'

};



export const parentAccountService = {
    getAllParentAccount,
    createParentAccount,
    deleteParentAccount,
    updateParentAccount,
    getParentAccountById

}


/******************************** ParentAccount Actions Starts here  ****************************************/

// get All ParentAccount Action
function getAllData(subdivisionId) {
    return dispatch => {
        dispatch(request());
        parentAccountService.getAllParentAccount(subdivisionId)
            .then(
                parentAccounts => dispatch(success(parentAccounts)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {return {type: parentAccountConstant.GET_ALL_PARENT_ACCOUNT_REQUEST}}
    function success(parentAccounts) {return {type: parentAccountConstant.GET_ALL_PARENT_ACCOUNT_SUCCESS, parentAccounts}}
    function failure(error) {return {type: parentAccountConstant.GET_ALL_PARENT_ACCOUNT_FAILURE, error}}
}



//create parentAccount  Action
function createParentAccountAction(obj,subdivisionId,setDisable,setShow) {
    return dispatch => {
        dispatch(request({obj}));

        parentAccountService.createParentAccount(obj,subdivisionId)
            .then(
                parentAccount => {
                    if (parentAccount['errorCode'] === 200) {
                        showSuccessToast("Parent Account added successfully!");
                        dispatch(success(parentAccount));
                        setShow(false)
                        dispatch(subdivisionAction.getSubdivisionByIdAction(subdivisionId));
                    } else {
                        responseDTOExceptionHandler(parentAccount);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(parentAccount) {return { type: parentAccountConstant.CREATE_PARENT_ACCOUNT_REQUEST, parentAccount }}
    function success(parentAccount) { return {type: parentAccountConstant.CREATE_PARENT_ACCOUNT_SUCCESS, parentAccount }}
    function failure(error) { return {type: parentAccountConstant.CREATE_PARENT_ACCOUNT_FAILURE, error}}
}



// delete ParentAccount Action
function deleteParentAccountAction(parentId,setDisable,subdivisionId,setShowParentDeleteConfirmation) {
    return dispatch => {
        dispatch(request());
        parentAccountService.deleteParentAccount(parentId)
            .then(
                parentAccount => {
                    if (parentAccount['errorCode'] === 200) {
                        showSuccessToast("Parent Account deleted successfully!");
                        dispatch(success(parentAccount));
                        setShowParentDeleteConfirmation(false)
                        dispatch(subdivisionAction.getSubdivisionByIdAction(subdivisionId));
                    } else {
                        responseDTOExceptionHandler(parentAccount);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {return {type: parentAccountConstant.DELETE_PARENT_ACCOUNT_REQUEST}}
    function success(parentAccount) {return {type: parentAccountConstant.DELETE_PARENT_ACCOUNT_SUCCESS, parentAccount}}
    function failure(error) {return {type: parentAccountConstant.DELETE_PARENT_ACCOUNT_FAILURE, error}}
}


//update parentAccount  Action
function updateParentAccountAction(obj,parentId,setDisable,setShow,subdivisionId) {
    return dispatch => {
        dispatch(request({obj}));

        parentAccountService.updateParentAccount(obj,parentId)
            .then(
                parentAccount => {
                    if (parentAccount['errorCode'] === 200) {
                        showSuccessToast("Parent  Account Updated  Successfully!");
                        dispatch(success(parentAccount));
                        setShow(false)
                        dispatch(subdivisionAction.getSubdivisionByIdAction(subdivisionId));
                    } else {
                        responseDTOExceptionHandler(parentAccount);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(parentAccount) {return { type: parentAccountConstant.UPDATE_PARENT_ACCOUNT_REQUEST, parentAccount }}
    function success(parentAccount) { return {type: parentAccountConstant.UPDATE_PARENT_ACCOUNT_SUCCESS, parentAccount }}
    function failure(error) { return {type: parentAccountConstant.UPDATE_PARENT_ACCOUNT_FAILURE, error}}
}


function getParentAccountByIdAction(parentId) {
    return dispatch => {
        dispatch(request());
        parentAccountService.getParentAccountById(parentId)
            .then(
                parentAccount => dispatch(success(parentAccount)),
                error => dispatch(failure(error.toString())));
    };
    function request() {return {type: parentAccountConstant.GET_PARENT_ACCOUNT_BY_ID_REQUEST }}
    function success(parentAccount) {return {type: parentAccountConstant.GET_PARENT_ACCOUNT_BY_ID_SUCCESS , parentAccount}}
    function failure(error) {return {type: parentAccountConstant.GET_PARENT_ACCOUNT_BY_ID_FAILURE, error}}
}




/******************************** Parent Account Actions Ends here  ****************************************/




/************************** Parent Reducers starts here. **************************************/

//get all Parent Account reducer
export function getAllParentAccountReducer(state = {}, action) {
    switch (action.type) {
        case parentAccountConstant.GET_ALL_PARENT_ACCOUNT_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case parentAccountConstant.GET_ALL_PARENT_ACCOUNT_SUCCESS:
            return {
                loggedIn: true,
                items: action.parentAccounts
            };
        case parentAccountConstant.GET_ALL_PARENT_ACCOUNT_FAILURE:
            return {};
        default:
            return state
    }
}


//get Parent Account By Id reducer
export function getParentAccountByIdReducer(state = {}, action) {
    switch (action.type) {
        case parentAccountConstant.GET_PARENT_ACCOUNT_BY_ID_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case parentAccountConstant.GET_PARENT_ACCOUNT_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.parentAccount
            };
        case parentAccountConstant.GET_PARENT_ACCOUNT_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}


/************************** Parent Reducers ends here. **************************************/



/******************************Parent Services starts here  ****************************************/
//get all Parent-Account
export function getAllParentAccount(subdivisionId) {
    return axios.get(`/ajax/parent-account/${subdivisionId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

//create Parent Account service api
export function  createParentAccount(obj,subdivisionId){
    return axios.post(`/ajax/create-parent-account/${subdivisionId}`,obj).then(
        (response)=>{
            return response.data;
        }
    )
}


//delete Parent Account service api
export function  deleteParentAccount(parentId){

    return axios.post(`/ajax/delete-parent-account/${parentId}`).then(
        (response)=>{
            return response.data;
        }
    )
}

//update Parent Account service api
export function  updateParentAccount(obj,parentId){

    return axios.put(`/ajax/update-parent-account/${parentId}`,obj).then(
        (response)=>{
            return response.data;
        }
    )
}

//get Parent-Account By Id
export function getParentAccountById(parentId) {
    return axios.get(`/ajax/get-parent-account-by-id/${parentId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


/******************************Parent Services ends here  ****************************************/
