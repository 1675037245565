import axios from "axios";
import {showErrorToast, showSuccessToast} from "../Toast";
import {jurisdictionAction} from "../../../features/data/jurisdiction/jurisdiction";
import {managementCompanyActions} from "../../../features/Hoa/managementCompany/managementCompany";
import exceptionHandler, {responseDTOExceptionHandler} from "../../helpers/exceptionHandler";
import {alertActions} from "../../../features/alert/alert";
import {
    associationActions
} from "../../../features/Hoa/association/association";
import {thirdPartyProviderActions} from "../../../features/Hoa/thirdPartyProvider/thirdPartyProvider";

export const certificateNoteActions = {
    createCertificateNoteAction,
    updateCertificateNoteAction,
    deleteCertificateNoteAction,
    updateCertificateSortOrderAction,
    getAllCertificateSortOrderAction
}

export const certificateNoteService = {
    createCertificateNote,
    updateCertificateNote,
    deleteCertificateNoteById,
    updateCertificateSortOrder,
    getCertificateNotes
}


export const certificateNoteConstants = {
    CREATE_CERTIFICATE_NOTE_REQUEST: 'CREATE_CERTIFICATE_NOTE_REQUEST',
    CREATE_CERTIFICATE_NOTE_SUCCESS: 'CREATE_CERTIFICATE_NOTE_SUCCESS',
    CREATE_CERTIFICATE_NOTE_FAILURE: 'CREATE_CERTIFICATE_NOTE_FAILURE',

    UPDATE_CERTIFICATE_NOTE_REQUEST: 'UPDATE_CERTIFICATE_NOTE_REQUEST',
    UPDATE_CERTIFICATE_NOTE_SUCCESS: 'UPDATE_CERTIFICATE_NOTE_SUCCESS',
    UPDATE_CERTIFICATE_NOTE_FAILURE: 'UPDATE_CERTIFICATE_NOTE_FAILURE',

    DELETE_CERTIFICATE_NOTE_REQUEST: 'DELETE_CERTIFICATE_NOTE_REQUEST',
    DELETE_CERTIFICATE_NOTE_SUCCESS: 'DELETE_CERTIFICATE_NOTE_SUCCESS',
    DELETE_CERTIFICATE_NOTE_FAILURE: 'DELETE_CERTIFICATE_NOTE_FAILURE',

    UPDATE_CERTIFICATE_SORT_ORDER_REQUEST: 'UPDATE_CERTIFICATE_SORT_ORDER_REQUEST',
    UPDATE_CERTIFICATE_SORT_ORDER_SUCCESS: 'UPDATE_CERTIFICATE_SORT_ORDER_SUCCESS',
    UPDATE_CERTIFICATE_SORT_ORDER_FAILURE: 'UPDATE_CERTIFICATE_SORT_ORDER_FAILURE',

    GET_CERTIFICATE_NOTES_REQUEST: 'GET_CERTIFICATE_NOTES_REQUEST',
    GET_CERTIFICATE_NOTES_SUCCESS: 'GET_CERTIFICATE_NOTES_SUCCESS',
    GET_CERTIFICATE_NOTES_FAILURE: 'GET_CERTIFICATE_NOTES_FAILURE',

}


function createCertificateNoteAction(obj, relationalId, setDisable, setShow, type) {
    return dispatch => {
        dispatch(request(obj));
        certificateNoteService.createCertificateNote(obj)
            .then(
                note => {
                    if (note['errorCode'] === 200) {
                        showSuccessToast("Note created successfully!");
                        dispatch(success(note));
                        setShow(false);
                        reloadSection(type, relationalId, dispatch);
                    } else {
                        responseDTOExceptionHandler(note);
                    }
                    setDisable(false)
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(note) {
        return {type: certificateNoteConstants.CREATE_CERTIFICATE_NOTE_REQUEST, note}
    }

    function success(note) {
        return {type: certificateNoteConstants.CREATE_CERTIFICATE_NOTE_SUCCESS, note}
    }

    function failure(error) {
        return {type: certificateNoteConstants.CREATE_CERTIFICATE_NOTE_FAILURE, error}
    }

}


function updateCertificateNoteAction(obj, noteId, setDisable, setShow, type, relationalId) {
    return dispatch => {
        dispatch(request(obj));
        certificateNoteService.updateCertificateNote(noteId, obj)
            .then(
                note => {
                    console.log("@@@ note", note)
                    if (note['errorCode'] === 200) {
                        showSuccessToast("Note updated successfully!");
                        dispatch(success(note));
                        setShow(false);
                        reloadSection(type, relationalId, dispatch);
                    } else {
                        responseDTOExceptionHandler(note);
                    }
                    setDisable(false)
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(note) {
        return {type: certificateNoteConstants.UPDATE_CERTIFICATE_NOTE_REQUEST, note}
    }

    function success(note) {
        return {type: certificateNoteConstants.UPDATE_CERTIFICATE_NOTE_SUCCESS, note}
    }

    function failure(error) {
        return {type: certificateNoteConstants.UPDATE_CERTIFICATE_NOTE_FAILURE, error}
    }

}


function deleteCertificateNoteAction(noteId, relationalId, setDisable, setShowDeleteConfirm, type) {
    return dispatch => {
        dispatch(request({noteId}));
        certificateNoteService.deleteCertificateNoteById(noteId)
            .then(
                note => {
                    if (note['errorCode'] === 200) {
                        showSuccessToast("Note deleted successfully!");
                        dispatch(success(note));
                        reloadSection(type, relationalId, dispatch);
                    } else {
                        responseDTOExceptionHandler(note);
                    }
                    setDisable(false);
                    setShowDeleteConfirm(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(note) {
        return {type: certificateNoteConstants.DELETE_CERTIFICATE_NOTE_REQUEST, note}
    }

    function success(note) {
        return {type: certificateNoteConstants.DELETE_CERTIFICATE_NOTE_SUCCESS, note}
    }

    function failure(error) {
        return {type: certificateNoteConstants.DELETE_CERTIFICATE_NOTE_FAILURE, error}
    }

}

function getAllCertificateSortOrderAction(relationalId) {

    return dispatch => {
        dispatch(request());
        certificateNoteService.getCertificateNotes(relationalId)
            .then(
                certificateNotes => dispatch(success(certificateNotes)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: certificateNoteConstants.GET_CERTIFICATE_NOTES_REQUEST}
    }

    function success(certificateNotes) {
        return {type: certificateNoteConstants.GET_CERTIFICATE_NOTES_SUCCESS, certificateNotes}
    }

    function failure(error) {
        return {type: certificateNoteConstants.GET_CERTIFICATE_NOTES_FAILURE, error}
    }

}



function updateCertificateSortOrderAction(relationalId, listOfNotes, type) {
    return dispatch => {
        dispatch(request());
        certificateNoteService.updateCertificateSortOrder(relationalId, listOfNotes, type)
            .then(
                notes => {
                    if (notes['errorCode'] === 200) {
                        showSuccessToast("Notes order updated successfully!");
                        dispatch(success(notes));
                    } else {
                        responseDTOExceptionHandler(notes);
                    }
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: certificateNoteConstants.UPDATE_CERTIFICATE_SORT_ORDER_REQUEST}
    }

    function success(notes) {
        return {type: certificateNoteConstants.UPDATE_CERTIFICATE_SORT_ORDER_SUCCESS, notes}
    }

    function failure(error) {
        return {type: certificateNoteConstants.UPDATE_CERTIFICATE_SORT_ORDER_FAILURE, error}
    }

}


//Collector fulfillment notes
export function getAllCertificateNotesByRelationIdReducer(state = {}, action) {
    switch (action.type) {
        case certificateNoteConstants.GET_CERTIFICATE_NOTES_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case certificateNoteConstants.GET_CERTIFICATE_NOTES_SUCCESS:
            return {
                loggedIn: true,
                items: action.certificateNotes
            };
        case certificateNoteConstants.GET_CERTIFICATE_NOTES_FAILURE:
            return {};
        default:
            return state
    }
}

export function createCertificateNote(obj) {
    return axios.post(`/ajax/create-certificate-note`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

//update Certificate  Note
export function updateCertificateNote(noteId, obj) {
    return axios.put(`/ajax/updateCertificateNote/${noteId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export async function deleteCertificateNoteById(noteId) {
    return axios.delete(`/ajax/deleteCertificateNote/${noteId}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

function reloadSection(type, relationalId, dispatch) {
        dispatch(getAllCertificateSortOrderAction(relationalId));
}



export function updateCertificateSortOrder(relationalId, listObj, type) {
    return axios.put(`/ajax/update-certificate-note-order/${relationalId}/${type}`, listObj).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export function getCertificateNotes(relationalId) {
    return axios.get(`/ajax/get-certificate-notes/${relationalId}`).then(
        (response) => {
            return exceptionHandler(response);
        })

}
