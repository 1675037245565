import axios from "axios";
import {alertActions} from "../alert/alert";
import {history} from "../../common/helpers/history";
import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import {homeActions} from "../home/home";
import {serialize} from "../../common/helpers/utils";


//export actions
export const authenticationActions = {
    loginActions,
    logoutActions,
    authenticateImpersonatorUserAction
};

//export constants
export const authenticationConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',

    IMPERSONATE_REQUEST: 'IMPERSONATE_REQUEST',
    IMPERSONATE_SUCCESS: 'IMPERSONATE_SUCCESS',
    IMPERSONATE_FAILURE: 'IMPERSONATE_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

};

//export service
export const authenticationService = {
    login,
    authenticateImpersonatorUser,
    logout,
};

{/********************** Actions started here. *****************************/
}

function loginActions(username, password, from) {
    return dispatch => {

        dispatch(request(username));

        authenticationService.login(username, password)
            .then(
                result => {
                    if (result['errorCode'] === 200) {
                        dispatch(success(result));
                        localStorage.setItem('user', "loggedIn");
                        localStorage.setItem("daysToExpirePassword", JSON.stringify(result['daysToExpirePassword']));

                        if (result['customAuthTokenDTO']['isUserHaveTeam'] !== null) {
                            localStorage.setItem("isUserHaveTeam", JSON.stringify(result['customAuthTokenDTO']['userHaveTeam']));

                        }
                        localStorage.setItem("isUserManager", JSON.stringify(result['customAuthTokenDTO']['isUserManager']));
                        history.push(from);
                        
                        dispatch(success(result));
                        dispatch(homeActions.fetchAuthoritiesAction());
                    } else {
                        dispatch(success(result));
                        responseDTOExceptionHandler(result);
                    }
                },
                error => {
                    localStorage.clear();
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };


    function request(user) {
        return {type: authenticationConstants.LOGIN_REQUEST, user}
    }

    function success(result) {
        return {type: authenticationConstants.LOGIN_SUCCESS, result}
    }

    function failure(error) {
        return {type: authenticationConstants.LOGIN_FAILURE, error}
    }
}

function authenticateImpersonatorUserAction(username, from) {
    return dispatch => {

        dispatch(request(username));

        authenticationService.authenticateImpersonatorUser(username)
            .then(
                result => {
                    if (result['errorCode'] === 200) {
                        dispatch(homeActions.resetReducerAction("fetchAuthoritiesReducer"));
                        localStorage.setItem('user', "loggedIn");
                        localStorage.setItem("daysToExpirePassword", JSON.stringify(result['daysToExpirePassword']));

                        if (result['customAuthTokenDTO']['isUserHaveTeam'] !== null) {
                            localStorage.setItem("isUserHaveTeam", JSON.stringify(result['customAuthTokenDTO']['userHaveTeam']));

                        }
                        localStorage.setItem("isUserManager", JSON.stringify(result['customAuthTokenDTO']['isUserManager']));
                        history.push(from);
                        dispatch(success(result));
                        window.location.reload();
                    } else {
                        responseDTOExceptionHandler(result);
                    }
                },
                error => {
                    localStorage.clear();
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };


    function request(user) {
        return {type: authenticationConstants.IMPERSONATE_REQUEST, user}
    }

    function success(result) {
        return {type: authenticationConstants.IMPERSONATE_SUCCESS, result}
    }

    function failure(error) {
        return {type: authenticationConstants.IMPERSONATE_FAILURE, error}
    }
}


function logoutActions() {
    authenticationService.logout();
    return {type: authenticationConstants.LOGOUT};
}

{/********************** Actions ended here. *****************************/
}

{/********************** Reducers started here. *****************************/
}

//reducer
const initialState = {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case authenticationConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
            };
        case authenticationConstants.LOGIN_SUCCESS:
            return {
                loggedIn: false,
                user: action.result
            };
        case authenticationConstants.LOGIN_FAILURE:
            return {
                loggingIn: false,

            };
        case authenticationConstants.LOGOUT:
            return {};
        default:
            return state
    }
}


{/********************************* Reducers ended here. ********************************************/
}


{/******************************* Services started here. ***************************************************/
}

function login(username, password) {
    const requestOptions = {username: username, password: password}
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.replaceAll("/", "&");

    return axios.post(`/ajax/authenticate/${timeZone}`, requestOptions).then(
        (response) => {
            return exceptionHandler(response);
        })
}

function authenticateImpersonatorUser(username) {
    const requestOptions = {username: username}
    let paramData = serialize(requestOptions)

    return axios.post(`/ajax/authenticate-impersonator?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    return axios.get(`/ajax/logout`).then(
        (response) => {
            history.push("/login");
        })
}



{/**************************** Services ended here. ***************************/
}



