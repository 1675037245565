import {usePrompt} from "../../app/useBeforeUnload";
import React from "react";

const UnsavedChangesWarning = ({unsavedChanges}) => {

    const Prompt = usePrompt({
        when: unsavedChanges,
        message: "Are you sure you want to leave this page? You will lose unsaved changes."
    });

    return (
        <>
            <Prompt/>
        </>
    )
}
export default UnsavedChangesWarning;