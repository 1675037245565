import axios from "axios";
import {showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import { serialize} from "../../../common/helpers/utils";
import {highRiskAccountActions} from "../highRiskAccount/highRiskAccount";

//export actions
export const highRiskAccountVerifiedActions = {
    getAllHighRiskAccountVerifiedAction,
    submitHighRiskAccountVerifiedAction,
    getHighRiskAccountVerifiedByIdAction,
    deleteHighRiskAccountVerifiedByIdAction
};

//export constants
export const highRiskAccountVerifiedConstants = {
    GET_ALL_HIGH_RISK_ACCOUNT_VERIFIED_REQUEST: 'GET_ALL_HIGH_RISK_ACCOUNT_VERIFIED_REQUEST',
    GET_ALL_HIGH_RISK_ACCOUNT_VERIFIED_SUCCESS: 'GET_ALL_HIGH_RISK_ACCOUNT_VERIFIED_SUCCESS',
    GET_ALL_HIGH_RISK_ACCOUNT_VERIFIED_FAILURE: 'GET_ALL_HIGH_RISK_ACCOUNT_VERIFIED_FAILURE',

    CREATE_HIGH_RISK_ACCOUNT_VERIFIED_REQUEST: 'CREATE_HIGH_RISK_ACCOUNT_VERIFIED_REQUEST',
    CREATE_HIGH_RISK_ACCOUNT_VERIFIED_SUCCESS: 'CREATE_HIGH_RISK_ACCOUNT_VERIFIED_SUCCESS',
    CREATE_HIGH_RISK_ACCOUNT_VERIFIED_FAILURE: 'CREATE_HIGH_RISK_ACCOUNT_VERIFIED_FAILURE',

    GET_HIGH_RISK_ACCOUNT_VERIFIED_BY_ID_REQUEST: 'GET_HIGH_RISK_ACCOUNT_VERIFIED_BY_ID_REQUEST',
    GET_HIGH_RISK_ACCOUNT_VERIFIED_BY_ID_SUCCESS: 'GET_HIGH_RISK_ACCOUNT_VERIFIED_BY_ID_SUCCESS',
    GET_HIGH_RISK_ACCOUNT_VERIFIED_BY_ID_FAILURE: 'GET_HIGH_RISK_ACCOUNT_VERIFIED_BY_ID_FAILURE',

    DELETE_HIGH_RISK_ACCOUNT_VERIFIED_REQUEST: 'DELETE_HIGH_RISK_ACCOUNT_VERIFIED_REQUEST',
    DELETE_HIGH_RISK_ACCOUNT_VERIFIED_SUCCESS: 'DELETE_HIGH_RISK_ACCOUNT_VERIFIED_SUCCESS',
    DELETE_HIGH_RISK_ACCOUNT_VERIFIED_FAILURE: 'DELETE_HIGH_RISK_ACCOUNT_VERIFIED_FAILURE'

};

//export service
export const highRiskAccountVerifiedService = {
    getAllHighRiskAccountVerified,
    createHighRiskAccountVerified,
    getHighRiskAccountVerifiedById,
    deleteHighRiskAccountVerifiedByIdService
};

{/************************************* High Risk Account Actions Starts here  ******************************************/
}

function getAllHighRiskAccountVerifiedAction(query) {
    return dispatch => {
        dispatch(request());

        highRiskAccountVerifiedService.getAllHighRiskAccountVerified(query)

            .then(
                highRiskAccounts => dispatch(success(highRiskAccounts)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: highRiskAccountVerifiedConstants.GET_ALL_HIGH_RISK_ACCOUNT_VERIFIED_REQUEST}
    }

    function success(highRiskAccounts) {
        return {type: highRiskAccountVerifiedConstants.GET_ALL_HIGH_RISK_ACCOUNT_VERIFIED_SUCCESS, highRiskAccounts}
    }

    function failure(error) {
        return {type: highRiskAccountVerifiedConstants.GET_ALL_HIGH_RISK_ACCOUNT_VERIFIED_FAILURE, error}
    }
}


function submitHighRiskAccountVerifiedAction(obj, from, setDisable, id, setShow) {
    return dispatch => {
        dispatch(request({obj}));

        highRiskAccountVerifiedService.createHighRiskAccountVerified(obj)
            .then(
                highRiskAccount => {
                    if (highRiskAccount['errorCode'] === 200) {
                        showSuccessToast("High Risk Account Verified added successfully!");
                        dispatch(success(highRiskAccount));
                        dispatch(highRiskAccountActions.getAllHighRiskAccountAction({}));
                        setShow(false)
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(highRiskAccount);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(highRiskAccount) {
        return {type: highRiskAccountVerifiedConstants.CREATE_HIGH_RISK_ACCOUNT_VERIFIED_REQUEST, highRiskAccount}
    }

    function success(highRiskAccount) {
        return {type: highRiskAccountVerifiedConstants.CREATE_HIGH_RISK_ACCOUNT_VERIFIED_SUCCESS, highRiskAccount}
    }

    function failure(error) {
        return {type: highRiskAccountVerifiedConstants.CREATE_HIGH_RISK_ACCOUNT_VERIFIED_FAILURE, error}
    }
}

function getHighRiskAccountVerifiedByIdAction(id) {
    return dispatch => {
        dispatch(request());
        highRiskAccountVerifiedService.getHighRiskAccountVerifiedById(id)
            .then(
                highRiskAccount => dispatch(success(highRiskAccount)),
                error => dispatch(failure(error.toString()))
            );

    };

    function request() {
        return {type: highRiskAccountVerifiedConstants.GET_HIGH_RISK_ACCOUNT_VERIFIED_BY_ID_REQUEST}
    }

    function success(highRiskAccount) {
        return {type: highRiskAccountVerifiedConstants.GET_HIGH_RISK_ACCOUNT_VERIFIED_BY_ID_SUCCESS, highRiskAccount}
    }

    function failure(error) {
        return {type: highRiskAccountVerifiedConstants.GET_HIGH_RISK_ACCOUNT_VERIFIED_BY_ID_FAILURE, error}
    }
}

function deleteHighRiskAccountVerifiedByIdAction(id,setDisable, setShowDeleteConfirm) {
    return dispatch => {
        highRiskAccountVerifiedService.deleteHighRiskAccountVerifiedByIdService(id)
            .then(
                highRiskAccount => {
                    if (highRiskAccount['errorCode'] === 200) {
                        showSuccessToast("High Risk Account deleted successfully!");
                        dispatch(success(highRiskAccount));
                        dispatch(highRiskAccountVerifiedActions.getAllHighRiskAccountVerifiedAction({}));
                    } else {
                        responseDTOExceptionHandler(highRiskAccount);
                    }
                    setDisable(false)
                    setShowDeleteConfirm(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() {
        return {type: highRiskAccountVerifiedConstants.DELETE_HIGH_RISK_ACCOUNT_VERIFIED_REQUEST,}
    }

    function success(highRiskAccount) {
        return {type: highRiskAccountVerifiedConstants.DELETE_HIGH_RISK_ACCOUNT_VERIFIED_SUCCESS, highRiskAccount}
    }

    function failure(error) {
        return {type: highRiskAccountVerifiedConstants.DELETE_HIGH_RISK_ACCOUNT_VERIFIED_FAILURE, error}
    }

}

{/***************************** High Risk Account Actions Ends here **********************************/
}


{/****************** Reducers starts here. **************************************/
}

export function getAllPagedHighRiskAccountVerifiedReducer(highRiskAccount = {}, action) {
    switch (action.type) {
        case highRiskAccountVerifiedConstants.GET_ALL_HIGH_RISK_ACCOUNT_VERIFIED_REQUEST:
            return {
                ...highRiskAccount,
                loading: true,
                loggingIn: true,
            };
        case highRiskAccountVerifiedConstants.GET_ALL_HIGH_RISK_ACCOUNT_VERIFIED_SUCCESS:
            return {
                loading: false,
                loggingIn: true,
                items: action.highRiskAccounts
            };
        case highRiskAccountVerifiedConstants.GET_ALL_HIGH_RISK_ACCOUNT_VERIFIED_FAILURE:
            return {};
        default:
            return highRiskAccount
    }
}
export function getHighRiskAccountVerifiedByIdReducer(highRiskAccount = {}, action) {
    switch (action.type) {
        case highRiskAccountVerifiedConstants.GET_HIGH_RISK_ACCOUNT_VERIFIED_BY_ID_REQUEST:
            return {};
        case highRiskAccountVerifiedConstants.GET_HIGH_RISK_ACCOUNT_VERIFIED_BY_ID_SUCCESS:
            return {
                items: action.highRiskAccount
            };
        case highRiskAccountVerifiedConstants.GET_HIGH_RISK_ACCOUNT_VERIFIED_BY_ID_FAILURE:
            return {};
        default:
            return highRiskAccount
    }
}

{/*************************** Reducers ends here. **************************************/
}

{/******************************High Risk Account Verified Services starts here  ****************************************/
}
export async function getAllHighRiskAccountVerified(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/high-risk-account-verified/search?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function getHighRiskAccountVerifiedById(id) {
    return axios.get(`/ajax/get-high-risk-account-verified-by-id/${id}`).then(
        (response) => {
            return response.data;
        })
}

export async function createHighRiskAccountVerified(obj) {
    return axios.post(`/ajax/high-risk-account-verified`, obj)
        .then((response) => {
            return response.data
        })
}

export async function deleteHighRiskAccountVerifiedByIdService(id){
    return axios.delete(`/ajax/high-risk-account-verified/delete/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


{/******************************High Risk Account Verified Services ends here  ****************************************/
}




