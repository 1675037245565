import axios from "axios";
import exceptionHandler from "../../../common/helpers/exceptionHandler";
import {resourceToFile, serialize} from "../../../common/helpers/utils";
import {showInfoToast} from "../../../common/components/Toast";


export const dataRetrieverActions = {
    getAllDataRetrievalConfigByCollectorIdAction,
    exportData
};

export const dataRetrievalService = {

    getAllDataRetrievalConfigByCollectorId,
    exportDataRetrieval
};

export const dataRetrievalConfigurationConstants = {

    GET_ALL_DATA_RETRIEVE_REQUEST: 'GET_ALL_DATA_RETRIEVE_REQUEST',
    GET_ALL_DATA_RETRIEVE_SUCCESS: 'GET_ALL_DATA_RETRIEVE_SUCCESS',
    GET_ALL_DATA_RETRIEVE_FAILURE: 'GET_ALL_DATA_RETRIEVE_FAILURE',


    EXPORT_DATA_RETRIEVAL_REQUEST:'EXPORT_DATA_RETRIEVAL_REQUEST',
    EXPORT_DATA_RETRIEVAL_SUCCESS:'EXPORT_DATA_RETRIEVAL_SUCCESS',
    EXPORT_DATA_RETRIEVAL_FAILURE:'EXPORT_DATA_RETRIEVAL_FAILURE',

};

function getAllDataRetrievalConfigByCollectorIdAction(query) {
    return dispatch => {
        dispatch(request());
        dataRetrievalService.getAllDataRetrievalConfigByCollectorId(query)
            .then(
                config => dispatch(success(config)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: dataRetrievalConfigurationConstants.GET_ALL_DATA_RETRIEVE_REQUEST}
    }

    function success(config) {
        return {type: dataRetrievalConfigurationConstants.GET_ALL_DATA_RETRIEVE_SUCCESS, config}
    }

    function failure(error) {
        return {type: dataRetrievalConfigurationConstants.GET_ALL_DATA_RETRIEVE_FAILURE, error}
    }
}





//export Customers Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        dataRetrievalService.exportDataRetrieval(query)
            .then(
                dataRetrievalData => dispatch(success(dataRetrievalData)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() {return {type: dataRetrievalConfigurationConstants.EXPORT_DATA_RETRIEVAL_REQUEST}}
    function success(dataRetrievalData) {query.setExportBtn(false); return {type: dataRetrievalConfigurationConstants.EXPORT_DATA_RETRIEVAL_SUCCESS, dataRetrievalData}}
    function failure(error) {query.setExportBtn(false); return {type: dataRetrievalConfigurationConstants.EXPORT_DATA_RETRIEVAL_FAILURE, error}}

}






export function  getAllDataRetrievalConfigByCollectorIdReducer(state = {}, action) {
    switch (action.type) {
        case dataRetrievalConfigurationConstants.GET_ALL_DATA_RETRIEVE_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case dataRetrievalConfigurationConstants.GET_ALL_DATA_RETRIEVE_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.config
            };
        case  dataRetrievalConfigurationConstants.GET_ALL_DATA_RETRIEVE_FAILURE:
            return {};
        default:
            return state
    }
}

//get all SystemProperty
export function getAllDataRetrievalConfigByCollectorId(query) {
    let paramData = serialize(query)
    return axios.get(`/ajax/get-retrieve-data-configuration?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}



//export customer Data
export async function exportDataRetrieval(query) {
    let paramData = serialize(query)
    return axios.post(`/export/data-retrieval?${paramData}`,null,
        {
            headers:
                {'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "dataRetrievalData");
            }
        })
        .catch((error) => console.log(error));
}





