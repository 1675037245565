import React from "react"
import {history} from "../helpers/history";
import {useLocation} from "react-router-dom";

export default function ErrorPage({error, status, msg}) {
    const location = useLocation();

    function formatString(str) {
        return str
            .replace(/(\B)[^ ]*/g, match => (match.toLowerCase()))
            .replace(/^[^ ]/g, match => (match.toUpperCase()));
    }

    return (
        <>
            <div className="error_wrapper">
                <div className={"text-center"}><h2>{formatString(error.replace('_', ' '))}</h2></div>
                <p className={"error_text text-center"}>{status}</p>
                <p className={"text-center"}>
                    {location.state === undefined || location.state === '' ?
                        <b>{msg}</b>
                        :
                        <b>{location.state}</b>
                    }
                </p>
                <div className={"text-center"}><button className="cm_btn" onClick={() => history.push("/home")}>Back To Home</button></div>
            </div>
        </>
    )
}

