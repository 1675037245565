import axios from "axios";
import exceptionHandler from "../../../common/helpers/exceptionHandler";
import {showInfoToast} from "../../../common/components/Toast";
import {
    GetCachedResponseFromReducer,
    handleExportError,
    resourceToFile,
    serialize
} from "../../../common/helpers/utils";

export const externalActions = {
    getAllBillReleaseData,
    exportBillReleaseAction,
    getAllTenantAction,
    getAllStateAction,
    getCountyByStateAbbrAction
}

export const externalConstants = {

    EXPORT_EXTERNAL_BILL_RELEASE_DATA_REQUEST: 'EXPORT_EXTERNAL_BILL_RELEASE_DATA_REQUEST',
    EXPORT_EXTERNAL_BILL_RELEASE_DATA_SUCCESS: 'EXPORT_EXTERNAL_BILL_RELEASE_DATA_SUCCESS',
    EXPORT_EXTERNAL_BILL_RELEASE_DATA_FAILURE: 'EXPORT_EXTERNAL_BILL_RELEASE_DATA_FAILURE',

    GET_EXTERNAL_ALL_BILL_RELEASE_REQUEST: 'GET_EXTERNAL_ALL_BILL_RELEASE_REQUEST',
    GET_EXTERNAL_ALL_BILL_RELEASE_SUCCESS: 'GET_EXTERNAL_ALL_BILL_RELEASE_SUCCESS',
    GET_EXTERNAL_ALL_BILL_RELEASE_FAILURE: 'GET_EXTERNAL_ALL_BILL_RELEASE_FAILURE',

    GET_EXTERNAL_ALL_STATES_REQUEST: 'GET_EXTERNAL_ALL_STATES_REQUEST',
    GET_EXTERNAL_ALL_STATES_SUCCESS: 'GET_EXTERNAL_ALL_STATES_SUCCESS',
    GET_EXTERNAL_ALL_STATES_FAILURE: 'GET_EXTERNAL_ALL_STATES_FAILURE',

    GET_EXTERNAL_ALL_COUNTY_BY_STATE_ABBR_FAILURE: 'GET_EXTERNAL_ALL_COUNTY_BY_STATE_ABBR_FAILURE',
    GET_EXTERNAL_ALL_COUNTY_BY_STATE_ABBR_SUCCESS: 'GET_EXTERNAL_ALL_COUNTY_BY_STATE_ABBR_SUCCESS',
    GET_EXTERNAL_ALL_COUNTY_BY_STATE_ABBR_REQUEST: 'GET_EXTERNAL_ALL_COUNTY_BY_STATE_ABBR_REQUEST',

    GET_EXTERNAL_ALL_TENANT_REQUEST: 'GET_EXTERNAL_ALL_TENANT_REQUEST',
    GET_EXTERNAL_ALL_TENANT_SUCCESS: 'GET_EXTERNAL_ALL_TENANT_SUCCESS',
    GET_EXTERNAL_ALL_TENANT_FAILURE: 'GET_EXTERNAL_ALL_TENANT_FAILURE'

}

export const externalService = {
    getAllBillReleases,
    exportBillReleaseData,
    getAllTenantService,
    getAllState,
    getAllCountyByStateAbbr

}

function getAllBillReleaseData(query) {
    return dispatch => {
        dispatch(request());

        externalService.getAllBillReleases(query)

            .then(
                billReleases => dispatch(success(billReleases)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: externalConstants.GET_EXTERNAL_ALL_BILL_RELEASE_REQUEST}
    }

    function success(billReleases) {
        return {type: externalConstants.GET_EXTERNAL_ALL_BILL_RELEASE_SUCCESS, billReleases}
    }

    function failure(error) {
        return {type: externalConstants.GET_EXTERNAL_ALL_BILL_RELEASE_FAILURE, error}
    }
}

function exportBillReleaseAction(query) {
    return dispatch => {
        dispatch(request());
        externalService.exportBillReleaseData(query)
            .then(
                billReleaseData => dispatch(success(billReleaseData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: externalConstants.EXPORT_EXTERNAL_BILL_RELEASE_DATA_REQUEST}
    }

    function success(billReleaseData) {
        query.setExportBtn(false);
        return {type: externalConstants.EXPORT_EXTERNAL_BILL_RELEASE_DATA_SUCCESS, billReleaseData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: externalConstants.EXPORT_EXTERNAL_BILL_RELEASE_DATA_FAILURE, error}
    }
}

function getAllTenantAction() {
    return dispatch => {
        dispatch(request());
        externalService.getAllTenantService()
            .then(tenants => dispatch(success(tenants)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: externalConstants.GET_EXTERNAL_ALL_TENANT_REQUEST}
    }

    function success(tenants) {
        return {type: externalConstants.GET_EXTERNAL_ALL_TENANT_SUCCESS, tenants}
    }

    function failure(error) {
        return {type: externalConstants.GET_EXTERNAL_ALL_TENANT_FAILURE, error}
    }
}

function getAllStateAction() {
    return dispatch => {
        if (GetCachedResponseFromReducer("getAllStateReducer") === undefined) {
            dispatch(request());
            externalService.getAllState()
                .then(states => dispatch(success(states)), error => dispatch(failure(error.toString())));
        }
    };

    function request() {
        return {type: externalConstants.GET_EXTERNAL_ALL_STATES_REQUEST}
    }

    function success(states) {
        return {type: externalConstants.GET_EXTERNAL_ALL_STATES_SUCCESS, states}
    }

    function failure(error) {
        return {type: externalConstants.GET_EXTERNAL_ALL_STATES_FAILURE, error}
    }

}

function getCountyByStateAbbrAction(stateAbbr) {
    return dispatch => {
        dispatch(request());
        externalService.getAllCountyByStateAbbr(stateAbbr)
            .then(
                county => dispatch(success(county)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: externalConstants.GET_EXTERNAL_ALL_COUNTY_BY_STATE_ABBR_REQUEST}
    }

    function success(county) {
        return {type: externalConstants.GET_EXTERNAL_ALL_COUNTY_BY_STATE_ABBR_SUCCESS, county}
    }

    function failure(error) {
        return {type: externalConstants.GET_EXTERNAL_ALL_COUNTY_BY_STATE_ABBR_FAILURE, error}
    }

}

///reducer/////


export function getExternalPageBillReleaseReducer(billRelease = {}, action) {
    switch (action.type) {
        case externalConstants.GET_EXTERNAL_ALL_BILL_RELEASE_REQUEST:
            return {
                ...billRelease,
                loading: true,
                loggingIn: true,
            };
        case externalConstants.GET_EXTERNAL_ALL_BILL_RELEASE_SUCCESS:
            return {
                loading: false,
                loggingIn: true,
                items: action.billReleases
            };
        case externalConstants.GET_EXTERNAL_ALL_BILL_RELEASE_FAILURE:
            return {};
        default:
            return billRelease
    }
}

export function getExternalPageStateReducer(state = {}, action) {
    switch (action.type) {
        case externalConstants.GET_EXTERNAL_ALL_STATES_REQUEST:
            return {
                loggingIn: true,
            };
        case externalConstants.GET_EXTERNAL_ALL_STATES_SUCCESS:
            return {
                loggedIn: true, items: action.states
            };
        case externalConstants.GET_EXTERNAL_ALL_STATES_FAILURE:
            return {};
        default:
            return state
    }
}

export function getExternalPageCountyByStateAbbrReducer(state = {}, action) {
    switch (action.type) {
        case externalConstants.GET_EXTERNAL_ALL_COUNTY_BY_STATE_ABBR_REQUEST:
            return {
                loggingIn: true,
            };
        case externalConstants.GET_EXTERNAL_ALL_COUNTY_BY_STATE_ABBR_SUCCESS:
            return {
                loggedIn: true,
                items: action.county
            };
        case externalConstants.GET_EXTERNAL_ALL_COUNTY_BY_STATE_ABBR_FAILURE:
            return {};
        default:
            return state
    }
}


export function getExternalPageTenantReducer(state = {}, action) {
    switch (action.type) {
        case externalConstants.GET_EXTERNAL_ALL_TENANT_REQUEST:
            return {
                loggingIn: true,
            };
        case externalConstants.GET_EXTERNAL_ALL_TENANT_SUCCESS:
            return {
                loggedIn: true, items: action.tenants
            };
        case externalConstants.GET_EXTERNAL_ALL_TENANT_FAILURE:
            return {};
        default:
            return state
    }
}


///service

export async function getAllBillReleases(query) {
    let paramData = serialize(query)
    return axios.post(`/external/billReleases?${paramData}`).then(
        (response) => {
            console.log(response);
            return exceptionHandler(response);
        })
}


export async function exportBillReleaseData(query) {
    let paramData = serialize(query)
    return axios.post(`/external/billRelease?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "billReleaseData");
            }
        })
        .catch(handleExportError);
}

export async function getAllTenantService() {
    return axios.get(`/external/tenant`).then((response) => {
        return exceptionHandler(response);
    })

}

export async function getAllState() {
    return await axios.get(`/external/get-all-states`).then((response) => {
        return exceptionHandler(response);
    })

}

//get all County by State Abbr
export async function getAllCountyByStateAbbr(stateAbbreviation) {
    return await axios.get(`/external/county-by-state/${stateAbbreviation}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

