
//export actions
export const editorActions = {
    editorAction,

}

//export constants
export const editorConstant = {
    GET_EDITOR_DATA:'GET_EDITOR_DATA',

}

/*********** Actions started here. ******************/

function  editorAction(data1){
    return dispatch => {
        dispatch(data(data1));
    };
    function data(data1) {
        return {type: editorConstant.GET_EDITOR_DATA,data1}
    }
}


/*********** Actions ended here. ******************/


/*********** Reducers started here. ******************/

export function editorReducer(state = {}, action) {
    switch (action.type) {
        case editorConstant.GET_EDITOR_DATA:
            return {
                items: action.data1,
                loggingIn: true,
            };
        default:
            return state
    }
}

/*********** Reducers ended here. ******************/




/*********** Services ended here. ******************/
