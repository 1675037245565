import axios from "axios";
import exceptionHandler from "../../../common/helpers/exceptionHandler";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";
import {showInfoToast} from "../../../common/components/Toast";

//export actions
export const taxRollActions = {
    getAllData,
    getTaxRollByIdAction,
    exportData
};

//export constants
export const taxRollConstant = {
    GET_ALL_TAX_ROLL_REQUEST: 'GET_ALL_TAX_ROLL_REQUEST',
    GET_ALL_TAX_ROLL_SUCCESS: 'GET_ALL_TAX_ROLL_SUCCESS',
    GET_ALL_TAX_ROLL_FAILURE: 'GET_ALL_TAX_ROLL_FAILURE',

    GET_ALL_TAX_ROLL_BY_ID_REQUEST: 'GET_ALL_TAX_ROLL_BY_ID_REQUEST',
    GET_ALL_TAX_ROLL_BY_ID_SUCCESS: 'GET_ALL_TAX_ROLL_BY_ID_SUCCESS',
    GET_ALL_TAX_ROLL_BY_ID_FAILURE: 'GET_ALL_TAX_ROLL_BY_ID_FAILURE',

    EXPORT_TAX_ROLL_DATA_REQUEST: 'EXPORT_TAX_ROLL_DATA_REQUEST',
    EXPORT_TAX_ROLL_DATA_SUCCESS: 'EXPORT_TAX_ROLL_DATA_SUCCESS',
    EXPORT_TAX_ROLL_DATA_FAILURE: 'EXPORT_TAX_ROLL_DATA_FAILURE',


};

//export service
export const taxRollService = {
    getAllTaxRoll,
    getTaxRollById,
    exportTaxRollData
};


{/******************************** TaxRoll Actions Starts here  ****************************************/
}

// get All TaxRoll Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());
        taxRollService.getAllTaxRoll(query)
            .then(
                taxRolls => dispatch(success(taxRolls)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: taxRollConstant.GET_ALL_TAX_ROLL_REQUEST}
    }

    function success(taxRolls) {
        return {type: taxRollConstant.GET_ALL_TAX_ROLL_SUCCESS, taxRolls}
    }

    function failure(error) {
        return {type: taxRollConstant.GET_ALL_TAX_ROLL_FAILURE, error}
    }

}

function getTaxRollByIdAction(id) {
    return dispatch => {
        dispatch(request());
        taxRollService.getTaxRollById(id)
            .then(
                taxRoll => dispatch(success(taxRoll)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: taxRollConstant.GET_ALL_TAX_ROLL_BY_ID_REQUEST}
    }

    function success(taxRoll) {
        return {type: taxRollConstant.GET_ALL_TAX_ROLL_BY_ID_SUCCESS, taxRoll}
    }

    function failure(error) {
        return {type: taxRollConstant.GET_ALL_TAX_ROLL_BY_ID_FAILURE, error}
    }
}


//export TaxRoll Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        taxRollService.exportTaxRollData(query)
            .then(
                taxRollData => dispatch(success(taxRollData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: taxRollConstant.EXPORT_TAX_ROLL_DATA_REQUEST}
    }

    function success(taxRollData) {
        query.setExportBtn(false);
        return {type: taxRollConstant.EXPORT_TAX_ROLL_DATA_SUCCESS, taxRollData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: taxRollConstant.EXPORT_TAX_ROLL_DATA_FAILURE, error}
    }
}


/************************************* TaxRoll Actions Ends here ********************************************/


/************************** Reducers starts here. **************************************/

//get all taxRoll reducer
export function getAllTaxRollReducer(state = {}, action) {
    switch (action.type) {
        case taxRollConstant.GET_ALL_TAX_ROLL_REQUEST:
            return {
                ...state,
                loading:true,
                loggingIn: true,
            };
        case taxRollConstant.GET_ALL_TAX_ROLL_SUCCESS:
            return {
                loggedIn: true,
                loading:false,
                items: action.taxRolls
            };
        case taxRollConstant.GET_ALL_TAX_ROLL_FAILURE:
            return {};
        default:
            return state
    }

}

export function getTaxRollByIdReducer(state = {}, action) {
    switch (action.type) {
        case taxRollConstant.GET_ALL_TAX_ROLL_BY_ID_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case taxRollConstant.GET_ALL_TAX_ROLL_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.taxRoll
            };
        case taxRollConstant.GET_ALL_TAX_ROLL_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

/*************************** Reducers ends here. **************************************/


/******************************taxRoll Services starts here  ****************************************/
//get all collectors
export function getAllTaxRoll(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/tax-rolls?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}



//get all taxRoll by id
export function getTaxRollById(id) {
    return axios.get(`/ajax/get-tax-roll-by-id/` + id).then(
        (response) => {
            return response.data;
        })
}


//export TaxRoll Data Service
export async function exportTaxRollData(query) {
    let paramData = serialize(query)
    return axios.post(`/export/tax-roll?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "taxRollData");
            }
        })
        .catch(handleExportError);
}




/******************************taxRoll Services ends here  ****************************************/




