import {CommonSubmitLoader, getUserSettingList, getUserSettingListAll, onDraggableStart} from "../../helpers/utils";
import React, {useEffect, useRef, useState} from "react";
import "./userSettings.css";
import {useDispatch, useSelector} from "react-redux";
import {userSettingsAction} from "./userSettings";
import Draggable from "react-draggable";
import {LinkOpeningStyle, PageControl} from "../../enums/Enums";
import {Form} from "../Form";


const UserSettingsModal = ({gridName, headerGroups, show, setShow, selector}) => {

    const dispatch = useDispatch();
    const userSettingsData = useSelector(selector === 'findUserSettingsByGroupNameReducer' ? state => state.findUserSettingsByGroupNameReducer : selector);

    const [userSettingsList, setUserSettingsList] = useState([]);
    const [pageNumber, setPageNumber] = useState("15");
    const [pageControl, setPageControl] = useState("BOTTOM");
    const [linkOpeningStyle, setLinkOpeningStyle] = useState("CURRENT TAB");
    const [disable, setDisable] = useState(false);
    const [bounds, setBounds] = useState({left: 0, right: 0});
    const draggleRef = useRef(null);

    useEffect(() => {
        if (userSettingsData.items) {
            if (Array.isArray(userSettingsData.items) && userSettingsData.items.length === 0) {
                setUserSettingsList(getUserSettingListAll(headerGroups, gridName));
            } else {
                setPageNumber(findKeyFromUserSetting(userSettingsData,"pageNumber")?.value);
                let pageControlSetting =  findKeyFromUserSetting(userSettingsData,"pageControl")?.value ?? "BOTTOM";
                setPageControl(pageControlSetting);

                let linkOpeningStyleSetting = findKeyFromUserSetting(userSettingsData,"linkOpeningStyle")?.value ?? "CURRENT TAB";
                setLinkOpeningStyle(linkOpeningStyleSetting);
                setUserSettingsList(getUserSettingList(userSettingsData.items, headerGroups, gridName, null, null, userSettingsData.items.length > 0 && findKeyFromUserSetting(userSettingsData,"pageNumber")?.value, pageControlSetting, linkOpeningStyleSetting));
            }
        }
        }, [userSettingsData]);


    function findKeyFromUserSetting(userSettingsData, key) {
        return userSettingsData?.items?.find(cur => cur.key === key);
    }

    function dismissModal(e) {
        e.preventDefault();
        setShow(false);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        setDisable(true);
        dispatch(userSettingsAction.updateUserSettingsActions(userSettingsList.filter(cur => cur.key !== null && cur.key !== undefined), setDisable, setShow));
    }


    const handleChange = (e) => {
        const {name, checked, value} = e !== undefined && e.target;
        setUserSettingsList(getUserSettingList(userSettingsList, headerGroups, gridName, name,
            (name.includes("pageControl") || name.includes("linkOpeningStyle")) ? null : checked,
            pageNumber, name.includes("pageControl")  ? value : pageControl,
            name.includes("linkOpeningStyle") ? value : linkOpeningStyle));
    }


    return (
        <>
            <div className={!show ? "modal fade" : "modal fade show customModal"} id="user_settings_model"
                 tabIndex="-1"
                 role="dialog" style={!show ? {display: 'none'} : {display: 'block', overflow: 'scroll'}}
                 aria-labelledby="noteAddModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">

                <Draggable handle=".handler"
                           bounds={bounds}
                           onStart={(event, uiData) => onDraggableStart(event, uiData, draggleRef, bounds, setBounds)}>

                    <div className="modal-dialog modal-dialog-centered note-modal-dialog" role="document"
                         ref={draggleRef}>
                        <div className="modal-content group_main_form">

                            <div className="modal-header   handler ">
                                <h2 className="modal-title" id="noteAddModalLabel">{gridName} Table Settings</h2>
                                <i className="fa fa-arrows ml-3 mt-1  modal_drag_context_icon"/>
                                <button type="button" className="close" onClick={dismissModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                <Form onSubmit={handleSubmit} fields={<>
                                    <div className={"row"}>

                                        {Array.isArray(userSettingsList) && userSettingsList.length > 0 &&
                                            userSettingsList.filter(cur => cur.key !== "pageNumber" && cur.key !== "pageControl" && cur.key !== "linkOpeningStyle" && cur.key).map((curCol, index) => {
                                                return <>
                                                    <div className={"col-md-6 mb-3"}>
                                                        <div className="form-check">
                                                            <input type="checkbox" id={index}
                                                                   className="form-check-input"
                                                                   name={curCol.key}
                                                                   onClick={handleChange}
                                                                   checked={curCol.checked}
                                                                   disabled={headerGroups[index] !== undefined && headerGroups[index].canBeHidden !== undefined && !headerGroups[index].canBeHidden === true}
                                                            />
                                                            <label className="form-check-label"
                                                                   htmlFor="exampleCheck1">{curCol.key}</label>
                                                        </div>
                                                    </div>

                                                </>
                                            })
                                        }

                                        {Array.isArray(userSettingsList) && userSettingsList.length > 0 &&
                                            <>
                                              <div className={"col-md-12"}>
                                                 <div className="form-group mb-0">
                                                     <label className="form-check-label ml-0"
                                                           htmlFor="exampleCheck1">Page Control</label>
                                                     <select className="form-control ml-0 mb-2" name="pageControl"
                                                             value={userSettingsList.find(cur => cur.key === 'pageControl')?.value}
                                                            onChange={(e) => handleChange(e)}
                                                            required>
                                                        {Array.isArray(PageControl) && PageControl.map((curEnum) => {
                                                                return (
                                                                    <option
                                                                        value={curEnum.constant}>{curEnum.value}</option>
                                                                )
                                                            }
                                                        )
                                                        }
                                                     </select>

                                                 </div>
                                              </div>
                                                <div className={"col-md-12"}>
                                                    <div className="form-group mb-0">
                                                        <label className="form-check-label ml-0"
                                                               htmlFor="exampleCheck1">Link Opening Style</label>
                                                        <select className="form-control ml-0 mb-2" name="linkOpeningStyle"
                                                                value={userSettingsList.find(cur => cur.key === 'linkOpeningStyle')?.value}
                                                                onChange={(e) => handleChange(e)}
                                                                required>
                                                            {Array.isArray(LinkOpeningStyle) && LinkOpeningStyle.map((curEnum) => {
                                                                    return (
                                                                        <option
                                                                            value={curEnum.constant}>{curEnum.value}</option>
                                                                    )
                                                                }
                                                            )
                                                            }
                                                        </select>

                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </div>
                                    <div className="row mt-3">

                                        <div className="col-sm-12">
                                            <button className="cm_btn cm_bg" onClick={dismissModal}>Cancel</button>
                                            {CommonSubmitLoader(disable, "Apply")}
                                        </div>
                                    </div>
                                </>}>
                                </Form>
                            </div>

                        </div>
                    </div>
                </Draggable>
            </div>
        </>

    )

}

export default UserSettingsModal;