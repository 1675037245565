import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import {handleExportError, resourceToFile, serialize} from "../../common/helpers/utils";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../common/components/Toast";
import {alertActions} from "../alert/alert";

export const transactionActions = {
    getAllData,
    exportData,
    updateTransactionStatusAction
}

export const transactionConstants = {
    GET_ALL_TRANSACTION_REQUEST: 'GET_ALL_TRANSACTION_REQUEST',
    GET_ALL_TRANSACTION_SUCCESS: 'GET_ALL_TRANSACTION_SUCCESS',
    GET_ALL_TRANSACTION_FAILURE: 'GET_ALL_TRANSACTION_FAILURE',

    EXPORT_TRANSACTION_DATA_REQUEST: 'EXPORT_TRANSACTION_DATA_REQUEST',
    EXPORT_TRANSACTION_DATA_SUCCESS: 'EXPORT_TRANSACTION_DATA_SUCCESS',
    EXPORT_TRANSACTION_DATA_FAILURE: 'EXPORT_TRANSACTION_DATA_FAILURE',

    UPDATE_TRANSACTION_STATUS_REQUEST: 'UPDATE_TRANSACTION_STATUS_REQUEST',
    UPDATE_TRANSACTION_STATUS_SUCCESS: 'UPDATE_TRANSACTION_STATUS_SUCCESS',
    UPDATE_TRANSACTION_STATUS_FAILURE: 'UPDATE_TRANSACTION_STATUS_FAILURE'

}

export const transactionService = {
    getAllTransactionService,
    exportTransactionData,
    updateTransactionStatusService
}


function exportData(query) {
    return dispatch => {
        dispatch(request());
        transactionService.exportTransactionData(query)
            .then(
                transactionData => dispatch(success(transactionData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: transactionConstants.EXPORT_TRANSACTION_DATA_REQUEST}
    }

    function success(transactionData) {
        query.setExportBtn(false);
        return {type: transactionConstants.EXPORT_TRANSACTION_DATA_SUCCESS, transactionData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: transactionConstants.EXPORT_TRANSACTION_DATA_FAILURE, error}
    }

}


function getAllData(query) {
    return dispatch => {
        dispatch(request());

        transactionService.getAllTransactionService(query)
            .then(
                transactions => dispatch(success(transactions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: transactionConstants.GET_ALL_TRANSACTION_REQUEST}
    }

    function success(transactions) {
        return {type: transactionConstants.GET_ALL_TRANSACTION_SUCCESS, transactions}
    }

    function failure(error) {
        return {type: transactionConstants.GET_ALL_TRANSACTION_FAILURE, error}
    }
}

function updateTransactionStatusAction(transactionId, transactionStatus, setShowFnfStatusUpdateModal, setDisable) {
    return dispatch => {
        dispatch(request());
        transactionService.updateTransactionStatusService(transactionId, transactionStatus)
            .then(transaction => {
                if (transaction['errorCode'] === 200) {
                    showSuccessToast("FNF Transaction status updated successfully!");
                    dispatch(success(transaction));
                } else {
                    responseDTOExceptionHandler(transaction);
                }
                dispatch(getAllData({}));
                setShowFnfStatusUpdateModal(false);
                setDisable(false)
            }, error => {
                showErrorToast(error['message']);
                setShowFnfStatusUpdateModal(false);
                setDisable(false)
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: transactionConstants.UPDATE_TRANSACTION_STATUS_REQUEST}
    }

    function success(transaction) {
        return {type: transactionConstants.UPDATE_TRANSACTION_STATUS_SUCCESS, transaction}
    }

    function failure(error) {
        return {type: transactionConstants.UPDATE_TRANSACTION_STATUS_FAILURE, error}
    }

}


/*********** Reducers started here. ******************/

//get all user reducer
export function getAllTransactionsReducer(state = {}, action) {
    switch (action.type) {
        case transactionConstants.GET_ALL_TRANSACTION_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case transactionConstants.GET_ALL_TRANSACTION_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.transactions
            };
        case transactionConstants.GET_ALL_TRANSACTION_FAILURE:
            return {};
        default:
            return state
    }
}

//export customer Data
export async function exportTransactionData(query) {
    let paramData = serialize(query)
    return axios.post(`/export/fnf-transactions?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    ).then((response) => {
        if (response.data.byteLength === 0) {
            showInfoToast("No data found to export!")
        } else {
            resourceToFile(response.data, "FnfTransactionReport");
        }
    })
        .catch(handleExportError);

}

export async function getAllTransactionService(query) {
    let paramData = serialize(query)
    return axios.get(`/ajax/fnf-transactions?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}
export function updateTransactionStatusService(fnfTransactionId, transactionStatus) {
    return axios.put(`/ajax/updateFnfTransactionStatus/${fnfTransactionId}/${transactionStatus}`).then((response) => {
        return exceptionHandler(response);
    })


}

