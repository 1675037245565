import axios from "axios";
import exceptionHandler, {responseDTOExceptionHandler} from "../../helpers/exceptionHandler";
import {showSuccessToast} from "../Toast";
import {alertActions} from "../../../features/alert/alert";
import {homeActions} from "../../../features/home/home";

export const contactActions = {
    getContactByIdAction,
    createContactAction,
    updateContactAction,
    deleteContactAction,
    getAllContactsByRelationIdAction
}

export const contactServices = {
    getContactByIdService,
    createContactService,
    updateContactService,
    deleteContactService,
    getAllContactsByRelationIdService
}

export const contactConstants = {
    GET_CONTACT_BY_ID_REQUEST: 'GET_CONTACT_BY_ID_REQUEST',
    GET_CONTACT_BY_ID_SUCCESS: 'GET_CONTACT_BY_ID_SUCCESS',
    GET_CONTACT_BY_ID_FAILURE: 'GET_CONTACT_BY_ID_FAILURE',

    CREATE_CONTACT_REQUEST: 'CREATE_CONTACT_REQUEST',
    CREATE_CONTACT_SUCCESS: 'CREATE_CONTACT_SUCCESS',
    CREATE_CONTACT_FAILURE: 'CREATE_CONTACT_FAILURE',

    UPDATE_CONTACT_REQUEST: 'UPDATE_CONTACT_REQUEST',
    UPDATE_CONTACT_SUCCESS: 'UPDATE_CONTACT_SUCCESS',
    UPDATE_CONTACT_FAILURE: 'UPDATE_CONTACT_FAILURE',

    DELETE_CONTACT_REQUEST: 'DELETE_CONTACT_REQUEST',
    DELETE_CONTACT_SUCCESS: 'DELETE_CONTACT_SUCCESS',
    DELETE_CONTACT_FAILURE: 'DELETE_CONTACT_SUCCESS',

    GET_ALL_CONTACT_BY_RELATION_ID_REQUEST:'GET_ALL_CONTACT_BY_RELATION_ID_REQUEST',
    GET_ALL_CONTACT_BY_RELATION_ID_SUCCESS:'GET_ALL_CONTACT_BY_RELATION_ID_SUCCESS',
    GET_ALL_CONTACT_BY_RELATION_ID_FAILURE:'GET_ALL_CONTACT_BY_RELATION_ID_FAILURE',
    
}

function getAllContactsByRelationIdAction(relationId) {
    return dispatch => {
        dispatch(request());

        contactServices.getAllContactsByRelationIdService(relationId)
            .then(
                contacts => dispatch(success(contacts)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: contactConstants.GET_ALL_CONTACT_BY_RELATION_ID_REQUEST}
    }

    function success(contacts) {
        return {type: contactConstants.GET_ALL_CONTACT_BY_RELATION_ID_SUCCESS, contacts}
    }

    function failure(error) {
        return {type: contactConstants.GET_ALL_CONTACT_BY_RELATION_ID_FAILURE, error}
    }
}

function getContactByIdAction(contactId) {
    return dispatch => {
        dispatch(request());
        contactServices.getContactByIdService(contactId)
            .then(
                contact => dispatch(success(contact)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: contactConstants.GET_CONTACT_BY_ID_REQUEST}
    }

    function success(contact) {
        return {type: contactConstants.GET_CONTACT_BY_ID_SUCCESS, contact}
    }

    function failure(error) {
        return {type: contactConstants.GET_CONTACT_BY_ID_FAILURE, error}
    }
}


function createContactAction(relationalId, obj, setShow, setDisable, parentAction, submitParentMethod, parentId) {
    return dispatch => {
        dispatch(request({obj}));

        contactServices.createContactService(relationalId, obj)
            .then(
                contact => {
                    if (contact['errorCode'] === 200) {
                        showSuccessToast("Contact added successfully!");
                        dispatch(success(contact));
                        setShow(false);
                        dispatch(parentAction[submitParentMethod](parentId));
                    } else {
                        responseDTOExceptionHandler(contact);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(contact) {
        return {type: contactConstants.CREATE_CONTACT_REQUEST, contact}
    }

    function success(contact) {
        return {type: contactConstants.CREATE_CONTACT_SUCCESS, contact}
    }

    function failure(error) {
        return {type: contactConstants.CREATE_CONTACT_FAILURE, error}
    }
}

function updateContactAction(contactId, obj, setShow, setDisable, parentAction, submitParentMethod, parentId) {
    return dispatch => {
        dispatch(request({contactId, obj}));
        contactServices.updateContactService(contactId, obj)
            .then(
                contact => {
                    if (contact['errorCode'] === 200) {
                        showSuccessToast("Contact updated successfully!");
                        setShow(false);
                        dispatch(success(contact));
                        dispatch(parentAction[submitParentMethod](parentId));
                        dispatch(homeActions.resetReducerAction("getContactByIdReducer"));
                    } else {
                        responseDTOExceptionHandler(contact);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(contact) {
        return {type: contactConstants.UPDATE_CONTACT_REQUEST, contact}
    }

    function success(contact) {
        return {type: contactConstants.UPDATE_CONTACT_SUCCESS, contact}
    }

    function failure(error) {
        return {type: contactConstants.UPDATE_CONTACT_FAILURE, error}
    }
}

function deleteContactAction(contactId, setDisable, setShowContactDeleteConfirm, parentAction, submitParentMethod, parentId) {
    return dispatch => {
        dispatch(request({contactId}));
        contactServices.deleteContactService(contactId)
            .then(
                association => {
                    if (association['errorCode'] === 200) {
                        showSuccessToast("Contact deleted successfully!");
                        dispatch(success(association));
                        dispatch(parentAction[submitParentMethod](parentId));
                    } else {
                        responseDTOExceptionHandler(association);
                    }
                    setDisable(false);
                    setShowContactDeleteConfirm(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(association) {
        return {type: contactConstants.DELETE_CONTACT_REQUEST, association}
    }

    function success(association) {
        return {type: contactConstants.DELETE_CONTACT_SUCCESS, association}
    }

    function failure(error) {
        return {type: contactConstants.DELETE_CONTACT_FAILURE, error}
    }

}


export function getAllContactsByRelationIdReducer(state = {}, action) {
    switch (action.type) {
        case contactConstants.GET_ALL_CONTACT_BY_RELATION_ID_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case contactConstants.GET_ALL_CONTACT_BY_RELATION_ID_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.contacts
            };
        case contactConstants.GET_ALL_CONTACT_BY_RELATION_ID_FAILURE:
            return {};
        default:
            return state
    }
}


export function getContactByIdReducer(state = {}, action) {
    switch (action.type) {
        case contactConstants.GET_CONTACT_BY_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case contactConstants.GET_CONTACT_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.contact
            };
        case contactConstants.GET_CONTACT_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

export function getContactByIdService(contactId) {
    return axios.get(`/ajax/get-contact/${contactId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export function createContactService(relationalId, obj) {
    return axios.post(`/ajax/create-contact/${relationalId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function updateContactService(contactId, obj) {
    return axios.put(`/ajax/update-contact/${contactId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


export function deleteContactService(contactId) {
    return axios.delete(`/ajax/delete-contact-by-id/${contactId}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function getAllContactsByRelationIdService(relationId){
    return axios.get(`/ajax/get-all-contact-by-relationId/${relationId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}