export const ExemptionsStatus =
    [
        {
            "constant": "HOM",
            "value": "HOM"
        },
        {
            "constant": "OV65",
            "value": "OV65"
        },
        {
            "constant": "CAP",
            "value": "CAP"
        },
        {
            "constant": "ABT",
            "value": "ABT"
        },
        {
            "constant": "AG",
            "value": "AG"
        },
        {
            "constant": "BIE",
            "value": "BIE"
        },
        {
            "constant": "CBL",
            "value": "CBL"
        },
        {
            "constant": "CCF",
            "value": "CCF"
        },
        {
            "constant": "CHAR",
            "value": "CHAR"
        },
        {
            "constant": "DP",
            "value": "DP"
        },
        {
            "constant": "DV",
            "value": "DV"
        },
        {
            "constant": "FRSS",
            "value": "FRSS"
        },
        {
            "constant": "GOV",
            "value": "GOV"
        },
        {
            "constant": "JETI",
            "value": "JETI"
        },
        {
            "constant": "MED",
            "value": "MED"
        },
        {
            "constant": "OTHER",
            "value": "OTHER"
        },
        {
            "constant": "RELIG",
            "value": "RELIG"
        },
        {
            "constant": "SOL",
            "value": "SOL"
        },
        {
            "constant": "WSA",
            "value": "WSA"
        },
        {
            "constant": "XMPT",
            "value": "XMPT"
        }
    ]


export const PageControl =
    [
        {
            "constant": "TOP",
            "value": "Top"
        },
        {
            "constant": "BOTTOM",
            "value": "Bottom"
        },
        {
            "constant": "BOTH",
            "value": "Both"
        }
    ]


export const LinkOpeningStyle =
    [
        {
            "constant": "CURRENT TAB",
            "value": "Current Tab"
        },
        {
            "constant": "NEW TAB",
            "value": "New Tab"
        }

    ]
