import NumberFormat from "react-number-format";
import "../../app/App.css";
import {currentMonthLastDate, feeListEnabledEnumsRegex, maxYear, minYear, nextMonthLastDate} from "./constants";
import axios from "axios";
import FixRequiredSelect from "../components/FixRequiredSelect";
import BaseSelect from "react-select";
import {
    doesCurrentTaxYearMatchCurrentYear, formatBuyerSellerNames,
    formatValueAsDate,
    getCurrentTaxYear,
    getObjectiveArray,
    getPrimitiveArray
} from "./utils";
import moment from "moment/moment";
import _ from "lodash";
import DatePicker from "react-date-picker";
import React from "react";


export const getUserFriendlyTaxDataSource = taxDataSource => {
    switch (taxDataSource) {
        case 'ROLL':
            return 'Rolls'
        case 'SUBDIVISION':
            return 'Subdivision';
        case 'EDITOR':
            return 'Editor';
        case 'SCRAPER':
            return 'Web';
        default:
            return 'Unknown';
    }
}

export const getShortenedTaxDataSource = taxDataSource => {
    let letter = taxDataSource.slice(0, 1)
    return letter === 'U' ? '-' : letter;
}

export const upperCaseFirstLetter = string =>
    `${string.slice(0, 1).toUpperCase()}${string.slice(1)}`;

export const lowerCaseAllWordsExceptFirstLetters = string =>
    `${string.slice(0, 1)}${string.slice(1).toLowerCase()}`;

export const shouldDisableUpdateTax = (taskList) => {
    for (let i = 0; i < taskList.length; i++) {
        if (taskList[i].type === 'SCRAPER' && (taskList[i].status === 'PENDING' || taskList[i].status === 'NEW')) {
            return true;
        }
    }
    return false;
}

export const jurisdictionRowFormatting = (row, setAccounts, setJurisdictionId, p, setAutoCalculate, accounts, index, name, editable, setIsSavedResult, curAccount, setUpdateTotalTaxValue = null) => {

    const isFalsyValue = row[name] === null || row[name] === undefined || row[name] === '';

    return (
        <>
            <NumberFormat
                placeholder={((isFalsyValue) ? "N/A" : '...')}
                className={row['placeHolder'] === false ? (isFalsyValue ? "border-0 form-control text-center place_holder_data" : "border-0 form-control text-center") : (isFalsyValue ? "place_holder border-0 form-control text-center place_holder_data" : "place_holder border-0 form-control text-center")}
                decimalSeparator="."
                fixedDecimalScale
                min="0"
                prefix={!isFalsyValue ? "$" : ''}
                isNumericString={(row[name])}
                value={
                    (isFalsyValue ? "N/A" :
                        (countDecimals(row[name]) === 2 ? row[name] : parseFloat(row[name]).toFixed(2)))
                }
                thousandSeparator={true}
                onChange={(e) => {
                    setJurisdictionId(p);
                    row[name] = e.target.value.replace('$', '').replaceAll(',', '');
                    row["placeHolder"] = false;
                    setIsSavedResult(false);
                    setAccounts([...accounts]);
                    if (setUpdateTotalTaxValue !== null) {
                        setUpdateTotalTaxValue(true);
                    }
                    calculateAsOfDate(row);
                    setAutoCalculate(true);
                }}

                disabled={row['jurisdictionName'] === '' || row['collectorName'] === '' || row['jurisdictionName'] === null || row['collectorName'] === null || !editable}
            />

        </>
    )
}

export const summaryRowFormatting = (curAccount, name) => {
    {

        return (
            <>
                <NumberFormat
                    placeholder='...'
                    className="border-0 text-center outline_remove1 readonly_bg"
                    decimalScale={curAccount !== undefined && curAccount[name] !== null && curAccount[name] !== '' ? 2 : ''}
                    decimalSeparator="."
                    fixedDecimalScale
                    isNumericString={curAccount !== undefined && (curAccount[name] !== null || curAccount[name] !== '')}
                    value={curAccount === undefined || (curAccount[name] === null || curAccount[name] === '') ? "N/A" : curAccount[name]}
                    prefix={curAccount !== undefined && (curAccount[name] !== null && curAccount[name] !== '') ? "$" : ''}
                    thousandSeparator={true}
                    disabled={true}
                />
            </>
        )

    }
}

export function getUniqueLatestYearJurisdictions(jurisdictionList) {
    let list = [];
    for (let i = 0; i < jurisdictionList.length; i++) {
        if (jurisdictionList[i]['jurisdictionId'] !== '' && jurisdictionList[i]['jurisdictionId'] !== null && jurisdictionList[i]['inActive'] !== true && jurisdictionList[i]['nonCollecting'] !== true) {
            let index = list.findIndex(obj => obj.jurisdictionName === jurisdictionList[i]['jurisdictionName']);
            if (index > -1) {
                if (parseInt(jurisdictionList[i]['bill_year']) > parseInt(list[index]['bill_year'])) {
                    list[index] = jurisdictionList[i];
                }
            } else {
                list.push(jurisdictionList[i]);
            }
        }
    }
    return list;
}



export function updateSummaryTotalRowCalculation(currentAccount, summaryList) {

    if (summaryList !== undefined) {

        currentAccount.baseTaxTotal = summaryList.reduce(function (prev, current) {
            if (current.baseTax !== '' && current.baseTax != null && prev != null)
                return prev + parseFloat(current.baseTax.toString().replace(',', ''))
            else if (current.baseTax === '' || current.baseTax === null || prev === null)
                return null;
            else
                return prev;

        }, 0);

        currentAccount.baseTaxDueTotal = summaryList.reduce(function (prev, current) {
            if (current.baseTaxDue !== '' && current.baseTaxDue != null && prev != null)
                return prev + parseFloat(current.baseTaxDue.toString().replace(',', ''))
            else if (current.baseTaxDue === '' || current.baseTaxDue === null || prev === null)
                return null;
            else
                return prev;

        }, 0);

        currentAccount.currentMonthTotal = summaryList.reduce(function (prev, current) {
            if (current.currentMonthDue !== '' && current.currentMonthDue != null && prev != null)
                return prev + parseFloat(current.currentMonthDue.toString().replace(',', ''))
            else if (current.currentMonthDue === '' || current.currentMonthDue === null || prev === null)
                return null;
            else
                return prev;

        }, 0);

        currentAccount.nextMonthTotal = summaryList.reduce(function (prev, current) {
            if (current.nextMonthTaxDue !== '' && current.nextMonthTaxDue != null && prev != null)
                return prev + parseFloat(current.nextMonthTaxDue.toString().replace(',', ''));
            else if (current.nextMonthTaxDue === '' || current.nextMonthTaxDue === null || prev === null) {
                return null;
            } else
                return prev;

        }, 0);
    }
}



export const selectDropDownField = (row, name, disabled, setIsSavedResult, accounts, setAccounts, Enums, editable, curAccount, showAccount, updatedAccountIndexList, setAutoCalculate) => {
    return (
        <>
            <select className="form-control" name="platStatus"
                    value={row[name]}
                    disabled={disabled || !editable}
                    onChange={(e) => {
                        curAccount['taxDataSource'] = 'EDITOR';
                        if (name === 'levyPeriod') {
                            let summaryList = curAccount.summaryList.filter(el => el.collector === row.collectorName && el.taxYear === row.taxYear && el.levyPeriod === row.levyPeriod);
                            if (summaryList.length > 0) {
                                summaryList[0]['levyPeriod'] = e.target.value;
                            }
                            setAccounts([...accounts]);
                        }

                        row[name] = e.target.value;
                        setIsSavedResult(false);
                        updatedAccountIndexList.push(showAccount);
                        if (name === 'levyPeriod') {
                            setAutoCalculate(true);
                        }
                        setAccounts([...accounts]);
                        if (name === 'type' && !feeListEnabledEnumsRegex.test(e.target.value)) {
                            row['periodStart'] = '';
                            row['periodEnd'] = '';
                            row['frequency'] = 'ONE_TIME';

                        }
                    }} required={true}>
                {name === 'type' ? < option value="">Select Type</option> : < option value="">Select Frequency</option>}
                {Enums && Enums.map((curEnum) => {
                        return (
                            <option value={curEnum.constant}>{curEnum.value}</option>
                        )
                    }
                )
                }
            </select>
        </>
    )
}
export const datePickerField = (row, name, disabled, setIsSavedResult, accounts, setAccounts, editable, curAccount, showAccount, updatedAccountIndexList) => {
    let date = row[name] != null && row[name] !== '' ? new Date(row[name].toString()).setMinutes(new Date(row[name].toString()).getMinutes() + new Date(row[name].toString()).getTimezoneOffset()) : null;
    let val = date != null ? new Date(date) : null;

    return (

        <>
            <DatePicker
                disabled={disabled || !editable}
                onChange={(value) => {
                    curAccount['taxDataSource'] = 'EDITOR';
                    row[name] = value != null && value !== '' ? moment(value).format("yyyy-MM-DD").toUpperCase() : null;
                    setIsSavedResult(false);
                    updatedAccountIndexList.push(showAccount);
                    setAccounts([...accounts]);
                }}
                value={val}
                dayPlaceholder={"dd"} monthPlaceholder={"mm"} format={"MM-dd"}
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
                timeZoneOffsetInMinutes={0}
                utcOffset={0}
            />
        </>

    )

}

export function updateSummaryRowInfo(currentAccount, sumList, i) {

    if (currentAccount.summaryList[i] !== undefined) {


        currentAccount.summaryList[i]['baseTax'] = sumList.reduce(function (prev, current) {
            if (current.baseTax !== '' && current.baseTax !== null && prev != null)
                return prev + parseFloat(current.baseTax);
            else if (current.baseTax === '' || current.baseTax === null || prev == null)
                return null;
            else
                return prev;
        }, 0);


        currentAccount.summaryList[i]['baseTaxDue'] = sumList.reduce(function (prev, current) {
            if (current.baseDue !== '' && current.baseDue !== null && prev != null)
                return prev + parseFloat(current.baseDue);
            else if (current.baseDue === '' || current.baseDue === null || prev == null)
                return null;
            else
                return prev;
        }, 0);

        currentAccount.summaryList[i]['currentMonthDue'] = sumList.reduce(function (prev, current) {
            if (current.currentMonthDue !== '' && current.currentMonthDue !== null && prev != null)
                return prev + parseFloat(current.currentMonthDue);
            else if (current.currentMonthDue === '' || current.currentMonthDue === null || prev == null)
                return null;
            else
                return prev;
        }, 0);

        currentAccount.summaryList[i]['nextMonthTaxDue'] = sumList.reduce(function (prev, current) {
            if (current.nextMonthTaxDue !== '' && current.nextMonthTaxDue !== null && prev != null)
                return prev + parseFloat(current.nextMonthTaxDue);
            else if (current.nextMonthTaxDue === '' || current.nextMonthTaxDue === null || prev == null) {
                return null;
            } else
                return prev;
        }, 0);


        let asOfDateArray = [];
        sumList.map((cur) => {
            if (cur['asOfDate'] !== null && cur['asOfDate'] !== '') {
                asOfDateArray.push(moment(cur['asOfDate'], 'YYYY-MM-DD').valueOf());
            }
        })

        currentAccount.summaryList[i]['asOfDate'] = asOfDateArray.length === 0 ? null : new Date(Math.min(...asOfDateArray));
    }
}


export function collapseNav() {
    let div = document.getElementById("wrapper");
    if (div != null) {
        div.classList.add("toggled");
        div.classList.remove("side_nav_");
        const collection = document.getElementsByClassName("navbarDropdown");
        for (const element of collection) {
            element.classList.add("disabled");
        }
    }
}

export function getAssessmentList(certificate, i) {
    let account = certificate.accounts[i];
    if (account?.assessments?.length === 0) {
        return dummyAssessmentList();
    }

    // Filter out invalid assessments
    let assessments = account.assessments;
    if (assessments.length === 0) {
        return dummyAssessmentList();
    }

    // Sort the highest years first, so we grab the years we want
    assessments = sortAssessmentList(assessments, ['taxYear'], ['desc'])

    let outputAssessments = [];

    if (assessments.length > 1) {
        outputAssessments.push(assessments[0]);
        outputAssessments.push(assessments[1]);
    } else {
        let currentTaxYear = getCurrentTaxYear();
        let hasCurrentAssessment = assessments[0]?.taxYear && assessments[0].taxYear === currentTaxYear;

        let desiredYear = doesCurrentTaxYearMatchCurrentYear() ? currentTaxYear + 1 : currentTaxYear - 1;

        let assessment = {
            "taxYear": hasCurrentAssessment ? desiredYear : getCurrentTaxYear(),
            "improvementMarketValue": null,
            "landMarketValue": null,
            "agMarketValue": null,
            "overrideAppraisedValue": null,
            "overrideAssessedValue": null,
            "agTimberValue": null,
            "agLossValue": null,
            "homesteadCapValue": 0,
            "exemptions": [],
            "assessedValue": null,
            "agriculturalValue": null,
            "landTotalValue": null,
            "overrideAgValue": null,
            "appraisedValue": null,
            "certified": !!hasCurrentAssessment,
            "overrideLandTotalValue": null,
            "woExemptionAmount": null,
            "overrideWoExemptionAmount": null,
        };

        outputAssessments.push(assessments[0]);
        outputAssessments.push(assessment);
    }

    // Sort the lowest years first, so we order our two records correctly
    return sortAssessmentList(outputAssessments, ['taxYear'], ['asc']);
}

export function setTaskList(taskList, setVendorTaskList, properties) {

    taskList.map(curTask => {
        if (curTask.args === null) {
            return;
        }
        if (properties) {
            let list = JSON.parse(JSON.stringify(properties)).filter(obj => obj?.id === curTask?.args?.propertyId);
            Object.assign(curTask.args, {property: list.length > 0 ? list[0] : null});
        }
    });

    setVendorTaskList(taskList);
}

export function dummyAssessmentList() {
    let currentTaxYear = getCurrentTaxYear();
    let desiredYear = doesCurrentTaxYearMatchCurrentYear() ? currentTaxYear + 1 : currentTaxYear - 1;

    let assessment = {
        "taxYear": desiredYear > currentTaxYear ? currentTaxYear : desiredYear,
        "improvementMarketValue": null,
        "landMarketValue": null,
        "agMarketValue": null,
        "overrideAppraisedValue": null,
        "overrideAssessedValue": null,
        "agTimberValue": null,
        "agLossValue": null,
        "homesteadCapValue": 0,
        "exemptions": [],
        "assessedValue": null,
        "agriculturalValue": null,
        "landTotalValue": null,
        "overrideAgValue": null,
        "appraisedValue": null,
        "certified": false,
        "overrideLandTotalValue": null,
        "woExemptionAmount": null,
        "overrideWoExemptionAmount": null,
    };

    return [
        {
            ...assessment,
            "taxYear": desiredYear > currentTaxYear ? currentTaxYear : desiredYear,
        },
        {
            ...assessment,
            "taxYear": desiredYear > currentTaxYear ? desiredYear : currentTaxYear,
        }
    ]
}

export const bondRowFormatting = (row, setAccounts, setBondId, p, accounts, name, setIsSavedResult, editable, showAccount, updatedAccountIndexList) => {
    {
        return (
            <>
                <NumberFormat
                    placeholder={(row[name] === null || row[name] === '' || row[name] === undefined) ? "N/A" : '...'}
                    className={(row[name] === null || row[name] === '' || row[name] === undefined) ? "border-0 form-control text-center place_holder_data" : "border-0 form-control text-center"}
                    decimalScale={row[name] !== null && row[name] !== '' ? 2 : ''}
                    decimalSeparator="."
                    fixedDecimalScale
                    isNumericString={row[name] !== null || row[name] !== ''}
                    value={row[name] === null || row[name] === '' ? "N/A" : row[name]}
                    prefix={row[name] !== null && row[name] !== '' ? "$" : ''}
                    thousandSeparator={true}
                    onChange={(e) => {
                        setBondId(p);
                        updatedAccountIndexList.push(showAccount);
                        row[name] = e.target.value.replace('$', '').replaceAll(',', '');
                        setIsSavedResult(false);
                        setAccounts([...accounts]);
                    }}
                    disabled={row['jurisdictionName'] === '' || row['collectorName'] === '' || row['jurisdictionName'] === null || row['collectorName'] === null}
                    readOnly={!editable}
                />
            </>
        )

    }
}

export const SuitRowFormatting = (row, setAccounts, setSuitId, p, accounts, name, setIsSavedResult, editable) => {

    {
        return (
            <>
                <NumberFormat
                    placeholder={(row[name] === null || row[name] === '' || row[name] === undefined) ? "N/A" : '...'}
                    className={(row[name] === null || row[name] === '' || row[name] === undefined) ? "border-0 form-control selector text-center place_holder_data" : "border-0 form-control selector text-center"}
                    decimalScale={row[name] !== null && row[name] !== '' ? 2 : ''}
                    decimalSeparator="."
                    fixedDecimalScale
                    isNumericString={row[name] !== null || row[name] !== ''}
                    value={row[name] === null || row[name] === '' ? "N/A" : row[name]}
                    prefix={row[name] !== null && row[name] !== '' ? "$" : ''}
                    thousandSeparator={true}
                    onChange={(e) => {
                        setSuitId(p);
                        row[name] = e.target.value.replace('$', '').replaceAll(',', '');
                        setIsSavedResult(false);
                        setAccounts([...accounts]);
                    }}
                    readOnly={row['payee'] === '' || row['suitNumber'] === '' || row['parcel'] === '' || row['feeType'] === '' || !editable}
                />
            </>
        )

    }
}

export const assessmentsOverrideFormatting = (setAccounts, accounts, curAccount, index, name, readOnly, setName, setProductTypeInvisible, editable, overrideFieldName, setAssessmentIndex, setIsSavedResult, setRender, showAccount, updatedAccountIndexList) => {
    {
        return (
            <>

                <div className="assessment_data">

                    <NumberFormat
                        placeholder={(curAccount?.assessments[index][overrideFieldName] === null || curAccount?.assessments[index][overrideFieldName] === undefined) ? "N/A" : '...'}
                        className={(curAccount?.assessments[index][overrideFieldName] === null || curAccount?.assessments[index][overrideFieldName] === undefined) ? "form-control place_holder_data" : "form-control"}
                        id={name + '-' + index}
                        decimalScale={(curAccount?.assessments[index][overrideFieldName] !== null) || (curAccount?.assessments[index][name] !== null) ? (name !== 'woExemptionAmount' ? 0 : 2) : ''}
                        decimalSeparator="."
                        fixedDecimalScale
                        isNumericString={curAccount?.assessments[index][overrideFieldName] !== null || curAccount?.assessments[index][name] !== null || curAccount?.assessments[index][name] !== undefined}
                        value={curAccount?.assessments[index][overrideFieldName] === null ? (curAccount?.assessments[index][name] === null ? "N/A" : curAccount?.assessments[index][name]) : curAccount?.assessments[index][overrideFieldName]}
                        onChange={(e) => {
                            curAccount.assessments[index][overrideFieldName] = e.target.value !== '' ? parseFloat(e.target.value.replace('$', '').replaceAll(',', '')) : e.target.value;
                            setName(name);
                            setProductTypeInvisible(e.target.value + new Date().getTime());
                            curAccount['taxDataSource'] = 'EDITOR';
                            curAccount['asOfDate'] = new Date();
                            setAccounts([...accounts]);
                            if (name === "appraisedValue" && curAccount?.assessments[index].taxYear >= getCurrentTaxYear()) {
                                calculateCurrentWoExemptAmountValue(curAccount, curAccount?.assessments[index], setRender).then();
                            }
                            setIsSavedResult(false);
                            updatedAccountIndexList.push(showAccount);

                        }}
                        prefix={(curAccount?.assessments[index][overrideFieldName] !== null) ? '$' : (curAccount?.assessments[index][name] !== null ? '$' : '')}
                        thousandSeparator={true}
                        readOnly={!editable || curAccount?.assessments[index][overrideFieldName] === null}
                    />

                    <div className="assessment_icons">
                        {curAccount?.assessments[index][overrideFieldName] == null ?
                            <i className="fa fa-pen ml-auto mr-2 readonly_icon"
                               onClick={
                                   (e) => {
                                       if (editable === true) {
                                           curAccount.assessments[index][overrideFieldName] = curAccount?.assessments[index][name];
                                           setAccounts([...accounts]);
                                       }
                                   }
                               }/> :
                            <i className="fa fa-rotate-right mr-2 readonly_icon" onClick={
                                (e) => {
                                    if (editable === true) {
                                        curAccount.assessments[index][overrideFieldName] = null;
                                        updatedAccountIndexList.push(showAccount);
                                        setIsSavedResult(false);
                                        setAccounts([...accounts]);
                                        if (name === 'appraisedValue') {
                                            calculateAppraisedValue(curAccount?.assessments[index], setRender).then();
                                            if (curAccount?.assessments[index].taxYear >= getCurrentTaxYear()) {
                                                calculateCurrentWoExemptAmountValue(curAccount, curAccount?.assessments[index], setRender).then();
                                            }
                                        } else {
                                            calculateAssessedValue(curAccount?.assessments[index], setRender).then();
                                        }
                                    }
                                }
                            }/>
                        }
                    </div>


                </div>
            </>
        )
    }
}

export const assessmentsAgOverrideFormatting = (setAccounts, accounts, curAccount, index, name, readOnly, setName, setProductTypeInvisible, editable, overrideFieldName, setAssessmentIndex, setIsSavedResult, setRender = null) => {
    {
        return (
            <>

                <div className="assessment_data">

                    <NumberFormat
                        placeholder={(curAccount?.assessments[index][overrideFieldName] === null || curAccount?.assessments[index][overrideFieldName] === undefined) ? "N/A" : '...'}
                        className={(curAccount?.assessments[index][overrideFieldName] === null || curAccount?.assessments[index][overrideFieldName] === undefined) ? "form-control place_holder_data" : "form-control"}
                        id={name + '-' + index}
                        decimalScale={(curAccount?.assessments[index][overrideFieldName] !== null) || (curAccount?.assessments[index][name] !== null) ? 0 : ''}
                        decimalSeparator="."
                        fixedDecimalScale
                        value={curAccount?.assessments[index][overrideFieldName] === null ? (curAccount?.assessments[index][name] === null ? "N/A" : curAccount?.assessments[index][name]) : curAccount?.assessments[index][overrideFieldName]}
                        isNumericString={curAccount?.assessments[index][overrideFieldName] !== null || curAccount?.assessments[index][name] !== null || curAccount?.assessments[index][name] !== undefined}
                        onChange={(e) => {
                            curAccount.assessments[index][overrideFieldName] = e.target.value !== '' ? parseFloat(e.target.value.replace('$', '').replaceAll(',', '')) : e.target.value;
                            setName(name);
                            setProductTypeInvisible(e.target.value + new Date().getTime());
                            setAccounts([...accounts]);
                            curAccount['taxDataSource'] = 'EDITOR';
                            curAccount['asOfDate'] = new Date();
                            if (name === "landTotalValue") {
                                calculateAppraisedValue(curAccount?.assessments[index], setRender).then();
                            } else {
                                calculateAssessedValue(curAccount?.assessments[index], setRender).then();
                            }
                            setIsSavedResult(false);
                        }}
                        prefix={(curAccount?.assessments[index][overrideFieldName] !== null) ? '$' : (curAccount?.assessments[index][name] !== null ? '$' : '')}
                        thousandSeparator={true}
                        readOnly={!editable || curAccount?.assessments[index][overrideFieldName] === null}
                    />

                    <div className="assessment_icons">
                        {curAccount?.assessments[index][overrideFieldName] == null ?
                            <i className={(editable === true && isAgExemptionExist(curAccount, index) === true) || name !== "agriculturalValue" ? "fa fa-pen ml-auto mr-2 readonly_icon" : "fa fa-pen ml-auto mr-2 disableSelection"}
                               onClick={
                                   (e) => {
                                       if ((editable === true && isAgExemptionExist(curAccount, index) === true) || name !== "agriculturalValue") {
                                           curAccount.assessments[index][overrideFieldName] = curAccount?.assessments[index][name];
                                           setAccounts([...accounts]);
                                       }
                                   }
                               }/> :
                            <i className={(editable === true && isAgExemptionExist(curAccount, index) === true) || name !== "agriculturalValue" ? "fa fa-rotate-right mr-2 readonly_icon" : "fa fa-rotate-right mr-2 disableSelection"}
                               onClick={
                                   (e) => {
                                       curAccount.assessments[index][overrideFieldName] = null;
                                       setIsSavedResult(false);
                                       setAccounts([...accounts]);
                                       if (editable === true && isAgExemptionExist(curAccount, index) === true && name === "agriculturalValue") {
                                           calculateAgriculturalValue(curAccount?.assessments[index], setRender).then();
                                           calculateAssessedValue(curAccount?.assessments[index], setRender).then();
                                       } else if (name === "landTotalValue") {
                                           calculateLandTotalValue(curAccount?.assessments[index], setRender).then();
                                           calculateAppraisedValue(curAccount?.assessments[index], setRender).then();
                                       }
                                   }
                               }/>
                        }
                    </div>


                </div>
            </>
        )
    }
}


export const assessmentsRowFormatting = (setAccounts, accounts, curAccount, index, name, setName, setProductTypeInvisible, editable, setIsSavedResult, setRender = null) => {
    {
        return (
            <>
                <NumberFormat
                    placeholder={(curAccount?.assessments[index][name] === null || curAccount?.assessments[index][name] === '' || curAccount?.assessments[index][name] === undefined) ? "N/A" : '...'}
                    className={(curAccount?.assessments[index][name] === null || curAccount?.assessments[index][name] === '' || curAccount?.assessments[index][name] === undefined) ? "form-control place_holder_data" : "form-control"}
                    fixedDecimalScale
                    decimalScale={curAccount?.assessments[index][name] !== null && curAccount?.assessments[index][name] !== '' ? 0 : ''}
                    decimalSeparator="."
                    isNumericString={curAccount?.assessments[index][name] !== null || curAccount?.assessments[index][name] !== ''}
                    value={curAccount?.assessments[index][name] === null || curAccount?.assessments[index][name] === '' || curAccount?.assessments[index][name] === undefined ? "N/A" : curAccount?.assessments[index][name]}
                    prefix={curAccount?.assessments[index][name] !== null && curAccount?.assessments[index][name] !== '' && curAccount?.assessments[index][name] !== undefined ? "$" : ''}
                    id={name + '-' + index}
                    onChange={(e) => {
                        curAccount.assessments[index][name] = e.target.value !== '' && e.target.value !== null ? parseFloat(e.target.value.replace('$', '').replaceAll(',', '')) : null;
                        curAccount['taxDataSource'] = 'EDITOR';
                        curAccount['asOfDate'] = new Date();
                        setIsSavedResult(false);
                        setName(name);
                        setProductTypeInvisible(e.target.value + new Date().getTime());
                        setAccounts([...accounts]);
                        if (name === "homesteadCapValue") {
                            calculateAssessedValue(curAccount?.assessments[index], setRender).then();
                        }
                    }}
                    thousandSeparator={true}
                    readOnly={!editable}
                />
            </>
        )

    }
}

export const assessmentsAgRowFormatting = (setAssessmentIndex, setOverrideValue, curAccount, index, editable, accounts, setAccounts, name, setIsSavedResult, showAccount, updatedAccountIndexList) => {
    {
        return (
            <>

                <NumberFormat
                    placeholder={(curAccount?.assessments[index][name] === null || curAccount?.assessments[index][name] === '' || curAccount?.assessments[index][name] === undefined) ? "N/A" : '...'}
                    className={(curAccount?.assessments[index][name] === null || curAccount?.assessments[index][name] === '' || curAccount?.assessments[index][name] === undefined) ? "form-control place_holder_data" : "form-control"}
                    fixedDecimalScale
                    decimalScale={curAccount?.assessments[index][name] !== null && curAccount?.assessments[index][name] !== '' ? 0 : ''}
                    decimalSeparator="."
                    isNumericString={curAccount?.assessments[index][name] !== null || curAccount?.assessments[index][name] !== ''}
                    value={curAccount?.assessments[index][name] === null || curAccount?.assessments[index][name] === '' || curAccount?.assessments[index][name] === undefined ? "N/A" : curAccount?.assessments[index][name]}
                    prefix={curAccount?.assessments[index][name] !== null && curAccount?.assessments[index][name] !== '' && curAccount?.assessments[index][name] !== undefined ? "$" : ''}
                    id={name + '-' + index}
                    onChange={(e) => {
                        if (editable) {
                            setAssessmentIndex(index)
                            curAccount.assessments[index][name] = e.target.value.replace('$', '').replaceAll(',', '');
                            curAccount['taxDataSource'] = 'EDITOR';
                            curAccount['asOfDate'] = new Date();
                            setOverrideValue(true);
                            setAccounts([...accounts]);
                            updatedAccountIndexList.push(showAccount);
                            setIsSavedResult(false);
                        }
                    }}
                    readOnly={!editable || !isAgExemptionExist(curAccount, index)}/>
            </>
        )

    }
}

export const assessmentYearRow = (curAccount, index, setAccounts, accounts, name, setName, editable) => {
    return (
        <>
            <input className="form-control form-control-number input_height ml-5 text-alignment width_data"
                   name="taxYear"
                   id="taxYear-0"
                   type="number"
                   autoComplete="off"
                   placeholder="Select Year..."
                   value={curAccount?.assessments[index] && curAccount?.assessments[index].taxYear}
                   onChange={(e) => {
                       validateAssessmentYear(e, curAccount?.assessments[index], index, name, curAccount);
                       setName(name)
                       setAccounts([...accounts]);
                       curAccount['taxDataSource'] = 'EDITOR';
                       curAccount['asOfDate'] = new Date();
                   }}
                   readOnly={!editable}
            />
        </>
    )
}

export const taxInputRow = (row, name, setIsSavedResult, accounts, setAccounts, editable, showAccount, updatedAccountIndexList) => {
    return (
        <>
            <input className="border-0 form-control selector text-center"
                   placeholder="..."
                   value={row[name]}
                   onChange={(e) => {
                       row[name] = e.target.value;
                       updatedAccountIndexList.push(showAccount);
                       setIsSavedResult(false);
                       setAccounts([...accounts]);
                   }}
                   readOnly={!editable}
                   disabled={row['payee'] === '' || row['payee'] === ''}
            />
        </>
    )
}

export const propertyInfoFields = (curAccount, setIsSavedResult, setAccounts, accounts, certificate, name, editable, updatedAccountIndexList, showAccount, similarAccountList = null, setSimilarAccountList = null) => {
    return (
        <>
            {name !== 'propertyId' ?
                <input placeholder="..."
                       className="form-control"
                       type="text"
                       value={curAccount !== undefined && curAccount[name] !== null && curAccount[name] !== "" ? curAccount[name] : ''}
                       onChange={(e) => {
                           curAccount[name] = e.target.value;
                           curAccount['taxDataSource'] = 'EDITOR';
                           curAccount['asOfDate'] = new Date();
                           setIsSavedResult(false);
                           updatedAccountIndexList.push(showAccount);
                           setAccounts([...accounts]);
                       }} readOnly={!editable}/>
                :
                <input className="form-control"
                       placeholder="..."
                       type="text"
                       value={curAccount !== undefined ? curAccount[name] : ''}
                       onChange={(e) => {
                           if (setSimilarAccountList !== null) {
                               similarAccountList = similarAccountList.filter((el) => el.propertyId !== curAccount[name]);
                               setSimilarAccountList(similarAccountList);
                           }
                           curAccount[name] = e.target.value;
                           certificate.propertyIds[showAccount] = {
                               id: e.target.value,
                               visibility: true
                           };
                           curAccount['taxDataSource'] = 'EDITOR';
                           setIsSavedResult(false);
                           updatedAccountIndexList.push(showAccount);
                           setAccounts([...accounts]);
                       }} readOnly={!editable}/>
            }
        </>
    )
}


export const exemptionsList = (itemMap, label, value, placeholderText, isMulti, isRequired, curAccount, i, setProductTypeInvisible, setIsSavedResult, showAccount, updatedAccountIndexList) => {

    let assessment = curAccount?.assessments[i];


    const options = itemMap && Array.isArray(itemMap) && itemMap?.map(item => {
        return {
            value: value instanceof Function ? value(item) : item[value],
            label: label instanceof Function ? label(item) : item[label]
        }
    });

    const handleChange = (value) => {
        assessment.exemptions = getPrimitiveArray(value, "value");
        setIsSavedResult(false);
        updatedAccountIndexList.push(showAccount);
        setProductTypeInvisible(Date.now())
    };

    const Select = props => (
        <FixRequiredSelect
            {...props}
            SelectComponent={BaseSelect}
            options={props.options || options}
        />
    );

    return (
        <Select
            name={"stateName"}
            classNamePrefix={"select"}
            isClearable
            isMulti={isMulti}
            options={options}
            placeholder={placeholderText}
            title={assessment?.exemptions}
            onChange={
                handleChange
            }
            isDisabled={!options || !options.length}
            value={getObjectiveArray(assessment?.exemptions)}
            required={isRequired}
        />
    )
}

export function calculateTotalTaxValue(certificate, showAccount, setRender) {
    certificate.accounts[showAccount].taxRateTotal = getUniqueLatestYearJurisdictions(certificate.accounts[showAccount].jurisdictionList).reduce(function (prev, current) {

        if (current.tax_rate !== '' && current.tax_rate !== null && prev !== null) {
            return prev + parseFloat(current.tax_rate);
        } else if (current.tax_rate === '' || current.tax_rate === null || prev === null) {
            return null;
        } else {
            return prev;
        }
    }, 0);


    recalculateAssessment(certificate.accounts[showAccount].assessments[0], certificate.accounts[showAccount].assessments[1], showAccount, certificate.accounts[showAccount], setRender).then();

}

export function autoCalculateSummaryRow(certificate, setCertificate, showAccount) {
    for (let i = 0; i < certificate.accounts[showAccount].summaryList.length; i++) {
        let list = certificate.accounts[showAccount].jurisdictionList.filter(obj => {
                if (obj.collectorName === certificate.accounts[showAccount].summaryList[i]['collector'] && parseInt(obj.bill_year) === parseInt(certificate.accounts[showAccount].summaryList[i]['taxYear'])) {
                    return obj;
                }
            }
        );
        if (list.length > 0) {
            updateSummaryRowInfo(certificate.accounts[showAccount], list, i);
            setCertificate((certificate) => {
                return certificate
            });
        }
    }
    updateSummaryTotalRowCalculation(certificate.accounts[showAccount], certificate.accounts[showAccount].summaryList);
}

export function updateSummarySectionRow(certificate, showAccount, jurisdictionId, taxYear, setCertificate) {
    let account = certificate.accounts[showAccount];
    let accountSummaryList = account.summaryList[0];
    let jurisdiction = account.jurisdictionList[jurisdictionId];

    if (account.summaryList.length === 1 && accountSummaryList['taxYear'] === '') {
        accountSummaryList['taxYear'] = taxYear === '' ? jurisdiction['bill_year'] : taxYear;
        accountSummaryList['collector'] = jurisdiction['collectorName'];
        accountSummaryList['collectorAccountNumber'] = jurisdiction['collectorAccountNumber'];
        accountSummaryList['asOfDate'] = jurisdiction['asOfDate'];

        accountSummaryList['baseTax'] = jurisdiction['baseTax'];
        accountSummaryList['baseTaxDue'] = jurisdiction['baseDue'];
        accountSummaryList['currentMonthDue'] = jurisdiction['currentMonthDue'];
        accountSummaryList['nextMonthTaxDue'] = jurisdiction['nextMonthTaxDue'];

        setCertificate(certificate);

    } else {

        let filterList = account.summaryList.filter((v) => v.collector === jurisdiction['collectorName'] && parseInt(v.taxYear) === parseInt(taxYear));

        if (filterList.length === 0) {

            account.summaryList = ([...account.summaryList, {
                collector: jurisdiction['collectorName'],
                collectorAccountNumber: jurisdiction['collectorAccountNumber'],
                taxYear: taxYear,
                baseTax: jurisdiction['baseTax'],
                baseTaxDue: jurisdiction['baseDue'],
                currentMonthDue: jurisdiction['currentMonthDue'],
                nextMonthTaxDue: jurisdiction['nextMonthTaxDue'],
                asOfDate: jurisdiction['asOfDate']
            }]);
        }

        setCertificate(certificate);

        for (let i = 0; i < account.summaryList.length; i++) {

            let list = account.summaryList;

            let tempPer = account.jurisdictionList.filter(obj => obj.collectorName === list[i]['collector'] && parseInt(obj.bill_year) === parseInt(list[i]['taxYear']));

            if (tempPer.length === 0) {

                let index1 = account.summaryList.findIndex(obj => obj.collector === list[i]['collector'] && parseInt(obj.taxYear) === parseInt(list[i]['taxYear']));

                if (index1 !== -1) {

                    account.summaryList = account.summaryList.filter((v, i) => {
                        return i !== index1;
                    });

                    setCertificate(certificate);
                }

            }
        }
    }
}

export function getProductIcon(type) {

    if (type.includes("TAX_") === true) {
        return "fa-2x fa-scroll fas mr-3 notClickable";
    } else if (type.includes("HOA_") === true) {
        return "fas fa-house-flag fa-2x notClickable";
    } else if (type.includes("NTP") === true) {
        return "fa-solid fa-gavel fa-2x mr-3 notClickable";
    } else {
        return "fa-2x fa-scroll fas mr-3 notClickable";
    }
}


export const jurisdictionHead = (setShowJurisdictionPickerModal, editable) => {
    return (
        <>
            <thead>
            <tr>
                <th scope="col" className="text-center"><i className="fa fa-plus-circle onHover"
                                                           onClick={() => {
                                                               if (editable)
                                                                   setShowJurisdictionPickerModal(true);
                                                           }}
                                                           title={"Add Jurisdiction"}/></th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Collector*</a>
                </th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Jurisdiction*</a></th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Bill Year</a>
                </th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Tax Rate</a>
                </th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Base Tax</a>
                </th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Base Due</a>
                </th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Due {formatValueAsDate(moment(), "MM/YY")}</a>
                </th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Due {formatValueAsDate(moment().add(1, 'months'), "MM/YY")}</a>
                </th>

                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Source</a>
                </th>

            </tr>
            </thead>
        </>
    )
}
export const BondHead = (setAddedNewBond, editable) => {
    return (
        <>
            <thead>
            <tr>
                <th scope="col"><i className="fa fa-plus-circle onHover"
                                   onClick={() => {
                                       if (editable)
                                           setAddedNewBond(true)
                                   }} title={"Add Bond"}/>
                </th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Jurisdiction Name</a>
                </th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Bonds Approved</a></th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Bonds Issued</a>
                </th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Standby Fees</a>
                </th>
            </tr>
            </thead>
        </>
    )
}

export const SuitsHead = (setAddedNewSuit, editable) => {
    return (
        <>
            <thead>
            <tr>
                <th scope="col"><i className="fa fa-plus-circle onHover"
                                   onClick={() => {
                                       if (editable)
                                           setAddedNewSuit(true)
                                   }} title={"Add Tax Suit"}/>
                </th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Payee</a>
                </th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Suit Number</a></th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Parcel</a>
                </th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Fee Type</a>
                </th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Amount</a>
                </th>
                <th scope="col" className="text-center"><a
                    className="grid-color toggle-data">Balance</a>
                </th>
            </tr>
            </thead>
        </>
    )
}

export function getUniqueFeeListAndNote(el, feeList, note, certificateFees, certificateNotes, setIsSavedResult) {

    let noteList = [];

    certificateFees.map(f => {

        let isFeeListHaveNotNullValue = Object.values(f).every(value => {
            return !(value !== null && value !== '' && value !== false && value !== true);
        });

        if (!isFeeListHaveNotNullValue && f.associationFee !== true) {
            feeList.push(f);
        }

    });

    if (el.fees != null && el.fees.length > 0) {

        el.fees.map((fl) => {

            const found = feeList.find(obj => {
                return obj.type === fl.feeType && obj.amount === fl.feeAmount && obj.periodStart === fl.assessmentPeriodStart && obj.periodEnd === fl.assessmentPeriodEnd && obj.frequency === fl.frequency &&
                    obj.note === fl.note;
            });

            if (!found) {
                feeList.push(
                    {
                        "type": fl.feeType,
                        "amount": fl.feeAmount,
                        "balance": fl.feeAmount,
                        "periodStart": fl.assessmentPeriodStart,
                        "periodEnd": fl.assessmentPeriodEnd,
                        "frequency": fl.frequency,
                        "note": fl.note,
                        "associationFee": true
                    }
                )
                setIsSavedResult(false);
            }
        });
    } else {
        if (feeList.length > 0) {
            feeList.filter(el => el.associationFee === true);
        }
    }

    certificateNotes.map((nl) => {
        if (nl.comment !== "Voluntary HOA") {
            noteList.push({
                comment: nl.comment,
                associationNote: nl.associationNote,
                redColor: nl.redColor,
                yellowHighlight: nl.yellowHighlight,
                bold: nl.bold,
                saveAsCanned: nl.saveAsCanned
            })
        }
    });


    if (el.certificateNotes != null && el.certificateNotes.length > 0) {
        el.certificateNotes.map((n) => {

            const found = noteList.find(obj => {
                return obj.comment === n.comment;
            });

            if (!found && n.visible === true) {
                noteList.push({
                    comment: n.comment,
                    associationNote: true,
                    redColor: n.redColor,
                    yellowHighlight: n.yellowHighlight,
                    bold: n.bold,
                    saveAsCanned: n.saveAsCanned
                });
                setIsSavedResult(false);
            }

        });
    } else {
        if (noteList.length > 0) {
            noteList.filter(el => el.associationNote === true);
        }
    }

    if (feeList.length === 0) {
        feeList.push({
            "type": "",
            "amount": null,
            "balance": null,
            "periodStart": null,
            "periodEnd": null,
            "frequency": "",
            "note": "",
            "associationFee": false
        });
    }

    if (el.voluntary === true) {
        const found = noteList.find(obj => {
            return obj.comment === "Voluntary HOA";
        });
        if (!found) {
            noteList.push({
                comment: "Voluntary HOA",
                associationNote: true
            });
        }
    }

    if (noteList.length > 0) {

        noteList.map(kl => {
            if (!(el.certificateNotes != null && el.certificateNotes.length > 0 && el.certificateNotes.some(pl => pl.noteBody === kl.comment && pl.visible === false) === true)) {
                note.push(kl);
            }
        });
    }

}

export function PopulateHoaInfo(associationName, managementCompanyName, managementCompanyId, email, phone, mailingAddress, zipCode, city, state, contactName, certificate, showAccount, setIsSavedResult, el, website, thirdPartyProviderId, thirdPartyProviderName, fax) {


    if (associationName !== certificate.accounts[showAccount]['associationName']) {
        certificate.accounts[showAccount]['associationName'] = el['name'];
        setIsSavedResult(false);
    }

    if (managementCompanyName !== certificate.accounts[showAccount]['managementCompany']['name']) {
        certificate.accounts[showAccount]['managementCompany']['name'] = managementCompanyName;
        setIsSavedResult(false);
    }

    if (managementCompanyId !== certificate.accounts[showAccount]['managementCompany']['managementCompanyId']) {
        certificate.accounts[showAccount]['managementCompany']['managementCompanyId'] = managementCompanyId;
        setIsSavedResult(false);
    }

    if (email !== certificate.accounts[showAccount]['managementCompany']['email']) {
        certificate.accounts[showAccount]['managementCompany']['email'] = email;
        setIsSavedResult(false);
    }

    if (phone !== certificate.accounts[showAccount]['managementCompany']['phone']) {
        certificate.accounts[showAccount]['managementCompany']['phone'] = phone;
        setIsSavedResult(false);
    }

    if (fax !== certificate.accounts[showAccount]['managementCompany']['fax']) {
        certificate.accounts[showAccount]['managementCompany']['fax'] = fax;
        setIsSavedResult(false);
    }

    if (contactName !== certificate.accounts[showAccount]['managementCompany']['contactName']) {
        certificate.accounts[showAccount]['managementCompany']['contactName'] = contactName;
        setIsSavedResult(false);
    }

    if (mailingAddress !== certificate.accounts[showAccount]['managementCompany']['address']['mailingAddress']) {
        certificate.accounts[showAccount]['managementCompany']['address']['mailingAddress'] = mailingAddress;
        setIsSavedResult(false);
    }

    if (zipCode !== certificate.accounts[showAccount]['managementCompany']['address']['zipCode']) {
        certificate.accounts[showAccount]['managementCompany']['address']['zipCode'] = zipCode;
        setIsSavedResult(false);
    }

    if (city !== certificate.accounts[showAccount]['managementCompany']['address']['city']) {
        certificate.accounts[showAccount]['managementCompany']['address']['city'] = city;
        setIsSavedResult(false);
    }

    if (state !== certificate.accounts[showAccount]['managementCompany']['address']['state']) {
        certificate.accounts[showAccount]['managementCompany']['address']['state'] = state;
        setIsSavedResult(false);
    }

    if (website !== certificate.accounts[showAccount]['managementCompany']['website']) {
        certificate.accounts[showAccount]['managementCompany']['website'] = website;
        setIsSavedResult(false);
    }

    if (thirdPartyProviderId !== certificate.accounts[showAccount]['thirdPartyProviderId']) {
        certificate.accounts[showAccount]['thirdPartyProviderId'] = thirdPartyProviderId;
        setIsSavedResult(false);
    }

    if (thirdPartyProviderName !== certificate.accounts[showAccount]['thirdPartyProviderName']) {
        certificate.accounts[showAccount]['thirdPartyProviderName'] = thirdPartyProviderName;
        setIsSavedResult(false);
    }


}

export const summaryHeadDefault = () => {
    return (
        <>
            <thead>
            <tr>
                <th scope="col" className="text-center">Collector
                </th>
                <th scope="col" className="text-center">Account Number
                </th>
                <th scope="col" className="text-center">Tax Year</th>
                <th scope="col" className="text-center">Base Tax
                </th>
                <th scope="col" className="text-center">Base Due
                </th>
                <th scope="col" className="text-center">If Paid By
                    {' ' + new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    }).format(currentMonthLastDate)}</th>
                <th scope="col" className="text-center">If Paid By
                    {' ' + new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    }).format(nextMonthLastDate)}</th>

                <th scope="col" className="text-center">As of Date
                </th>
            </tr>


            </thead>
        </>
    )
}

export function bondExist(bondList, jurisdictionId) {
    return bondList.filter(el => {
            if (el.jurisdictionId === jurisdictionId && el.jurisdictionId !== '') {
                return el;
            }
        }
    )
}

export const jurisdictionYearFields = (row, setJurisdictionId, p, curAccount, setAccounts, accounts, setUpdateTotalTaxValue, setTaxYear, setIsAddNewSummaryRow, name, disabled, setIsSavedResult, editable, setAutoCalculate) => {
    let style = 'border-0 form-control pr-0 text-center selector1';
    style = style + (row['placeHolder'] && row['name'] ? ' place_holder' : '');
    style = style + (!row['name'] ? ' place_holder_data' : '');

    return (
        <>
            <input
                className={style}
                placeholder={!row[name] ? "N/A" : 'Year...'}
                type='number'
                name='bill_year'
                min={minYear}
                max={maxYear}
                value={row[name]}
                onChange={(e) => {
                    row["placeHolder"] = false;
                    setJurisdictionId(p);
                    setIsSavedResult(false);
                    validateYear(e, p, name, curAccount.jurisdictionList, setUpdateTotalTaxValue, setTaxYear, setJurisdictionId, setIsAddNewSummaryRow);
                    calculateAsOfDate(row);
                    setAutoCalculate(true);
                    setAccounts([...accounts]);
                }}
                readOnly={disabled || !editable}
            />
        </>
    )
}


const validateYear = (e, index, name, listType, setUpdateTotalTaxValue, setTaxYear, setJurisdictionId, setIsAddNewSummaryRow) => {
    if (e.target.value.length > 4) {
        listType[index][name] = e.target.value.slice(0, 4);
        e.target.value = listType[index][name];
    }

    if (e.target.value.length === 4) {
        if (!(e.target.value > minYear && e.target.value <= maxYear)) {
            listType[index][name] = e.target.value.slice(0, 0);
            e.target.value = listType[index][name];
        } else {
            listType[index][name] = e.target.value;
            if (name === 'bill_year') {
                let result = listType[index]['tax_rate_List'].filter(value1 => value1.year.toString() === e.target.value);
                if (result.length > 0 && listType[index]['nonCollecting'] === false) {
                    listType[index]['tax_rate'] = result[0]['rate'];
                } else {
                    listType[index]['tax_rate'] = '';
                }
                // Total Tax Rate Update////
                setUpdateTotalTaxValue(true);
            }
            e.target.value = listType[index][name];
        }
    } else {
        listType[index][name] = e.target.value;
        e.target.value = listType[index][name];
    }

    if (name === 'bill_year' && e.target.value.length === 4) {
        setTaxYear(e.target.value);
        setJurisdictionId(index)
        setIsAddNewSummaryRow(true);
    }
}


export const countDecimals = (str1) => {
    let str = str1.toString();
    if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
        return str.split("-")[1] || 0;
    } else if (str.indexOf(".") !== -1) {
        return str.split(".")[1].length || 0;
    }
    return str.split("-")[1] || 0;
}


const validateAssessmentYear = (e, curAssessment, index, name, curAccount) => {
    if (e.target.value.length > 4) {
        curAssessment[name] = e.target.value.slice(0, 4);
        e.target.value = curAssessment[name];
    } else if (e.target.value.length === 4) {
        curAccount[name] = e.target.value;
        if (index === 0) {
            if (curAccount?.assessments[1][name].toString() === e.target.value.toString()) {
                curAssessment[name] = e.target.value.slice(0, 0);
                e.target.value = curAssessment[name];
            } else {
                curAssessment[name] = e.target.value;
                e.target.value = curAssessment[name];
            }
        } else if (index === 1) {
            if (curAccount?.assessments[0][name].toString() === e.target.value.toString()) {
                curAssessment[name] = e.target.value.slice(0, 0);
                e.target.value = curAssessment[name];
            } else {
                curAssessment[name] = e.target.value;
                e.target.value = curAssessment[name];
            }
        } else {
            curAssessment[name] = e.target.value;
            e.target.value = curAssessment[name];
        }
    } else {
        curAssessment[name] = e.target.value;
        e.target.value = curAssessment[name];
    }
}

export async function recalculateAssessment(obj1, obj2, i, curAccount, setRender) {

    try {
        populateUpdatedJurisdiction(curAccount);
        const promise1 = axios.post(`/editor/auto-calculated-values/assessedValue`, obj1) //use data destructuring to get data from the promise object
        const promise2 = axios.post(`/editor/auto-calculated-values/assessedValue`, obj2) //use data destructuring to get data from the promise object
        const promise3 = axios.post(`/editor/auto-calculated-values/appraisedValue`, obj1) //use data destructuring to get data from the promise object
        const promise4 = axios.post(`/editor/auto-calculated-values/appraisedValue`, obj2) //use data destructuring to get data from the promise object
        const promise5 = axios.post(`/editor/auto-calculated-values/agriculturalValue`, obj1) //use data destructuring to get data from the promise object
        const promise6 = axios.post(`/editor/auto-calculated-values/agriculturalValue`, obj2) //use data destructuring to get data from the promise object
        const promise7 = axios.post(`/editor/auto-calculated-values/landTotal`, obj1) //use data destructuring to get data from the promise object
        const promise8 = axios.post(`/editor/auto-calculated-values/landTotal`, obj2) //use data destructuring to get data from the promise object

        Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8]).then(function (res) {
            curAccount.assessments[0].assessedValue = res[0].data;
            curAccount.assessments[1].assessedValue = res[1].data;
            curAccount.assessments[0].appraisedValue = res[2].data;
            curAccount.assessments[1].appraisedValue = res[3].data;
            curAccount.assessments[0].agriculturalValue = res[4].data;
            curAccount.assessments[1].agriculturalValue = res[5].data;
            curAccount.assessments[0].landTotalValue = res[6].data;
            curAccount.assessments[1].landTotalValue = res[7].data;
            setRender(new Date().getMilliseconds());

            return res.data;
        });

    } catch (error) {
        console.log(error);
    }
}

export async function calculateAgriculturalValue(obj1, setRender) {
    try {
        const promise1 = axios.post(`/editor/auto-calculated-values/agriculturalValue`, obj1)

        Promise.all([promise1]).then(function (res) {
            obj1.agriculturalValue = res[0].data;
            setRender(new Date().getMilliseconds());
            return res.data;
        });

    } catch (error) {
        console.log(error);
    }
}

export async function calculateLandTotalValue(obj1, setRender) {
    try {
        const promise1 = axios.post(`/editor/auto-calculated-values/landTotal`, obj1)

        Promise.all([promise1]).then(function (res) {
            obj1.landTotalValue = res[0].data;
            setRender(new Date().getMilliseconds());
            return res.data;
        });

    } catch (error) {
        console.log(error);
    }
}

export async function calculateAssessedValue(obj1, setRender) {
    try {
        const promise1 = axios.post(`/editor/auto-calculated-values/assessedValue`, obj1)

        Promise.all([promise1]).then(function (res) {
            obj1.assessedValue = res[0].data;
            setRender(new Date().getMilliseconds());
            return res.data;
        });

    } catch (error) {
        console.log(error);
    }
}


export async function calculateCurrentWoExemptAmountValue(curAccount, obj, setRender) {

    try {
        populateUpdatedJurisdiction(curAccount);

        const promise = axios.post(`/editor/auto-calculated-values/currentWoExemptAmount/${obj.taxYear ? obj.taxYear : 0}`, curAccount)

        Promise.all([promise]).then(function (res) {
            obj.woExemptionAmount = res[0].data;
            setRender(new Date().getMilliseconds());
            return res.data;
        });

    } catch (error) {
        console.log(error);
    }
}


export async function calculateAppraisedValue(obj1, setRender) {
    try {
        const promise1 = axios.post(`/editor/auto-calculated-values/appraisedValue`, obj1)

        Promise.all([promise1]).then(function (res) {
            obj1.appraisedValue = res[0].data;
            setRender(new Date().getMilliseconds());
            return res.data;
        });

    } catch (error) {
        console.log(error);
    }


}

export function calculateAsOfDate(row) {
    row['asOfDate'] = new Date();
    row['taxDataSource'] = 'EDITOR';
}


export function sortByKeysPriority(jurisdictionList, sortingKeyArrayInOrder, order) {
    return _.orderBy(jurisdictionList, sortingKeyArrayInOrder, order);
}

const billTypeOrder = [
    'ANNUAL',
    'FIRST_HALF',
    'FIRST_QUARTER',
    'SUMMER',
    'SECOND_QUARTER',
    'SECOND_HALF',
    'THIRD_QUARTER',
    'WINTER',
    'FOURTH_QUARTER'
];

export function sortByYearAndLevyPeriod(jurisdictionList) {
    return _.orderBy(
        jurisdictionList,
        [
            'taxYear',  // First sort by Year (DESC)
            (item) => billTypeOrder.indexOf(item.levyPeriod) // Then sort by levyPeriod (using custom order)
        ],
        ['desc', 'asc'] // 'desc' for taxYear, 'asc' for levyPeriod (because the predefined order is ascending)
    );
}


export function sortAssessmentList(list, sortingKeyArrayInOrder, order) {
    return _.orderBy(list, sortingKeyArrayInOrder, order);
}

export function commonSortingList(list, sortingKeyArrayInOrder, order) {
    return _.orderBy(list, sortingKeyArrayInOrder, order);
}

export function convertLowerCase(value) {
    if (value != null && value !== '') {
        return value.toLowerCase();
    }
    return value != null ? value : "";
}


export function sortCollectorSummaryList(summary, sortingKeyArrayInOrder, order) {
    return _.orderBy(summary, sortingKeyArrayInOrder, order);
}


export function getTaxRateYearValue(list, taxYear) {
    if (list.length > 0) {
        let data = list.filter(el => el.year === taxYear);
        if (data.length > 0) {
            return data[0].rate;
        } else {
            return null;
        }
    }
    return null;
}


export function sortTaxSuits(taxSuits) {
    return []
        .concat(...taxSuits)
        .sort((a, b) => convertLowerCase(a.payee).localeCompare(convertLowerCase(b.payee)));
}


export function sortSelectedJurisdiction(list) {
    return []
        .concat(...list)
        .sort((a, b) => convertLowerCase(a.name).localeCompare(convertLowerCase(b.name)));
}

export function sortCadInfo(cadInfo) {
    return []
        .concat(...cadInfo)
        .filter(ci => ci && ci.taxingUnit)
        .sort((a, b) => a.taxingUnit.localeCompare(b.taxingUnit));
}


export function computeBuyerSellerNote(buyerName, sellerName) {

    if ((sellerName == null || sellerName === "") && (buyerName == null || buyerName === "")) {
        return null;
    } else if (buyerName != null && buyerName !== "" && sellerName != null && sellerName !== "") {
        return "BUYER: " + formatBuyerSellerNames(buyerName) + "\t\t\t\t\tSELLER: " + formatBuyerSellerNames(sellerName);
    } else if (buyerName != null && buyerName !== "" && (sellerName == null || sellerName === "")) {
        return "BUYER: " + formatBuyerSellerNames(buyerName) + "\t\t\t\t\tSELLER: " + '-'
    } else {
        return "BUYER: " + '-' + "\t\t\t\t\tSELLER: " + formatBuyerSellerNames(sellerName);
    }
}

export function computePropertyAddressNote(orderData, certificate) {
    let includeOriginalPropertyNote = orderData.items?.branchDTO?.includeOriginalPropertyNote;
    if (certificate.accounts !== undefined && certificate.accounts !== "" && certificate.accounts !== null && certificate.accounts.length > 0 && includeOriginalPropertyNote) {
        let isSitusNotStartsWithNumber = _.some(certificate.accounts, (obj) => {
            const firstChar = _.get(obj, "situsAddress", '')[0]?.trim();
            return isNaN(parseInt(firstChar));
        });
        return isSitusNotStartsWithNumber && includeOriginalPropertyNote ? "P/A " + orderData?.items?.properties[0].addressLine1 : null;
    }
}

export function isAgExemptionExist(curAccount, index) {
    return curAccount?.assessments[index]?.exemptions !== null && curAccount?.assessments[index]?.exemptions.includes("AG") === true;
}

export function removeDuplicateAndNegativeItems(arr) {
    arr = arr.filter(function (x) {
        return x > -1
    });
    return [...new Set(arr)];
}

export function yearArray(startYear, endYear) {

    const endDate = endYear;
    let years = [];

    for (let i = startYear; i <= endDate; i++) {
        years.push(startYear);
        startYear++;
    }
    return years;
}

export function populateUpdatedJurisdiction(curAccount) {
    let jurisdictions = [];

    curAccount?.jurisdictionList?.map((el) => {

        let levies = [];
        let bonds = curAccount?.bondList.filter(obj => obj.jurisdictionId === el.jurisdictionId);
        let index = jurisdictions.findIndex(obj => obj.collectorId === el.collectorId && obj.jurisdictionId === el.jurisdictionId);
        let summary = curAccount?.summaryList.filter(obj => obj.collector === el.collectorName && obj.taxYear === el.bill_year);
        let jurisdictionId = el.jurisdictionId;
        let jurisdictionName = el.jurisdictionName;
        let collectorId = el.collectorId;
        let collectorAccountNumber = summary.length > 0 ? summary[0].collectorAccountNumber : null;

        if ((jurisdictionId != null && jurisdictionId !== "" && collectorId != null && collectorId !== "" && el.placeHolder === false) || el.placeHolder === true) {

            let billYear = el.bill_year;
            let baseDue = el.baseDue;
            let baseTax = el.baseTax;
            let taxRate = el.tax_rate != null && el.tax_rate !== '' ? parseFloat(el.tax_rate.toString().replaceAll(',', '')) : el.tax_rate;
            let asOfDate = el.asOfDate;
            let taxDataSource = el.taxDataSource;
            let currentMonthDue = el.currentMonthDue;
            let nextMonthDue = el.nextMonthTaxDue;
            let inActive = el.inActive;
            let nonCollecting = el.nonCollecting;
            let placeHolder = el.placeHolder;

            if (index === -1) {
                let obj = {
                    billYear, taxRate, baseTax, baseDue, currentMonthDue, nextMonthDue
                }
                levies.push(obj)
                jurisdictions.push({
                    jurisdictionId,
                    jurisdictionName,
                    collectorAccountNumber,
                    asOfDate,
                    taxDataSource,
                    collectorId,
                    levies,
                    bonds,
                    placeHolder,
                    inActive,
                    nonCollecting
                })
            } else {

                let temp1 = levies.findIndex(obj => obj.taxYear === el.bill_year);

                if (temp1 !== -1) {
                    levies[temp1] = {
                        billYear, taxRate, baseTax, baseDue, currentMonthDue, nextMonthDue
                    }
                } else {

                    let obj = {
                        billYear, taxRate, baseTax, baseDue, currentMonthDue, nextMonthDue
                    }

                    levies.push(...jurisdictions[index].levies, obj);
                }

                jurisdictions[index] = {
                    jurisdictionId,
                    jurisdictionName,
                    collectorAccountNumber,
                    asOfDate,
                    taxDataSource,
                    collectorId,
                    levies,
                    bonds,
                    placeHolder,
                    inActive,
                    nonCollecting
                }
            }
        }

    });

    if (curAccount !== undefined) {
        curAccount.jurisdictions = jurisdictions;

    }
}

/**************Data table search form array all common component code and grid ends here***********/



