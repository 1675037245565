import axios from "axios";
import {showErrorToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";


//export actions
export const forgotPasswordActions = {
    forgotPasswordAction,
    tokenValidationAction,
    resetPasswordAction,
};


//export constants
export const forgotPasswordConstants = {

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

    TOKEN_VALIDATION_REQUEST: "TOKEN_VALIDATION_REQUEST",
    TOKEN_VALIDATION_SUCCESS: "TOKEN_VALIDATION_SUCCESS",
    TOKEN_VALIDATION_FAILURE: "TOKEN_VALIDATION_FAILURE",

    PASSWORD_RESET_REQUEST: 'PASSWORD_RESET_REQUEST',
    PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
    PASSWORD_RESET_FAILURE: 'PASSWORD_RESET_FAILURE',

};

//export service
export const forgotPasswordService = {
    forgotPassword,
    tokenValidation,
    resetPassword,
};

/************************************* forgot Actions Starts here  ******************************************/

//forgot password action
export function forgotPasswordAction(email, setDisable = null, setShowRestPasswordModal = null) {
    return dispatch => {
        dispatch(request());
        forgotPasswordService.forgotPassword(email)
            .then(
                email => {
                    if (email['errorCode'] === 200) {
                        dispatch(success(email));
                        showSuccessToast("A password reset email has been sent to the specified email address, if there is a user associated with it");
                    } else {
                        responseDTOExceptionHandler(email);
                    }
                        if (setDisable == null) {
                            history.push("login");
                        } else {
                            setDisable(false);
                            setShowRestPasswordModal(false);
                        }
                     },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: forgotPasswordConstants.FORGOT_PASSWORD_REQUEST}
    }

    function success(email) {
        return {type: forgotPasswordConstants.FORGOT_PASSWORD_SUCCESS, email}
    }
    function failure(error) {
        return {type: forgotPasswordConstants.FORGOT_PASSWORD_SUCCESS, error}
    }
}


//token validation Action
export function tokenValidationAction(obj) {
    return dispatch => {
        dispatch(request());
        forgotPasswordService.tokenValidation(obj)
            .then(
                response => {
                    if (response['errorCode'] === 200) {
                        dispatch(success(response));

                        if (response['tokenExpiredDTO']['sessionFlag'] === false) {
                            const email = response['tokenExpiredDTO']['userEmail'];
                            history.push({pathname: '/forgot-password-check', state: {email: email}});
                        } else {
                            console.log("allowed to change password");
                        }
                    } else {
                        showErrorToast(response['message']);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: forgotPasswordConstants.TOKEN_VALIDATION_REQUEST}
    }

    function success(res) {
        return {type: forgotPasswordConstants.TOKEN_VALIDATION_SUCCESS, res}
    }

    function failure(error) {
        return {type: forgotPasswordConstants.TOKEN_VALIDATION_FAILURE, error}
    }
}

//reset password action
export function resetPasswordAction(obj) {
    return dispatch => {
        dispatch(request());
        forgotPasswordService.resetPassword(obj)
            .then(
                res => {
                    if (res['errorCode'] === 200) {
                        showSuccessToast("Password Reset Successfully!");
                        dispatch(success(res));
                        history.push("/login");
                    } else {
                        showErrorToast(res['message']);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: forgotPasswordConstants.PASSWORD_RESET_REQUEST}
    }

    function success(res) {
        return {type: forgotPasswordConstants.PASSWORD_RESET_SUCCESS, res}
    }

    function failure(error) {
        return {type: forgotPasswordConstants.PASSWORD_RESET_FAILURE, error}
    }
}

{/***************************** forgot Actions Ends here **********************************/
}


{/****************** Reducers starts here. **************************************/
}

//validate token reducer
export function validateTokenReducer(state = {}, action) {
    switch (action.type) {
        case forgotPasswordConstants.TOKEN_VALIDATION_REQUEST:
            return {};
        case forgotPasswordConstants.TOKEN_VALIDATION_SUCCESS:
            return {
                items: action.res
            };
        case forgotPasswordConstants.TOKEN_VALIDATION_FAILURE:
            return {};
        default:
            return state
    }
}

{/*************************** Reducers ends here. **************************************/
}


{/******************************forgot Services starts here  ****************************************/
}

//get reset password link in email API
function forgotPassword(email) {
    return axios.post(`/ajax/forgot-password/${email}`)
        .then((response) => {
            return response.data
        }).catch((error) => {
            console.log(error)
        })

}


//Validate Token API and id
function tokenValidation(obj) {
    return axios.post(`/ajax/validate-token`,obj)
        .then((response) => {
            return response.data
        }).catch((error) => {
            console.log(error)
        })

}

//reset Password API
function resetPassword(obj) {
    return axios.post(`/ajax/reset-password`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error)
        })
}

{/******************************forgot Services ends here  ****************************************/
}




