import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import Preloader from "../../../../common/components/Preloader";
import {cannedNotesActions} from "../cannedNotes";
import CannedNotesFormFields from "./CannedNotesFormFields";
import {isUserHaveAccess, useAuthorities} from "../../../../common/helpers/utils";


const CannedNotesDetailsPage = () => {

    const dispatch = useDispatch();
    const authorities = useAuthorities();

    const [crumbs] = useState([
            {content: "Home", url: "/home"},
            {content: "Canned Notes", url: "/canned-notes"},
            {content: "Update Canned Notes", url: ""}
        ]
    )

    let {cannedId} = useParams();
    const connedNotesData = useSelector(state => state.getCannedNoteByIdReducer);

    useEffect(() => {
        dispatch(cannedNotesActions.getCannedNoteByIdAction(cannedId));
    }, []);

    return (
        <>
            {connedNotesData.items === undefined || connedNotesData?.items?.id !== cannedId?
                <Preloader props={connedNotesData}/>
                :
                <CannedNotesFormFields formType={"Update"} cannedId={cannedId} crumbs={crumbs}
                                       connedNotesData={connedNotesData} isReadOnly={isUserHaveAccess(authorities,["CANNOTE-U"]) === false}/>
            }
        </>
    );

};

export default CannedNotesDetailsPage;